import { useEffect } from "react";

export const usePreventAccidentalPageLeave = isDirty => {
  useEffect(() => {
    const message = "Are you sure you want to leave?";
    const beforeUploadHandler = event => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message;
      }
    };
    const beforePageChangeHandler = event => {
      if (isDirty && !confirm(message)) {
        event.preventDefault();
      }
    };
    window.addEventListener("beforeunload", beforeUploadHandler);
    document.addEventListener(
      "turbolinks:before-visit",
      beforePageChangeHandler,
    );
    return () => {
      window.removeEventListener("beforeunload", beforeUploadHandler);
      document.removeEventListener(
        "turbolinks:before-visit",
        beforePageChangeHandler,
      );
    };
  }, [isDirty]);
};
