import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { communityBillingPath } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { Button } from "@circle-react-uikit/Button";

interface PlanLimitExceededLiveLocationProps {
  event: Event;
}

export const PlanLimitExceededLiveLocation = ({
  event,
}: PlanLimitExceededLiveLocationProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const isMemberOwner =
    event?.community_member_id == currentCommunityMember?.id;

  const isMemberAtendee = !isAdmin && !isMemberOwner;
  const limitExceedMessage = isAdmin
    ? t(`events.locations.limit_exceeded.admin_message`)
    : t(`events.locations.limit_exceeded.non_admin_owner_message`);
  const handleUpgradeClick = () => {
    const url = communityBillingPath();
    window.open(url, "_blank");
  };

  return (
    <div className="flex flex-col">
      {isAdmin && (
        <div className="my-2">
          <Button variant="primary" onClick={handleUpgradeClick}>
            {t(`events.locations.limit_exceeded.upgrade`)}
          </Button>
        </div>
      )}
      {(isAdmin || isMemberOwner) && (
        <Typography.BodySm>{limitExceedMessage}</Typography.BodySm>
      )}
      {isMemberAtendee && (
        <Typography.BodySm>
          {t(`events.locations.limit_exceeded.member_message`)}
        </Typography.BodySm>
      )}
    </div>
  );
};
