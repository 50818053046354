import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  reactQueryDelete,
  reactQueryGet,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const REWARDS_QUERY_KEY = "rewards";
export interface RewardLogEntry {
  id: number;
  points: number;
  reason: string;
  event_name:
    | "gamification.member.points_awarded"
    | "gamification.member.points_revoked";
  awarded_by: {
    name: string;
    avatar_url: string;
  };
  revoked: boolean;
  triggered_at: string;
}

export interface RewardLogResponse {
  count: number;
  page: number;
  per_page: number;
  has_next_page: boolean;
  records: RewardLogEntry[];
}

export const useRewardsLog = (memberId: number) => {
  const queryKey = [REWARDS_QUERY_KEY, memberId];

  const fetchRewards = (page: number) =>
    reactQueryGet(
      internalApi.gamification.pointAwards({
        params: { member_id: memberId, page },
      }),
    );

  const infiniteRewardsQuery = useInfiniteQuery<RewardLogResponse>(
    queryKey,
    ({ pageParam = 1 }) => fetchRewards(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const rewards = useMemo(
    () =>
      infiniteRewardsQuery.isLoading
        ? []
        : uniqBy(
            flatten(
              infiniteRewardsQuery.data?.pages.map(page => page.records) ?? [],
            ),
            reward => `${reward.id}-${reward.event_name}`,
          ),
    [infiniteRewardsQuery.data],
  );
  return { ...infiniteRewardsQuery, rewards };
};

export const useRevokePointAwardMutation = ({
  pointAwardId,
}: {
  pointAwardId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      reactQueryDelete(
        internalApi.gamification.revokePointAward({ pointAwardId }),
      ),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [REWARDS_QUERY_KEY] });
    },
  });
};
