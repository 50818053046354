import { useEffect } from "react";
import type { ImagePost } from "@/react/types";
import { STEPS } from "../constants";
import { useImagePostModalStore } from "../store";
import type { NewPost } from "./usePost";

export const useInitializeStore = (post: ImagePost | NewPost | null) => {
  const { setCurrentStep, setUploadedImages } = useImagePostModalStore();

  useEffect(() => {
    setCurrentStep(post?.gallery ? STEPS.CREATE_POST : STEPS.UPLOAD_IMAGES);
    if (post?.gallery) {
      setUploadedImages(post.gallery.images);
    }
  }, [post?.gallery, setCurrentStep, setUploadedImages]);
};
