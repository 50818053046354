import { t } from "@/i18n-js/instance";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { Form } from "@circle-react-uikit/Form";
import { ImageInput } from "@circle-react-uikit/ImageInput";

const META_FIELD_NAMES = [
  "meta_title",
  "meta_description",
  "opengraph_title",
  "opengraph_description",
];

export const Meta = () => (
  <div className="form-section">
    <div className="form-section__content">
      {META_FIELD_NAMES.map(attribute => (
        <Form.Item
          key={attribute}
          label={t(`meta_tag_attributes.${attribute}`)}
          description={t(`meta_tag_attributes.${attribute}_description`, {
            obj: "post",
          })}
          name={`meta_tag_attributes.${attribute}`}
          hideBorder
          variant="extra-bottom-margin"
        >
          <Form.Input
            key={attribute}
            id={attribute}
            name={`meta_tag_attributes.${attribute}`}
          />
        </Form.Item>
      ))}
      <Form.Item
        name="meta_tag_attributes.opengraph_image"
        label={t("meta_tag_attributes.opengraph_image")}
        hideBorder
        hideDescription
        variant="extra-bottom-margin"
      >
        <ImageInput
          name="meta_tag_attributes.opengraph_image"
          aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
        />
      </Form.Item>
    </div>
  </div>
);
