import React from "react";
import { Menu } from "@headlessui/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface MenuOptionProps {
  renderIcon?: React.ReactNode;
  label: string;
  value: boolean;
  disabled: boolean;
  onClick: () => void;
}

export const MenuOption = ({
  renderIcon,
  label,
  value,
  disabled,
  onClick,
}: MenuOptionProps) => (
  <Menu.Item disabled={disabled} key="rsvp-attending">
    {active => (
      <button
        type="submit"
        className={classNames("rsvp-radio__list__option", {
          "rsvp-radio__list__option--active": active,
          "rsvp-radio__list__option--disabled": disabled,
        })}
        onClick={onClick}
      >
        <div className="rsvp-radio__list__option__label">
          {renderIcon}
          <span className="label-text">{label}</span>
        </div>
        <input type="radio" checked={value} readOnly />
      </button>
    )}
  </Menu.Item>
);
