import { delay } from "lodash";
import { useHistory } from "react-router-dom";
import type { ChatRoom } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/ChatRoomTypes";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";

export const useThreads = () => {
  const history = useHistory();
  const {
    openThreadsRailbar,
    setMessageHighlightId,
    messageHighlightId,
    shouldShowEmbeddedRailBar,
  } = useRailbar();

  const { message_id: messageThreadId } = useRouterQueryParams();
  const removeThreadIdUrlParam = () => {
    if (messageHighlightId) {
      history.replace({
        search: "",
        hash: window.location.hash,
      });
      setMessageHighlightId(null);
    }
  };

  const openThreadsRailbarOnThreadsNotification = ({
    messageHashId,
    chatRoomMessages,
    chatRoom,
  }: {
    messageHashId: string;
    chatRoomMessages: ChatRoomMessage[];
    chatRoom: ChatRoom;
  }) => {
    if (messageThreadId) {
      const parentMessage = chatRoomMessages.find(
        message => message.id === Number(messageHashId),
      );
      if (parentMessage) {
        delay(
          () => {
            setMessageHighlightId(Number(messageThreadId));
            openThreadsRailbar(parentMessage, chatRoom);
          },
          shouldShowEmbeddedRailBar ? 0 : 500,
        );
      }
    }
  };

  return { removeThreadIdUrlParam, openThreadsRailbarOnThreadsNotification };
};
