import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useLiveStreamsConfig = ({ enabled } = { enabled: true }) => {
  const { data: configArray, isLoading } = useQuery(
    internalApi.liveStreams.configurations(),
    {
      enabled,
    },
  );
  const config = {};
  configArray?.forEach(configObj => {
    config[configObj.feature_flag] = configObj.value;
  });

  return {
    isLoading,
    config,
  };
};
