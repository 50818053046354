import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { useEventAttendeesApi } from "../../../hooks/useEventAttendeesApi";
import { ATTENDEE_ROLES } from "../../../utils";
import { Attendee } from "./Attendee";
import { EmptyList } from "./EmptyList";

const LATEST_RSVP_MAX_RECORDS = 10;

export const LatestRSVP = ({ event }) => {
  const { isLoading, records: attendees } = useEventAttendeesApi(
    event,
    ATTENDEE_ROLES.PARTICIPANT,
    LATEST_RSVP_MAX_RECORDS,
  );

  return (
    <div className="border-primary w-full rounded-lg border p-4">
      <Typography.LabelMd weight="medium" color="text-light">
        {t("events.edit_modal.overview.latest_rsvps")}
      </Typography.LabelMd>
      {attendees?.length > 0 && (
        <table className="mt-6 w-full">
          <thead className="text-xxs text-light uppercase">
            <th>{t("events.edit_modal.overview.name")}</th>
            <th className="hidden md:[display:revert]">
              {t("events.edit_modal.overview.email")}
            </th>
            <th>{t("events.edit_modal.overview.rsvp_date")}</th>
          </thead>
          <tbody>
            {attendees.map(attendee => (
              <Attendee
                key={attendee.id}
                name={attendee.name}
                avatarUrl={attendee.avatar_url}
                email={attendee.email}
                rsvpDate={attendee.rsvp_date}
              />
            ))}
          </tbody>
        </table>
      )}
      {!isLoading && !attendees?.length && <EmptyList />}
    </div>
  );
};

LatestRSVP.propTypes = {
  event: PropTypes.object,
};
