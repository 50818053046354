import { chatRoomPath, chatSpacePath } from "@/react/helpers/urlHelpers";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";

export const getCopyLinkUrl = ({
  id,
  parentMessageId,
  chatRoomUrl,
}: {
  id: number;
  parentMessageId?: number;
  chatRoomUrl?: string;
}) => {
  const hostUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    (chatRoomUrl || window.location.pathname);
  return (
    hostUrl +
    (parentMessageId
      ? `?message_id=${id}#message_${parentMessageId}`
      : `#message_${id}`)
  );
};

export const doesActiveMessageIdMatches = ({
  activeMessageId,
  id,
  isMessageThread = false,
}: {
  activeMessageId: string | null;
  id: number;
  isMessageThread: boolean;
}) => activeMessageId === getActiveMessageId(id, isMessageThread);

export const getActiveMessageId = (id: number, isMessageThread: boolean) =>
  `${isMessageThread ? "thread-" : "message-"}${String(id)}`;

export const getDefaultMessagePortalId = () =>
  document.getElementById("threads-scroll-view") ||
  document.getElementById("message-thread-scroll-view") ||
  document.getElementById("message-scroll-view") ||
  document.body;

export const getChatRoomUrlWithMessageId = (
  chatRoom: {
    slug: string;
    uuid: string;
  },
  message: ChatRoomMessage,
) => {
  const { slug, uuid } = chatRoom;
  const {
    id,
    parent_message_id: parentMessageId,
    embedded: isEmbedded,
  } = message;

  const chatRoomUrl = isEmbedded
    ? chatSpacePath({ spaceSlug: slug })
    : chatRoomPath({ uuid });

  let messageParams = `#message_${id}`;
  if (parentMessageId) {
    messageParams = `?message_id=${id}#message_${parentMessageId}`;
  }

  return `${chatRoomUrl}${messageParams}`;
};
