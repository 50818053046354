/* eslint-disable no-restricted-syntax -- Not translatable strings */
export const INTERVAL_OPTION_MAPPER = {
  daily: {
    interval: "daily",
    interval_count: 1,
    interval_label: "daily",
  },
  weekly: {
    interval: "weekly",
    interval_count: 1,
    interval_label: "weekly",
  },
  biweekly: {
    interval: "weekly",
    interval_count: 2,
    interval_label: "biweekly",
  },
  monthly: {
    interval: "monthly",
    interval_count: 1,
    interval_label: "monthly",
  },
  quarter: {
    interval: "monthly",
    interval_count: 3,
    interval_label: "quarter",
  },
  biannual: {
    interval: "monthly",
    interval_count: 6,
    interval_label: "biannual",
  },
  annual: {
    interval: "yearly",
    interval_count: 1,
    interval_label: "annual",
  },
  onetime: {
    interval: undefined,
    interval_count: undefined,
    interval_label: "onetime",
  },
};
