import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useLiveStreamsUsage = ({ enabled } = { enabled: true }) => {
  const { data: usageArray, isLoading } = useQuery(
    internalApi.liveStreams.usage(),
    {
      enabled,
    },
  );
  const usage = {};
  usageArray?.forEach(usageObj => {
    usage[usageObj.type] = usageObj.value;
  });

  return {
    isLoading,
    usage,
  };
};
