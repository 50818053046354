import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { Tabs } from "@circle-react-uikit/Tabs";
import { Typography } from "@circle-react-uikit/Typography";
import { PEOPLE_TABS, isLiveRoom } from "../../utils";
import { DownloadCSVButton } from "./DownloadCSVButton";
import { PeopleBody } from "./PeopleBody";
import { useFormSections } from "./hooks/useFormSections";

export const People = ({ event }) => {
  const isRoom = isLiveRoom(event);
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const { onTabClick, currentTab, tabs } = useFormSections(isRoom);
  const shouldDisplayDownloadCSVButton =
    isAdmin && currentTab?.key === PEOPLE_TABS.ATTENDEES;

  return (
    <>
      <Typography.TitleXl>{t("events.create.form.people")}</Typography.TitleXl>
      {currentTab?.label && (
        <div className="relative">
          <Tabs
            variant="underline"
            className="sticky"
            wrapperClassName="overflow-x-auto overflow-y-hidden"
            controlled
            activeTab={currentTab.label}
            onClick={onTabClick}
            options={tabs}
          />
          {shouldDisplayDownloadCSVButton && (
            <div className="absolute bottom-2 right-0">
              <DownloadCSVButton event={event} />
            </div>
          )}
        </div>
      )}
      <PeopleBody event={event} currentTab={currentTab} />
    </>
  );
};

People.propTypes = {
  event: PropTypes.object.isRequired,
};
