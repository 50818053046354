import { t } from "@/i18n-js/instance";

export const RESULTS_PER_PAGE = 10;
export const ALL_STATUS = "all";
export const DRAFT_STATUS = "draft";
export const SCHEDULED_STATUS = "scheduled";
export const PUBLISHED_STATUS = "published";

export const LOCALE_NAMESPACE = "settings.posts";

export const TABS = Object.freeze({
  [ALL_STATUS]: t([LOCALE_NAMESPACE, "tabs", "all"]),
  [DRAFT_STATUS]: t([LOCALE_NAMESPACE, "tabs", "drafts"]),
  [SCHEDULED_STATUS]: t([LOCALE_NAMESPACE, "tabs", "scheduled"]),
});
