import { useEffect } from "react";
import { useToast } from "@circle-react-uikit/ToastV2";

export const RailsFlashMessages = ({ alert, notice }) => {
  const { success, error } = useToast();
  useEffect(() => {
    if (alert) {
      error(alert);
    }
    if (notice) {
      success(notice);
    }
  }, []);
  return null;
};
