import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useEventSpaces } from "@circle-react/components/Events/hooks/useEventSpaces";
import { TICKET_TYPES } from "@circle-react/components/EventsV3/Modal/utils";
import { useLiveStreamsConfig } from "@circle-react/components/LiveStreams/hooks";
import {
  ROOM_ACCESS_TYPES,
  ROOM_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import type { RoomType } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

interface AccessTypeProps {
  name?: string;
  roomType?: RoomType;
}

export const AccessType = ({
  name = "",
  roomType = ROOM_TYPES.STREAM,
}: AccessTypeProps) => {
  const { spaces } = useEventSpaces();
  const { config: liveStreamConfig }: any = useLiveStreamsConfig();
  const liveRoomCapacity = liveStreamConfig?.live_room_participants_capacity;
  const { control, getValues, setValue } = useFormContext();

  const spaceSelectedId = getValues("space_id");
  const ticketType = getValues("event_setting_attributes.ticket_type");
  const spaceSelected = spaces.find(
    (space: any) => space.id == spaceSelectedId,
  );
  const spaceVisibility = spaceSelected ? spaceSelected.visibility : "open";

  const accessTypeOptions: any[] = [
    {
      value: ROOM_ACCESS_TYPES.SECRET,
      label: t(`events.create.form.${spaceVisibility}.access_type_secret`),
      description: (
        <Typography.LabelSm>
          {t(`events.create.form.${spaceVisibility}.access_type_secret_label`)}
        </Typography.LabelSm>
      ),
    },
  ];

  if (ticketType == TICKET_TYPES.FREE) {
    accessTypeOptions.push({
      value: ROOM_ACCESS_TYPES.OPEN,
      label: t(`events.create.form.${spaceVisibility}.access_type_space`),
      description: (
        <Typography.LabelSm>
          {t(`events.create.form.${spaceVisibility}.access_type_space_label`, {
            maxCapacity: liveRoomCapacity,
          })}
        </Typography.LabelSm>
      ),
    });
  }

  if (roomType === ROOM_TYPES.STREAM && ticketType == TICKET_TYPES.FREE) {
    accessTypeOptions.push({
      value: ROOM_ACCESS_TYPES.PUBLIC_STREAM,
      label: t(`events.create.form.${spaceVisibility}.access_type_public`),
      description: (
        <Typography.LabelSm>
          {t(`events.create.form.${spaceVisibility}.access_type_public_label`)}
        </Typography.LabelSm>
      ),
    });
  }

  useEffect(() => {
    if (ticketType == "paid") {
      setValue(
        "event_setting_attributes.live_stream_room_setting_attributes.access_type",
        ROOM_ACCESS_TYPES.SECRET,
        { shouldDirty: true },
      );
    }
  }, [ticketType, setValue]);

  const getOption = (value: any) =>
    accessTypeOptions.find(option => option.value === value);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentOption = getOption(value) || accessTypeOptions[0];
        return (
          <Dropdown
            direction="bottom-start"
            linkType="button"
            button={
              <Dropdown.MenuButton className="form-control relative inline-block w-full text-left">
                <div className="flex justify-between">
                  <div className="content-start">
                    <Typography.LabelMd>
                      {currentOption?.label}
                    </Typography.LabelMd>
                  </div>
                  <div className="flex content-end">
                    <Icon type="cheveron-down" />
                  </div>
                </div>
              </Dropdown.MenuButton>
            }
            buttonClassName="w-full"
            className="relative w-full"
            size={DROPDOWN_SIZE.EXTRA_LARGE}
            onChange={onChange}
            options={accessTypeOptions}
            labelWeight="semibold"
          />
        );
      }}
    />
  );
};
