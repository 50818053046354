import { useAttendeeSeatsAddon } from "./useAttendeeSeatsAddon";
import { useRecordingHoursAddon } from "./useRecordingHoursAddon";
import { useStreamHoursAddon } from "./useStreamHoursAddon";

export const useLiveStreamsAddonsQuantities = () => {
  const {
    isLoading: isLoadingRecordingHoursAddon,
    currentQuantity: recordingHoursAddonQuantity,
    refetch: refetchRecordingHours,
  } = useRecordingHoursAddon();
  const {
    isLoading: isLoadingStreamHoursAddon,
    currentQuantity: streamHoursAddonQuantity,
    refetch: refetchStreamHours,
  } = useStreamHoursAddon();
  const {
    isLoading: isLoadingAttendeeSeatsAddon,
    currentQuantity: attendeeSeatsAddonQuantity,
    refetch: refetchAttendees,
  } = useAttendeeSeatsAddon();

  const isLoading =
    isLoadingRecordingHoursAddon ||
    isLoadingStreamHoursAddon ||
    isLoadingAttendeeSeatsAddon;
  const refetch = () => {
    // We will refetch all the addon quantities
    refetchRecordingHours();
    refetchStreamHours();
    refetchAttendees();
  };

  return {
    recordingHoursAddonQuantity,
    streamHoursAddonQuantity,
    attendeeSeatsAddonQuantity,
    isLoading,
    refetch,
  };
};
