import React from "react";

export interface MenuLabelProps {
  label: string;
  renderIcon?: React.ReactNode;
}

export const MenuLabel = ({ label, renderIcon }: MenuLabelProps) => (
  <div className="rsvp-radio__button__label">
    {renderIcon}
    <span className="button-text !text-dark">{label}</span>
  </div>
);
