import { upperFirst } from "lodash";
import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";

export const dateComponents = (dateString: string, timeZone?: string) => {
  let dayOfMonth: string | undefined;
  let shortMonthString: string | undefined;
  let monthName: string | undefined;

  try {
    const date = new Date(dateString);
    const locale = getCurrentBcp47Locale();

    const formatter = new Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "short",
      timeZone,
    });

    const formattedParts = formatter.formatToParts(date);
    monthName = formattedParts.find(part => part.type === "month")?.value;
    dayOfMonth = formattedParts.find(part => part.type === "day")?.value;
    shortMonthString = upperFirst(monthName).replace(".", "");
  } catch (error) {
    if (error instanceof Error) {
      console.error(`dateComponents - Could not parse date: ${error.message}`);
    }
  }

  return {
    dayOfMonth,
    shortMonthString,
  };
};
