import { useState } from "react";

const REGEX_FOR_TITLE_WITH_NOTIFICATION_COUNT = /^\(\d+\)\s/;
const REGEX_FOR_DIRECT_MESSAGE_SPECIFIC_TITLE = /| [a-z 0-9@_\-#.!]+ $/i;

export const useDocumentTitle = () => {
  // This is needed because when use click back links an extra counter gets added in the title.
  const documentTitleWithoutNotificationCount = () =>
    document.title.replace(REGEX_FOR_TITLE_WITH_NOTIFICATION_COUNT, "");
  const [originalTitle] = useState(documentTitleWithoutNotificationCount());

  const prependTitle = string => (document.title = `${string}${originalTitle}`);
  const resetTitleToOriginal = () => (document.title = originalTitle);

  const addDirectMessageParticipantNameInTitle = name => {
    const notificationCount = document.title.match(
      REGEX_FOR_TITLE_WITH_NOTIFICATION_COUNT,
    );
    let replacedString = originalTitle.replace(
      REGEX_FOR_DIRECT_MESSAGE_SPECIFIC_TITLE,
      `${name} `,
    );

    if (notificationCount) {
      replacedString = `${notificationCount[0]}${replacedString}`;
    }

    document.title = replacedString;
  };

  return {
    originalTitle,
    prependTitle,
    resetTitleToOriginal,
    addDirectMessageParticipantNameInTitle,
  };
};
