import {
  reactQueryGet,
  reactQueryPatch,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export interface ApiResource {
  id?: string;
  params?: object;
}

export const useModerationApi = () => {
  const list = ({ params = {} }: ApiResource) =>
    reactQueryGet(internalApi.flaggedContent.index({ params }));

  const get = async (id: string, type: string) => {
    const params = type === "post" ? { post_id: id } : { comment_id: id };

    const response = await reactQueryGet(
      internalApi.flaggedContent.index({ params }),
    );

    if (
      response &&
      Array.isArray(response.records) &&
      response.records.length > 0
    ) {
      return response.records[0];
    }

    return response;
  };

  const approve = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.approve({ id }));

  const reject = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.reject({ id }));

  return {
    list,
    get,
    approve,
    reject,
  };
};
