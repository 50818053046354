import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { reactionsApi } from "@/react/api";
import {
  useChatMessageReaction,
  useChatThreadListReaction,
  useChatThreadReaction,
} from "@circle-react/hooks/chatsV2";

export interface ReactionProps {
  currentCommunityMemberId: number;
  emoji: string;
  reactionableId: number;
  reactionableType?: string;
  setCommunityMembers?: (data: any) => void;
  communityMemberIds?: number[];
}

export const useReactions = ({
  currentCommunityMemberId,
  emoji,
  reactionableId,
  reactionableType,
  setCommunityMembers,
  communityMemberIds = [],
}: ReactionProps) => {
  const { createReactionFromMessage, removeReactionFromMessage } =
    useChatMessageReaction();

  const { createReactionFromThread, removeReactionFromThread }: any =
    useChatThreadReaction();

  const { createReactionFromThreadList, removeReactionFromThreadList } =
    useChatThreadListReaction();
  const [isLoading, setIsLoading] = useState(false);

  const addReaction = (body: any) => {
    setIsLoading(true);
    onCreateReactionCallback({
      communityMemberId: currentCommunityMemberId,
      emoji: body.emoji,
      reactionableId: body.chat_room_message,
    });

    void addReactionRequest(body);
  };

  const removeReaction = (body: any) => {
    setIsLoading(true);
    onRemoveReactionCallback({
      communityMemberId: currentCommunityMemberId,
      emoji: body.emoji,
      reactionableId: body.chat_room_message,
    });

    void removeReactionRequest(body);
  };

  const { mutateAsync: addReactionRequest } = useMutation<void, unknown, any>(
    body => reactionsApi.addReaction(body),
    {
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const { mutateAsync: removeReactionRequest } = useMutation(
    body => reactionsApi.removeReaction(body),
    {
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const { refetch: fetchCommunityMembers } = useQuery<any, unknown, void>(
    ["reaction", reactionableId, reactionableType, emoji],
    () =>
      reactionsApi.fetchCommunityMemberDetails({
        ids: communityMemberIds.join(","),
      }),
    {
      onSuccess: data => {
        if (!setCommunityMembers) return;
        setCommunityMembers(data);
      },
      enabled: false,
    },
  );

  const onCreateReactionCallback = (reactionAttributes: any) => {
    createReactionFromMessage?.(reactionAttributes);
    createReactionFromThread?.(reactionAttributes);
    createReactionFromThreadList?.();
  };

  const onRemoveReactionCallback = (reactionAttributes: any) => {
    removeReactionFromMessage?.(reactionAttributes);
    removeReactionFromThread?.(reactionAttributes);
    removeReactionFromThreadList?.();
  };

  return {
    addReaction,
    removeReaction,
    fetchCommunityMembers,
    isLoading,
  };
};
