import { t } from "@/i18n-js/instance";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import type { Event } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useRemoveCohostMutation } from "./useRemoveCohostMutation";

export interface AttendeeMenuProps {
  event: Event;
  attendeeId: number;
  accessType: string;
  setSelectedAttendeeId: any;
  onRemove: (id: number) => void;
  setShouldShowAttendeeRemovalConfirmation: (value: boolean) => void;
}

export const AttendeeMenu = ({
  event,
  attendeeId,
  onRemove,
  accessType,
  setSelectedAttendeeId,
  setShouldShowAttendeeRemovalConfirmation,
}: AttendeeMenuProps) => {
  const { removeCohostMutation } = useRemoveCohostMutation(event, onRemove);
  const isFreeOrInvitedEvent = accessType == "invited" || !isPaidEvent(event);
  const handleOnClick = (id: any) => {
    if (isFreeOrInvitedEvent) {
      removeCohostMutation.mutate(id);
    } else {
      setSelectedAttendeeId(id);
      setShouldShowAttendeeRemovalConfirmation(true);
    }
  };

  const buttonText = isFreeOrInvitedEvent
    ? t("remove_member")
    : t("manage_attendee");

  return (
    <Dropdown
      direction="bottom-end"
      className="flex justify-end"
      button={
        <Dropdown.MenuButton className="invisible w-full group-hover:visible">
          <Icon
            type="20-menu-dots-horizontal"
            size={20}
            className="text-dark"
          />
        </Dropdown.MenuButton>
      }
    >
      <Dropdown.Item onClick={() => handleOnClick(attendeeId)}>
        <Dropdown.ItemLink type="button">{buttonText}</Dropdown.ItemLink>
      </Dropdown.Item>
    </Dropdown>
  );
};
