import { useEffect } from "react";
import PropTypes from "prop-types";
import { isFunction } from "lodash";

export const useKeyboardNavigation = ({
  onArrowDown,
  onArrowUp,
  onEnter,
  onEscape,
  enabled = true,
  elementRef = null,
}) => {
  useEffect(() => {
    const element = elementRef?.current || document;

    const eventKeyMap = {
      ArrowDown: onArrowDown,
      ArrowUp: onArrowUp,
      Enter: onEnter,
      Escape: onEscape,
    };

    const handleKeyDown = event => {
      const handler = eventKeyMap[event?.key];
      if (isFunction(handler)) {
        handler(event);
      }
    };

    if (enabled && element) {
      element.addEventListener("keydown", handleKeyDown);
    } else {
      element?.removeEventListener("keydown", handleKeyDown);
    }

    return () => element?.removeEventListener("keydown", handleKeyDown);
  }, [enabled, onArrowDown, onArrowUp, onEnter, onEscape, elementRef]);
};

useKeyboardNavigation.propTypes = {
  options: PropTypes.shape({
    onArrowDown: PropTypes.func,
    onArrowUp: PropTypes.func,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    enabled: PropTypes.bool,
  }),
};
