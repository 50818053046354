import classnames from "classnames";
import { PostFooter } from "@/react/components/Spaces/ImageSpace/GridView/PostFooter";
import { useTailwindSmScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import type { ImagePost } from "@/react/types";
import { Icon } from "@circle-react-shared/Icon";
import { OpenFullPostButton } from "./OpenFullPostButton";
import { OpenPostLink } from "./OpenPostLink";

interface GridPostItemProps {
  post: ImagePost;
  isSquareGrid?: boolean;
  onPostChange: (post: ImagePost) => void;
  onPostDestroy: (postId: number) => void;
}
export const GridPostItem = ({
  post,
  isSquareGrid = true,
  onPostChange,
  onPostDestroy,
}: GridPostItemProps) => {
  const AccessPostComponent = useTailwindSmScreenMediaQuery()
    ? OpenFullPostButton
    : OpenPostLink;
  return (
    <div className="group relative">
      <div className="absolute right-2 top-2 flex">
        {post.gallery.images.length > 1 && (
          <Icon type="16-multiple-images" size={16} />
        )}
        {post.pinned_at_top_of_space && (
          <Icon type="16-pin-sm-white" size={16} />
        )}
      </div>
      <AccessPostComponent
        post={post}
        onPostChange={onPostChange}
        onPostDestroy={onPostDestroy}
      >
        <img
          loading="lazy"
          src={post.gallery.images[0].url}
          alt={post.truncated_content}
          className={classnames(
            "pointer-events-none inline-block w-full rounded-md md:rounded-none",
            {
              "aspect-square object-cover": isSquareGrid,
            },
          )}
        />
      </AccessPostComponent>
      <PostFooter
        post={post}
        onPostChange={onPostChange}
        onPostDestroy={onPostDestroy}
      />
    </div>
  );
};
