import { IFrameViewItem } from "@/react/components/PostsV3/IFrameViewItem";
import SimplifiedPostItemV3 from "@/react/components/Profile/Posts/SimplifiedPostItemV3";
import { GridPostItem } from "@/react/components/Spaces/ImageSpace/GridView/GridPostItem";
import { MasonryPostItem } from "@/react/components/Spaces/ImageSpace/GridView/MasonryPostItem";
import {
  isCardViewSpace,
  isFeedViewImageSpace,
  isGridViewImageSpace,
  isListViewSpace,
  isMasonryViewImageSpace,
} from "@/react/helpers/spaceHelpers";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { CardViewItem } from "../CardViewItem";
import { ListViewItem } from "../ListViewItem";
import { PostViewListItem } from "../PostViewListItem";

export const PostItem = ({
  space = {},
  post,
  isFirst = false,
  forcePostView = false,
  forceSimplifiedView = false,
  forceIframeView = false,
  usedIn,
  ...rest
}: any) => {
  const isPinned = Boolean(post.pinned_at_top_of_space);
  const isCardView = isCardViewSpace(space);
  const isListView = isListViewSpace(space);

  let Component: any = PostViewListItem;

  if (isFeedViewImageSpace(space)) {
    Component = PostViewListItem;
  } else if (isMasonryViewImageSpace(space)) {
    Component = MasonryPostItem;
  } else if (isGridViewImageSpace(space)) {
    Component = GridPostItem;
  } else if (forceSimplifiedView) {
    Component = SimplifiedPostItemV3;
  } else if (forceIframeView) {
    Component = IFrameViewItem;
  } else if (forcePostView || (isPinned && isFirst)) {
    Component = PostViewListItem;
  } else if (isCardView) {
    Component = CardViewItem;
  } else if (isListView) {
    Component = ListViewItem;
  }

  return (
    <CurrentPostContextProvider post={post} usedIn={usedIn}>
      <Component post={post} {...rest} />
    </CurrentPostContextProvider>
  );
};
