import { createContext, useContext } from "react";
import type { QueryObserverResult } from "react-query";
import { useQuery } from "react-query";
import type { Event } from "@circle-react/types";
import type { ScheduledEmail } from "@circle-react-shared/ScheduledEmailModal";
import { useScheduledEmailsForm } from "@circle-react-shared/ScheduledEmailModal/useScheduledEmailsForm";

export interface ScheduledEmailListResponse {
  records?: Array<ScheduledEmail>;
}

interface ScheduledEmailContextProps {
  scheduledEmailList: Array<ScheduledEmail>;
  isLoading: boolean;
  maxDate?: Date;
  minDate?: Date;
  refetch: () => Promise<QueryObserverResult<ScheduledEmailListResponse>>;
  hasScheduledEmails: () => boolean;
}

const ScheduledEmailContext = createContext<ScheduledEmailContextProps>({
  scheduledEmailList: [],
  isLoading: false,
  hasScheduledEmails: () => false,
  refetch: () => new Promise(resolve => resolve),
});

ScheduledEmailContext.displayName = "ScheduledEmailContext";

export const ScheduledEmailProvider = ({
  event,
  children,
}: {
  event: Event;
  children: any;
}) => {
  const { getScheduledEmailList } = useScheduledEmailsForm();

  const recordID = String(event?.id || "");

  const startsAt = event?.event_setting_attributes?.starts_at;
  const maxDate = startsAt ? new Date(startsAt) : undefined;
  const minDate = new Date();

  const {
    data: scheduledEmailResponse,
    isLoading,
    refetch,
  } = useQuery<ScheduledEmailListResponse>(
    ["scheduled-email-list", recordID, startsAt],
    () =>
      getScheduledEmailList({
        record_id: recordID,
        scheduled_email_type: "event",
      }),
  );

  const hasScheduledEmails = () => !!scheduledEmailResponse?.records?.length;

  const value: ScheduledEmailContextProps = {
    scheduledEmailList: scheduledEmailResponse?.records || [],
    isLoading,
    maxDate,
    minDate,
    refetch,
    hasScheduledEmails,
  };

  return (
    <ScheduledEmailContext.Provider value={value}>
      {children}
    </ScheduledEmailContext.Provider>
  );
};

export const useScheduledEmailContext = (): ScheduledEmailContextProps => {
  const context = useContext(ScheduledEmailContext);

  if (context === undefined) {
    throw new Error(
      "useScheduledEmailContext must be used within a ScheduledEmailProvider",
    );
  }

  return context;
};
