import { isEmpty, noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { SpotlightedHeader } from "@/react/components/LiveStreams/Room/Sidebar/QA/common/SpotlightedHeader";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { Icon, isIconType } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Avatar, Row } from "@circle-react-uikit/SkeletonLoader/components";
import { getRole } from "../helpers";
import { ContentBody } from "./ContentBody";
import { BottomActions } from "./MessageActions/BottomActions";
import { MessageTimestamp } from "./MessageTimestamp";
import { ModeratorTag } from "./ModeratorTag";
import { RichTextUpdateBox } from "./RichTextUpdateBox";
import { RoleIcon } from "./RoleIcon";
import { useSpotlightStatus } from "./hooks/useSpotlightStatus";
import "./styles.scss";

export interface MessageBlockProps {
  chatRoom?: any;
  currentParticipant?: any;
  handleSubmit?: (value: any) => void | Promise<void>;
  isEditMessage?: boolean;
  isInitialPage?: boolean;
  isMessageThread?: boolean;
  isModal?: boolean;
  isParentMessage?: boolean;
  isPinned?: boolean;
  isPinnedMessageAtTop?: boolean;
  isRenderedOnHeader?: boolean;
  message: any;
  messageBodyClassName?: string;
  participants?: any[];
  roles?: any;
  chatRoomUrl?: string;
  toggleDeleteConfirmationModal?: () => void;
}

export const MessageBlock = ({
  chatRoom,
  chatRoomUrl,
  currentParticipant = {},
  handleSubmit,
  isEditMessage = false,
  isInitialPage = false,
  isMessageThread = false,
  isModal = false,
  isParentMessage = false,
  isPinned = false,
  isPinnedMessageAtTop = false,
  isRenderedOnHeader = false,
  message,
  messageBodyClassName,
  participants = [],
  roles = {},
  toggleDeleteConfirmationModal = noop,
}: MessageBlockProps) => {
  const memberProfileDrawer = useProfileDrawer();

  const { chat_room_participant_id: participantId } = message;
  const { isSpotlighted, isCurrentSpotlightedItem } =
    useSpotlightStatus(message);
  const sender = participants.find(
    participant => participant.id === participantId,
  );

  const role = getRole(sender?.community_member_id, roles);
  const isLoadingSender = isEmpty(sender);
  const isMemberProfileAvailable = sender?.user_public_uid;

  const { community_member_id: currentCommunityMemberId, admin: isAdmin } =
    currentParticipant || {};

  const { isLiveStream } = useRailbar();
  const openProfileDrawer = () => {
    if (isMemberProfileAvailable) {
      void memberProfileDrawer.show({
        memberPublicId: sender.user_public_uid,
        params: {
          shouldOpenInNewTab: isLiveStream,
        },
      });
    }
  };

  const renderSenderName = () =>
    sender.id === currentParticipant?.id ? t("messaging.you") : sender.name;

  const renderRemovedOrLeftBadge = () =>
    sender.deleted_at ? t("messaging.removed_or_left") : "";

  return (
    <div
      className={classNames("flex w-full flex-col", {
        "px-4 py-3": !isRenderedOnHeader,
        "px-3 py-1.5": isRenderedOnHeader,
      })}
    >
      {isSpotlighted && (
        <SpotlightedHeader isQueuedForSpotlight={!isCurrentSpotlightedItem} />
      )}
      <div className="flex w-full justify-start">
        <button
          type="button"
          className={classNames("mr-3 mt-1 self-start", {
            "cursor-default": !isMemberProfileAvailable,
          })}
          aria-label={t("messaging.open_member_profile_button_aria_label", {
            name: sender?.name,
          })}
          onClick={openProfileDrawer}
        >
          {isLoadingSender ? (
            <Avatar />
          ) : (
            <ProfileHoverCard
              communityMemberId={sender.community_member_id}
              name={sender.name}
              publicUid={sender.user_public_uid}
              headline={sender.headline}
              avatarURL={sender.avatar_url}
              disabled={!isMemberProfileAvailable}
            >
              <UserImage src={sender.avatar_url} name={sender.name} size="9" />
            </ProfileHoverCard>
          )}
        </button>
        <div
          className={classNames("w-full overflow-x-auto", messageBodyClassName)}
        >
          <div className="mb-px flex items-center gap-1.5">
            {isPinned && (
              <Icon
                type="14-pin"
                aria-label={t("messaging.pinned_message")}
                aria-hidden={false}
                size={14}
                className="text-darkest !w-3.5"
              />
            )}
            <button
              type="button"
              className={classNames(
                "text-darkest truncate text-left text-sm font-semibold",
                {
                  "cursor-default": !isMemberProfileAvailable,
                },
              )}
              onClick={openProfileDrawer}
              data-testid="number-of-replies"
            >
              {isLoadingSender ? (
                <Row md />
              ) : (
                <>
                  {renderSenderName()}{" "}
                  <span className="font-normal">
                    {renderRemovedOrLeftBadge()}
                  </span>
                </>
              )}
            </button>
            {role && isIconType(role) && <RoleIcon role={role} />}
            <ModeratorTag chatRoom={chatRoom} sender={sender} />
            <MessageTimestamp
              chatRoom={chatRoom}
              message={message}
              chatRoomUrl={chatRoomUrl}
              isParentMessage={isParentMessage}
              isMessageThread={isMessageThread}
              isRenderedOnHeader={isRenderedOnHeader}
            />
          </div>
          {!isModal && isEditMessage ? (
            <RichTextUpdateBox
              message={message}
              onSubmit={handleSubmit}
              onDelete={toggleDeleteConfirmationModal}
              chatRoom={chatRoom}
              isRenderedOnHeader={isRenderedOnHeader}
            />
          ) : (
            <ContentBody
              message={message}
              isInitialPage={isInitialPage}
              isPinnedMessageAtTop={isPinnedMessageAtTop}
            />
          )}
          <BottomActions
            message={message}
            chatRoom={chatRoom}
            currentCommunityMemberId={currentCommunityMemberId}
            isSpotlighted={isSpotlighted}
            isCurrentSpotlightedItem={isCurrentSpotlightedItem}
            isAdmin={isAdmin}
          />
        </div>
      </div>
    </div>
  );
};
