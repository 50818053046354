import { useRef } from "react";
import { isFunction } from "lodash";

export const useValidateFormSections = () => {
  const triggerValidationRef = useRef(null);

  const validateAndSetCurrentSection = async (
    sectionKey,
    setCurrentSection,
  ) => {
    if (isFunction(triggerValidationRef.current)) {
      const validationResult = await triggerValidationRef.current();
      if (!validationResult) {
        return;
      }
    }

    setCurrentSection(sectionKey);
  };

  return {
    validateAndSetCurrentSection,
    triggerValidationRef,
  };
};
