import { EmbeddableChat } from "@circle-react/components/Chat/EmbeddableChat";
import { ChatThreads } from "@circle-react/components/Chat/MessagingV2/ChatThreads";
import { RailbarContextProvider } from "@circle-react/hooks/chatsV2";
import { ChatRoomContextProvider } from "@circle-react/hooks/chatsV2/useChatRoomContext";

interface ProfileMessaging {
  uuid: string;
}

export const ProfileMessaging = ({ uuid }: ProfileMessaging) => (
  <div className="flex grow flex-col overflow-auto">
    <ChatRoomContextProvider chatRoomUuid={uuid} isMessaging>
      <RailbarContextProvider isDrawer>
        <EmbeddableChat
          uuid={uuid}
          displayDayChange
          displayRoomName={false}
          isDrawer
        />
        <div id="rail-bar-drawer" />
        <ChatThreads />
      </RailbarContextProvider>
    </ChatRoomContextProvider>
  </div>
);
