import * as yup from "yup";

export const isValidBody = (body: string) => !!body;

export const isValidName = (name: string) => !!name;

interface Node {
  type: string;
  content?: Node[];
}

const NODE_TYPES_WITHOUT_CONTENT = [
  "image",
  "file",
  "embed",
  "mention",
  "horizontalRule",
];
export const isValidTiptapBody = (tiptap_body?: { body?: Node }) => {
  if (tiptap_body?.body?.content?.length) {
    return tiptap_body.body.content.some(
      content =>
        !!content.content || NODE_TYPES_WITHOUT_CONTENT.includes(content.type),
    );
  }
  return false;
};

const commonValidationSchema = yup.object().shape({
  space_id: yup.number().required(),
});

export const trixValidationSchema = yup
  .object()
  .shape(
    {
      name: yup.string().when("body", {
        is: (body: any) => !isValidBody(body),
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      body: yup.string().when("name", {
        is: (name: any) => !isValidName(name),
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
    },
    [["name", "body"]],
  )
  .concat(commonValidationSchema);

export const tiptapValidationSchema = yup
  .object()
  .shape(
    {
      name: yup.string().when("tiptap_body", {
        is: (tiptap_body: any) => !isValidTiptapBody(tiptap_body),
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      tiptap_body: yup.object().when("name", {
        is: (name: any) => !isValidName(name),
        then: yup.object().test({
          test: (value: any) => isValidTiptapBody(value),
        }),
        otherwise: yup.object().nullable(),
      }),
    },
    [["name", "tiptap_body"]],
  )
  .concat(commonValidationSchema);
