import PropTypes from "prop-types";
import { UserInformation } from "@circle-react-shared/ProfileFields";
import { Section } from "./Section";

export const ProfileFieldsList = ({ profileFields }) => {
  const { visible, not_visible: hidden } = profileFields;
  return (
    <Section dataTestId="profile-fields-section">
      <UserInformation fields={visible} />
      <UserInformation fields={hidden} isHidden />
    </Section>
  );
};

ProfileFieldsList.propTypes = {
  profileFields: PropTypes.shape({
    visible: PropTypes.array,
    not_visible: PropTypes.array,
  }).isRequired,
};
