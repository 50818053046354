import type { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";

export const useIsVisibleOnDOM = <T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  boolean,
] => {
  const ref = useRef<T>(null);
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const observer = new IntersectionObserver(e => {
      setIsVisible(e?.[0].isIntersecting);
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, isVisible];
};
