import type { StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";
import { liveStreamApi } from "@circle-react/api";
import type { SpotlightItem } from "@circle-react/types/Live/SpotlightItem";

interface SpotlightState {
  items: SpotlightItem[];
  errorMessage?: string;
  isLoading: boolean;
}

interface SpotlightActions {
  fetchSpotlightItems: (roomId: number, params?: object) => void;
  addSpotlightItem: (item: SpotlightItem) => void;
  deleteSpotlightItem: (id: number) => void;
  setSpotlightItems: (items: SpotlightItem[]) => void;
  clearSpotlightItems: (roomId: number) => void;
}

export interface SpotlightSlice {
  spotlight: SpotlightState;
  spotlightActions: SpotlightActions;
}

export const createSpotlightSlice: StateCreator<
  SpotlightSlice,
  [],
  [["zustand/immer", never]],
  SpotlightSlice
> = immer(set => ({
  spotlight: {
    items: [],
    errorMessage: undefined,
    isLoading: false,
  },
  spotlightActions: {
    fetchSpotlightItems: (roomId: number, params = {}) => {
      set(state => {
        state.spotlight.isLoading = true;
      });
      liveStreamApi
        .getSpotlightItems({ roomId, params: { ...params, sort: "oldest" } })
        .then(data => {
          set(state => {
            state.spotlight.items = data.records;
            state.spotlight.isLoading = false;
          });
        })
        .catch(err => {
          console.error(err);
          set(state => {
            state.spotlight.errorMessage = "Failed to fetch spotlight items";
            state.spotlight.isLoading = false;
          });
        });
    },
    addSpotlightItem: (item: SpotlightItem) => {
      set(state => {
        state.spotlight.items = [...state.spotlight.items, item];
      });
    },
    deleteSpotlightItem: id => {
      set(state => {
        state.spotlight.items = state.spotlight.items.filter(
          item => item.id !== id,
        );
      });
    },
    setSpotlightItems: items => {
      set(state => {
        state.spotlight.items = items;
      });
    },
    clearSpotlightItems: roomId => {
      set(state => {
        state.spotlight.items = [];
      });
      void liveStreamApi.clearSpotlightItems({ roomId });
    },
  },
}));
