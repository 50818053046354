import { useState } from "react";
import { PropTypes } from "prop-types";
import { t } from "@/i18n-js/instance";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { RECURRING_EDIT_MODE } from "../helpers/constants";

const translationRoot = "events.delete.recurring_event_modal";

export const DeleteRecurringModal = ({
  show = false,
  onConfirm,
  onCloseModal,
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState();

  const onRadioChangeHandler = value => {
    setSelectedFrequency(value);
  };

  const onConfirmHandler = () => {
    onConfirm(selectedFrequency);
  };

  return (
    <ConfirmationModal
      title={t(`${translationRoot}.title`)}
      isOpen={show}
      confirmText={t(`${translationRoot}.accept`)}
      cancelText={t(`${translationRoot}.cancel`)}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      onConfirm={onConfirmHandler}
      confirmVariant="danger"
      size="sm"
    >
      <div className="mt-6">
        <RadioButtonGroup
          defaultChecked={RECURRING_EDIT_MODE.CURRENT}
          name="recurring_event_settings.frequency_edit"
          options={[
            {
              label: t(`${translationRoot}.this_event`),
              value: RECURRING_EDIT_MODE.CURRENT,
            },
            {
              label: t(`${translationRoot}.this_and_following`),
              value: RECURRING_EDIT_MODE.REMAINING,
            },
            {
              label: t(`${translationRoot}.all_events`),
              value: RECURRING_EDIT_MODE.ALL,
            },
          ]}
          size="sm"
          onChange={onRadioChangeHandler}
        />
      </div>
    </ConfirmationModal>
  );
};

DeleteRecurringModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCloseModal: PropTypes.func,
};
