import { noop } from "lodash";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { EditPostWrapper } from "@circle-react/components/QuickPostV2/EditPostWrapper";
import type { PostForQuickPost } from "@circle-react/components/QuickPostV2/Form";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import { useCloseOnEscape } from "@circle-react/components/QuickPostV2/useCloseOnEscape";
import { usePreventAccidentalModalClose } from "@circle-react/components/QuickPostV2/usePreventAccidentalModalClose";
import { Modal } from "@circle-react/components/shared/uikit/ModalV2";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import type { Post } from "@circle-react/types/Post";
import { useEmbedModal } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/EmbedModal";
import { useFileUploadModal } from "@circle-react-shared/uikit/TipTapBlockEditor/FileExtension/FileUploadModal";
import { useImagePickerModal } from "@circle-react-shared/uikit/TipTapBlockEditor/ImageExtension/ImageModal";
import "./trix-compatibility.scss";

const POST_DETAILS_QUERY_CONTEXT = "edit-post-modal";

interface EditPostModalProps {
  spaceId?: string;
  shouldSkipRedirectAfterSubmit?: boolean;
  post: PostForQuickPost;
  onSubmitSuccess?: () => void;
  onPostChange?: (data?: Post) => void;
}

export const EditPostModalComponent = ({
  post,
  onSubmitSuccess,
  onPostChange,
  shouldSkipRedirectAfterSubmit,
}: EditPostModalProps) => {
  const modal = useModal();

  const { isDirty } = useQuickPostModalStore();
  const conditionalClose = usePreventAccidentalModalClose({
    shouldPrevent: isDirty,
  });

  const filePickerModal = useFileUploadModal();
  const imageUploadModal = useImagePickerModal();
  const embedModal = useEmbedModal();

  const shouldBlockEscape =
    filePickerModal.visible || imageUploadModal.visible || embedModal.visible;

  useCloseOnEscape({
    disabled: shouldBlockEscape,
    onClose: conditionalClose,
  });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={noop}
      className="quick-post-v2-modal w-full max-w-3xl"
    >
      <Modal.Overlay onClick={conditionalClose} />
      <SpacesContextProvider>
        <PostsDetailsContextProvider
          perPagePostIds={[[post.id]]}
          queryOptions={{ cacheTime: 0 }}
          queryContext={POST_DETAILS_QUERY_CONTEXT}
        >
          <EditPostWrapper
            post={post}
            onPostChange={onPostChange}
            onSubmitSuccess={onSubmitSuccess}
            shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
          />
        </PostsDetailsContextProvider>
      </SpacesContextProvider>
    </Modal>
  );
};

const EditPostModal = ModalManager.create((props: EditPostModalProps) => (
  <EditPostModalComponent {...props} />
));

export const useEditPostV2Modal = () => useModal(EditPostModal);
