import { useQueryClient } from "react-query";

export const useInvalidateEventQueries = () => {
  const queryClient = useQueryClient();

  return ({
    shouldInvalidateHomePagePosts = false,
    shouldInvalidateAllEvents = false,
  } = {}) => {
    queryClient.invalidateQueries("post-show");
    queryClient.invalidateQueries("posts");
    queryClient.invalidateQueries("space-show", {
      refetchInactive: true,
    });

    if (shouldInvalidateAllEvents) {
      queryClient.invalidateQueries(["all", "events", "calendar-view"]);
    }
    if (shouldInvalidateHomePagePosts) {
      queryClient.invalidateQueries("home-page-posts");
    }
  };
};
