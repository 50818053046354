import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const useSubmitViaKeyboard = ({ enabled = true } = {}) => {
  const { triggerFormSubmit } = useFormContext();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const listener = e => {
      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
        triggerFormSubmit();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [enabled, triggerFormSubmit]);
};
