import { useState } from "react";
import classnames from "classnames";
import { noop } from "lodash";
import { useSwipeable } from "react-swipeable";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { useFullPostModal } from "@/react/components/Spaces/ImageSpace/FullPostModal";
import { useMobileFullCarouselModal } from "@/react/components/Spaces/ImageSpace/MobileFullCarouselModal";
import { Header } from "@/react/components/Spaces/ImageSpace/MobileFullCarouselModal/Header";
import { useTailwindSmScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import type { ImagePost } from "@/react/types";
import type { UsedInOptions } from "@circle-react/contexts";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
} from "@circle-react/contexts";
import { Images } from "./Images";
import { Indicators } from "./Indicators";
import { useZoom } from "./hooks/useZoom";

interface CarouselProps {
  onPostChange?: (post: ImagePost) => void;
  onPostDestroy?: (postId: number) => void;
  startingImageIndex?: number;
  className?: string;
}

export const Carousel = ({
  onPostChange = noop,
  onPostDestroy = noop,
  startingImageIndex = 0,
  className = "",
}: CarouselProps) => {
  const {
    record: post,
    usedIn,
  }: { record?: ImagePost; usedIn?: UsedInOptions } = useCurrentPostContext();
  const isUsedInFullPostModal = usedIn === "full-post-modal";
  const isUsedInMobileFullCarouselModal =
    usedIn === "mobile-full-carousel-modal";
  const isNotUsedInModal =
    usedIn !== "full-post-modal" && usedIn !== "mobile-full-carousel-modal";
  const isZoomEnabled = isUsedInFullPostModal;

  const { isPostBodyEmpty } = useCurrentPostHelpers();
  const shouldHaveRoundedBottomCorners =
    isNotUsedInModal &&
    post?.is_liking_disabled &&
    post?.is_comments_disabled &&
    isPostBodyEmpty;

  const [currentImageIndex, setCurrentImageIndex] =
    useState(startingImageIndex);
  const { zoomLevel, onMouseMove, resetZoom, cycleZoom, zoomPosition } =
    useZoom(isZoomEnabled);
  const [shouldAnimate, setShouldAnimate] = useState(startingImageIndex === 0);
  const { data: space } = useCurrentSpaceContext();
  const fullPostModal = useFullPostModal();
  const mobileFullCarouselModal = useMobileFullCarouselModal();
  const shouldOpenFullPostModal = useTailwindSmScreenMediaQuery();
  const shouldOpenMobileFullCarouselModal = !useTailwindSmScreenMediaQuery();
  const isCarousedUsedInModal =
    isUsedInFullPostModal || isUsedInMobileFullCarouselModal;
  const { isSharedPostView, isSharedPostWithTeaserLayout } =
    useCurrentPostHelpers();

  const images = post?.gallery?.images;
  const usedImages =
    isSharedPostWithTeaserLayout && images ? images.slice(0, 1) : images;

  const onNextImage = () => {
    if (isZoomEnabled) resetZoom();
    setShouldAnimate(true);
    setCurrentImageIndex(prevState =>
      Math.min(
        prevState + 1,
        usedImages ? usedImages.length - 1 : prevState + 1,
      ),
    );
  };
  const onPrevImage = () => {
    if (isZoomEnabled) resetZoom();
    setShouldAnimate(true);
    setCurrentImageIndex(prevState => Math.max(prevState - 1, 0));
  };

  const onSelectIndicator = (index: number) => {
    if (isZoomEnabled) resetZoom();
    setCurrentImageIndex(index);
  };

  const onTapImage = ({ event }: any) => {
    if (
      event.target.classList.contains("carousel-image") &&
      !isSharedPostWithTeaserLayout
    ) {
      if (isZoomEnabled) {
        cycleZoom();
      } else if (shouldOpenMobileFullCarouselModal) {
        void mobileFullCarouselModal.show({
          post,
          spaceId: space?.id,
          startingImageIndex: currentImageIndex,
        });
      } else if (shouldOpenFullPostModal) {
        void fullPostModal.show({
          post,
          spaceId: space?.id,
          onPostChange,
          onPostDestroy,
          isSharedPostView,
          startingImageIndex: currentImageIndex,
        });
      }
    }
  };

  const swipeableHandlers = useSwipeable({
    onSwipedRight: zoomLevel && zoomLevel > 1 ? undefined : onPrevImage,
    onSwipedLeft: zoomLevel && zoomLevel > 1 ? undefined : onNextImage,
    onTap: onTapImage,
    trackMouse: true,
  });

  if (!post || !usedImages) return null;

  return (
    <div
      className={classnames(
        "group relative w-full cursor-pointer px-0 pt-5",
        {
          "!py-0 lg:h-full": isUsedInFullPostModal,
          "flex h-full flex-col justify-between !py-0":
            isUsedInMobileFullCarouselModal,
          "!cursor-zoom-in": isZoomEnabled,
          "!pt-0": !isPostBodyEmpty,
          "mb-5": !isPostBodyEmpty && !isCarousedUsedInModal,
        },
        className,
      )}
      onMouseMove={isZoomEnabled ? onMouseMove : undefined}
      onTouchMove={isZoomEnabled ? onMouseMove : undefined}
      {...swipeableHandlers}
    >
      {isUsedInMobileFullCarouselModal && (
        <Header
          canDownload={post?.gallery?.downloadable_images}
          image={usedImages[currentImageIndex]}
        />
      )}
      <Images
        images={usedImages}
        currentImageIndex={currentImageIndex}
        onPrevImage={onPrevImage}
        onNextImage={onNextImage}
        usedIn={usedIn}
        shouldAnimate={shouldAnimate}
        zoomLevel={zoomLevel}
        zoomPosition={zoomPosition}
        shouldHaveRoundedBottomCorners={shouldHaveRoundedBottomCorners}
      />
      <Indicators
        images={usedImages}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={onSelectIndicator}
        usedIn={usedIn}
      />
    </div>
  );
};
