import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormItem } from "@circle-react/components/EventsV3/Modal/Common";
import {
  eventWithPublishedPaywall,
  hasEventStarted,
  isDraft,
  isPaidEvent,
  isRecurring,
} from "@circle-react/components/EventsV3/Modal/utils";
import { SupportedCurrencyChooser } from "@circle-react/components/SupportedCurrencyChooser";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { PriceField } from "./PriceField";

const MAX_DESCRIPTION_LENGTH = 16777215;

export const PaidTicket = ({ event }) => {
  const { watch } = useFormContext();
  const isEditMode = !!event;
  const isOngoingEvent = event && hasEventStarted(event);
  const updatedTicketType = watch("event_setting_attributes.ticket_type");
  const isPaidTicket = isPaidEvent(event) || updatedTicketType == "paid";
  const activeEventPaywall = eventWithPublishedPaywall(event);
  const disableCurrencyEdit =
    (isEditMode && isPaidTicket && activeEventPaywall && !isDraft(event)) ||
    isOngoingEvent ||
    isRecurring(event);
  const currencyFormItemName = "paywall_attributes.currency_id";
  const currencyId =
    watch(currencyFormItemName) || event?.paywall_attributes?.currency_id;
  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();
  if (isLoadingPaywallsCurrencies) {
    return <Loader />;
  }

  const currency = paywallsCurrencies?.find(
    currency => currency.id.toString() === currencyId?.toString(),
  );

  return (
    <>
      <div className="flex flex-col items-stretch md:flex-row">
        <div className="w-full pr-0 md:w-1/2 md:pr-2">
          <PriceField
            name="paywall_attributes.price_amount"
            labelKey="price"
            currency={currency}
            placeholder={t(
              "events.create.form.paywall_attributes.price_placeholder",
            )}
            isDisabled={isOngoingEvent}
          />
        </div>
        <div className="w-full pl-0 md:w-1/2 md:pl-2">
          <SupportedCurrencyChooser
            formItemName="paywall_attributes.currency_id"
            formItemTranslationRoot="events.create.form"
            disabled={disableCurrencyEdit}
            markDefaultCurrencyDirty
          />
        </div>
      </div>
      <div className="flex flex-col items-stretch">
        <FormItem
          name="paywall_attributes.description"
          rules={{
            maxLength: {
              value: MAX_DESCRIPTION_LENGTH,
              message: t(
                "events.create.form.paywall_attributes.description_length_error",
              ),
            },
          }}
          placeholder={t(
            "events.create.form.paywall_attributes.description_placeholder",
          )}
          translationRoot="events.create.form"
        >
          <Form.TextArea
            data-testid="event-paywall-description"
            disabled={isOngoingEvent}
          />
        </FormItem>
      </div>
    </>
  );
};
