import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@/react/api";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useOnCreateLiveStream = (event: any) => {
  const toastr = useToast();

  return useMutation<Event, ApiError, any>(
    () => eventApi.createLiveRoomForEvent({ event }),
    {
      onSuccess: data => {
        const { event_setting_attributes } = data || {};
        const { live_stream_slug: liveStreamSlug } =
          event_setting_attributes || {};

        if (liveStreamSlug) {
          let url = liveStreamPaths.room({ roomSlug: liveStreamSlug });

          if (isPublicStream(event))
            url = liveStreamPaths.publicRoom({
              roomSlug: liveStreamSlug,
            });

          window.open(url, "_blank");
        }
      },
      onError: () => {
        toastr.error(t("events.can_not_create_live_room_for_event"));
      },
    },
  );
};
