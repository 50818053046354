import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ProfileModalV3 } from "@circle-react/components/ProfileModalV3";
import type { ProfileDrawerProps } from "./ProfileDrawerComponent";
import "./styles.scss";

const ProfileDrawer = ModalManager.create(
  ({ memberPublicId, params }: ProfileDrawerProps) => (
    <ProfileModalV3 memberPublicId={memberPublicId} params={params} />
  ),
);

export const useProfileDrawer = () => useModal(ProfileDrawer);
