import { t } from "@/i18n-js/instance";
import { useAttendeeSeatsAddon } from "@circle-react/components/LiveStreams/hooks";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { RoomDetailsText } from "./RoomDetailsText";
import { RoomTypeOption } from "./RoomTypeOption";

interface RoomTypePickerComponentProps {
  onChange: (value: string) => void;
  value: string;
  liveStreamConfig: any;
  shouldDisableEventOption: boolean;
}

export const RoomTypePickerComponent = ({
  onChange,
  value,
  liveStreamConfig,
  shouldDisableEventOption,
}: RoomTypePickerComponentProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const totalHosts = liveStreamConfig?.live_stream_max_host_capacity;
  const spectatorsCapacity =
    liveStreamConfig?.live_stream_max_spectator_capacity;
  const { currentQuantity: spectatorsSeatsAddonQuantity } =
    useAttendeeSeatsAddon({ enabled: isAdmin });

  const totalSpectators =
    spectatorsCapacity + (spectatorsSeatsAddonQuantity || 0);
  const totalParticipants = liveStreamConfig?.live_room_participants_capacity;

  return (
    <div className="order-1 grid w-[1024px] grid-cols-3 gap-6 p-0">
      <div className="h-full" data-testid="schedule-a-live-event">
        <RoomTypeOption
          title={t("live_streams.room_type_picker.live_event.v3.title")}
          onClick={() => onChange(ROOM_TYPES.EVENT)}
          isSelected={value === ROOM_TYPES.EVENT}
          disabled={shouldDisableEventOption}
        >
          <ul className="order-1 flex w-[277.33px] flex-none list-outside flex-col items-start gap-3 self-stretch p-0">
            <RoomDetailsText
              iconType="16-join-date"
              text={t("live_streams.room_type_picker.live_event.v3.bullet_1", {
                spectators: totalSpectators,
              })}
            />
            <RoomDetailsText
              iconType="16-calendar-add"
              text={t("live_streams.room_type_picker.live_event.v3.bullet_2", {
                cohosts: totalHosts,
              })}
            />
            <RoomDetailsText
              iconType="16-live"
              text={t("live_streams.room_type_picker.live_event.v3.bullet_3")}
            />
            <RoomDetailsText
              iconType="20-members"
              text={t("live_streams.room_type_picker.live_event.v3.bullet_4")}
            />
          </ul>
        </RoomTypeOption>
      </div>

      <div className="h-full" data-testid="start-a-live-room">
        <RoomTypeOption
          title={t("live_streams.room_type_picker.live_room.title")}
          onClick={() => onChange(ROOM_TYPES.CONFERENCE)}
          isSelected={value === ROOM_TYPES.CONFERENCE}
        >
          <ul className="order-1 flex w-[277.33px] flex-none list-outside flex-col items-start gap-3 self-stretch p-0">
            <RoomDetailsText
              iconType="20-checkmark-circle"
              text={t("live_streams.room_type_picker.live_room.v3.bullet_1")}
            />
            <RoomDetailsText
              iconType="20-members"
              text={t("live_streams.room_type_picker.live_room.v3.bullet_2", {
                participants: totalParticipants,
              })}
            />
            <RoomDetailsText
              iconType="16-video"
              text={t("live_streams.room_type_picker.live_room.v3.bullet_3")}
            />
            <RoomDetailsText
              iconType="16-chat"
              text={t("live_streams.room_type_picker.live_room.v3.bullet_4")}
            />
          </ul>
        </RoomTypeOption>
      </div>

      <div className="h-full" data-testid="start-a-live-stream">
        <RoomTypeOption
          title={t("live_streams.room_type_picker.live_stream.title")}
          onClick={() => onChange(ROOM_TYPES.STREAM)}
          isSelected={value === ROOM_TYPES.STREAM}
        >
          <ul className="order-1 flex w-[277.33px] flex-none list-outside flex-col items-start gap-3 self-stretch p-0">
            <RoomDetailsText
              iconType="20-checkmark-circle"
              text={t("live_streams.room_type_picker.live_stream.v3.bullet_1")}
            />
            <RoomDetailsText
              iconType="20-members"
              text={t("live_streams.room_type_picker.live_stream.v3.bullet_2", {
                spectators: totalSpectators,
              })}
            />
            <RoomDetailsText
              iconType="16-video"
              text={t("live_streams.room_type_picker.live_stream.v3.bullet_3")}
            />
            <RoomDetailsText
              iconType="16-chat"
              text={t("live_streams.room_type_picker.live_stream.v3.bullet_4")}
            />
          </ul>
        </RoomTypeOption>
      </div>
    </div>
  );
};
