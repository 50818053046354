import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import I18n from "@/i18n-js/instance";
import {
  reactQueryGet,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { getLessonKey } from "@circle-react/hooks/courses/useCoursesApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useLessons = ({ forEdition = false }) => {
  const { lessonId, sectionId } = useParams();
  const queryClient = useQueryClient();
  const { data: space } = useCurrentSpaceContext();
  const toast = useToast();

  const {
    data: currentLesson,
    isLoading,
    remove: refetchLesson,
  } = useQuery(
    ["lesson", lessonId, space?.id, sectionId],
    () =>
      reactQueryGet(
        internalApi.courses.getLesson({
          courseId: space.id,
          lessonId,
          sectionId,
          params: { for_edition: forEdition },
        }),
      ),
    {
      enabled: !!lessonId && !!space && !!sectionId,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: e => {
        if (e?.status === 401) {
          window.location.href = "/";
        }
      },
    },
  );

  const { mutate: updateLesson, isLoading: isUpdatingLesson } = useMutation(
    data =>
      reactQueryPut(
        internalApi.courses.updateLesson({
          courseId: space.id,
          lessonId,
          sectionId,
        }),
        { course_lesson: data },
      ),
    {
      onSuccess: () => {
        refetchLesson();
        queryClient.removeQueries(getLessonKey(lessonId));
        toast.success(I18n.t("courses.edit.lesson.changes_saved"));
      },
      onError: e => {
        toast.error(e.errorDetails);
      },
    },
  );

  const { mutate: updateFilesOrder } = useMutation(({ filesOrder }) =>
    reactQueryPut(
      internalApi.courses.updateLesson({
        courseId: space.id,
        lessonId,
        sectionId,
      }),
      {
        course_lesson: {
          files_order: filesOrder,
        },
      },
    ),
  );

  return {
    currentLesson,
    isLoading,
    updateLesson,
    isUpdatingLesson,
    updateFilesOrder,
  };
};
