import { t } from "@/i18n-js/instance";
import { useCurrentSpaceContext } from "@circle-react/contexts";

export const useCourseCustomLabels = () => {
  const { data: space } = useCurrentSpaceContext();

  const lessonLabel = space?.lesson_label || "lesson";
  const sectionLabel = space?.section_label || "section";

  return {
    lessonLabel,
    sectionLabel,
    translatedLabels: {
      lesson: {
        singular: t(`courses.labels.${lessonLabel}.singular`),
        plural: t(`courses.labels.${lessonLabel}.plural`),
      },
      section: {
        singular: t(`courses.labels.${sectionLabel}.singular`),
        plural: t(`courses.labels.${sectionLabel}.plural`),
      },
    },
  };
};
