import { userPaths } from "@/react/helpers/urlHelpers";

export const rewriteLoginRoutes = parentElement => {
  if (!parentElement) {
    return;
  }
  const { href } = window.location;
  parentElement
    .querySelectorAll("a[data-dynamic-login-link]")
    .forEach(loginButton => {
      const currentLink = new URL(loginButton.href);
      const urlSearchParams = new URLSearchParams(currentLink.search);
      urlSearchParams.set("post_login_redirect", encodeURI(href));
      currentLink.search = urlSearchParams.toString();
      loginButton.href = currentLink.toString();
    });
};

export const getEventRegistrationUrl = (
  canUsersSignUp,
  userSignUpUrl,
  isRecurring = false,
) => {
  let url;

  const params = isRecurring
    ? { post_login_redirect: window.location.href }
    : { post_login_redirect: window.location.href, signup: true };
  if (canUsersSignUp) {
    url = userSignUpUrl;
  } else {
    url = userPaths.signIn({
      params: params,
    });
  }
  return url;
};
