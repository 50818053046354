import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { SelectedTopicProps } from "./SelectedTopic";
import { SelectedTopic } from "./SelectedTopic";

export const SortableSelectedTopic = ({
  topic,
  ...rest
}: SelectedTopicProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: topic?.id || "" });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.33 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SelectedTopic topic={topic} {...rest} />
    </div>
  );
};
