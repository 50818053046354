import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import { PROFILE_SCROLLABLE_TARGET_ID } from "@circle-react/components/ProfileModalV3/Body";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { useProfileSpaces } from "@circle-react/hooks/profile/useProfileSpaces";
import EmptyState from "../EmptyState";
import { Space } from "./Space";

export const ProfileSpaces = () => {
  const { member, onClose } = useProfileContext();
  const { spaces, isLoading, fetchNextPage, hasNextPage } = useProfileSpaces({
    memberPublicId: member.public_uid,
  });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (spaces.length < 1) {
    return (
      <EmptyState
        title={t("profile_drawer.spaces.empty_state.title")}
        subtitle={t("profile_drawer.spaces.empty_state.subtitle")}
      />
    );
  }

  return (
    <InfiniteScroll
      scrollThreshold={0.5}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<LoaderRow />}
      dataLength={spaces.length}
      scrollableTarget={PROFILE_SCROLLABLE_TARGET_ID}
    >
      {spaces.map(space => (
        <Space key={space.id} onClose={() => onClose?.()} space={space} />
      ))}
    </InfiniteScroll>
  );
};
