import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Cropper from "react-easy-crop";
import type { SelectedImage } from "../../store";
import { useImagePostModalStore } from "../../store";

interface MainImageProps {
  currentImage: SelectedImage;
}

export const MainImage = ({ currentImage }: MainImageProps) => {
  const [imageObject, setImageObject] = useState<HTMLImageElement | null>(null);
  const { updateImage, galleryAspectRatio, setGalleryAspectRatio } =
    useImagePostModalStore();

  useEffect(() => {
    const image = new Image();
    image.src = currentImage.preview;
    image.onload = () => {
      setImageObject(image);
    };
  }, [currentImage.preview]);

  useEffect(() => {
    if (!galleryAspectRatio && imageObject) {
      const { width, height } = imageObject;
      const aspectRatio = width / height;
      setGalleryAspectRatio(aspectRatio);
    }
  }, [galleryAspectRatio, imageObject]);

  if (!imageObject || !galleryAspectRatio) return null;

  return (
    <div className="relative flex aspect-video max-w-full grow cursor-pointer">
      <Cropper
        image={currentImage.preview}
        crop={currentImage.crop}
        aspect={galleryAspectRatio}
        zoom={currentImage.zoom}
        onCropChange={crop => {
          if (crop === currentImage.crop) return;
          updateImage({ ...cloneDeep(currentImage), crop });
        }}
        onZoomChange={zoom => {
          if (zoom === currentImage.zoom) return;
          updateImage({ ...cloneDeep(currentImage), zoom });
        }}
        onCropComplete={(_, croppedAreaPixels) => {
          updateImage({ ...cloneDeep(currentImage), croppedAreaPixels });
        }}
      />
    </div>
  );
};
