import { t } from "@/i18n-js/instance";
import { ScoreCell } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { hasSocialLinks } from "@circle-react/helpers/memberHelper";
import { useHideEmailsOnMemberProfiles } from "@circle-react/hooks/useHideEmailsOnMemberProfiles";
import { useIsActivityScoreEnabledOrUpgradable } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ScoreContextProvider } from "@circle-react-shared/ActivityScore";
import {
  UpgradeFlow,
  useIsProfileFieldsEnabled,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { Typography } from "@circle-react-uikit/Typography";
import { ProfileFieldsList } from "./ProfileFieldsList";
import { ProfileInfoContainer } from "./ProfileInfoContainer";
import { ProfileLinkContainer } from "./ProfileLinkContainer";
import { Section } from "./Section";

export interface ProfileAboutComponentProps {
  member: any;
}

export const ProfileAboutComponent = ({
  member,
}: ProfileAboutComponentProps) => {
  const isEmail = Object.prototype.hasOwnProperty.call(member, "email");
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();
  const isActivityScoreEnabledOrUpgradable =
    useIsActivityScoreEnabledOrUpgradable();
  const shouldHideEmailsOnMemberProfiles = useHideEmailsOnMemberProfiles();
  const isSmScreen = useSmScreenMediaQuery();
  const visibleMemberTags = member.member_tags?.filter(
    (memberTag: any) =>
      memberTag?.is_public && memberTag?.display_locations?.profile_page,
  );
  const hasVisibleMemberTags = visibleMemberTags?.length > 0;

  return (
    <div className="profile-drawer__about">
      {!isProfileFieldsEnabled && member.bio && (
        <Section
          dataTestId="biography-section"
          title={t("profile_drawer.biography")}
        >
          <ProfileInfoContainer
            info={member.bio}
            dataTestId="profile-member-bio"
          />
        </Section>
      )}

      <Section dataTestId="profile-info-section">
        {!shouldHideEmailsOnMemberProfiles && isEmail && (
          <ProfileInfoContainer
            iconType="at-symbol"
            info={member.email}
            dataTestId="profile-member-email"
          />
        )}
        {!isProfileFieldsEnabled && (
          <ProfileInfoContainer
            iconType="map-pin"
            iconProps={{ viewBox: "0 0 14 18" }}
            info={member.location}
            dataTestId="profile-member-location"
          />
        )}
        <ProfileInfoContainer
          iconType="calendar"
          info={t("profile_drawer.member_since", {
            member_since: formatDateString(member.created_at, {
              month: "long",
              year: "numeric",
              day: "numeric",
            }),
          })}
          dataTestId="profile-member-since"
        />
        {Boolean(member.last_seen_at) && (
          <ProfileInfoContainer
            iconType="16-clock"
            info={t("profile_drawer.last_seen", {
              last_seen: dateStringToTimeAgo(member.last_seen_at),
            })}
            iconProps={{
              viewBox: "0 0 18 18",
              className: "text-dark ml-0.5 mt-0.5",
            }}
          />
        )}
      </Section>

      {isActivityScoreEnabledOrUpgradable && (
        <Section dataTestId="activity-score-section">
          <div className="flex flex-col gap-y-3">
            <Typography.LabelSm weight="semibold">
              {t("activity_score.activity_score_title")}
            </Typography.LabelSm>
            <ScoreContextProvider ids={[member.id]}>
              <ScoreCell memberId={member.id} />
            </ScoreContextProvider>
          </div>
        </Section>
      )}

      {hasVisibleMemberTags && !isSmScreen && (
        <Section title={t("profile_drawer.tags")}>
          <div className="profile-drawer__about__tags">
            <MemberTags limit={Infinity} memberTags={visibleMemberTags} />
          </div>
        </Section>
      )}

      {shouldShowProfileFieldsUpgradeModal && (
        <Section title={t("profile_fields.upgrade_flow.title")}>
          <UpgradeFlow />
        </Section>
      )}

      {isProfileFieldsEnabled && (
        <ProfileFieldsList profileFields={member.profile_fields} />
      )}

      {!isProfileFieldsEnabled && hasSocialLinks(member) && (
        <Section title={t("profile_drawer.social")}>
          <ProfileLinkContainer iconType="globe" link={member.website_url} />
          <ProfileLinkContainer
            iconType="facebook"
            link={member.facebook_url}
          />
          <ProfileLinkContainer iconType="twitter" link={member.twitter_url} />
          <ProfileLinkContainer
            iconType="linkedin"
            link={member.linkedin_url}
          />
          <ProfileLinkContainer
            iconType="instagram"
            link={member.instagram_url}
          />
        </Section>
      )}
    </div>
  );
};
