import { useEffect, useRef } from "react";

export const useDebounceApi = () => {
  const timeoutRef = useRef(null);

  useEffect(() => clearTimeout(timeoutRef.current), []);

  const debouncedFunction = (dynamicFunction, delay, ...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      dynamicFunction(...args);
    }, delay);
  };

  return { debouncedFunction };
};
