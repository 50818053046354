import { useHistory } from "react-router-dom";
import { scrollToMessage } from "../../helpers";

export const useRedirectToMessage = () => {
  const history = useHistory();

  const redirectToMessage = (message: any) => {
    const { id, parent_message_id: parentMessageId } = message;
    if (parentMessageId) {
      history.push({
        hash: `message_${parentMessageId}`,
        search: `message_id=${id}`,
      });
    } else {
      history.push({ hash: `message_${id}` });
    }
  };

  const handlePinnedMessage = (message: any) => {
    const messageElement = document.getElementById(message.id);

    if (messageElement) {
      scrollToMessage(message.id);
    } else {
      redirectToMessage(message);
    }
  };

  return { redirectToMessage, handlePinnedMessage };
};
