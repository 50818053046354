import { useEffect, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import {
  activeStorageBlobUrl,
  directUploadPath,
} from "@circle-react/helpers/urlHelpers";

interface Response {
  blob_url: string;
  signed_id: string;
  filename: string;
}

export interface FileUploadState {
  progress: number;
  status: "idle" | "uploading" | "completed" | "error";
  response: Response;
}

export const useParallelDirectUploads = () => {
  const [fileStates, setFileStates] = useState<FileUploadState[]>([]);
  const [overallProgress, setOverallProgress] = useState(0);

  const uploadFiles = (files: any) => {
    const initialStates = files.map(() => ({
      progress: 0,
      status: "idle",
    }));

    setFileStates(initialStates);

    files.forEach((file: any, idx: any) => {
      const upload = new DirectUpload(file, directUploadPath(), {
        directUploadWillStoreFileWithXHR: xhr => {
          xhr.upload.addEventListener("loadstart", () => {
            setFileStates(prevStates => {
              const updatedStates = [...prevStates];
              updatedStates[idx] = {
                ...updatedStates[idx],
                status: "uploading",
              };
              return updatedStates;
            });
          });
          xhr.upload.addEventListener("progress", e => {
            setFileStates(prevStates => {
              const updatedStates = [...prevStates];
              updatedStates[idx] = {
                ...updatedStates[idx],
                progress: (e.loaded / e.total) * 100,
              };
              return updatedStates;
            });
          });
        },
      });
      upload.create((error, response) => {
        if (error) {
          setFileStates(prevStates => {
            const updatedStates = [...prevStates];
            updatedStates[idx] = { ...updatedStates[idx], status: "error" };
            return updatedStates;
          });
          throw new Error(`Direct upload failed: ${error?.message}`);
        } else {
          setFileStates(prevStates => {
            const updatedStates = [...prevStates];
            updatedStates[idx] = {
              ...updatedStates[idx],
              status: "completed",
              progress: 100,
              response: {
                blob_url: activeStorageBlobUrl({
                  signed_id: response.signed_id,
                  filename: encodeURIComponent(response.filename),
                }),
                ...response,
              },
            };
            return updatedStates;
          });
        }
      });
    });
  };

  useEffect(() => {
    const totalProgress = fileStates.reduce(
      (acc, curr) => acc + curr.progress,
      0,
    );
    setOverallProgress(
      fileStates.length ? totalProgress / fileStates.length : 0,
    );
  }, [fileStates]);

  const reset = () => {
    setFileStates([]);
    setOverallProgress(0);
  };

  let status;

  if (fileStates.some(file => file.status === "error")) {
    status = "error";
  } else if (fileStates.every(file => file.status === "idle")) {
    status = "idle";
  } else if (fileStates.every(file => file.status === "completed")) {
    status = "completed";
  } else {
    status = "uploading";
  }

  return {
    status,
    fileStates,
    overallProgress,
    reset,
    uploadFiles,
  };
};
