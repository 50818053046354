import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useCreateLiveMutation = (roomType, onClose) => {
  const { success, error } = useToast();
  const history = useHistory();

  return useMutation(
    formData => liveStreamApi.create({ formData, forReactQuery: true }),
    {
      onSuccess: room => {
        onClose && onClose();
        success(t(`live_streams.form.${roomType}.created_successfully`));
        history.push(liveStreamPaths.room({ roomSlug: room.slug }));
      },
      onError: () => error(t("live_streams.form.create_error")),
    },
  );
};
