import { BrowserRouter, Route } from "react-router-dom";
import type { SignUpProps } from "@circle-react/components/OnboardingPage/SignUp";
import { OnboardingPage } from "@circle-react/pages/Communities/Onboarding";
import { CreateUserProfilePage } from "@circle-react/pages/Settings/Profile";
import { DiscoverNewPasswordPage } from "@circle-react/pages/Users/DiscoverNewPassword";
import { DiscoverSignInPage } from "@circle-react/pages/Users/DiscoverSignIn";
import { DiscoverSignUpPage } from "@circle-react/pages/Users/DiscoverSignUp";
import { NewPasswordPage } from "@circle-react/pages/Users/NewPassword";
import { SignInPage } from "@circle-react/pages/Users/SignIn";
import { SignUpPage } from "@circle-react/pages/Users/SignUp";

export const AuthenticationRoutes = ({
  hasEmail = false,
  otpSignInResponse,
  setOtpSignInResponse,
}: SignUpProps) => (
  <BrowserRouter>
    <Route path="/user/sign_in" component={DiscoverSignInPage} />
    <Route path="/user/sign_up">
      <DiscoverSignUpPage
        hasEmail={hasEmail}
        otpSignInResponse={otpSignInResponse}
        setOtpSignInResponse={setOtpSignInResponse}
      />
    </Route>
    <Route path="/user/password" component={DiscoverNewPasswordPage} />

    <Route path="/users/login" component={SignInPage} />
    <Route path="/users/sign_in" component={SignInPage} />
    <Route path="/sign_in" component={SignInPage} />
    <Route path="/sign_up">
      <SignUpPage
        hasEmail={hasEmail}
        otpSignInResponse={otpSignInResponse}
        setOtpSignInResponse={setOtpSignInResponse}
      />
    </Route>
    <Route path="/users/password/new" component={NewPasswordPage} />
    <Route path="/communities" component={OnboardingPage} />
    <Route path="/settings/profile" component={CreateUserProfilePage} />
  </BrowserRouter>
);
