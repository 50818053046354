import { useEffect, useState } from "react";
import { flatMap } from "lodash";
import reject from "lodash/reject";
import uniqBy from "lodash/uniqBy";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const DEFAULT_PAGE_SIZE = 10;
export const COMMENTS_BASE_QUERY_KEY = "comments";
const COMMENTS_LIST_QUERY_KEY = "list";

export const getCommentsListKey = ({
  postId,
  parentCommentId,
  usedIn = null,
}) =>
  [
    COMMENTS_BASE_QUERY_KEY,
    postId,
    COMMENTS_LIST_QUERY_KEY,
    parentCommentId,
    usedIn,
  ].filter(Boolean);

export const useCommentsList = ({
  postId,
  parentCommentId,
  preloaded = false,
  preloadedComments = [],
  preloadedCommentsTotalCount = 0,
  postCommentsClosed,
  postLikingDisabled,
  usedIn = "post",
  //, refetch eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
}) => {
  const [comments, setComments] = useState(preloadedComments || []);
  const [areCommentsClosed, setAreCommentsClosed] =
    useState(postCommentsClosed);
  const [isLikingDisabled, setIsLikingDisabled] = useState(postLikingDisabled);

  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, remove } =
    useInfiniteQuery(
      getCommentsListKey({ postId, parentCommentId, usedIn }),
      ({ pageParam = 1 }) => {
        const pageParams = { per_page: DEFAULT_PAGE_SIZE, page: pageParam };
        const params = parentCommentId
          ? { parent_comment_id: parentCommentId, ...pageParams }
          : pageParams;

        return reactQueryGet(
          internalApi.comments.index({
            postId: postId,
            params,
          }),
        );
      },
      {
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onSuccess: ({ pages }) => {
          setComments(
            uniqBy(
              flatMap(pages, page => page.records),
              "id",
            ),
          );
        },
        initialData: () => {
          if (preloaded) {
            return {
              pages: [
                {
                  records: preloadedComments,
                  page: 0,
                  has_next_page:
                    preloadedCommentsTotalCount > preloadedComments.length,
                },
              ],
              pageParams: [],
            };
          }
        },
        cacheTime: 0,
        staleTime: Infinity,
        notifyOnChangeProps: "tracked",
      },
    );

  const didPermissionsChange =
    postCommentsClosed !== areCommentsClosed ||
    postLikingDisabled !== isLikingDisabled;

  // Fully reload comments when closing/opening comments and disabling/enabling
  // likes. This ensures that permissions are correctly updated in the UI.
  useEffect(() => {
    if (!preloaded && didPermissionsChange) {
      setAreCommentsClosed(postCommentsClosed);
      setIsLikingDisabled(postLikingDisabled);
      setComments([]);
      remove();
    }
  }, [
    preloaded,
    didPermissionsChange,
    postCommentsClosed,
    postLikingDisabled,
    remove,
  ]);

  const addCommentToList = comment =>
    setComments(prevComments => [...prevComments, comment]);
  const updateCommentInList = comment =>
    setComments(prevComments =>
      prevComments.map(prevComment =>
        prevComment.id === comment.id ? comment : prevComment,
      ),
    );
  const removeCommentFromList = ({ id }) =>
    setComments(prevComments => reject(prevComments, ["id", id]));

  return {
    comments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    addCommentToList,
    updateCommentInList,
    removeCommentFromList,
  };
};
