import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@/react/contexts";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { getEmbedValidationSchema } from "@circle-react/components/shared/uikit/TipTapBlockEditor/EmbedExtension/constants";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

interface FormData {
  url: string;
}
interface EmbedProps {
  createEmbed: (url: string) => void;
  onClose: () => void;
}
export const Embed = ({ createEmbed, onClose }: EmbedProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isIframelyEnabled = Boolean(currentCommunitySettings?.iframely_enabled);
  const handleSubmit = (data: FormData) => {
    createEmbed(data.url);
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={getEmbedValidationSchema({
        allowUnsupportedURLs: isIframelyEnabled,
      })}
    >
      <div
        className="mx-8 flex h-full flex-col items-center rounded-md"
        role="tabpanel"
      >
        <Form.Item
          name="url"
          fullWidth
          hideBorder
          hideLabel
          hideDescription
          className="!pt-0"
          placeholder={t("courses.edit.lesson.embed_placeholder")}
        >
          <Form.Input />
        </Form.Item>
        <div className="mt-12 text-center">
          <Button type="submit" variant="primary">
            {t("courses.edit.lesson.embed_media")}
          </Button>
          <div className="mt-2">
            <Typography.LabelSm weight="semibold">
              {t("courses.edit.lesson.embed_description")}{" "}
              <span className="border-primary inline-flex border-b border-dotted">
                <Tippy
                  placement="bottom-end"
                  content={t("courses.edit.lesson.embed_providers_list")}
                  className="inline-flex"
                >
                  {t("courses.edit.lesson.embed_description_more")}
                </Tippy>
              </span>
            </Typography.LabelSm>
          </div>
        </div>
      </div>
    </Form>
  );
};
