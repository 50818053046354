import { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { SubmitButton } from "@/react/components/QuickPost/ModalForm/SubmitButton";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { useSmScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { usePreventAccidentalPageLeave } from "@/react/hooks/usePreventAccidentalPageLeave";
import { POST_FORM_CACHE } from "@circle-react/components/constants";
import {
  useCurrentPostContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { usePersistFormFields } from "@circle-react/hooks/utils/usePersistFormFields";
import { useSubmitViaKeyboard } from "@circle-react/hooks/utils/useSubmitViaKeyboard";
import { DisplayNone } from "../../shared/DisplayNone";
import { ErrorMessages } from "../../shared/Form/ErrorMessages";
import { Icon } from "../../shared/Icon";
import { If } from "../../shared/If";
import { Modal_DEPRECATED } from "../../shared/Modal";
import { SpaceSelect } from "../../shared/SpaceSelect";
import { MainSection } from "./MainSection";
import { SettingsSection } from "./SettingsSection";

export const ModalContent = ({ show, closeModal }) => {
  const [settingsView, setSettingsView] = useState(false);
  const [fullPageModal, setFullPageModal] = useState(false);
  const [showAddCoverBtn, setShowAddCoverBtn] = useState();
  const spaceContext = useSpacesContext();
  const currentPostContext = useCurrentPostContext();
  const post = currentPostContext.helpers.postForTrixEditor();
  const smScreen = useSmScreenMediaQuery();
  const quickPostPropsLoaded = spaceContext.helpers.isDataLoaded() && post;
  const spaces = spaceContext.helpers.basicSpacesWithPostCreationAllowed();
  const {
    watch,
    formState: {
      dirtyFields,
      errors,
      isSubmitting,
      isDirty,
      isSubmitSuccessful,
    },
  } = useFormContext();
  const { space_id, name, body, id } = watch();
  const space = spaces.find(space => space.id === space_id);
  const backArrow = () => {
    setSettingsView(false);
  };
  const submitDisabled = (!name && !body) || isSubmitting || isSubmitSuccessful;
  const confirmModalClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      const message = t("post.errors.unsaved_post_warning");
      if (confirm(message)) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };
  useSubmitViaKeyboard({ enabled: !submitDisabled });
  usePreventAccidentalPageLeave(isDirty && !isSubmitSuccessful);
  const isEditMode = !!id;
  const title = isEditMode ? "Edit Post" : "Create Post";
  let submitButtonText = !id ? "Post" : "Save";
  if (isSubmitting || isSubmitSuccessful) {
    submitButtonText = !id ? "Posting..." : "Saving...";
  }

  usePersistFormFields({
    cacheKey: POST_FORM_CACHE,
    fields: ["name", "body", "space_id", "cover_image"],
    enabled: !isEditMode && !isSubmitSuccessful,
  });

  const separator = "/";

  return (
    <Modal_DEPRECATED
      show={show}
      onClose={confirmModalClose}
      fullscreen
      closeOnEscape={!settingsView}
      trixMode={`quickpost-modal ${
        fullPageModal && "quickpost-modal__fullpage"
      }`}
    >
      <Modal_DEPRECATED.Header onClose={!settingsView && confirmModalClose}>
        <div className="modal__header__inside">
          <div className="mr-4 flex items-center gap-4">
            <If condition={!settingsView}>
              <If condition={!smScreen}>
                <button
                  type="button"
                  onClick={() => setFullPageModal(!fullPageModal)}
                >
                  <Icon type={fullPageModal ? "shrink" : "expand"} size={16} />
                </button>
              </If>
              <If condition={canManageSpace(space)}>
                <button type="button" onClick={() => setSettingsView(true)}>
                  <Icon type="settings" />
                </button>
              </If>
            </If>
            <If condition={!!settingsView}>
              <button className="flex" type="button" onClick={backArrow}>
                <Icon type="back-arrow-v2" size={16} />
              </button>
            </If>
          </div>
          <div className="modal__header--center">
            <span className="quickpost-modal__title">
              <If condition={settingsView}>{t("post.post_settings")}</If>
              <If condition={!settingsView}>
                {title}
                <span className="title-separator">{separator}</span>
              </If>
            </span>
            <If condition={!settingsView}>
              <SpaceSelect
                spaces={spaces}
                name="space_id"
                rules={{ required: t("post.errors.space_is_required") }}
              />
            </If>
          </div>
          <div className="modal__header--right">
            {!settingsView && fullPageModal && quickPostPropsLoaded && (
              <div className="flex items-center justify-end gap-4">
                <SubmitButton
                  post={post}
                  space={space}
                  submitDisabled={submitDisabled}
                  isSubmitting={isSubmitting || isSubmitSuccessful}
                  datepickerPlacement="bottom"
                />
              </div>
            )}
            <If condition={settingsView}>
              <button
                type="submit"
                onClick={backArrow}
                className="btn modal__btn--save"
              >
                {t("post.done")}
              </button>
            </If>
          </div>
        </div>
        <If condition={!settingsView}>
          <ErrorMessages errors={errors} />
        </If>
      </Modal_DEPRECATED.Header>
      <Modal_DEPRECATED.Body>
        <div className="form-section form-section__topic-content trix-v2">
          <div
            className="form-section__content"
            onMouseEnter={() => setShowAddCoverBtn(true)}
            onMouseLeave={() => setShowAddCoverBtn(false)}
          >
            {space && canManageSpace(space) && settingsView && (
              <SettingsSection space={space} spaces={spaces} />
            )}
            <DisplayNone condition={!!settingsView}>
              <MainSection space={space} showAddCoverBtn={showAddCoverBtn} />
            </DisplayNone>
          </div>
        </div>
      </Modal_DEPRECATED.Body>
      {!settingsView && !fullPageModal && (
        <div className="quickpost-modal__footer">
          <div className="quickpost-modal__footer--actions h-8 gap-4">
            <SubmitButton
              post={post}
              space={space}
              submitDisabled={submitDisabled}
              submitButtonText={submitButtonText}
              isSubmitting={isSubmitting || isSubmitSuccessful}
            />
          </div>
        </div>
      )}
    </Modal_DEPRECATED>
  );
};

ModalContent.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
};
