import { useWebSocket } from "../useWebSocket";

const channel = "NotificationChannel";

export const useNotificationWebSocket = (
  communityMemberId,
  onMessageReceive,
) => {
  useWebSocket(
    {
      channel,
      onMessageReceive,
      community_member_id: communityMemberId,
      canCreateConnection: !!communityMemberId,
    },
    [communityMemberId],
  );
};
