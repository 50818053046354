import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useInfiniteQueryHelpers } from "@/react/hooks/reactQuery/useInfiniteQueryHelpers";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const DEFAULT_PER_PAGE = 20;
export const CALENDAR_VIEW_PER_PAGE = 120;

export const useAllEvents = ({
  params = {},
  isCalendarView = false,
}: any = {}) => {
  const { removeRecord, findAndReplaceRecord } = useInfiniteQueryHelpers();
  const queryKey = [
    "all",
    "events",
    params,
    { type: isCalendarView ? "calendar" : "default" },
  ];
  const queryClient = useQueryClient();

  const onEventChange = async (event: any, { refetch = true }: any = {}) => {
    let fetchedEvent = event;
    if (refetch) {
      fetchedEvent = await queryClient.fetchQuery(
        internalApi.posts.show({
          spaceId: event.space_id,
          postSlug: event.slug,
          spaceSlug: event.space_slug,
        }),
      );
    }
    return findAndReplaceRecord(fetchedEvent, queryKey);
  };

  const onEventDestroy = (eventId: any) => {
    removeRecord(eventId, queryKey);
  };

  const fetchAllEvents = (page: any) =>
    reactQueryGet(
      internalApi.events.allEvents({
        params: {
          page,
          per_page: isCalendarView ? CALENDAR_VIEW_PER_PAGE : DEFAULT_PER_PAGE,
          ...params,
        },
      }),
    );

  const {
    data: { pages: events = [], pagesList = [] } = {},
    isLoading,
    refetch: refetchAllEvents,
    hasNextPage,
    fetchNextPage,
  }: any = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchAllEvents(pageParam),
    {
      select: ({ pages = [] }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
      keepPreviousData: isCalendarView,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? Number(lastPage.page) + 1 : undefined,
    },
  );

  const lastPage = pagesList[pagesList.length - 1];
  useEffect(() => {
    if (
      isCalendarView &&
      lastPage &&
      lastPage.count > lastPage.page * lastPage.per_page
    ) {
      fetchNextPage();
    }
  }, [lastPage, isCalendarView, fetchNextPage]);

  const perPageEventIds = pagesList.map((page: any) =>
    page.records.map((event: any) => event.id),
  );

  return {
    events,
    pagesList,
    isLoading,
    refetchAllEvents,
    hasNextPage: hasNextPage,
    fetchNextPage,
    perPageEventIds,
    onEventChange,
    onEventDestroy,
  };
};
