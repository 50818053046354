import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { useEmbeddableChatApi } from "@/react/hooks/embeddable_chat/useEmbeddableChatsApi";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import {
  useChatMessageReaction,
  useChatMessagesV2Api,
} from "@circle-react/hooks/chatsV2";
import { useChatsV2Api } from "@circle-react/hooks/chatsV2/useChatsV2Api";
import { EmptyChatRoom } from "../ChatWindowV2/EmptyChatRoom";
import { RichTextMessageBox } from "../sharedV2";
import { EmbeddableMessagesContainer } from "./EmbeddableMessagesContainer";

export const EmbeddableChat = ({
  uuid,
  displayDayChange = true,
  onNewChatMessage,
  isChatSpace = false,
  chatUserSearchRef,
  isLiveStream = false,
  isInCourseSpace = false,
  isDrawer = false,
  roles = {},
  messageBodyClassName = "",
  isChatPreview,
  userList = [],
  isChatRoomLoading = false,
  isMessaging = false,
}) => {
  const { chatRoomMessages, onEventReceive: onMessagesEventReceive } =
    useChatMessagesV2Api();
  const { onReactionWebsocketEvent } = useChatMessageReaction();

  const currentCommunityMember = useCurrentCommunityMember();

  const {
    chatRooms,
    setChatRooms,
    onEventReceive: onChatEventReceive,
    onEmbedEventReceive,
    fetchChatRoomForUuid,
  } = useChatsV2Api();
  const { chatRoom, onEventReceive: onEmbeddedEventReceive } =
    useEmbeddableChatApi(uuid);
  const currentEmbeddedChatRoom = fetchChatRoomForUuid(chatRooms, uuid);
  const chatRoomsRef = useRef([]);

  useEffect(() => {
    chatRoomsRef.current = chatRooms;
  }, [chatRooms]);

  useEffect(() => {
    if (chatRoom && !currentEmbeddedChatRoom) {
      setChatRooms([chatRoom, ...chatRooms]);
    }
  }, [chatRoom, currentEmbeddedChatRoom, chatRooms, setChatRooms]);

  useWebSocket(
    {
      channel: "Chats::RoomChannel",
      chat_room_uuid: chatRoom?.uuid,
      onMessageReceive: data => {
        if (data.event === "newMessage") {
          onChatEventReceive(
            data,
            currentCommunityMember?.id,
            chatRoomsRef.current,
            uuid === chatRoom?.uuid,
          );
          if (onNewChatMessage && data?.json_message) {
            onNewChatMessage(data.json_message);
          }
        }
        onMessagesEventReceive(data, chatRoomMessages);
        onEmbeddedEventReceive(data);
      },
      canCreateConnection: Boolean(
        chatRoom?.uuid && currentCommunityMember?.id,
      ),
    },
    [chatRoom?.uuid, currentCommunityMember?.id],
  );

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: data => {
        onReactionWebsocketEvent(data);
        onEmbedEventReceive(data);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: chatRoom?.uuid && currentCommunityMember?.id,
    },
    [chatRoom?.uuid, currentCommunityMember?.id],
  );

  if (!currentEmbeddedChatRoom) {
    if (!isMessaging) {
      return null;
    }
    if (uuid === "new" && (userList.length === 0 || isChatRoomLoading)) {
      return <RichTextMessageBox onClick={noop} disabled />;
    }
    return <EmptyChatRoom userList={userList} />;
  }

  return (
    <EmbeddableMessagesContainer
      chatRoom={{
        ...currentEmbeddedChatRoom,
        first_unread_message_id: chatRoom?.first_unread_message_id,
        current_participant: chatRoom
          ? chatRoom.current_participant
          : currentEmbeddedChatRoom.current_participant,
        pinned_message: chatRoom?.pinned_message,
      }}
      mobileViewSection={noop}
      displayDayChange={displayDayChange}
      isChatSpace={isChatSpace}
      chatUserSearchRef={chatUserSearchRef}
      isLiveStream={isLiveStream}
      isInCourseSpace={isInCourseSpace}
      isDrawer={isDrawer}
      roles={roles}
      messageBodyClassName={messageBodyClassName}
      isChatPreview={isChatPreview}
    />
  );
};

EmbeddableChat.propTypes = {
  uuid: PropTypes.string.isRequired,
  displayDayChange: PropTypes.bool,
  displayRoomName: PropTypes.bool,
  onNewChatMessage: PropTypes.func,
  isChatSpace: PropTypes.bool,
  chatUserSearchRef: PropTypes.number,
  isLiveStream: PropTypes.bool,
  isInCourseSpace: PropTypes.bool,
  isDrawer: PropTypes.bool,
  roles: PropTypes.object,
  isChatPreview: PropTypes.bool,
  isMessaging: PropTypes.bool,
};
