import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

export const SearchInput = ({ isLoading, query, setQuery }) => (
  <div className="bg-primary sticky top-0 isolate z-10 flex items-center justify-between p-4 md:static md:top-auto md:p-0">
    <div className="mr-0 flex w-full items-center md:p-5">
      <div className="bg-secondary sm:bg-primary border-secondary flex w-full items-center gap-3 rounded-md border px-2 py-1 sm:rounded-md sm:px-2 sm:py-1">
        <div className="flex">
          {isLoading ? (
            <Icon
              type="loader"
              className="text-default"
              size={20}
              useWithFillCurrentColor
            />
          ) : (
            <Icon
              type="16-search"
              className="text-default"
              size={16}
              useWithFillCurrentColor
            />
          )}
        </div>
        <input
          className="text-dark sm:bg-primary placeholder:text-light h-6 w-full rounded bg-transparent text-sm outline-none placeholder:opacity-100 sm:rounded-none"
          placeholder={t("view_only_masquerading.input_placeholder")}
          aria-autocomplete="list"
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
        <button
          type="submit"
          className="text-default flex pt-1"
          onClick={() => setQuery("")}
        >
          {!!query && <Icon type="20-close-md" useWithFillCurrentColor />}
        </button>
      </div>
    </div>
  </div>
);
