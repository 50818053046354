import { useEffect } from "react";
import { pick } from "lodash";
import { useWatch } from "react-hook-form";
import { useLocalStorage } from "./useLocalStorage";

export const usePersistFormFields = ({
  cacheKey,
  fields = [],
  enabled = true,
}) => {
  const fieldValues = useWatch();
  const formFieldsToPersist = pick(fieldValues, fields);
  const { setValue: setPersistedValue } = useLocalStorage(cacheKey);

  useEffect(() => {
    if (enabled) {
      setPersistedValue(formFieldsToPersist);
    }
  }, [formFieldsToPersist]);
};

export const usePersistedFormFieldValues = ({ cacheKey }) => {
  if (!cacheKey) throw new Error("CacheKey is required");
  const { getValue: getPersistedFormFieldValues, resetValue: resetValues } =
    useLocalStorage(cacheKey);

  return { getPersistedFormFieldValues, resetValues };
};
