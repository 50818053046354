import { t } from "@/i18n-js/instance";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { FieldLabel } from "../../shared/Form/FieldLabel";
import { FormInput } from "./FormInput";

const attributes = [
  "meta_title",
  "meta_description",
  "opengraph_title",
  "opengraph_description",
];

export const MetaAttributesSection = () => (
  <div className="form-section" data-controller="toggle">
    <div className="form-section__header has-toggle">
      <button
        type="button"
        data-action="click->toggle#toggle"
        data-target="toggle.toggleTrigger"
        data-open="false"
        className="block-toggle"
      >
        {t("meta_tag_attributes.meta_tags")}
      </button>
    </div>
    <div className="form-section__content" data-target="toggle.toggleable">
      {attributes.map(attribute => (
        <FormInput
          key={attribute}
          id={attribute}
          label={t(`meta_tag_attributes.${attribute}`)}
          description={t(`meta_tag_attributes.${attribute}_description`, {
            obj: "post",
          })}
          name={`meta_tag_attributes.${attribute}`}
        />
      ))}
      <div className="form-group form-group--two-columns">
        <FieldLabel
          field="opengraph_image"
          label={t("meta_tag_attributes.open_graph_image")}
        />
        <ImageInput
          name="meta_tag_attributes.opengraph_image"
          aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
        />
      </div>
    </div>
  </div>
);
