import { t } from "@/i18n-js/instance";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";

interface RsvpStatusBadgeProps {
  rsvpStatus: "yes" | "no" | "invited";
}

export const RsvpStatusBadge = ({ rsvpStatus }: RsvpStatusBadgeProps) => {
  if (!rsvpStatus) {
    return null;
  }

  const badgeVariant = rsvpStatus === "yes" ? "success" : "default";
  const badgeLabel =
    rsvpStatus === "yes"
      ? t("events.rsvp.attending")
      : rsvpStatus === "invited"
        ? t("events.rsvp.invited")
        : t("events.rsvp.not_attending");

  return <StatusBadge variant={badgeVariant}>{badgeLabel}</StatusBadge>;
};
