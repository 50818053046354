import { UserImage } from "@circleco/ui-kit";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ChatIcon } from "../Chat/ChatIcon";
import { BlockInViewOnlyMode } from "../ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Badge } from "./Badge";
import { CopyProfileLink } from "./CopyProfileLink";
import { EditProfileButton } from "./EditProfileButton";
import { MobileProfileHeader } from "./MobileProfileHeader";
import { ViewOnlyMasqueradeButton } from "./ViewOnlyMasqueradeButton";
import { useMemberProfileActions } from "./useMemberProfileActions";

export interface ProfileHeaderProps {
  member: any;
  onSendMessageClick: (chatRoomUuid: string) => void;
  isFullPageProfile?: boolean;
  shouldHideMessageButton?: boolean;
  shouldOpenInNewTab?: boolean;
}

export const ProfileHeader = ({
  member,
  onSendMessageClick,
  isFullPageProfile,
  shouldHideMessageButton,
  shouldOpenInNewTab,
}: ProfileHeaderProps) => {
  const isSmScreen = useSmScreenMediaQuery();

  const {
    shouldShowEditProfileButton,
    onClickOpenEditProfileDrawer,
    shouldShowMemberChatIcon,
    canEditProfile,
    canViewOnlyMasquerade,
  } = useMemberProfileActions({
    memberPublicId: member.public_id,
    member,
    isFullPageProfile,
    shouldHideMessageButton,
  });

  if (isSmScreen) {
    return (
      <MobileProfileHeader
        member={member}
        shouldAutoNavigate={!shouldOpenInNewTab}
        onSendMessageClick={onSendMessageClick}
        shouldHideMessageButton={shouldHideMessageButton}
        isFullPageProfile={isFullPageProfile}
      />
    );
  }

  return (
    <div className="profile-drawer__header">
      <div className="relative flex items-center justify-center">
        <UserImage
          size="24"
          src={member.avatar_url}
          name={member.name}
          isFullRounded
        />
        <div className="absolute -bottom-2 flex items-center gap-1">
          <Badge member={member} />
        </div>
      </div>
      <div className="profile-drawer__header__info">
        <div>
          <h3 className="profile-drawer__header__name">{member.name}</h3>
        </div>
        <BlockInViewOnlyMode>
          <div className="profile-drawer__header__actions">
            {shouldShowMemberChatIcon && (
              <ChatIcon
                communityMemberId={member.id}
                onClick={onSendMessageClick}
                shouldAutoNavigate={!shouldOpenInNewTab}
                variant={canViewOnlyMasquerade ? "icon" : "button"}
              />
            )}
            {isFullPageProfile ? (
              canEditProfile && (
                <EditProfileButton
                  toggleDrawer={onClickOpenEditProfileDrawer}
                  memberId={member.public_id}
                />
              )
            ) : (
              <CopyProfileLink member={member} />
            )}
            {canViewOnlyMasquerade && (
              <ViewOnlyMasqueradeButton member={member} />
            )}
            {shouldShowEditProfileButton && (
              <EditProfileButton
                toggleDrawer={onClickOpenEditProfileDrawer}
                memberId={member.public_id}
              />
            )}
          </div>
        </BlockInViewOnlyMode>
      </div>
    </div>
  );
};
