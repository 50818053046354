import { t } from "@/i18n-js/instance";

export interface MessageIndicatorLinesProps {
  showDateChangeLine?: boolean;
  showUnreadMessageLine?: boolean;
  day: string;
}

export const MessageIndicatorLines = ({
  showDateChangeLine = false,
  showUnreadMessageLine = false,
  day,
}: MessageIndicatorLinesProps) => {
  if (showDateChangeLine && showUnreadMessageLine) {
    return (
      <div
        id="unread-message-breakpoint"
        className="before:border-v2-danger relative z-0 mx-5 flex items-center justify-center before:bottom-0 before:left-0 before:top-1/2 before:z-[-1] before:m-0 before:w-full before:border-t before:border-solid before:content-['']"
      >
        <span className="text-disabled bg-primary text-xxs absolute border-none px-[15px] py-[5px] font-bold uppercase">
          {day}
        </span>
        <span className="text-v2-danger text-xxs border-none py-[5px] pl-[10px] font-semibold uppercase">
          {t("messaging.new")}
        </span>
      </div>
    );
  }

  if (showDateChangeLine) {
    return (
      <div className="before:border-primary relative z-0 mx-5 flex items-center justify-center before:absolute before:bottom-0 before:left-0 before:top-1/2 before:z-[-1] before:m-0 before:w-full before:border-t before:border-solid before:content-['']">
        <span className="bg-primary text-disabled text-xxs border-none px-[15px] py-[5px] font-bold uppercase">
          {day}
        </span>
      </div>
    );
  }

  if (showUnreadMessageLine) {
    return (
      <div
        id="unread-message-breakpoint"
        className="before:border-v2-danger relative z-0 mx-5 flex items-center justify-center before:z-[-1] before:m-0 before:w-full before:border-t before:border-solid before:content-['']"
      >
        <span className="text-v2-danger text-xxs border-none py-[5px] pl-[10px] font-semibold uppercase">
          {t("messaging.new")}
        </span>
      </div>
    );
  }

  return null;
};
