import { isEmpty } from "lodash";
import type { ChatRoom } from "@/react/types/Chat/ChatRoom";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";

export const useCanSendMessage = (chatRoom: ChatRoom) => {
  const { current_participant, chat_room_kind } = chatRoom;
  const isDirectMessaging = chat_room_kind === DIRECT_CHAT_ROOM;
  const canParticipantsMessage =
    isEmpty(current_participant) ||
    current_participant?.can_send_message_in_room;
  const doesCurrentParticipantExist =
    !isEmpty(current_participant) && !current_participant?.deleted_at;

  const canSendMessage = isDirectMessaging
    ? canParticipantsMessage
    : doesCurrentParticipantExist;

  return {
    canSendMessage,
    canParticipantsMessage,
  };
};
