import { useEffect, useRef } from "react";
import { ResizeObserver } from "@juggle/resize-observer";
import classNames from "classnames";
import { CommentsSection } from "@/react/components/PostsV3/PostViewListItem/CommentsSection";
import { useCurrentPostContext } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";

export const IFrameViewItem = () => {
  const { record: post } = useCurrentPostContext();
  const containerRef = useRef(null);
  const commentsFormRef = useRef(null);
  const { currentCommunityMember } = usePunditUserContext();

  useEffect(() => {
    if (!containerRef.current || !post?.is_comments_enabled) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const height = document.documentElement.scrollHeight;

      window.parent.postMessage(["setHeight", height], "*");
    });

    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [post?.is_comments_enabled]);

  if (!post?.is_comments_enabled) return null;

  return (
    <div
      ref={containerRef}
      className={classNames(
        "post bg-primary border-primary border md:mb-6 md:rounded-lg",
        {
          "pb-1 pt-2.5": !!currentCommunityMember,
        },
      )}
    >
      <CommentsSection
        commentsFormRef={commentsFormRef}
        autofocusReplyForm={false}
      />
    </div>
  );
};
