import { Controller, useFormContext } from "react-hook-form";
import { RoomTypePickerComponent } from "./RoomTypePickerComponent";

interface RoomTypePickerProps {
  name: string;
  rules: any;
  liveStreamConfig: any;
  shouldDisableEventOption: boolean;
}

export const RoomTypePicker = ({
  name,
  rules,
  liveStreamConfig,
  shouldDisableEventOption,
  ...passThroughProps
}: RoomTypePickerProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <RoomTypePickerComponent
          {...passThroughProps}
          onChange={onChange}
          value={value}
          liveStreamConfig={liveStreamConfig}
          shouldDisableEventOption={shouldDisableEventOption}
        />
      )}
    />
  );
};
