import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface RoomDetailsTextProps {
  iconType: IconType;
  text: string;
}

export const RoomDetailsText = ({ iconType, text }: RoomDetailsTextProps) => (
  <li className="list-none">
    <div className="flex items-start gap-2.5 text-left">
      <span className="mt-0.5 flex w-4">
        <Icon size={16} type={iconType} />
      </span>
      <Typography.LabelSm>{text}</Typography.LabelSm>
    </div>
  </li>
);
