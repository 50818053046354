import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface MarketingItemProps {
  localeItem: string;
}

export const MarketingItem = ({ localeItem }: MarketingItemProps) => {
  const localeScope = "settings.community_ai.marketing_modal";

  return (
    <li className="mb-4 flex items-start justify-start last:mb-0">
      <div className="mt-1 flex h-5 w-5 items-center justify-center">
        <Icon size={14} type="14-magic-ai" />
      </div>
      <span className="ml-3 text-blue-100">
        <Typography.BodySm color="text-default">
          <SanitizeHTMLString
            content={t([localeScope, "marketing_items", localeItem])}
            as="span"
          />
        </Typography.BodySm>
      </span>
    </li>
  );
};
