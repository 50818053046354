import { forwardRef } from "react";
import * as classnames from "classnames";
import { useFormContext } from "react-hook-form";
import { FieldLabel } from "../../shared/Form/FieldLabel";

const TextArea = forwardRef((props, ref) => <textarea {...props} ref={ref} />);
TextArea.displayName = "TextArea";

const Input = forwardRef((props, ref) => <input {...props} ref={ref} />);
Input.displayName = "Input";

export const FormInput = ({
  name,
  variant = "",
  label = "",
  description = "",
  autofocus = false,
  fullWidth = false,
}) => {
  label = label || I18n.t(`post.${name}`);
  description = description || I18n.t(`post.${name}_description`);

  const { register } = useFormContext();
  const id = `post_${name}`;
  let Comp = Input;

  if (variant === "textarea") {
    Comp = TextArea;
  }

  return (
    <div
      className={classnames("form-group", {
        "form-group--column": fullWidth,
      })}
    >
      <FieldLabel field={id} label={label} description={description} />
      <div className="form-input">
        <Comp
          {...register(name)}
          id={id}
          className="form-control placeholder:text-light placeholder:opacity-100"
          name={name}
          autofocus={autofocus}
        />
      </div>
    </div>
  );
};
