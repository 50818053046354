import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const PER_PAGE = 50;

export const useEntityPreviewsApi = (
  spaceIds,
  spaceGroupIds,
  memberTagIds,
  memberIds,
) => {
  const fetchPage = page =>
    reactQueryGet(
      internalApi.entityPreviews.index({
        params: {
          page,
          spaces_ids: spaceIds,
          space_groups_ids: spaceGroupIds,
          member_tags_ids: memberTagIds,
          members_ids: memberIds,
        },
      }),
    );

  const {
    isLoading,
    isFetching,
    data,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
  } = useInfiniteQuery(
    `entities-preview-${spaceIds}-${spaceGroupIds}-${memberTagIds}-${memberIds}`,
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const records = data?.pages?.reduce(
    (prev, page) => prev.concat(page.records),
    [],
  );

  const lastPage = data?.pages[data?.pages.length - 1] || {};
  const page = lastPage.page;
  const totalRecordCount = lastPage.count;
  const pageRecordCount = lastPage.page_count;
  const pageCount = totalRecordCount
    ? Math.ceil(totalRecordCount / PER_PAGE)
    : 0;
  const hasNextPage = !!lastPage.has_next_page;
  const startRecordNumber = (page - 1) * PER_PAGE + 1;
  const endRecordNumber = startRecordNumber + pageRecordCount - 1;

  return {
    page,
    records,
    totalRecordCount,
    pageRecordCount,
    pageCount,
    hasNextPage,
    startRecordNumber,
    endRecordNumber,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    fetchPrevPage: fetchPreviousPage,
  };
};
