import { createContext, useContext, useState } from "react";
import type { CoursesLesson } from "@/react/types/CoursesLesson";

type CurrentLessonContext =
  | {
      isSubmitEnabled: boolean;
      setIsSubmitEnabled: (value: boolean) => void;
      currentLesson: CoursesLesson | Record<string, never>;
      setCurrentLesson: (value: any) => void;
    }
  | Record<string, never>;

interface CurrentLessonContextProviderProps {
  children: React.ReactNode;
}

const CurrentLessonContext = createContext<CurrentLessonContext>({});
CurrentLessonContext.displayName = "CurrentLessonContext";

export const CurrentLessonContextProvider = ({
  children,
}: CurrentLessonContextProviderProps) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [currentLesson, setCurrentLesson] = useState({});

  const value = {
    isSubmitEnabled,
    setIsSubmitEnabled,
    currentLesson,
    setCurrentLesson,
  };

  return (
    <CurrentLessonContext.Provider value={value}>
      {children}
    </CurrentLessonContext.Provider>
  );
};

export const useCurrentLessonContext = (): CurrentLessonContext => {
  const context = useContext(CurrentLessonContext);
  if (!context) {
    throw new Error(
      "useCurrentLessonContext must be used within a CurrentLessonContextProvider",
    );
  }
  return context;
};
