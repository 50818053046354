import { Fragment } from "react";
import { t } from "@/i18n-js/instance";
import { isLiveRoom } from "@circle-react/components/EventsV3/Modal/utils";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { POPPER_OPTIONS } from "./constants";
import { useMobileEventActions } from "./hooks/useMobileEventActions";

interface MobileEventActionsDropdownProps {
  event?: Event;
}

interface MobileActionsDropdownOption {
  label: string;
  onClick: () => void;
  iconName: IconType;
  disabled?: boolean;
}

export const MobileEventActionsDropdown = ({
  event,
}: MobileEventActionsDropdownProps) => {
  const { onViewEvent, onCopyLink, eventUrl, liveRoomUrl } =
    useMobileEventActions(event);
  const liveStreamSlug =
    event?.event_setting_attributes?.live_stream_slug || "";
  const host = window.location.origin;
  const liveStreamUrl =
    host + liveStreamPaths.publicRoom({ roomSlug: liveStreamSlug });
  const copyUrlLabel = isLiveRoom(event)
    ? t("events.edit_modal.overview.copy_live_room_url")
    : t("events.edit_modal.overview.copy_live_stream_url");
  const liveUrl = isLiveRoom(event) ? liveRoomUrl : liveStreamUrl;

  const MOBILE_ACTIONS_DROPDOWN_OPTIONS: MobileActionsDropdownOption[] = [
    {
      label: t("events.edit_modal.overview.view_event"),
      onClick: onViewEvent,
      iconName: "16-arrow",
    },
    {
      label: t("events.edit_modal.overview.copy_url"),
      onClick: () => onCopyLink(eventUrl),
      iconName: "16-copy",
    },
    {
      label: copyUrlLabel,
      onClick: () => onCopyLink(liveUrl),
      iconName: "16-copy",
      disabled: !liveStreamSlug,
    },
  ];

  return (
    <Dropdown
      size="medium"
      direction="bottom-start"
      button={
        <IconButton
          name="16-menu-dots-horizontal"
          className="!text-dark"
          ariaLabel={t("options_dropdown")}
        />
      }
      buttonWrapperClassName="flex items-center ml-4"
      popperOptions={POPPER_OPTIONS}
      transitionWrapperClassName="z-30"
      dataTestId="user-profile"
    >
      {MOBILE_ACTIONS_DROPDOWN_OPTIONS.map(option => (
        <Fragment key={option.label}>
          {!option.disabled && (
            <Dropdown.ItemWithLink onClick={option.onClick}>
              <div className="text-dark flex items-center justify-between">
                {option.label}
                <Icon size={16} type={option.iconName} className="text-dark" />
              </div>
            </Dropdown.ItemWithLink>
          )}
        </Fragment>
      ))}
    </Dropdown>
  );
};
