import { useMemo, useState } from "react";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy from "@uppy/core";
import { noop } from "lodash";
import { getUploadInfo } from "@circle-react/components/UppyUploader/utils";
import { useMediaPickerModal } from "./MediaPickerModal";

const NUMBER_OF_RETRIES = 2;
const RETRIES_DELAY_SECONDS = 5;

export const useUppyUpload = ({
  onUploadStarted = noop,
  onUploadComplete = noop,
  onUploadFailed = noop,
  allowedFileTypes = undefined,
}) => {
  const [isProcessingChecksum, setIsProcessingChecksum] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const mediaPickerModal = useMediaPickerModal();

  const uppy = useMemo(
    () =>
      new Uppy({
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes,
        },
        autoProceed: false,
      })
        .use(AwsS3Multipart, {
          limit: 10,
          companionUrl: "/",
          getChunkSize: file => {
            const MB = 1024 * 1024;
            return Math.max(25 * MB, Math.ceil(file.size / 500));
          },
        })
        .on("file-added", async file => {
          mediaPickerModal.hide();
          setIsProcessingChecksum(true);

          try {
            const data = await getUploadInfo(file.data);
            uppy.setState(data);
            uppy.setState({ retries: NUMBER_OF_RETRIES });
            uppy.setFileMeta(file.id, { key: data.key });
            uppy.upload();
          } catch (error) {
            console.error(error);
            setIsProcessingChecksum(false);
            onUploadFailed();
          }
        })
        .on("upload", () => {
          setIsProcessingChecksum(false);
          setIsUploading(true);
          onUploadStarted();
        })
        .on("upload-progress", (file, progress) => {
          let percentage = 0;
          if (progress.bytesUploaded !== 0) {
            percentage = progress.bytesUploaded / progress.bytesTotal;
          }
          setProgress(percentage * 100);
        })
        .on("upload-success", file => {
          const {
            checksum,
            id,
            created_at,
            attachable_sgid,
            service_name,
            signed_id,
            key,
          } = uppy.getState();
          const responseForActiveStorage = {
            attachable_sgid: attachable_sgid,
            byte_size: file.size,
            checksum: checksum,
            content_type: file.type,
            created_at: created_at,
            filename: file.name,
            id: id,
            service_name: service_name,
            signed_id: signed_id,
            key: key,
          };
          onUploadComplete(responseForActiveStorage);
          setIsUploading(false);
        })
        .on("error", () => {
          setIsProcessingChecksum(false);
          setIsUploading(false);

          const { retries } = uppy.getState();

          if (retries === 0) {
            onUploadFailed();
            return;
          }

          setIsRetrying(true);

          setTimeout(() => {
            uppy.setState({ retries: retries - 1 });
            setIsRetrying(false);
            uppy.retryAll();
          }, RETRIES_DELAY_SECONDS * 1000);
        })
        .on("cancel-all", () => {
          setIsUploading(false);
          setIsProcessingChecksum(false);
        }),
    [],
  );

  return {
    uppy,
    isUploading,
    isRetrying,
    progress,
    isProcessingChecksum,
  };
};
