import classnames from "classnames";
import { useListItemDataProvider } from "@circle-react-shared/uikit/SortableList";

export const ThumbnailImagePlaceholder = ({ isOverlay = false }: any) => {
  const { item }: any = useListItemDataProvider();
  return (
    <div
      className={classnames(
        "rounded-lg border-2 border-transparent p-0.5 opacity-50",
        {
          "!opacity-100": isOverlay,
        },
      )}
    >
      <img
        loading="eager"
        src={item.preview}
        className="h-12 w-12 rounded-md object-cover"
        alt={item.file.name}
      />
    </div>
  );
};
