import { t } from "@/i18n-js/instance";
import { postsPath } from "@circle-react/helpers/urlHelpers";

interface LeaveCommentButtonProps {
  post: {
    slug: string;
    space_slug: string;
  };
}

export const LeaveCommentButton = ({ post }: LeaveCommentButtonProps) => {
  const { slug, space_slug: spaceSlug } = post || {};

  return (
    <div className="border-primary flex items-center justify-center gap-4 border-t p-12">
      <a
        className="btn btn-secondary"
        href={postsPath({
          spaceSlug,
          slug,
        })}
      >
        {t("leave_a_comment")}
      </a>
    </div>
  );
};
