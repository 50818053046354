import { useUsageContext } from "@circle-react/providers";

/**
 * For buttons inside shared components split between admin and user sections of the app
 * @returns button variant
 */
export const useButtonVariant = () => {
  const { isAdminUsageContext } = useUsageContext();

  return isAdminUsageContext ? "circle" : "primary";
};
