import { useEffect } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { defer } from "lodash";

export const useEditorFocus = (
  isPostCommentFormInMinimalPostModal: boolean,
  autoFocus?: boolean,
  editorRef?: RefObject<Editor>,
) => {
  useEffect(() => {
    defer(() => {
      if (autoFocus && !isPostCommentFormInMinimalPostModal) {
        editorRef?.current?.commands?.focus?.("end");
      }
    });
  }, [autoFocus, isPostCommentFormInMinimalPostModal, editorRef]);
};
