import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { GridItem } from "./GridItem";

export const ActivityScoresFeature = () => {
  const localeScope = "settings.community_ai.marketing_modal";

  return (
    <div className="border-primary rounded border p-5">
      <div className="mb-1">
        <Typography.LabelLg weight="bold" color="text-default">
          {t(`${localeScope}.activity_score.title`)}
        </Typography.LabelLg>
      </div>
      <Typography.BodySm color="text-default">
        {t(`${localeScope}.activity_score.description`)}
      </Typography.BodySm>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <GridItem property={t(`${localeScope}.params.presence`)} number="9.5" />
        <GridItem
          property={t(`${localeScope}.params.contribution`)}
          number="5.0"
          red
        />
        <GridItem
          property={t(`${localeScope}.params.participation`)}
          number="8.3"
        />
        <GridItem
          property={t(`${localeScope}.params.connection`)}
          number="8.5"
        />
      </div>
    </div>
  );
};
