import { ThreeUserRow } from "@circleco/ui-kit";
import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { LikedByMessageV3 } from "./LikedByMessageV3";
import { usePostLikesModal } from "./PostLikesModal";

interface PostLikedByMemberStackV3Props {
  shouldDisplayVerticalDivider?: boolean;
}

export const PostLikedByMemberStackV3 = ({
  shouldDisplayVerticalDivider = true,
}: PostLikedByMemberStackV3Props) => {
  const { post, isPostBodyEmpty } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();

  const {
    user_likes_count: likesCount,
    is_liking_enabled: isLikingEnabled,
    sample_user_likes_community_members: communityMembersThatLiked = [],
    post_details_loaded: hasPostDetailsLoaded,
  } = post;
  const postLikesModal = usePostLikesModal();

  if (!hasPostDetailsLoaded) {
    return (
      <div
        className={classNames("post__engagement", {
          "!pt-5": isImagePost(post) && isPostBodyEmpty,
          "mx-2": usedIn !== "minimal-post-modal",
        })}
      >
        <SkeletonLoader variant="members-avatar-row-v3" />
      </div>
    );
  }

  if (!isLikingEnabled || !likesCount || !communityMembersThatLiked.length) {
    return null;
  }

  const transformUserRowData = () =>
    communityMembersThatLiked.slice(0, 3).map((user: any) => ({
      src: user.avatar_url,
      name: user.name,
    }));

  return (
    <>
      <button
        type="button"
        onClick={() => postLikesModal.show({ post })}
        className="post__engagement focus-visible:outline-secondary group focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        data-testid="post-liked-by"
      >
        <div className="engagement__likes flex items-center">
          <div className="engagement__members border-tertiary flex items-center space-x-2">
            <ThreeUserRow
              users={transformUserRowData()}
              isFullRounded
              size="5"
            />
            <LikedByMessageV3
              likesCount={likesCount}
              isLoading={!hasPostDetailsLoaded}
            />
          </div>
        </div>
      </button>
      {shouldDisplayVerticalDivider && (
        <div className="bg-badge h-0.5 w-0.5 rounded-full" />
      )}
    </>
  );
};
