import { useHotkeys } from "react-hotkeys-hook";

export const useCloseOnEscape = ({
  disabled,
  onClose,
}: {
  disabled: boolean;
  onClose: () => void;
}) => {
  useHotkeys(
    "esc",
    onClose,
    {
      enabled: !disabled,
      enableOnContentEditable: true,
      enableOnFormTags: ["INPUT", "TEXTAREA"],
    },
    [disabled],
  );
};
