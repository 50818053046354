import { t } from "@/i18n-js/instance";
import { toLocaleString } from "@circle-react/helpers/number";

interface LikeCounterProps {
  likesCount: number;
  onClick: () => void;
}

export const LikeCounter = ({ likesCount, onClick }: LikeCounterProps) => (
  <button
    type="submit"
    className="text-default hover:text-dark group ml-auto mr-0 text-sm font-medium"
    data-testid="like-count"
    onClick={onClick}
  >
    <span className="group-focus-visible:outline-secondary group-focus-visible:rounded-sm group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2">
      {t("comments.likes", {
        count: likesCount,
        formatted_count: toLocaleString(likesCount),
      })}
    </span>
  </button>
);
