import type { KeyboardEvent } from "react";
import { useCallback } from "react";

export const useEnterOrSpaceKeyDownHandler = <T extends HTMLElement>(
  callback: (event: KeyboardEvent<T>) => void,
) =>
  useCallback(
    (event: KeyboardEvent<T>) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        callback(event);
      }
    },
    [callback],
  );
