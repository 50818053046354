import { create } from "zustand";
import type { GalleryImage } from "@/react/types/GalleryImage";
import { ASPECT_RATIOS, STEPS } from "./constants";

type StepName = (typeof STEPS)[keyof typeof STEPS] | null;

interface Point {
  x: number;
  y: number;
}

export interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface SelectedImage {
  file: File;
  preview: string;
  crop: Point;
  croppedAreaPixels?: Crop;
  zoom: number;
}

export interface UploadedImage extends Omit<GalleryImage, "id"> {
  id?: number;
}

interface ImagePostModalStore {
  currentStep: StepName;
  isSubmitting: boolean;
  isFormDirty: boolean;
  isImagesDirty: boolean;
  isScheduledBarPortalRendered: boolean;
  galleryAspectRatio: number | null;
  selectedFiles: SelectedImage[];
  uploadedImages: UploadedImage[];
  setCurrentStep: (identifier: StepName) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setIsFormDirty: (isFormDirty: boolean) => void;
  setIsImagesDirty: (isImagesDirty: boolean) => void;
  setIsScheduledBarPortalRendered: (isScheduledBarRendered: boolean) => void;
  updateImage: (image: SelectedImage) => void;
  addSelectedFiles: (files: SelectedImage[]) => void;
  setSelectedFiles: (files: SelectedImage[]) => void;
  setUploadedImages: (images: UploadedImage[]) => void;
  addUploadedImages: (files: UploadedImage[]) => void;
  setGalleryAspectRatio: (aspectRatio: number) => void;
  resetSelectedFiles: () => void;
  reset: () => void;
}

const cleanupSelectedFiles = (state: any) => {
  state.selectedFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
  return { selectedFiles: [] };
};

export const useImagePostModalStore = create<ImagePostModalStore>(set => ({
  currentStep: STEPS.UPLOAD_IMAGES,
  isSubmitting: false,
  isFormDirty: false,
  isImagesDirty: false,
  isScheduledBarPortalRendered: false,
  galleryAspectRatio: null,
  selectedFiles: [],
  uploadedImages: [],
  setCurrentStep: identifier => set({ currentStep: identifier }),
  setIsSubmitting: isSubmitting => set({ isSubmitting }),
  setIsFormDirty: isFormDirty => set({ isFormDirty }),
  setIsImagesDirty: isImagesDirty => set({ isImagesDirty }),
  setIsScheduledBarPortalRendered: isScheduledBarPortalRendered =>
    set({ isScheduledBarPortalRendered }),
  updateImage: image => {
    set(state => {
      const imageIndex = state.selectedFiles.findIndex(
        img => img.preview === image.preview,
      );
      state.selectedFiles[imageIndex] = image;
      return { selectedFiles: [...state.selectedFiles] };
    });
  },
  addSelectedFiles: files => {
    set(state => ({
      selectedFiles: [...state.selectedFiles, ...files],
    }));
  },
  setSelectedFiles: files => {
    set({ selectedFiles: files });
  },
  addUploadedImages: images => {
    set(state => ({
      uploadedImages: [...state.uploadedImages, ...images],
    }));
  },
  setUploadedImages: images => {
    set({ uploadedImages: images });
  },
  setGalleryAspectRatio: aspectRatio => {
    if (aspectRatio < (ASPECT_RATIOS.portrait + ASPECT_RATIOS.square) / 2) {
      set({ galleryAspectRatio: ASPECT_RATIOS.portrait });
    } else if (
      aspectRatio >
      (ASPECT_RATIOS.landscape + ASPECT_RATIOS.square) / 2
    ) {
      set({ galleryAspectRatio: ASPECT_RATIOS.landscape });
    } else {
      set({ galleryAspectRatio: ASPECT_RATIOS.square });
    }
  },
  resetSelectedFiles: () => {
    set(cleanupSelectedFiles);
  },
  reset: () => {
    set(state => {
      const cleanedFiles = cleanupSelectedFiles(state);
      return {
        ...cleanedFiles,
        currentStep: null,
        uploadedImages: [],
        galleryAspectRatio: null,
        isSubmitting: false,
        isFormDirty: false,
        isImagesDirty: false,
        isScheduledBarPortalRendered: false,
      };
    });
  },
}));
