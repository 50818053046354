import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { ROOM_ACCESS_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { EVENT_TYPES, LOCATION_TYPES, STATUS, TICKET_TYPES } from "./constants";

export const isDraft = event => event?.status === STATUS.DRAFT;
export const isPublished = event => event?.status === STATUS.PUBLISHED;
export const isRecurring = event => event?.event_type === EVENT_TYPES.RECURRING;
export const isSingle = event => event?.event_type === EVENT_TYPES.SINGLE;
export const isLiveRoom = event =>
  event?.event_setting_attributes?.location_type === LOCATION_TYPES.LIVE_ROOM;
export const isLiveStream = event =>
  event?.event_setting_attributes?.location_type === LOCATION_TYPES.LIVE_STREAM;
export const isPublicStream = event =>
  isLiveStream(event) &&
  event?.event_setting_attributes?.live_stream_room_setting_attributes
    ?.access_type === ROOM_ACCESS_TYPES.PUBLIC_STREAM;
export const isLiveLocation = locationType =>
  [LOCATION_TYPES.LIVE_STREAM, LOCATION_TYPES.LIVE_ROOM].includes(locationType);
export const hasEventEnded = event =>
  dateIsPast(event?.event_setting_attributes?.ends_at);
export const hasEventStarted = event =>
  event && dateIsPast(event?.event_setting_attributes?.starts_at);
export const isPaidEvent = event =>
  event?.event_setting_attributes?.ticket_type == TICKET_TYPES.PAID;
export const eventWithPublishedPaywall = event =>
  event && event?.paywall_attributes?.published;
export const isRecurringFreeEvent = event =>
  isRecurring(event) && !isPaidEvent(event);
export const isPastOrDraft = event => hasEventEnded(event) || isDraft(event);
export const eventDeletionMessage = event => {
  const isRecurringEvent = isRecurring(event);

  let message = isRecurringEvent
    ? I18n.t("events.recurring_deletion_successful")
    : I18n.t("event_dropdown_actions.delete_successful");

  if (isPastOrDraft(event)) {
    message = isRecurringEvent
      ? I18n.t("events.recurring_draft_deletion_successful")
      : I18n.t("event_dropdown_actions.draft_delete_successful");
  }
  return message;
};
