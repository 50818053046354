import { isFunction } from "lodash";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useUpdatePostThumbnailMutation = ({
  post,
  onSuccess,
  onError,
}: {
  post: Post;
  onSuccess?: (post: Post) => void;
  onError?: () => void;
}) => {
  const { success, error } = useToast();

  return useMutation<Post, ApiError, FormData>({
    mutationFn: formData =>
      reactQueryPatch(
        internalApi.posts.update({
          spaceId: post.space_id,
          postSlug: post.slug,
        }),
        {
          post: {
            space_id: post.space_id,
            ...formData,
          },
        },
      ),
    onSuccess: response => {
      if (isFunction(onSuccess)) onSuccess(response);
      success(t("changes_saved"));
    },
    onError: () => {
      if (isFunction(onError)) onError();
      error(t("failed_to_save_changes"));
    },
  });
};
