import { useEffect, useState } from "react";
import { useWaitForDOMElement } from "@circle-react/hooks/useWaitForDOMElement";

export const useHideDOMElement = selector => {
  const [originalDisplayValue, setOriginalDisplayValue] = useState("unset");
  const element = useWaitForDOMElement(selector);

  useEffect(() => {
    if (element) {
      setOriginalDisplayValue(element.style.display || "unset");
      element.style.display = "none";
    }

    return () => {
      if (element) {
        element.style.display = originalDisplayValue;
      }
    };
  }, [element]);
};
