import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Tabs } from "@circle-react-uikit/Tabs";
import { useRoomType } from "../../hooks";
import { PEOPLE_TABS } from "../../utils";
import { Attendees } from "./Attendees";
import { Cohosts } from "./Cohosts";

export const People = () => {
  const roomType = useRoomType();
  const isLiveStream = roomType === ROOM_TYPES.STREAM;
  const tabs = [
    {
      label: t("live_streams.form.sections.people_attendees"),
      key: PEOPLE_TABS.ATTENDEES,
    },
  ];
  if (isLiveStream) {
    tabs.push({
      label: t("live_streams.form.sections.people_cohosts"),
      key: PEOPLE_TABS.COHOSTS,
    });
  }
  const shouldDisplayTabs = tabs.length > 1;
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const onTabClick = tabLabel => {
    const tab = tabs.find(tab => tab.label === tabLabel);
    setCurrentTab(tab);
  };
  return (
    <>
      {shouldDisplayTabs && (
        <Tabs
          variant="underline"
          className="sticky"
          wrapperClassName="overflow-x-auto overflow-y-hidden"
          controlled
          activeTab={currentTab.label}
          onClick={onTabClick}
          options={tabs}
        />
      )}
      {currentTab.key === PEOPLE_TABS.ATTENDEES && <Attendees />}
      {currentTab.key === PEOPLE_TABS.COHOSTS && <Cohosts />}
    </>
  );
};
