import { useCallback, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

type UseBoolean = [boolean, () => void, Dispatch<SetStateAction<boolean>>];

export const useBoolean = (initialValue = false): UseBoolean => {
  const [isStateSet, setState] = useState(initialValue);
  const toggle = useCallback(() => setState(state => !state), [setState]);
  return [isStateSet, toggle, setState];
};
