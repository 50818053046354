import PropTypes from "prop-types";
import { useCustomizeUIData } from "@circle-react/components/shared/CustomizeUI";
import { SECTIONS } from "../../utils";
import { AttendeesPreview } from "./AttendeesPreview";
import { CohostsPreview } from "./CohostsPreview";
import { LatestRSVP } from "./LatestRSVP";
import { Summary } from "./Summary";

export const Overview = ({ event }) => {
  const { setCurrentSection } = useCustomizeUIData();
  const goToPeople = () => setCurrentSection(SECTIONS.people);

  return (
    <>
      <Summary event={event} />
      <div className="my-4 flex flex-col items-stretch gap-4 md:my-6 md:flex-row">
        <div className="w-full pr-0 md:w-1/2 md:pr-3">
          <AttendeesPreview event={event} onClick={goToPeople} />
        </div>
        <div className="w-full pl-0 md:w-1/2 md:pl-3">
          <CohostsPreview event={event} onClick={goToPeople} />
        </div>
      </div>
      <div className="mt-4 md:my-6">
        <LatestRSVP event={event} />
      </div>
    </>
  );
};

Overview.propTypes = {
  event: PropTypes.object,
};
