import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import type { Post } from "@circle-react/types/Post";
import { NewPostActionsDropdown } from "./NewPostActionsDropdown";

export interface PostActionsDropdownMenuProps {
  onChange: (data?: Post) => void;
  onPostDestroy: (data: Post) => void;
  refetchPosts?: () => void;
}

export const PostActionsDropdownMenu = ({
  onChange,
  onPostDestroy,
  refetchPosts,
}: PostActionsDropdownMenuProps) => {
  const {
    showPostActions: canShowPostActions,
    canUpdatePost,
    canDestroyPost,
    isEventPost,
  } = useCurrentPostHelpers();

  const { currentCommunity } = usePunditUserContext();

  if (!canShowPostActions) {
    return null;
  }

  if (isEventPost && !canUpdatePost && !canDestroyPost) {
    return null;
  }

  return (
    <NewPostActionsDropdown
      onChange={onChange}
      onPostDestroy={onPostDestroy}
      refetchPosts={refetchPosts}
      currentCommunity={currentCommunity}
    />
  );
};
