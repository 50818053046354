import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useRailbar } from "@circle-react/hooks/chatsV2";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = ({ title, onClose }) => {
  const isSmScreen = useSmScreenMediaQuery();
  const { isChatSpace, isCourseSpace, isLiveStream, isDrawer } = useRailbar();

  if (isSmScreen) {
    return (
      <div className="flex w-full items-center justify-between">
        <IconButton
          name="16-arrow-left"
          onClick={onClose}
          iconClassName="!text-dark"
          className="flex-none"
          ariaLabel={t("close")}
        />
        <div className="flex-auto text-center">
          <Typography.TitleSm as="h2" weight="semibold">
            {title}
          </Typography.TitleSm>
        </div>
      </div>
    );
  }

  if (isLiveStream || isCourseSpace || isDrawer) {
    return (
      <div className="flex w-full items-center justify-start">
        <IconButton
          name="20-back"
          onClick={onClose}
          iconSize={20}
          buttonSize={20}
          iconClassName="!text-dark !h-5 !w-5"
          className="mr-3 h-8 w-8 flex-none"
          ariaLabel={t("back")}
        />
        <Typography.TitleSm as="h2" weight="semibold">
          {title}
        </Typography.TitleSm>
      </div>
    );
  }

  const Title = isChatSpace ? Typography.TitleSm : Typography.LabelMd;
  return (
    <div className="flex w-full items-center justify-between">
      <Title as="h2" weight="semibold">
        {title}
      </Title>
      <IconButton
        name="20-close"
        iconSize={20}
        onClick={onClose}
        iconClassName="!text-dark"
        ariaLabel={t("close")}
      />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
