import { useEffect, useState } from "react";
import { noop } from "lodash";
import { useQueryClient } from "react-query";
import { getEventRegistrationUrl } from "@/react/helpers/loginHelpers";
import { useCommunitySignup } from "@circle-react/components/Events/hooks/useCommunitySignup";
import { useIsEventOwner } from "@circle-react/components/Events/hooks/useIsEventOwner";
import { useCurrentUser } from "@circle-react/contexts";
import { useRecurringEventRsvpApi } from "@circle-react/hooks/events/useRecurringEventRsvpApi";

export const useRecurringRsvpModal = ({
  event,
  onClose = noop,
  onChange = noop,
  eventRsvpedByDefault = true,
}) => {
  const [isRecurringRsvpOpen, setIsRecurringRsvpOpen] = useState();
  const [eventsState, setEventsState] = useState();
  const [eventsSelectedCount, setEventsSelectedCount] = useState();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const isEventOwner = useIsEventOwner(event);
  const role = isEventOwner ? "host" : "participant";

  const { isLoading, events, fetchEvents, onRecurringRsvpSubmit } =
    useRecurringEventRsvpApi({
      event,
    });

  const { canUsersSignUp, userSignUpUrl } = useCommunitySignup();

  const openRecurringRsvpModal = () => {
    if (!currentUser) {
      window.location.href = getEventRegistrationUrl(
        canUsersSignUp,
        userSignUpUrl,
        true,
      );
      return;
    }
    setIsRecurringRsvpOpen(isRecurringRsvpOpen => !isRecurringRsvpOpen);
  };

  const onRecurringModalCose = () => {
    setIsRecurringRsvpOpen(false);
  };

  const onSelectAllClickHandler = () => {
    setEventsState(prevEventState => {
      const newState = {};
      const eventsEntries = Object.entries(prevEventState);
      const areAllSelected = eventsEntries.every(item => item[1]);

      for (const [key] of eventsEntries) {
        newState[key] = !areAllSelected;
      }
      return newState;
    });
  };

  const onCheckboxChange = ({ name, value }) => {
    setEventsState(prevEventsState => ({
      ...prevEventsState,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    const eventIds = Object.entries(eventsState)
      .filter(([, value]) => value)
      .map(([key]) => key);

    const eventConfirmed = eventIds.find(e => e.id === event.id);

    await onRecurringRsvpSubmit({ eventIds, role });
    onChange({ ...event, rsvped_event: Boolean(eventConfirmed) });
    reloadEventList();
    onClose();
  };

  const reloadEventList = () => {
    queryClient.invalidateQueries("posts");
  };

  useEffect(() => {
    if (!events) {
      return;
    }
    const values = {};
    events.forEach(item => {
      values[`${item.id}`] =
        eventRsvpedByDefault && item.id === event.id ? true : item.rsvped_event;
    });
    setEventsState(values);
  }, [events]);

  useEffect(() => {
    if (!eventsState) {
      return;
    }

    setEventsSelectedCount(
      Object.values(eventsState).filter(item => item).length,
    );
  }, [eventsState]);

  const availableEvents = events.filter(item => !item.rsvp_limit_reached);

  return {
    isLoading,
    events: availableEvents,
    eventsState,
    isRecurringRsvpOpen,
    eventsSelectedCount,
    fetchEvents,
    onSubmit,
    onCheckboxChange,
    onSelectAllClickHandler,
    openRecurringRsvpModal,
    onRecurringModalCose,
  };
};
