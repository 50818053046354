import { useState } from "react";
import { noop } from "lodash";
import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useChatThreadsItem = ({
  parentMessage,
  threadMessageId,
  successCallback = noop,
}) => {
  const [threadMessages, setThreadMessages] = useState([]);
  const { error } = useToast();

  const { isLoading, refetch: fetchThreadMessages } = useQuery(
    ["THREAD_MESSAGES", parentMessage?.id, parentMessage?.chat_room_uuid],
    ({ previousPerPage = 15, nextPerPage = 0 }) => {
      const params = {
        previous_per_page: previousPerPage,
        next_per_page: nextPerPage,
        parent_message_id: parentMessage.id,
        id: threadMessageId,
      };
      return reactQueryGet(
        internalApi.chatRoomMessages.index({
          uuid: parentMessage.chat_room_uuid,
          params,
        }),
      );
    },
    {
      enabled: !!parentMessage,
      onSuccess: data => {
        setThreadMessages(data.records);
        successCallback();
      },
      onError: err => {
        error(err.message);
      },
    },
  );

  return { fetchThreadMessages, threadMessages, isLoading };
};
