import { isNull } from "lodash";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";

interface UseMessageActionsProps {
  message: any;
  chatRoom: any;
}

export const useMessageActions = ({
  message,
  chatRoom,
}: UseMessageActionsProps) => {
  const { id, chat_room_participant_id: messageSenderId } = message;
  const { current_participant: currentParticipant = {}, chat_room_kind } =
    chatRoom;

  const isDirect = chat_room_kind === DIRECT_CHAT_ROOM;

  const isSenderCurrentParticipant = messageSenderId === currentParticipant?.id;
  const isMessageCreated = !isNull(id);
  const isAdmin = currentParticipant?.admin;
  let canAdminDeleteMessage = isAdmin;

  if (isDirect) {
    canAdminDeleteMessage = false;
  }

  const canUserEditMessage = Boolean(
    isMessageCreated && isSenderCurrentParticipant,
  );

  const canUserDeleteMessage = Boolean(
    isMessageCreated && (isSenderCurrentParticipant || canAdminDeleteMessage),
  );

  const canUserModifyMessage = canUserEditMessage || canUserDeleteMessage;

  return {
    canUserDeleteMessage,
    canUserEditMessage,
    canUserModifyMessage,
  };
};
