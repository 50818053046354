import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { useChatUIStore } from "@/react/hooks/chatsV2/store";
import { ProfileV3 } from "@circle-react/components/ProfileModalV3/Profile";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import { useProfileInfo } from "@circle-react/hooks/profile/useProfileInfo";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { ModalCloseButton } from "@circle-react-shared/uikit/ModalV2/ModalCloseButton";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ProfileMessaging } from "./ProfileMessaging";

const pageParams = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
};

export interface ProfileModalProps {
  memberPublicId: string;
  params?: {
    shouldOpenInNewTab?: boolean;
  };
}

export const ProfileModalV3 = ({ memberPublicId }: ProfileModalProps) => {
  const { liveChatRoomUuid, setLiveChatRoomUuid } = useChatUIStore();
  const modal = useModal();
  const {
    getProfileInfoQuery: { data: member, isLoading },
  } = useProfileInfo({
    publicId: memberPublicId,
    params: pageParams,
  });

  const onClose = () => {
    setLiveChatRoomUuid(null);
    modal.remove();
  };

  return (
    <Modal
      isOpen={modal.visible}
      onClose={onClose}
      contentPortalId="post-modal-portal"
    >
      <Modal.Overlay />
      <Modal.Content size="full" className="max-w-screen-xl">
        <div className="border-primary text-dark flex justify-between border-b !px-9 !py-4">
          <Typography.TitleSm weight="semibold">
            {liveChatRoomUuid ? (member?.name ?? "") : t("profile")}
          </Typography.TitleSm>
          <ModalCloseButton onClick={onClose} />
        </div>
        {liveChatRoomUuid ? (
          <ProfileMessaging uuid={liveChatRoomUuid} />
        ) : (
          <Modal.Body className="!md:px-0 !md:pt-0 !md:overflow-hidden block grid-cols-12 !p-0 md:grid md:grid-flow-row-dense">
            <ProfileV3
              member={member}
              isLoading={isLoading}
              onClose={() => modal.remove()}
            />
          </Modal.Body>
        )}
      </Modal.Content>
    </Modal>
  );
};
