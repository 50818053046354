import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface RoomTypeOptionProps {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
  children?: any;
}

export const RoomTypeOption = ({
  title,
  onClick,
  isSelected,
  disabled,
  children,
}: RoomTypeOptionProps) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={classNames(
        "order-none box-border flex h-full cursor-pointer flex-col items-stretch gap-2 rounded-lg border border-solid p-6 pt-5",
        {
          "bg-tertiary border-tertiary": isSelected,
          "pointer-events-none cursor-not-allowed opacity-70": disabled,
        },
      )}
      onClick={handleOnClick}
    >
      <div className="flex items-center justify-between">
        <Typography.LabelLg weight="semibold">{title}</Typography.LabelLg>
        <div className="text-dark">
          <Icon
            type={isSelected ? "circle-checked" : "circle-unchecked"}
            size={16}
          />
        </div>
      </div>
      {children && <div className="mt-1">{children}</div>}
    </button>
  );
};
