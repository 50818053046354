import { t } from "@/i18n-js/instance";
import { PLATFORMS } from "./communityHelpers";
import { PaymentMethodType } from "./paywallCheckoutHelpers";

export const STATUS = {
  INCOMPLETE: "incomplete",
  PROCESSING: "processing",
  PAID: "paid",
  CANCELED: "canceled",
  REFUNDED: "refunded",
  FAILED: "failed",
  // This is not an official status and it is used on Admin Paywall Billing
  PARTIAL_REFUNDED: "partial_refunded",
} as const;

export const STATUS_PRESENTER = {
  PARTIAL_REFUND: t(
    "community_member_charge_presenter.display_status.partial_refund",
  ),
  INCOMPLETE: t("community_member_charge_presenter.display_status.incomplete"),
  PROCESSING: t("community_member_charge_presenter.display_status.processing"),
  PAID: t("community_member_charge_presenter.display_status.paid"),
  CANCELED: t("community_member_charge_presenter.display_status.canceled"),
  REFUNDED: t("community_member_charge_presenter.display_status.refunded"),
  FAILED: t("community_member_charge_presenter.display_status.failed"),
};

export const SEARCHABLE_STATUS: string[] = [
  STATUS.PAID,
  STATUS.PARTIAL_REFUNDED,
  STATUS.REFUNDED,
  STATUS.FAILED,
];

export interface Charge {
  status?: string;
  amount_refunded_raw?: number;
  amount_raw?: number;
  payment_method_type?:
    | (typeof PaymentMethodType)[keyof typeof PaymentMethodType]
    | null;
  platform?: string;
}

export const isPartialRefund = (charge?: Charge): boolean =>
  !!charge &&
  charge.status !== STATUS.REFUNDED &&
  (charge.amount_refunded_raw ?? 0) > 0;

export const isRefundedOrPartialRefunded = (charge?: Charge): boolean =>
  !!charge && (charge.status === STATUS.REFUNDED || isPartialRefund(charge));

export const isPaid = (charge?: Charge): boolean =>
  !!charge && charge.status === STATUS.PAID;

export const isFreeCharge = (charge?: Charge): boolean =>
  !!charge && charge.amount_raw === 0;

export const isPaymentMethodTypePix = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.PIX;

export const isPaymentMethodTypeCard = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.CARD;

export const isPaymentMethodTypeGooglePay = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.GOOGLE_PAY;

export const isPaymentMethodTypeApplePay = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.APPLE_PAY;

export const isPaymentMethodTypeLink = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.LINK;

export const isPaymentMethodTypeKlarna = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.KLARNA;

export const isPaymentMethodTypeAfterpayClearpay = (charge?: Charge): boolean =>
  !!charge &&
  charge.payment_method_type === PaymentMethodType.AFTERPAY_CLEARPAY;

export const isPaymentMethodTypeAffirm = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === PaymentMethodType.AFFIRM;

export const isPaymentMethodTypeNull = (charge?: Charge): boolean =>
  !!charge && charge.payment_method_type === null;

export const doesBelongToMobilePlatform = (charge?: Charge): boolean =>
  !!charge &&
  [PLATFORMS.APP_STORE, PLATFORMS.PLAY_STORE].some(
    p => p === (charge.platform || ""),
  );
