import { t } from "@/i18n-js/instance";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Post } from "@circle-react/types";
import type { Comment } from "@circle-react/types/Comment";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { COMMENT_ANCHOR_PREFIX } from "../constants";
import { MemberHeadline } from "./MemberHeadline";

interface MemberBioProps {
  post: Post;
  comment: Comment;
}

export const MemberBio = ({ post, comment }: MemberBioProps) => {
  const {
    id,
    community_member: communityMember,
    member_tags,
    flagged_for_approval_at,
    created_at,
    parent_comment_id,
  } = comment;
  const { currentCommunityMember } = usePunditUserContext();
  const { usedIn } = useCurrentPostContext();
  const isUsedInImageSpaceFullPostModal = usedIn === "full-post-modal";
  const isAdmin = Boolean(communityMember.admin);
  const visibleMemberTags = member_tags?.filter(
    memberTag => memberTag?.is_public && memberTag?.display_locations?.post_bio,
  );
  const isIframeEmbed = useIsIframeEmbed();
  const isCommunityAdminUser = isCommunityAdmin(currentCommunityMember);
  const hasMemberTags = visibleMemberTags?.length > 0 || isAdmin;
  const isSmScreen = useSmScreenMediaQuery();

  const shouldDisplayMemberTags = isSmScreen
    ? isCommunityAdminUser
    : hasMemberTags;

  const profileLinkProps = useProfileLinkProps({
    public_uid: communityMember.public_uid ?? "",
    params: { show_back_link: true },
  });

  const commentLinkProps = {
    href: postsPath({
      spaceSlug: post.space_slug,
      slug: post.slug,
      hash: `${COMMENT_ANCHOR_PREFIX}${id}`,
    }),
    ...(isIframeEmbed ? { target: "_blank", rel: "noreferrer" } : {}),
  };

  const shouldDisplayOneMemberTag =
    isSmScreen || isUsedInImageSpaceFullPostModal || !!parent_comment_id;

  return (
    <div className="post__bio mb-1.5 w-full">
      <div
        className={classNames(
          "author text-darkest flex flex-wrap items-center gap-x-2",
          {
            "no-headline": !communityMember.headline,
          },
        )}
      >
        <div className="author__name flex">
          <a
            className="text-dark hover:text-darkest focus-visible:outline-secondary focus-visible:text-dark overflow-anywhere text-sm font-semibold focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            {...profileLinkProps}
          >
            {communityMember.name}
          </a>
        </div>
        {hasMemberTags && shouldDisplayMemberTags && (
          <div className="author__tags member-tags">
            <MemberTags
              memberTags={visibleMemberTags}
              isAdmin={isAdmin}
              limit={shouldDisplayOneMemberTag ? 1 : 2}
              showMoreLabelVariant="numbers"
            />
          </div>
        )}
        {!isSmScreen && !isUsedInImageSpaceFullPostModal && (
          <div className="author__time flex">
            <a
              className={classNames(
                "text-default hover:text-dark text-xs",
                "focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
              )}
              {...commentLinkProps}
            >
              <TippyV2
                content={getDetailedTimestamp(created_at)}
                className="inline-flex"
                interactive={false}
              >
                {dateStringToTimeAgo(created_at, {
                  format: dateToTimeAgoFormats.short,
                })}
              </TippyV2>
            </a>
          </div>
        )}
        {!isSmScreen &&
          !isUsedInImageSpaceFullPostModal &&
          flagged_for_approval_at && (
            <BadgeV2
              className="authour__in-review !bg-author-in-review border-0 uppercase text-white"
              label={t("comments.in_review")}
            />
          )}
      </div>
      <MemberHeadline comment={comment} commentLinkProps={commentLinkProps} />
    </div>
  );
};
