import classNames from "classnames";
import { useRouteMatch } from "react-router";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";

interface PostContentWrapperProps {
  children: React.ReactNode;
}

export const PostContentWrapper = ({ children }: PostContentWrapperProps) => {
  const {
    isImagePost,
    isPostBodyEmpty,
    isPostMetaHidden,
    isSharedPostWithTeaserLayout,
  } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;
  const isUsedInFullPostModal = usedIn === "full-post-modal";

  const shouldAddBottomPaddingForPostsWithEmptyBody =
    isPostBodyEmpty &&
    !isHomePage &&
    !isFeedPage &&
    !isPostMetaHidden &&
    !isImagePost;

  return (
    <div
      className={classNames("post__content text-dark", {
        "p-5 md:px-6": !isImagePost && isPostMetaHidden,
        "px-5 pb-5 pt-4 md:px-6 md:py-5": !isImagePost && !isPostMetaHidden,
        "pt-5": isImagePost && !isUsedInFullPostModal && isPostMetaHidden,
        "pt-4 md:pt-5":
          isImagePost && !isUsedInFullPostModal && !isPostMetaHidden,
        "py-5": isImagePost && isUsedInFullPostModal,
        "!pb-0": isImagePost && isUsedInFullPostModal && !isPostBodyEmpty,
        "pb-5": shouldAddBottomPaddingForPostsWithEmptyBody,
        relative: isSharedPostWithTeaserLayout,
      })}
      data-testid="post-content-wrapper"
    >
      {children}
    </div>
  );
};
