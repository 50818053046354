import { isEmpty } from "lodash";
import { t } from "@/i18n-js/instance";
import { LevelBadge } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/LevelBadge";
import { ProfileFieldsList } from "@circle-react/components/Profile/About/ProfileFieldsList";
import { ProfileInfoContainer } from "@circle-react/components/Profile/About/ProfileInfoContainer";
import { ProfileLinkContainer } from "@circle-react/components/Profile/About/ProfileLinkContainer";
import { Section } from "@circle-react/components/Profile/About/Section";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { ScoreCell } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells";
import { hasSocialLinks } from "@circle-react/helpers/memberHelper";
import { useHideEmailsOnMemberProfiles } from "@circle-react/hooks/useHideEmailsOnMemberProfiles";
import { useIsActivityScoreEnabledOrUpgradable } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ScoreContextProvider } from "@circle-react-shared/ActivityScore";
import {
  UpgradeFlow,
  useIsProfileFieldsEnabled,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { Typography } from "@circle-react-uikit/Typography";

export interface ProfileInfoProps {
  member: any;
}

export const ProfileInfo = ({ member }: ProfileInfoProps) => {
  const { data: gamificationMembers } = useGamificationMember({
    enabled: !!member?.id,
    member_ids: [member?.id],
  });
  const gamificationMember = gamificationMembers?.[0];
  const isEmailPresent = !isEmpty(member.email);
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();
  const isActivityScoreEnabledOrUpgradable =
    useIsActivityScoreEnabledOrUpgradable();
  const shouldHideEmailsOnMemberProfiles = useHideEmailsOnMemberProfiles();
  const isSmScreen = useSmScreenMediaQuery();
  const visibleMemberTags = member.member_tags?.filter(
    (memberTag: any) =>
      memberTag?.is_public && memberTag?.display_locations?.profile_page,
  );
  const hasVisibleMemberTags = visibleMemberTags?.length > 0;

  const shouldShowEmailSection =
    isEmailPresent && !shouldHideEmailsOnMemberProfiles;

  return (
    <div className="text-dark px-0 py-4 text-sm">
      {gamificationMember && (
        <div className="mb-8">
          <LevelBadge
            level={gamificationMember.current_level}
            levelName={gamificationMember.current_level_name}
          />
          <div className="flex items-center space-x-2">
            <Typography.LabelXs weight="regular" color="text-light">
              {t("leaderboard.n_points", {
                count: gamificationMember.total_points,
              })}
            </Typography.LabelXs>
            <Typography.LabelXs weight="regular" color="text-light">
              {t("separator_dot")}
            </Typography.LabelXs>
            <Typography.LabelXs weight="regular" color="text-light">
              {t("leaderboard.n_to_level_up", {
                count: gamificationMember.points_to_next_level,
              })}
            </Typography.LabelXs>
          </div>
        </div>
      )}
      {!isProfileFieldsEnabled && member.bio && (
        <Section
          dataTestId="biography-section"
          title={t("profile_drawer.biography")}
        >
          <ProfileInfoContainer
            info={member.bio}
            dataTestId="profile-member-bio"
          />
        </Section>
      )}

      {(shouldShowEmailSection || member.location) && (
        <Section dataTestId="profile-info-section">
          {shouldShowEmailSection && (
            <ProfileInfoContainer
              iconType="at-symbol"
              info={member.email}
              dataTestId="profile-member-email"
            />
          )}
          {!isProfileFieldsEnabled && (
            <ProfileInfoContainer
              iconType="map-pin"
              iconProps={{ viewBox: "0 0 14 18" }}
              info={member.location}
              dataTestId="profile-member-location"
            />
          )}
        </Section>
      )}

      {isActivityScoreEnabledOrUpgradable && (
        <Section dataTestId="activity-score-section">
          <div className="flex flex-col gap-y-3">
            <Typography.LabelSm weight="semibold">
              {t("activity_score.activity_score_title")}
            </Typography.LabelSm>
            <ScoreContextProvider ids={[member.id]}>
              <ScoreCell memberId={member.id} />
            </ScoreContextProvider>
          </div>
        </Section>
      )}

      {hasVisibleMemberTags && !isSmScreen && (
        <Section title={t("profile_drawer.tags")}>
          <div className="profile-drawer__about__tags">
            <MemberTags limit={Infinity} memberTags={visibleMemberTags} />
          </div>
        </Section>
      )}

      {shouldShowProfileFieldsUpgradeModal && (
        <Section title={t("profile_fields.upgrade_flow.title")}>
          <UpgradeFlow />
        </Section>
      )}

      {!isProfileFieldsEnabled && hasSocialLinks(member) && (
        <Section title={t("profile_drawer.social")}>
          <ProfileLinkContainer iconType="globe" link={member.website_url} />
          <ProfileLinkContainer
            iconType="facebook"
            link={member.facebook_url}
          />
          <ProfileLinkContainer iconType="twitter" link={member.twitter_url} />
          <ProfileLinkContainer
            iconType="linkedin"
            link={member.linkedin_url}
          />
          <ProfileLinkContainer
            iconType="instagram"
            link={member.instagram_url}
          />
        </Section>
      )}

      {isProfileFieldsEnabled && (
        <ProfileFieldsList profileFields={member.profile_fields} />
      )}
    </div>
  );
};
