import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { Basic } from "./Basic";
import { DateAndTime } from "./DateAndTime";
import { Location } from "./Location";
import { Ticket } from "./Ticket";
import { useLiveValuesWatch } from "./hooks/useLiveValuesWatch";

export const BasicInfo = ({ event, setLocationType }) => {
  const { space } = useLiveValuesWatch(event);
  const { currentCommunityMember } = usePunditUserContext();
  const { is_admin: isAdmin } = currentCommunityMember || {};
  const isPaidEventCreationAllowed = isAdmin;

  return (
    <>
      {event && (
        <Typography.TitleXl>
          {t("events.create.form.basic_info")}
        </Typography.TitleXl>
      )}

      <Basic
        event={event}
        canCreateTopics={canManageSpace(space)}
        requireTopicSelection={space?.require_topic_selection}
      />
      <DateAndTime event={event} />
      <Location event={event} setLocationType={setLocationType} />
      {isPaidEventCreationAllowed && <Ticket event={event} />}
    </>
  );
};

BasicInfo.propTypes = {
  event: PropTypes.object,
  setLocationType: PropTypes.func,
};
