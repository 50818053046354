import { t } from "@/i18n-js/instance";
import { useShouldShowCommunityAiModal } from "@circle-react/hooks/useIsActivityScoreEnabled";
import {
  HoverScoreChip,
  ScoreChip,
  useScoreContext,
} from "@circle-react-shared/ActivityScore";
import { Typography } from "@circle-react-uikit/Typography";
import { getDisabledScoreDetail } from "./utils";

export function ScoreCell({
  memberId,
  shortDisabledLabel = false,
  shouldHideIfNoScore = false,
}: {
  memberId: number;
  shortDisabledLabel?: boolean;
  shouldHideIfNoScore?: boolean;
}) {
  const { data, isLoading } = useScoreContext();
  const shouldShowCommunityAiModal = useShouldShowCommunityAiModal();

  if (isLoading) {
    return null;
  }

  if (shouldShowCommunityAiModal) {
    const disabledScoreDetail = getDisabledScoreDetail(shortDisabledLabel);

    return (
      <div>
        <ScoreChip activityScore={disabledScoreDetail.activityScore} />
      </div>
    );
  }

  const scoreDetail = data?.get(memberId);

  if (shouldHideIfNoScore && !scoreDetail) {
    return null;
  }

  if (!scoreDetail) {
    return (
      <Typography.LabelSm color="text-default">{t("n/a")}</Typography.LabelSm>
    );
  }

  return <HoverScoreChip scoreDetail={scoreDetail} />;
}
