import type { Participant } from "@circle-react/types/Live/Participant";
import type { Peer } from "@circle-react/types/Live/Peer";
import type { Room } from "@circle-react/types/Live/Room";

export const ROOM_STATUS = Object.freeze({
  INITIALIZING: "initializing",
  BACKSTAGE: "backstage",
  LIVE: "active",
  ENDED: "inactive",
  CLOSING: "closing",
});

export const ROOM_TYPES = Object.freeze({
  CONFERENCE: "conference",
  STREAM: "stream",
  EVENT: "event",
});

export type RoomType = (typeof ROOM_TYPES)[keyof typeof ROOM_TYPES];

export const ROOM_ACCESS_TYPES = Object.freeze({
  OPEN: "open",
  SECRET: "secret",
  PUBLIC_STREAM: "public_stream",
});

export const ROOM_VIEW_TYPES = Object.freeze({
  SPEAKER: "speaker_view",
  SPEAKER_VARIANT: "speaker_variant_view",
  GRID: "grid_view",
});

export const PARTICIPANT_ROLES = Object.freeze({
  HOST: "host",
  SPECTATOR: "spectator",
  HLS_SPECTATOR: "hlsspectator",
  MODERATOR: "moderator",
});

export const PARTICIPANT_DISPLAY_ROLES = Object.freeze({
  HOST: "host",
  MODERATOR: "moderator",
  COHOST: "cohost",
  PARTICIPANT: "participant",
});

export const PARTICIPANT_STATUS = {
  JOINING: "joining",
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export const ROOM_PROVIDERS = Object.freeze({
  HMS: "hms",
  LIVEKIT: "livekit",
});

export const INITIAL_HOST_NUMBER = 1;

export const isBackstage = (room?: Pick<Room, "status">) =>
  room?.status === ROOM_STATUS.BACKSTAGE;
export const isLive = (room?: Pick<Room, "status">) =>
  room?.status === ROOM_STATUS.LIVE;
export const isClosing = (room?: Pick<Room, "status">) =>
  room?.status === ROOM_STATUS.CLOSING;
export const isEnded = (room?: Pick<Room, "status">) =>
  room?.status === ROOM_STATUS.ENDED;
export const isStream = (room?: Room) => room?.room_type === ROOM_TYPES.STREAM;
export const isConference = (room?: Room) =>
  room?.room_type === ROOM_TYPES.CONFERENCE;
export const isRecordingEnabled = (room?: Room) => !!room?.recording_enabled;
export const canManageRoom = (room?: Room) => !!room?.can_manage;
export const canHostRoom = (room?: Room) => !!room?.can_host;
export const canModerateRoom = (room?: Room) => !!room?.can_moderate;
export const roomHostCapacityReached = (room?: Room) =>
  !!room?.host_capacity_reached;
export const roomSpectatorCapacityReached = (room?: Room) =>
  !!room?.spectator_capacity_reached;
export const roomTypeData = (room?: Pick<Room, "room_type">) =>
  room?.room_type || ROOM_TYPES.STREAM;
export const isSpeakerView = (room?: Room) =>
  !room?.view_type ||
  room?.view_type === ROOM_VIEW_TYPES.SPEAKER ||
  room?.view_type === ROOM_VIEW_TYPES.SPEAKER_VARIANT;
export const isSpeakerTopView = (room?: Room) =>
  room?.view_type === ROOM_VIEW_TYPES.SPEAKER;
export const isGridView = (room?: Room) =>
  room?.view_type === ROOM_VIEW_TYPES.GRID;
export const isSecretRoom = (room?: Pick<Room, "access_type">) =>
  room?.access_type === ROOM_ACCESS_TYPES.SECRET;
export const isPublicStream = (room?: Pick<Room, "access_type">) =>
  room?.access_type === ROOM_ACCESS_TYPES.PUBLIC_STREAM;
export const isParticipantInactive = (participant?: Participant | null) =>
  participant?.status === PARTICIPANT_STATUS.INACTIVE;
export const isHostRole = (participant: Participant | null) =>
  participant?.role === PARTICIPANT_ROLES.HOST;
export const isSpectatorRole = (participant: Participant | null) =>
  participant?.role === PARTICIPANT_ROLES.SPECTATOR;
export const isHLSSpectatorRole = (participant: Participant | null) =>
  participant?.role === PARTICIPANT_ROLES.HLS_SPECTATOR;
export const isSecretRoomSelectedInForm = (field: string) =>
  field === ROOM_ACCESS_TYPES.SECRET;
export const isGridViewSelectedInForm = (field: string) =>
  field === ROOM_VIEW_TYPES.GRID;
export const isHMSProvider = (room?: Room) =>
  room?.room_provider === ROOM_PROVIDERS.HMS;
export const isLivekitProvider = (room?: Room) =>
  room?.room_provider === ROOM_PROVIDERS.LIVEKIT;
export const canManagePublicStream = (room?: Room) =>
  isPublicStream(room) && canManageRoom(room);
export const isLimitUrlSharingEnabled = (
  room?: Pick<Room, "limit_url_sharing">,
) => room?.limit_url_sharing;
export const shouldHideParticipantsList = (room?: Room) =>
  !!room?.hide_participants_list;
export const shouldHideParticipantsListForCurrentUser = (room?: Room) =>
  shouldHideParticipantsList(room) &&
  !canManageRoom(room) &&
  !canModerateRoom(room);
export const getParticipantFromPeerMetadata = (peer?: Peer) => {
  const strMetadata = peer?.metadata;
  if (!strMetadata) {
    return null;
  }
  try {
    const participant = JSON.parse(strMetadata);
    return { ...participant, role: peer.roleName };
  } catch (e) {
    console.error("Error parsing peer metadata", e);
    return null;
  }
};
