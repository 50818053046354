/* eslint-disable sonarjs/cognitive-complexity -- Disable to pass CI. This needs refactor. */
import { useThreadsStore } from "@circle-react/hooks/chatsV2/store";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";

export const useChatThreadReaction = () => {
  const { parentMessage, updateParentMessage } = useRailbar();
  const { chatMessages, setChatMessages, scrollInfo, setScrollInfo } =
    useThreadsStore();

  const createReactionFromThread = ({
    communityMemberId,
    emoji,
    reactionableId,
  }) => {
    if (parentMessage?.id === reactionableId) {
      const updatedParentMessage = structuredClone(parentMessage);
      const existingReactionIndex = updatedParentMessage.reactions.findIndex(
        reaction => reaction.emoji === emoji,
      );

      if (
        updatedParentMessage.reactions[
          existingReactionIndex
        ]?.community_member_ids?.includes(communityMemberId)
      )
        return;

      if (existingReactionIndex !== -1) {
        updatedParentMessage.reactions[
          existingReactionIndex
        ].community_member_ids.push(communityMemberId);
        updatedParentMessage.reactions[existingReactionIndex].count += 1;
      } else {
        updatedParentMessage.reactions.push({
          emoji: emoji,
          community_member_ids: [communityMemberId],
          count: 1,
        });
      }

      updateParentMessage(updatedParentMessage);
    } else {
      const updatedChatMessages = structuredClone(chatMessages);
      const chatMessageIndex = updatedChatMessages.findIndex(
        chatMessage => chatMessage.id === reactionableId,
      );

      if (chatMessageIndex !== -1) {
        const existingReactionIndex = updatedChatMessages[
          chatMessageIndex
        ].reactions.findIndex(reaction => reaction.emoji === emoji);

        if (
          updatedChatMessages[chatMessageIndex].reactions[
            existingReactionIndex
          ]?.community_member_ids?.includes(communityMemberId)
        )
          return;

        if (existingReactionIndex !== -1) {
          updatedChatMessages[chatMessageIndex].reactions[
            existingReactionIndex
          ].community_member_ids.push(communityMemberId);
          updatedChatMessages[chatMessageIndex].reactions[
            existingReactionIndex
          ].count += 1;
        } else {
          updatedChatMessages[chatMessageIndex].reactions.push({
            emoji: emoji,
            community_member_ids: [communityMemberId],
            count: 1,
          });
        }

        setScrollInfo({
          ...scrollInfo,
          action: "noScroll",
        });
        setChatMessages(updatedChatMessages);
      }
    }
  };

  const removeReactionFromThread = ({
    communityMemberId,
    emoji,
    reactionableId,
  }) => {
    if (parentMessage?.id === reactionableId) {
      const updatedParentMessage = structuredClone(parentMessage);
      const existingReactionIndex = updatedParentMessage.reactions.findIndex(
        reaction => reaction.emoji === emoji,
      );

      if (
        !updatedParentMessage.reactions[
          existingReactionIndex
        ]?.community_member_ids?.includes(communityMemberId)
      )
        return;

      if (existingReactionIndex !== -1) {
        if (updatedParentMessage.reactions[existingReactionIndex].count === 1) {
          updatedParentMessage.reactions.splice(existingReactionIndex, 1);
        } else {
          updatedParentMessage.reactions[
            existingReactionIndex
          ].community_member_ids = updatedParentMessage.reactions[
            existingReactionIndex
          ].community_member_ids.filter(id => id !== communityMemberId);
          updatedParentMessage.reactions[existingReactionIndex].count -= 1;
        }

        updateParentMessage(updatedParentMessage);
      }
    } else {
      const updatedChatMessages = structuredClone(chatMessages);

      const chatMessageIndex = updatedChatMessages.findIndex(
        chatMessage => chatMessage.id === reactionableId,
      );

      if (chatMessageIndex !== -1) {
        const existingReactionIndex = updatedChatMessages[
          chatMessageIndex
        ]?.reactions?.findIndex(reaction => reaction.emoji === emoji);

        if (
          !updatedChatMessages[chatMessageIndex].reactions[
            existingReactionIndex
          ]?.community_member_ids?.includes(communityMemberId)
        )
          return;

        if (existingReactionIndex !== -1) {
          if (
            updatedChatMessages[chatMessageIndex].reactions[
              existingReactionIndex
            ].count === 1
          ) {
            updatedChatMessages[chatMessageIndex].reactions.splice(
              existingReactionIndex,
              1,
            );
          } else {
            updatedChatMessages[chatMessageIndex].reactions[
              existingReactionIndex
            ].community_member_ids = updatedChatMessages[
              chatMessageIndex
            ].reactions[existingReactionIndex].community_member_ids.filter(
              id => id !== communityMemberId,
            );
            updatedChatMessages[chatMessageIndex].reactions[
              existingReactionIndex
            ].count -= 1;
          }
        }

        setScrollInfo({
          ...scrollInfo,
          action: "noScroll",
        });
        setChatMessages(updatedChatMessages);
      }
    }
  };

  const handleReactionFromWebsocket = data => {
    if (data.json_message.reactionable_type === "ChatRoomMessage") {
      const {
        community_member_id: communityMemberId,
        emoji,
        reactionable_id: reactionableId,
      } = data.json_message;

      if (data.event === "createReaction") {
        createReactionFromThread({
          communityMemberId: communityMemberId,
          emoji: emoji,
          reactionableId: reactionableId,
        });
      } else if (data.event === "destroyReaction") {
        removeReactionFromThread({
          communityMemberId: communityMemberId,
          emoji: emoji,
          reactionableId: reactionableId,
        });
      }
    }
  };

  return {
    createReactionFromThread,
    removeReactionFromThread,
    handleReactionFromWebsocket,
  };
};
