import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useProfileCommentsList = ({
  memberPublicId,
  params = { per_page: 10 },
}) => {
  const queryKey = ["profile-comments", memberPublicId];
  const fetchPage = page =>
    reactQueryGet(
      internalApi.profile.comments({
        memberPublicId,
        params: { page, ...params },
      }),
    );

  const infiniteCommentsQuery = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const comments = useMemo(
    () =>
      infiniteCommentsQuery.isLoading
        ? []
        : uniqBy(
            flatten(infiniteCommentsQuery.data.pages.map(page => page.records)),
            "id",
          ),
    [infiniteCommentsQuery.data],
  );

  return { ...infiniteCommentsQuery, comments };
};
