import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const PreviewEventLink = ({ event, isEventDraft }) => {
  const { slug: eventSlug, space_slug: spaceSlug } = event || {};
  const handleButtonClick = () => {
    window.open(
      postsPath({
        spaceSlug,
        slug: eventSlug,
      }),
      "_blank",
    );
  };

  return (
    <button
      type="button"
      className="flex cursor-pointer items-center whitespace-nowrap"
      onClick={handleButtonClick}
    >
      <Typography.LabelSm weight="medium">
        {t(
          isEventDraft
            ? "events.edit_modal.preview"
            : "events.edit_modal.overview.view_event",
        )}
      </Typography.LabelSm>
      <Icon type="16-arrow-top-right" size={16} />
    </button>
  );
};

PreviewEventLink.propTypes = {
  event: PropTypes.object.isRequired,
};
