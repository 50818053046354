import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types/Post";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

export interface DisableCommentsActionProps {
  post?: Post;
  handleClose?: () => void;
  onChange?: (postData: Post, options: { refetch: boolean }) => void;
}

export const DisableCommentsAction = ({
  post,
  handleClose,
  onChange,
}: DisableCommentsActionProps) => {
  const isCommentsDisabled = !!post?.is_comments_disabled;
  const translationRoot = isCommentsDisabled
    ? "post.enable_comments"
    : "post.disable_comments";
  const { success, error } = useToast();

  const { mutate: toggleCommentsDisabled } = useMutation<Post, ApiError, void>(
    () =>
      reactQueryPatch(
        internalApi.posts.update({
          spaceId: post?.space_id,
          postSlug: post?.slug,
        }),
        {
          post: {
            space_id: post?.space_id,
            is_comments_disabled: !isCommentsDisabled,
          },
        },
      ),
    {
      onSuccess: postData => {
        onChange?.(postData, { refetch: false });
        success(t(`${translationRoot}.alerts.success`));
      },
      onError: () => {
        error(t(`${translationRoot}.alerts.failure`));
      },
      onSettled: handleClose,
    },
  );

  return (
    <Dropdown.ItemLink onClick={() => toggleCommentsDisabled()}>
      <ToggleSwitchWithLabel
        label={t("post.action_menu.disable_comments")}
        field="disable_comments"
        value={isCommentsDisabled}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
