import { useParams } from "react-router-dom";
import { useSpacesContext } from "@circle-react/contexts";

export const useEventSpaces = () => {
  const spaceContext = useSpacesContext();
  const { spaceSlug } = useParams();
  const spaces = spaceContext.helpers.spacesWithPostCreationAllowed() || [];
  const eventSpaces = spaces.filter(space => space.post_type === "event");
  const spaceId =
    eventSpaces.find(space => space.slug === spaceSlug)?.id || null;
  return {
    spaces: eventSpaces,
    spaceId,
  };
};
