import { t } from "@/i18n-js/instance";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import { isPublishedPost } from "@circle-react/helpers/postHelpers";
import type { ImagePost, Space } from "@circle-react/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";
import { Typography } from "@circle-react-uikit/Typography";
import type { DuplicateParams } from "./types";

interface DuplicatePostModalProps {
  post: ImagePost;
  spaces: Space[];
  isOpen: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  onSubmit: (data: DuplicateParams) => void;
  onClose: () => void;
}

export const DuplicatePostModal = ({
  post,
  spaces,
  isOpen,
  isLoading,
  isSubmitting,
  onSubmit,
  onClose,
}: DuplicatePostModalProps) => {
  if (isLoading) {
    return null;
  }

  // TODO: Refactor to ModalV2
  return (
    <Modal
      title={t("duplicate_post")}
      variant="default"
      show={isOpen}
      onClose={onClose}
      overflowVisible
    >
      <Form
        defaultValues={{ space_id: post.space_id, send_notifications: false }}
        onSubmit={(data: DuplicateParams) => onSubmit(data)}
      >
        <Form.Item
          name="space_id"
          label={t("space")}
          description={t("duplicate_post_to_space")}
          rules={{
            required: t("events.create.form.space_required_error"),
          }}
          hideBorder
          fullWidth
        >
          <SpaceSelect
            name="space_id"
            variant="form-input"
            containerClassname="mt-2"
            spaces={spaces}
          />
        </Form.Item>
        {isPublishedPost(post) && (
          <>
            <Typography.LabelXs color="text-light">
              {t("comments_wont_be_duplicated")}
            </Typography.LabelXs>
            <Form.Item
              name="send_notifications"
              label={t("duplicate_post_send_notifications")}
              hideDescription
              inlineReverse
              hideBorder
              fullWidth
            >
              <Form.ToggleSwitch name="send_notifications" variant="small" />
            </Form.Item>
          </>
        )}
        <div className="mt-4">
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting ? t("duplicating") : t("duplicate")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
