import { Carousel } from "@/react/components/Spaces/ImageSpace/FeedView/Carousel";
import type { ImagePost } from "@/react/types";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { Modal } from "@circle-react-uikit/ModalV2";

interface MobileFullCarouselModalProps {
  post?: ImagePost;
  spaceId?: number;
  startingImageIndex?: number;
}
export const MobileFullCarouselModalComponent = ({
  post,
  spaceId,
  startingImageIndex,
}: MobileFullCarouselModalProps) => {
  const modal = useModal();
  return (
    <CurrentSpaceContextProvider spaceId={spaceId}>
      <PostsDetailsContextProvider perPagePostIds={[[post?.id]]}>
        <CurrentPostContextProvider
          post={post}
          usedIn="mobile-full-carousel-modal"
        >
          <Modal
            isOpen={modal.visible}
            onClose={modal.remove}
            contentPortalId="mobile-full-carousel-modal-portal"
          >
            <Modal.Overlay />
            <Modal.Content size="full" className="h-full">
              <Carousel startingImageIndex={startingImageIndex} />
            </Modal.Content>
          </Modal>
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};
const MobileFullCarouselModal = ModalManager.create(
  (props: MobileFullCarouselModalProps) => (
    <MobileFullCarouselModalComponent {...props} />
  ),
);
export const useMobileFullCarouselModal = () =>
  useModal(MobileFullCarouselModal);
