import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";
import { hasTags } from "@circle-react/helpers/memberHelper";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";
import { getRouteWithPrevLocation } from "@circle-react/helpers/urlHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Button } from "@circle-react-uikit/Button";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { Typography } from "@circle-react-uikit/Typography";
import { useMemberProfileActions } from "./useMemberProfileActions";

interface MobileProfileHeaderProps {
  member: any;
  onSendMessageClick: (chatRoomUuid: string) => void;
  shouldHideMessageButton?: boolean;
  isFullPageProfile?: boolean;
  shouldAutoNavigate?: boolean;
}

export const MobileProfileHeader = ({
  member,
  onSendMessageClick,
  isFullPageProfile = false,
  shouldHideMessageButton = false,
  shouldAutoNavigate = true,
}: MobileProfileHeaderProps) => {
  const { findOrCreateChatRoom } = useChatRoom();
  const history = useHistory();

  const {
    shouldShowEditProfileButton,
    onClickOpenEditProfileDrawer,
    handleCopyProfileLink,
    shouldShowMemberChatIcon,
  } = useMemberProfileActions({
    memberPublicId: member.public_id,
    member,
    isFullPageProfile,
    shouldHideMessageButton,
  });

  const handleMessageMember = async (memberId: any) => {
    const chatRoomUuid = await findOrCreateChatRoom({
      kind: DIRECT_CHAT_ROOM,
      community_member_ids: [memberId],
    });
    if (shouldAutoNavigate) {
      history.push(
        getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
      );
    }
    onSendMessageClick(chatRoomUuid);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 p-6">
      <UserImage name={member?.name} size="16" src={member?.avatar_url} />
      <div>
        <Typography.TitleSm weight="font-bold" textAlign="text-center">
          {member?.name}
        </Typography.TitleSm>
        <Typography.BodySm textAlign="text-center">
          {member?.headline}
        </Typography.BodySm>
      </div>
      {hasTags(member) && (
        <MemberTags limit={Infinity} memberTags={member.member_tags} />
      )}
      {shouldShowMemberChatIcon && (
        <Button
          full
          variant="circle"
          onClick={() => handleMessageMember(member.id)}
        >
          {t("message")}
        </Button>
      )}
      {isFullPageProfile && (
        <div
          className={classNames("grid gap-2", {
            "grid-cols-2": shouldShowEditProfileButton,
          })}
        >
          <Button
            full
            variant="secondary-light"
            onClick={handleCopyProfileLink}
          >
            {t("profile_drawer.dropdown.copy_profile_link")}
          </Button>
          {shouldShowEditProfileButton && (
            <Button
              full
              variant="secondary-light"
              onClick={onClickOpenEditProfileDrawer}
            >
              {t("profile_drawer.dropdown.edit_member_profile")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
