import { useState } from "react";
import { uniqBy } from "lodash";
import { t } from "@/i18n-js/instance";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useThreadsStore } from "@/react/hooks/chatsV2/store";
import { markChatThreadsAsRead } from "@circle-react/api";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useToast } from "@circle-react-uikit/ToastV2";
import { detectBreakpoints } from "../detectBreakpoints";

export const useChatThreadsApi = () => {
  const {
    chatMessages,
    setChatMessages,
    isLoading,
    setIsLoading,
    hasNextPage,
    hasPrevPage,
    setHasNextPage,
    setHasPrevPage,
    scrollInfo,
    setScrollInfo,
    setFirstMessageId,
    setLastMessageId,
    setTotalMessagesCount,
    setMetaData,
  } = useThreadsStore();

  const [isInitialPageLoaded, setIsInitialPageLoaded] = useState(false);
  const { isMobileView } = detectBreakpoints();
  const { parentMessage, updateParentMessage } = useRailbar();
  const { error } = useToast();

  const fetchChatMessages = async ({
    id,
    previousPerPage = 20,
    nextPerPage = 0,
    chatRoomUuid,
    isInitialPage = false,
    parentMessage,
  }) => {
    const isScrolledDown = previousPerPage === 0;
    if ((isScrolledDown || isInitialPage) && isLoading) {
      return;
    }
    setIsLoading(true);

    const params = {
      previous_per_page: previousPerPage,
      next_per_page: nextPerPage,
      parent_message_id: parentMessage.id,
    };
    if (id) params["id"] = id;

    try {
      const data = await reactQueryGet(
        internalApi.chatRoomMessages.index({
          uuid: chatRoomUuid,
          params: params,
        }),
      );
      if (isInitialPage) {
        if (data.meta?.chat_thread_participant_id) {
          setScrollInfo(calculateScrollInfo({ event: "chatThreadsLoad" }));
          markChatThreadsAsRead(parentMessage.chat_thread_id);
        }
        setMetaData(data.meta);
        setChatMessages(data.records);
        setHasNextPage(data.has_next_page);
        setHasPrevPage(data.has_previous_page);
        setTotalMessagesCount(data.total_count);
        setFirstMessageId(data.first_id);
        setLastMessageId(data.last_id);
        setIsInitialPageLoaded(true);
      } else {
        if (isScrolledDown) {
          setScrollInfo(
            calculateScrollInfo({ event: "nextPaginatedMessageLoad" }),
          );
          setLastMessageId(data.last_id);
        } else {
          setScrollInfo(calculateScrollInfo({ event: "paginatedMessageLoad" }));
          setFirstMessageId(data.first_id);
        }

        setChatMessages(uniqBy([...data.records, ...chatMessages], "id"));

        hasNextPage && setHasNextPage(data.has_next_page);
        hasPrevPage && setHasPrevPage(data.has_previous_page);
      }
    } catch (err) {
      error(t("request_failure_message"));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Based on the event we will update the action to be performed on scroll once updated chat messages are rendered.
  const calculateScrollInfo = ({ event }) => {
    const element = document.getElementById("message-thread-scroll-view");
    let action = "noScroll";

    switch (event) {
      case "messageSent":
        action = "scrollToBottom";
        break;
      case "messageReceived": {
        const distanceFromBottom =
          element.scrollTop - element.scrollHeight + element.offsetHeight;

        const isMobileBottom = isMobileView() && distanceFromBottom === 0;
        const isBottomRange =
          distanceFromBottom <= 1 || distanceFromBottom > 14;

        if (isMobileBottom || isBottomRange) {
          action = "scrollToBottom";
        }

        break;
      }
      case "chatThreadsLoad":
        action = "scrollToUnreadMessageIndicator";
        break;
      case "paginatedMessageLoad":
        action = "adjustTop";
        break;
      case "nextPaginatedMessageLoad":
        action = "noScroll";
        break;
      case "messageUpdated":
        action = "noScroll";
        break;
      case "messageDeleted":
        action = "noScroll";
        break;
    }

    return {
      action: action,
      scrollTopValue: element.scrollTop,
      scrollHeightValue: element.scrollHeight,
    };
  };

  const adjustScroller = () => {
    const element = document.getElementById("message-thread-scroll-view");
    const unreadMessageBreakpoint = document.getElementById(
      "unread-thread-message-breakpoint",
    );

    switch (scrollInfo.action) {
      case "scrollToBottom":
        element.scrollTop = element.scrollHeight;
        break;
      case "adjustTop":
        element.scrollTop =
          element.scrollHeight -
          (scrollInfo.scrollHeightValue - scrollInfo.scrollTopValue);
        break;
      case "scrollToUnreadMessageIndicator":
        if (unreadMessageBreakpoint) {
          unreadMessageBreakpoint.scrollIntoView({ behavior: "smooth" });
        } else {
          element.scrollTop = element.scrollHeight;
        }
        break;
    }
  };

  const onEventReceive = (eventData, chatMessages, currentParticipant) => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(eventData, currentParticipant);
        break;
      case "deletedMessage":
        onDeleteMessage(eventData, chatMessages);
        break;
      case "updatedMessage":
        onUpdateMessage(eventData, chatMessages);
        break;
    }
  };

  const onDeleteMessage = (eventData, chatMessages) => {
    const messageId = eventData.id;
    const updatedChatMessages = chatMessages.filter(
      message => message.id !== messageId,
    );

    setChatMessages(updatedChatMessages);
  };

  const onUpdateMessage = (eventData, chatMessages) => {
    const updatedMessage = eventData.json_message;
    const updatedChatMessages = chatMessages.reduce((acc, message) => {
      if (message.id === updatedMessage.id) {
        acc.push(updatedMessage);
      } else {
        acc.push(message);
      }
      return acc;
    }, []);
    setChatMessages([...updatedChatMessages]);

    // Update parent message if it is in a reply
    if (updatedMessage.id === parentMessage?.id) {
      updateParentMessage(updatedMessage);
    }
  };

  const onNewMessage = (eventData, currentParticipant) => {
    const latestChatRoomMessage = eventData.json_message;
    setScrollInfo(
      calculateScrollInfo({
        event:
          latestChatRoomMessage.sender.id === currentParticipant.id
            ? "messageSent"
            : "messageReceived",
      }),
    );
    setChatMessages([...chatMessages, latestChatRoomMessage]);
  };

  return {
    fetchChatMessages,
    calculateScrollInfo,
    adjustScroller,
    onEventReceive,
    isInitialPageLoaded,
  };
};
