import { t } from "@/i18n-js/instance";
import { useCancelAndRefundChargeModal } from "@circle-react-shared/Paywalls/Modals/RefundChargeModal";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

interface AttendeeRemovalConfirmationModalProps {
  onClose: () => void;
  removeAttendeeMutation: (id: any) => void;
  isOpen?: boolean;
  attendeeId?: string | null;
  charge?: any;
}

export const AttendeeRemovalConfirmationModal = ({
  isOpen = false,
  onClose,
  removeAttendeeMutation,
  attendeeId,
  charge,
}: AttendeeRemovalConfirmationModalProps) => {
  const isRefundAllowed = charge?.amount_to_refund_raw > 0;
  const refundModal = useCancelAndRefundChargeModal();

  const onButtonClick = (id: any) => {
    removeAttendeeMutation(id);
    onClose();
  };

  const afterRefundAction = () => {
    onButtonClick(attendeeId);
  };

  return (
    <Modal
      title={t("events.rsvp.remove_member_modal.title")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="md" className="text-center">
            {t("events.rsvp.remove_member_modal.title")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="px-6 pb-4 pt-5 text-center">
            <Typography.BodyMd size="text-sm">
              {t("events.rsvp.remove_member_modal.description")}
            </Typography.BodyMd>
          </div>
        </Modal.Body>
        <Modal.Footer className="space-y-2">
          <Button
            variant="secondary"
            full
            large
            onClick={() => onButtonClick(attendeeId)}
            className="h-10"
          >
            {t("events.rsvp.remove_member_modal.remove_button")}
          </Button>
          {isRefundAllowed && (
            <Button
              variant="secondary"
              full
              large
              onClick={() => refundModal.show({ charge, onSuccess: onClose })}
              className="h-10"
            >
              {t("events.rsvp.remove_member_modal.refund_button")}
            </Button>
          )}
          {isRefundAllowed && (
            <Button
              variant="danger"
              full
              large
              onClick={() =>
                refundModal.show({ charge, onSuccess: afterRefundAction })
              }
              className="h-10"
            >
              {t("events.rsvp.remove_member_modal.remove_and_refund_button")}
            </Button>
          )}
          {!isRefundAllowed && (
            <Button
              variant="secondary"
              full
              large
              onClick={onClose}
              className="h-10"
            >
              {t("events.rsvp.remove_member_modal.decline_button")}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
