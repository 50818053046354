export const sortAndFilterTopics = ({
  activeTopicIDs,
  topics,
  skipSort,
}: any) =>
  activeTopicIDs
    .map((topicId: any) =>
      topics?.records.find(({ id }: any) => id === topicId),
    )
    .filter((topic: any): topic is NonNullable<typeof topic> => !!topic)
    .sort((a: any, b: any) => {
      if (skipSort) {
        return 0;
      }
      if (a.admin_only && !b.admin_only) {
        return -1;
      }
      if (!a.admin_only && b.admin_only) {
        return 1;
      }
      return 0;
    });

export const sortTopicIDs = ({ activeTopicIDs, topics }: any) =>
  activeTopicIDs.sort((a: any, b: any) => {
    const topicA = topics?.records.find(({ id }: any) => id === a);
    const topicB = topics?.records.find(({ id }: any) => id === b);
    if (topicA?.admin_only && !topicB?.admin_only) {
      return -1;
    }
    if (!topicA?.admin_only && topicB?.admin_only) {
      return 1;
    }
    return 0;
  });
