import type { ComponentPropsWithoutRef } from "react";
import { Icon } from "@circle-react/components/shared/Icon";

export interface ArrowProps {
  direction?: "left" | "right";
  onClick?: ComponentPropsWithoutRef<"button">["onClick"];
}

export const Arrow = ({ direction = "right", onClick }: ArrowProps) => (
  <button
    type="button"
    className="bg-primary focus-visible:!outline-secondary flex h-12 w-12 items-center justify-center rounded-full shadow focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
    onClick={onClick}
  >
    <Icon
      size={20}
      type="20-arrow-left"
      className={direction == "right" ? "rotate-180" : ""}
    />
  </button>
);
