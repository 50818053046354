import { useSpacesContext } from "@circle-react/contexts";

interface UseCanCreateEventReturnType {
  canCreateEvent: boolean;
}

export const useCanCreateEvent = (): UseCanCreateEventReturnType => {
  const spaceContext = useSpacesContext();
  const spaces = spaceContext.helpers.spacesWithPostCreationAllowed() || [];
  const canCreateEvent = spaces.some(
    (space: any) => space.post_type === "event",
  );

  return {
    canCreateEvent,
  };
};
