import { usePunditUserContext } from "@circle-react/contexts";
import { communitySignupUrl } from "@circle-react/helpers/communityHelpers";

export const useCommunitySignup = () => {
  const { currentCommunity } = usePunditUserContext();
  const signupUrl = communitySignupUrl(currentCommunity);

  const {
    private_sign_up_url: customSignUpUrl,
    is_private: isPrivateCommunity,
    show_signup_link: shouldShowSignUpLink,
  } = currentCommunity ?? {};

  const canUsersSignUp = Boolean(
    shouldShowSignUpLink || (isPrivateCommunity && !!customSignUpUrl),
  );

  const userSignUpUrl = customSignUpUrl || signupUrl;

  return {
    canUsersSignUp,
    userSignUpUrl,
  };
};
