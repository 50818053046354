import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { useAnyHoverMediaQuery } from "@/react/hooks/useMediaQuery";
import type { GalleryImage } from "@/react/types/GalleryImage";
import type { UsedInOptions } from "@circle-react/contexts";

interface IndicatorsProps {
  images: GalleryImage[];
  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => void;
  usedIn: UsedInOptions;
}
export const Indicators = ({
  images,
  currentImageIndex,
  setCurrentImageIndex,
  usedIn,
}: IndicatorsProps) => {
  const canHover = useAnyHoverMediaQuery();
  const { isPostBodyEmpty } = useCurrentPostHelpers();
  if (images.length < 2) {
    if (usedIn === "mobile-full-carousel-modal") {
      return <div className="!pt-0 pb-12" />;
    }
    return null;
  }

  return (
    <div
      className={classnames("z-[2] w-full justify-center pt-5", {
        "absolute bottom-4 left-1/2 flex w-auto -translate-x-1/2 !pt-0":
          canHover,
        "pb-5": !canHover && (usedIn === "full-post-modal" || isPostBodyEmpty),
        "!pt-0 pb-12": usedIn === "mobile-full-carousel-modal",
      })}
    >
      <div
        className={classnames(
          "bg-primary flex justify-center gap-1 rounded-xl p-0",
          { "!bg-white !p-2": canHover },
        )}
      >
        {images.map((image, index) => (
          <button
            type="button"
            aria-label={t("image_space.carousel.indicator_button", {
              image_index: index + 1,
              total_images: images.length,
            })}
            key={image.id}
            onClick={() => setCurrentImageIndex(index)}
            className={classnames("border-3 h-1.5 w-1.5 rounded-full", {
              "border-dark": !canHover && index !== currentImageIndex,
              "border-gray-300": canHover && index !== currentImageIndex,
              "border-darkest": !canHover && index === currentImageIndex,
              "border-gray-900": canHover && index === currentImageIndex,
            })}
          />
        ))}
      </div>
    </div>
  );
};
