import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import { supportedCurrenciesApi } from "@/react/api/supportedCurrenciesApi";
import type { PaywallCurrency } from "@circle-react/types";

const defaultParam = {
  includeTraded: false,
};

export const useGetPaywallsCurrencies = ({
  includeTraded = false,
} = defaultParam): UseQueryResult<PaywallCurrency[]> =>
  useQuery(["paywalls-currencies", includeTraded], () =>
    supportedCurrenciesApi.index(includeTraded),
  );
