export const PostLoader = () => (
  <div className="border-primary rounded-xl border px-5 pt-6">
    <div className="flex items-center space-x-2">
      <div className="bg-tertiary h-10 w-10 animate-pulse rounded-full" />
      <div className="space-y-2">
        <div className="bg-tertiary h-5 w-36 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-3 w-20 animate-pulse rounded-md duration-300" />
      </div>
    </div>
    <div className="mb-5 mt-10 space-y-3">
      <div className="bg-tertiary h-3 w-full animate-pulse rounded-md duration-300" />
      <div className="bg-tertiary h-3 w-full animate-pulse rounded-md duration-300" />
      <div className="bg-tertiary h-3 w-64 animate-pulse rounded-md duration-300" />
    </div>
    <div className="border-primary -mx-5 flex h-12 items-center justify-between space-x-2 border-t px-5">
      <div className="flex items-center space-x-2">
        <div className="bg-tertiary h-6 w-6 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-6 w-6 animate-pulse rounded-md duration-300" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="bg-tertiary h-6 w-12 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-6 w-16 animate-pulse rounded-md duration-300" />
      </div>
    </div>
  </div>
);
