import { t } from "@/i18n-js/instance";
import { useIsMemberToMemberMessagingEnabled } from "@circle-react/hooks/useIsMessagingEnabled";
import { ChatBottomBanner } from "../ChatBottomBanner";
import { RichTextMessageBox } from "../sharedV2";
import { NewConversation } from "./NewConversation";

export interface EmptyChatRoomProps {
  userList: any[];
}

export const EmptyChatRoom = ({ userList }: EmptyChatRoomProps) => {
  const isMemberToMemberMessagingEnabled =
    useIsMemberToMemberMessagingEnabled();

  const shouldHideMessageBox =
    !isMemberToMemberMessagingEnabled && userList.length === 1;
  return (
    <div>
      <NewConversation userList={userList} />
      {shouldHideMessageBox ? (
        <ChatBottomBanner>
          {t("community_member.member_to_member_messaging_disabled")}
        </ChatBottomBanner>
      ) : (
        <RichTextMessageBox
          userList={userList}
          isChatPreview
          portalTargetId="fullscreen-modal-body"
        />
      )}
    </div>
  );
};
