import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { useProfileSpaces } from "@circle-react/hooks/profile/useProfileSpaces";
import EmptyState from "../EmptyState";
import { SpaceCard } from "./SpaceCard";
import "./styles.scss";

export const ProfileSpaces = ({
  memberPublicId,
  onClose,
  shouldOpenInNewTab,
  scrollableTarget = "panel",
}) => {
  const { spaces, isLoading, fetchNextPage, hasNextPage } = useProfileSpaces({
    memberPublicId,
  });

  const defaultScrollableTarget = "profile-spaces-scrollable-target";

  if (isLoading) {
    return <LoaderRow />;
  }

  if (spaces.length < 1) {
    return (
      <EmptyState
        title={I18n.t("profile_drawer.spaces.empty_state.title")}
        subtitle={I18n.t("profile_drawer.spaces.empty_state.subtitle")}
      />
    );
  }

  return (
    <div className="h-full overflow-auto" id="profile-spaces-scrollable-target">
      <InfiniteScroll
        scrollThreshold={0.5}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<LoaderRow />}
        dataLength={spaces.length}
        className="profile-spaces"
        scrollableTarget={scrollableTarget || defaultScrollableTarget}
      >
        {spaces.map(space => (
          <SpaceCard
            key={space.id}
            onClose={onClose}
            space={space}
            shouldOpenInNewTab={shouldOpenInNewTab}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

ProfileSpaces.propTypes = {
  memberPublicId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldOpenInNewTab: PropTypes.bool,
  scrollableTarget: PropTypes.string,
};
