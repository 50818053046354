import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface Member {
  name: string;
  avatar_url: string;
  public_uid: string;
  headline: string;
  id: number;
}

interface MemberRowProps {
  member: Member;
  hideModal: () => void;
}

export const MemberRow = ({ member, hideModal }: MemberRowProps) => {
  const {
    name,
    avatar_url: avatarUrl,
    public_uid: memberPublicId,
    headline,
  } = member;
  const {
    onClick,
    "aria-label": ariaLabel,
    href,
    rel,
    target,
  } = useProfileLinkProps({
    public_uid: memberPublicId,
  });

  return (
    <a
      className="flex items-center gap-3"
      onClick={event => {
        hideModal();
        onClick && onClick(event);
      }}
      aria-label={ariaLabel}
      href={href}
      rel={rel}
      target={target}
    >
      <div className="mt-0">
        <ProfileHoverCard
          communityMemberId={member.id}
          name={name}
          publicUid={memberPublicId}
          headline={headline}
          avatarURL={avatarUrl}
        >
          <UserImage name={name} src={avatarUrl} size="10" />
        </ProfileHoverCard>
      </div>
      <Typography.LabelSm weight="medium">{name}</Typography.LabelSm>
    </a>
  );
};
