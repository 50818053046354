import { t } from "@/i18n-js/instance";
import type { FlaggedContentReport } from "@circle-react/types";
import { Typography } from "@circle-react-uikit/Typography";
import { ReportContent } from "./ReportContent";
import { ReporterInfo } from "./ReporterInfo";

export interface ReportsListProps {
  reports?: FlaggedContentReport[];
}

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const ReportsList = ({ reports = [] }: ReportsListProps) => (
  <div className="flex flex-col gap-6 px-8 py-6">
    {reports.map(
      ({
        id,
        reported_reason_type = "",
        reported_reason_body,
        reported_at = "",
        reporter,
        profanity_filter_matches = "",
      }) => (
        <div key={id} className="flex flex-col gap-3">
          <ReporterInfo
            reporter={reporter}
            reported_at={reported_at}
            reported_reason_type={reported_reason_type}
            profanity_filter_matches={profanity_filter_matches}
          />
          <ReportContent
            reported_reason_type={reported_reason_type}
            profanity_filter_matches={profanity_filter_matches}
            reported_reason_body={reported_reason_body}
          />
        </div>
      ),
    )}
    {reports.length === 0 && (
      <Typography.LabelSm color="text-lightest">
        {t([i18nRoot, "empty"])}
      </Typography.LabelSm>
    )}
  </div>
);
