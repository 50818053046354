import { Suspense } from "react";
import type { SignUpProps } from "@circle-react/components/OnboardingPage/SignUp";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SignUpComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SignUpPage" */ "@circle-react/components/OnboardingPage/SignUp"
    ),
);

export const SignUpPage = (props: SignUpProps) => (
  <Suspense fallback={<PageLoader />}>
    <SignUpComponent {...props} />
  </Suspense>
);
