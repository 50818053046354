import { FormItem } from "@circle-react/components/EventsV3/Modal/Common/FormItem";
import { Form } from "@circle-react-uikit/Form";
import { Well } from "@circle-react-uikit/Well";

export const ThankYouMessage = () => (
  <Well backgroundNone className="!p-0 md:!px-4 md:!py-2">
    <FormItem
      name="event_setting_attributes.confirmation_message_title"
      hideLabel
    >
      <Form.Input />
    </FormItem>
    <FormItem
      name="event_setting_attributes.confirmation_message_description"
      hideLabel
    >
      <Form.TextArea />
    </FormItem>
    <FormItem
      name="event_setting_attributes.confirmation_message_button_title"
      hideLabel
    >
      <Form.Input />
    </FormItem>
    <FormItem
      name="event_setting_attributes.confirmation_message_button_link"
      hideLabel
    >
      <Form.Input />
    </FormItem>
  </Well>
);
