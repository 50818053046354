import { isFunction } from "lodash";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { duplicateImagePost } from "@circle-react/api/imagePostApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { ImagePost } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";
import type { DuplicateParams } from "../types";

interface DuplicatePostOptions {
  post: ImagePost;
  onSuccess?: (post: ImagePost) => void;
}

export const useDuplicatePost = ({ post, onSuccess }: DuplicatePostOptions) => {
  const { success, error } = useToast();

  const { mutate: duplicate, isLoading: isDuplicating } = useMutation<
    ImagePost,
    ApiError,
    DuplicateParams
  >(
    data =>
      duplicateImagePost({
        spaceId: post.space_id,
        postSlug: post.slug,
        payload: {
          post: {
            space_id: data.space_id,
            send_notifications: data.send_notifications,
          },
        },
      }),
    {
      onSuccess: duplicatedPost => {
        success(t("duplicated_successfully"));

        isFunction(onSuccess) && onSuccess(duplicatedPost);
      },
      onError: () => {
        error(t("duplication_failed"));
      },
    },
  );

  return {
    duplicate,
    isDuplicating,
  };
};
