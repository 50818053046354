import { useCallback } from "react";
import type { MouseEvent } from "react";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { getProfileLinkProps } from "@circle-react/helpers/profileHelpers";
import type { ParamsShape } from "@circle-react/helpers/profileHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useRailbar } from "../chatsV2";

export interface UseProfileLinkPropsArgs {
  name?: string;
  params?: ParamsShape;
  public_uid: string;
}

export const useProfileLinkProps = ({
  name,
  params = {},
  public_uid,
}: UseProfileLinkPropsArgs) => {
  const drawer = useProfileDrawer();
  const showMemberProfileDrawer: any = drawer.show;
  const isIframeEmbed = useIsIframeEmbed();
  const { isDrawer } = useRailbar();

  const handleLinkClick = useCallback(
    (event: MouseEvent) => {
      const shouldOpenInNewTab =
        isIntendedOnNewTab(event) || isIframeEmbed || isDrawer;
      if (!shouldOpenInNewTab) {
        event.preventDefault();
        void showMemberProfileDrawer({ memberPublicId: public_uid, params });
      }
    },
    [isIframeEmbed, isDrawer, public_uid, params, showMemberProfileDrawer],
  );

  return {
    ...getProfileLinkProps({
      name,
      params,
      publicUid: public_uid,
      shouldOpenInNewTab: isIframeEmbed,
      showMemberProfileDrawer,
    }),
    onClick: handleLinkClick,
  };
};
