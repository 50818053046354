import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { sqid } from "@/src/helpers/sqidHelper";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { notifyBugsnag } from "@circle-react/helpers/bugsnagHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import {
  internalApi,
  memberProfileModalPathsWithState,
} from "@circle-react/helpers/urlHelpers";
import type { AudienceType } from "@circle-react/types/AudienceList";
import { useNavigateToAudienceEdit } from "../useNavigateToAudienceEdit";

interface Member {
  sqid?: string;
  id?: number;
  public_uid?: string;
  type?: AudienceType;
}

interface ShowMemberProfileEditParams {
  member?: Member;
}

const PROFILE_PARAMS = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
} as const;

export const useShowProfileEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const navigateToAudienceEdit = useNavigateToAudienceEdit();

  const showOwnProfileEdit = useCallback(() => {
    history.push(memberProfileModalPathsWithState.profile(location));
  }, [history, location]);

  const generateAndNavigateWithSqid = useCallback(
    (id: number) => {
      const generatedSqid = sqid.encode([id]);
      navigateToAudienceEdit({ sqid: generatedSqid });
    },
    [navigateToAudienceEdit],
  );

  const fetchSqidAndNavigate = useCallback(
    (publicUid: string) =>
      reactQueryGet(
        internalApi.profile.show({
          memberPublicId: publicUid,
          params: PROFILE_PARAMS,
        }),
      )
        .then(profileData => {
          if (profileData?.sqid) {
            return navigateToAudienceEdit({ sqid: profileData.sqid });
          }
          if (profileData?.id) {
            return generateAndNavigateWithSqid(profileData.id);
          }
          console.error(t("show_member_profile_has_no_sqid_or_id"), {
            profileData,
          });
        })
        .catch(error => {
          console.error(t("show_member_profile_fetch_error"), {
            error,
          });
        }),
    [navigateToAudienceEdit, generateAndNavigateWithSqid],
  );

  const showMemberProfileEdit = useCallback(
    ({ member }: ShowMemberProfileEditParams) => {
      if (!isAdmin) {
        console.error(t("show_member_profile_no_admin"), {
          currentCommunityMember,
        });
        return;
      }

      if (!member) {
        console.error(t("show_member_profile_no_member"));
        return;
      }

      // Try to navigate with existing sqid
      if (member.sqid) {
        return navigateToAudienceEdit({ sqid: member.sqid });
      }

      // Try to generate sqid from id
      if (member.id) {
        const message = `Missing sqid for audience edit navigation ${member.public_uid} ${member.id} generating sqid`;
        notifyBugsnag(message);
        console.warn(message);
        return generateAndNavigateWithSqid(member.id);
      }

      // If we only have public_uid, fetch member details first
      if (member.public_uid) {
        const message = `Missing id or sqid for audience edit navigation ${member.public_uid} ${member.id} fetching the profile data and navigating`;
        notifyBugsnag(message);
        console.warn(message);
        void fetchSqidAndNavigate(member.public_uid);
        return;
      }

      console.error(t("show_member_profile_fallback_error"), { member });
    },
    [
      isAdmin,
      currentCommunityMember,
      navigateToAudienceEdit,
      generateAndNavigateWithSqid,
      fetchSqidAndNavigate,
    ],
  );

  return {
    showOwnProfileEdit,
    showMemberProfileEdit,
  };
};
