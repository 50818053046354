import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-uikit/Button";

interface ActionButtonProps {
  variant?: "icon-only" | "text-only" | "icon-and-text";
  isActive?: boolean;
  tooltipText?: string;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

export const ActionButton = ({
  variant = "icon-only",
  isActive,
  tooltipText,
  onClick,
  children,
  disabled = false,
}: ActionButtonProps) => (
  <TippyV2
    placement="top"
    content={tooltipText}
    enabled={Boolean(tooltipText)}
    className="inline-block"
  >
    <Button
      variant="secondary"
      small
      isFullRounded
      className={classNames(
        "flex h-7 items-center justify-center font-semibold",
        {
          "w-7 !p-0": variant === "icon-only",
          "!px-3": variant === "text-only",
          "!bg-v2-success/10 !border-v2-success/20 text-v2-success": isActive,
          "!border-primary": !isActive,
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  </TippyV2>
);
