import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types/Post";

export const REQUEST_KEY = "post-conversation-summary";

interface UsePostConversationSummaryProps {
  post: Post;
}

interface AiSummary {
  content: string;
  overview?: string;
}

export const usePostConversationSummary = ({
  post,
}: UsePostConversationSummaryProps) => {
  const queryKey = [REQUEST_KEY, post.id];
  const queryClient = useQueryClient();
  const { data, isLoading, refetch } = useQuery<AiSummary>(
    queryKey,
    () =>
      reactQueryGet(
        internalApi.posts.conversationSummary({
          postId: String(post.id),
        }),
      ),
    {
      enabled: false, // Don't auto-fetch when mounted
      staleTime: 10 * 60 * 1000, // 10 minutes
      retry: (_, error: any) => error?.status !== 404,
    },
  );

  const clearCache = () => {
    void queryClient.resetQueries({ queryKey, exact: true });
  };

  return {
    conversationSummary: data,
    isLoadingConversationSummary: isLoading,
    refetchConversationSummary: refetch,
    clearConversationSummary: clearCache,
  };
};
