import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { useAnyHoverMediaQuery } from "@/react/hooks/useMediaQuery";
import { useSortableListData } from "@circle-react-shared/uikit/SortableList";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";

interface DeleteImageButtonProps {
  onClick: () => void;
  index: number;
  currentImageIndex: number;
}
export const DeleteImageButton = ({
  onClick,
  index,
  currentImageIndex,
}: DeleteImageButtonProps) => {
  const { activeItem } = useSortableListData();
  const isDragging = Boolean(activeItem);
  const canHover = useAnyHoverMediaQuery();
  return (
    <IconButton
      onClick={onClick}
      className={classnames(
        "bg-tertiary border-secondary outline-tertiary absolute -right-0.5 -top-0.5 z-10 hidden h-4 w-4 rounded-full border !p-0 outline outline-2",
        { "group-hover:!flex": !isDragging },
        { "!flex": !canHover && !isDragging && index === currentImageIndex },
      )}
      buttonSize={16}
      iconClassName="!text-dark"
      name="16-close"
      ariaLabel={t("delete_image")}
    />
  );
};
