import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const spaceNotificationsReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_SPACE_NOTIFICATIONS": {
      return payload;
    }
    case "UPDATE_SPACE_NOTIFICATIONS": {
      const { spaces } = state;
      const spaceIndex = spaces.findIndex(
        space => space.id === payload.space.id,
      );

      if (spaceIndex > -1) {
        return {
          ...state,
          spaces: [
            ...spaces.slice(0, spaceIndex),
            payload.space,
            ...spaces.slice(spaceIndex + 1),
          ],
        };
      }
      return state;
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const SpaceNotificationsStateContext = createContext();
SpaceNotificationsStateContext.displayName = "SpaceNotificationsStateContext";

const SpaceNotificationsDispatchContext = createContext();
SpaceNotificationsDispatchContext.displayName =
  "SpaceNotificationsDispatchContext";

const initialState = { spaces: [] };

const SpaceNotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(spaceNotificationsReducer, initialState);
  return (
    <SpaceNotificationsStateContext.Provider value={state}>
      <SpaceNotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </SpaceNotificationsDispatchContext.Provider>
    </SpaceNotificationsStateContext.Provider>
  );
};

const useSpaceNotificationsState = () => {
  const context = useContext(SpaceNotificationsStateContext);
  if (context === undefined) {
    throw new Error(
      "useSpaceNotificationsState must be used within a SpaceNotificationsProvider",
    );
  }

  return context;
};

const useSpaceNotificationsDispatch = () => {
  const context = useContext(SpaceNotificationsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSpaceNotificationsDispatch must be used within a SpaceNotificationsProvider",
    );
  }

  return context;
};

SpaceNotificationsProvider.propTypes = {
  children: PropTypes.node,
};

export {
  SpaceNotificationsProvider,
  useSpaceNotificationsState,
  useSpaceNotificationsDispatch,
};
