import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useChatUIStore } from "@/react/hooks/chatsV2/store";
import { viewOnlyMasqueradeAs } from "@circle-react/api/masqueradingApi";
import { useMemberProfileActions } from "@circle-react/components/Profile/useMemberProfileActions";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  chatRoomPath,
  getRouteWithPrevLocation,
} from "@circle-react/helpers/urlHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import type { ButtonVariant } from "@circle-react-shared/uikit/Button";
import { DIRECT_CHAT_ROOM } from "../../../constants";
import { ActionsDropdown } from "./ActionsDropdown";

interface Action {
  label: string;
  icon: IconType;
  onClick: () => void;
  isPrimary?: boolean;
  testId?: string;
  variant?: ButtonVariant;
}

export const ProfileActions = () => {
  const { member, onClose } = useProfileContext();
  const { isViewOnlyMasquerading, currentCommunityMember } =
    usePunditUserContext();
  const { findOrCreateChatRoom } = useChatRoom();
  const { setLiveChatRoomUuid } = useChatUIStore();
  const history = useHistory();
  const {
    onClickOpenEditProfileDrawer,
    shouldShowMemberChatIcon,
    canEditProfile,
    canViewOnlyMasquerade,
    handleCopyProfileLink,
  } = useMemberProfileActions({
    memberPublicId: member.public_uid,
    member,
    isFullPageProfile: false,
    shouldHideMessageButton: false,
  });

  const handleMessageMember = async () => {
    const chatRoomUuid = await findOrCreateChatRoom({
      kind: DIRECT_CHAT_ROOM,
      community_member_ids: [member.id],
    });

    const isLiveStream = history.location.pathname.includes("/live");
    if (isLiveStream) {
      setLiveChatRoomUuid(chatRoomUuid);
      return;
    }
    history.push(
      getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
    );
    onClose?.();
  };

  const handleViewOnlyMasquerade = () =>
    viewOnlyMasqueradeAs({
      entityId: member.id,
      entityType: "CommunityMember",
    })
      .then(data => window.open(data.masquerade_url, "_self"))
      .catch(error => console.error(error));

  const isOwnProfile = member.id === currentCommunityMember?.id;

  const actions: Action[] = [];

  if (shouldShowMemberChatIcon) {
    actions.push({
      label: t("message"),
      icon: "chat",
      onClick: isViewOnlyMasquerading ? noop : handleMessageMember,
      isPrimary: true,
      testId: "chat-button",
      variant: "primary",
    });
  }

  if (isOwnProfile || canEditProfile) {
    actions.push({
      label: t("profile_drawer.edit"),
      icon: "16-edit",
      onClick: onClickOpenEditProfileDrawer,
      isPrimary: !actions.some(action => action.isPrimary),
      testId: "edit-profile-button",
      variant: actions.some(action => action.isPrimary)
        ? "secondary"
        : "primary",
    });
  }

  if (canViewOnlyMasquerade) {
    actions.push({
      label: t("post.share.view_as", {
        member_first_name: member.first_name,
      }),
      icon: "16-eye-open",
      onClick: () => void handleViewOnlyMasquerade(),
      testId: "view-only-masquerade-button",
    });
  }

  actions.push({
    label: t("post.share.copy_link_to_profile"),
    icon: "16-link",
    onClick: handleCopyProfileLink,
    testId: "copy-profile-link-button",
  });

  const primaryAction = actions.find(action => action.isPrimary);
  const secondaryActions = actions.filter(action => !action.isPrimary);

  return (
    <BlockInViewOnlyMode>
      <div className="flex space-x-3">
        {primaryAction && (
          <Button
            variant={primaryAction.variant}
            onClick={primaryAction.onClick}
            aria-label={primaryAction.label}
            data-testid={primaryAction.testId}
            large
          >
            {primaryAction.label}
          </Button>
        )}

        {secondaryActions.length > 0 &&
          (secondaryActions.length === 1 ? (
            <Button
              variant="secondary"
              onClick={secondaryActions[0].onClick}
              aria-label={secondaryActions[0].label}
              data-testid={secondaryActions[0].testId}
              large
              className="!px-2.5"
            >
              <Icon
                type={secondaryActions[0].icon}
                size={16}
                useWithFillCurrentColor
              />
            </Button>
          ) : (
            <ActionsDropdown actions={secondaryActions} />
          ))}
      </div>
    </BlockInViewOnlyMode>
  );
};
