import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { DisplayTiptapContent } from "@circle-react/components/CommentsV3/Comment/DisplayTiptapContent";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";

export interface CommentProps {
  comment: any;
  onClose: any;
  member: any;
  shouldOpenInNewTab: any;
}

export default function Comment({
  comment,
  onClose,
  shouldOpenInNewTab,
}: CommentProps) {
  const isTiptap = comment.editor === "tiptap";

  return (
    <Link
      to={
        postsPath({
          slug: comment.post_slug,
          spaceSlug: comment.space_slug,
        }) + `#comment_wrapper_${comment.id}`
      }
      onClick={e => (isIntendedOnNewTab(e) ? false : onClose())}
      className="profile-comments__item bg-primary focus-visible:outline-secondary hover:outline-secondary flex items-start p-5 transition-colors duration-150 md:px-6"
      target={shouldOpenInNewTab && "_blank"}
    >
      <div className="profile-comments__content text-darkest flex w-full flex-col space-y-3 text-base">
        <div className="profile-comments__meta text-default flex flex-row space-x-3 text-sm font-normal">
          <div className="space-x-2">
            <span className="profile-comments__post-name text-default break-all">
              {t("profile_drawer.comments.commented_on")}{" "}
              <span className="text-dark font-medium">
                {comment.post_name || comment.post_truncated_content}
              </span>
            </span>
            <span className="bg-badge inline-block h-1 w-1 rounded-full align-middle" />
            <TippyV2
              interactive={false}
              content={getDetailedTimestamp(comment.created_at)}
              className="inline-flex"
            >
              <span>
                {dateStringToTimeAgo(comment.created_at, {
                  format: dateToTimeAgoFormats.short,
                })}
              </span>
            </TippyV2>
          </div>
        </div>
        {isTiptap ? (
          <DisplayTiptapContent
            key={comment.updated_at}
            tiptapBody={comment.tiptap_body}
          />
        ) : (
          <DisplayTrixContent content={comment.body_trix_content} />
        )}
      </div>
    </Link>
  );
}
