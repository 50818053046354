import { useState } from "react";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { userLikeApi } from "@circle-react/api";
import {
  useCurrentUser,
  useIsOrCanBeCommunityMember,
} from "@circle-react/contexts";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";

const COMMENT_USER_LIKABLE_TYPE = "Comment";

export const useLikeHandler = ({ comment }) => {
  const {
    id,
    is_liking_enabled: isLikingEnabled,
    is_liked: isLiked,
    likes_count: likesCount,
  } = comment;
  const currentUser = useCurrentUser();
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const isIframeEmbed = useIsIframeEmbed();
  const [isCurrentlyLiked, setIsCurrentlyLiked] = useState(isLiked);
  const [currentLikesCount, setCurrentLikesCount] = useState(likesCount);

  const { mutate: like, isLoading: isLiking } = useMutation(() =>
    userLikeApi.create({
      userLikeableId: id,
      userLikableType: COMMENT_USER_LIKABLE_TYPE,
      forReactQuery: true,
    }),
  );

  const { mutate: removeLike, isLoading: isRemovingLike } = useMutation(() =>
    userLikeApi.destroy({
      userLikeableId: id,
      userLikableType: COMMENT_USER_LIKABLE_TYPE,
      forReactQuery: true,
    }),
  );

  // Handles liking optimistically assuming that errors mean that the like
  // state already matches the desired state.
  //
  // TODO: Handle this by updating React Query cache.
  const toggleLike = () => {
    if (!currentUser) {
      const loginPath = newSessionPath({
        params: {
          notice: t("comments.log_in_to_like_comments"),
          post_login_redirect: window.location.href,
        },
      });

      if (isIframeEmbed) {
        window.open(loginPath, "_blank", "noreferrer");
      } else {
        window.location.href = loginPath;
      }

      return;
    }

    if (isLiking || isRemovingLike) {
      return;
    }

    if (isCurrentlyLiked) {
      setIsCurrentlyLiked(false);
      setCurrentLikesCount(prevLikesCount => prevLikesCount - 1);
      removeLike();
    } else {
      setIsCurrentlyLiked(true);
      setCurrentLikesCount(prevLikesCount => prevLikesCount + 1);
      like();
    }
  };

  return {
    toggleLike,
    isLiked: isCurrentlyLiked,
    likesCount: currentLikesCount,
    canLike: isLikingEnabled && isOrCanBeCommunityMember,
    canSeeLikes: isLikingEnabled,
    isLoading: isLiking || isRemovingLike,
  };
};
