import { t } from "@/i18n-js/instance";
import { useEventRsvp } from "@circle-react/components/Events/Rsvp/hooks/useEventRsvp";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { openUrl } from "@circle-react/helpers/urlHelpers/openUrl";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import type { Event, RecurringEvent } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { ConfirmationDate } from "./ConfirmationDate";
import { RecurringEventConfirmationDate } from "./RecurringEventConfirmationDate";
import "./styles.scss";

interface ConfirmationModalProps {
  event: Event;
  events: RecurringEvent[];
  show: boolean;
  onClose: () => void;
  shouldShowConfirmationDate?: boolean;
  isRecurring?: boolean;
  eventsCount?: number;
}

export const ConfirmationModal = ({
  event,
  events = [],
  show,
  onClose,
  shouldShowConfirmationDate = true,
  isRecurring = false,
  eventsCount,
}: ConfirmationModalProps) => {
  const { name, event_setting_attributes: eventData } = event || {};
  const {
    confirmation_message_title: title,
    confirmation_message_description: message,
    confirmation_message_button_title: buttonLabel,
    confirmation_message_button_link: buttonLink,
    enable_custom_thank_you_message: isCustomThankYouMessageEnabled,
    send_email_confirmation: canSendEmailConfirmation,
  } = eventData || {};

  const shouldShowConfirmationDatesForRecurringEvents =
    !isPaidEvent(event) && isRecurring;

  const onButtonClick = () => {
    if (buttonLink) {
      openUrl(buttonLink);
    }
    onClose();
  };

  const { addToCalendar, downloadCalendar } = useEventRsvp(event);
  const onAddToCalendarClick = () => {
    void addToCalendar();
    onClose();
  };

  const onDownloadButtonClick = () => {
    downloadCalendar();
    onClose();
  };

  const { hasSelfUpdateCalendarEnabled } = useEventsFeatureFlags();
  return (
    <Modal isOpen={!!show} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className="flex w-full !justify-end pb-0 md:!pb-0">
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="mb-6 !overflow-visible pt-0">
          {!hasSelfUpdateCalendarEnabled && (
            <div className="event-confirmation">
              <h2>{t("events.notifications.event_setting_attributes.clap")}</h2>
              <h3>
                {title ||
                  t(
                    "events.notifications.event_setting_attributes.confirmation_message_title_placeholder",
                    { event_name: name },
                  )}
              </h3>
              <p>
                {isRecurring
                  ? message ||
                    t("events.rsvp.recurring_modal.confirmation_description", {
                      events: `${eventsCount} ${
                        eventsCount === 1
                          ? t("events.rsvp.recurring_modal.event")
                          : t("events.rsvp.recurring_modal.events")
                      }`,
                    })
                  : message ||
                    t(
                      "events.notifications.event_setting_attributes.confirmation_message_description_placeholder",
                    )}
              </p>
              {shouldShowConfirmationDate && (
                <ConfirmationDate
                  event={event}
                  label={isRecurring ? t("add_all_events_to_calendar") : ""}
                />
              )}
            </div>
          )}

          {hasSelfUpdateCalendarEnabled && (
            <div className="flex flex-col items-center text-center">
              <h2 className="mb-4 mt-0">
                {t("events.notifications.event_setting_attributes.clap")}
              </h2>
              <div className="mb-4">
                <Typography.TitleSm weight="bold" color="text-default">
                  {isCustomThankYouMessageEnabled
                    ? title
                    : t(
                        "events.notifications.event_setting_attributes.thank_you_message",
                      )}
                </Typography.TitleSm>
              </div>
              <div className="mb-6">
                <Typography.TitleMd color="text-darkest">
                  {name}
                </Typography.TitleMd>
              </div>
              {shouldShowConfirmationDate &&
                !shouldShowConfirmationDatesForRecurringEvents && (
                  <ConfirmationDate
                    event={event}
                    label={isRecurring ? t("add_all_events_to_calendar") : ""}
                  />
                )}
              {shouldShowConfirmationDate &&
                shouldShowConfirmationDatesForRecurringEvents &&
                events.map((recurringEvent, index) => (
                  <div className="pb-2" key={recurringEvent.id || index}>
                    <RecurringEventConfirmationDate
                      recurringEvent={recurringEvent}
                    />
                  </div>
                ))}
              {isCustomThankYouMessageEnabled && (
                <div>
                  <div className="my-6">
                    <Typography.BodyMd color="text-light">
                      {message}
                    </Typography.BodyMd>
                  </div>
                </div>
              )}
            </div>
          )}
          {isCustomThankYouMessageEnabled && (
            <Button variant="primary" full onClick={onButtonClick} large>
              {buttonLabel ||
                t(
                  "events.notifications.event_setting_attributes.confirmation_message_button_title_placeholder",
                )}
            </Button>
          )}
          <div className="flex flex-col gap-3 pt-6 text-center">
            {canSendEmailConfirmation ? (
              <Typography.BodyMd color="text-light">
                {t(
                  "events.notifications.event_setting_attributes.calendar_invite_message",
                )}
              </Typography.BodyMd>
            ) : (
              <>
                <Button
                  variant="secondary"
                  full
                  large
                  onClick={onDownloadButtonClick}
                  className="flex justify-center gap-x-2"
                  isFullRounded
                >
                  <Icon
                    type="16-download"
                    size={16}
                    className="h-4 !w-4"
                    useWithFillCurrentColor
                  />
                  {t("events.add_to_calendar.confirm_modal.download")}
                </Button>
                <Button
                  variant="secondary"
                  full
                  large
                  onClick={onAddToCalendarClick}
                  className="flex justify-center gap-x-2"
                  isFullRounded
                >
                  <Icon
                    type="16-refresh"
                    size={16}
                    className="h-4 !w-4"
                    useWithFillCurrentColor
                  />
                  {t("events.add_to_calendar.confirm_modal.send")}
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
