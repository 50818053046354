import { useQuery } from "react-query";
import { TOPICS_QUERY_KEY } from "@circle-react/components/Spaces/TopicFilters/hooks/useTopics";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PaginationHelper } from "@circle-react/types";

export const useTopics = ({ spaceId }: { spaceId?: string }) =>
  useQuery<
    PaginationHelper<{
      id: number;
      name: string;
      admin_only: boolean;
    }>
  >({
    queryKey: spaceId ? [TOPICS_QUERY_KEY, spaceId] : [TOPICS_QUERY_KEY],
    queryFn: () =>
      reactQueryGet(
        spaceId
          ? internalApi.topics.spaceTopics({ spaceId })
          : internalApi.settings.topics.index({
              params: {
                per_page: 250,
              },
            }),
      ),
  });
