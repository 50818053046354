import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const ReportContent = ({
  reported_reason_type,
  profanity_filter_matches,
  reported_reason_body,
}: any) =>
  (reported_reason_type && profanity_filter_matches) || reported_reason_body ? (
    <div
      className={classNames(
        "bg-tertiary ml-12 flex flex-col gap-1.5 rounded-lg px-4 py-3",
      )}
    >
      {reported_reason_type && profanity_filter_matches && (
        <Typography.LabelSm>
          <SanitizeHTMLString
            content={t([i18nRoot, "report_reasons", reported_reason_type], {
              matches: profanity_filter_matches,
            })}
            as="span"
          />
        </Typography.LabelSm>
      )}
      {reported_reason_body && (
        <Typography.LabelSm as="p" data-testid="report-reason-body">
          {reported_reason_body}
        </Typography.LabelSm>
      )}
    </div>
  ) : null;
