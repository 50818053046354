import { useCallback, useEffect } from "react";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { chatEventsEmitter } from "./chatEventsEmitter";

export const useBookmarkToggle = (messages: any, setMessages: any) => {
  const onBookmarkToggle = useCallback(
    async (messageId: any) => {
      const message = messages.find((message: any) => message.id === messageId);
      if (!message) return;

      let newMessage: any;
      if (message?.bookmark_id) {
        // Remove bookmark
        newMessage = { ...message, bookmark_id: null };
      } else {
        // Refetch message to get bookmark id
        newMessage = await reactQueryGet(
          internalApi.chatRoomMessages.show({
            uuid: message.chat_room_uuid,
            id: message.id,
          }),
        );
      }
      if (!newMessage) return;

      const newMessages = messages.map((chatRoomMessage: any) => {
        if (chatRoomMessage.id === message.id) {
          return newMessage;
        }
        return chatRoomMessage;
      });
      setMessages(newMessages);
    },
    [messages, setMessages],
  );

  // Listen for bookmark toggle event outside of context
  useEffect(() => {
    const handleBookMarkToggle = (messageId: any) => {
      void onBookmarkToggle(messageId);
    };
    chatEventsEmitter.on("onBookmarkToggle", handleBookMarkToggle);
    return () => {
      chatEventsEmitter.off("onBookmarkToggle", handleBookMarkToggle);
    };
  }, [onBookmarkToggle]);

  return onBookmarkToggle;
};
