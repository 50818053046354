import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common/FormItem";
import { RsvpLimit } from "./RsvpLimit";

export const Attendees = ({ event }) => {
  const rsvpLimitFieldEnabled = useWatch({
    name: "event_setting_attributes.rsvp_limit_enabled",
    defaultValue: event?.event_setting_attributes.rsvp_limit_enabled || false,
  });

  const attendeesFields = [
    "event_setting_attributes.rsvp_disabled",
    "event_setting_attributes.hide_attendees",
    "event_setting_attributes.rsvp_limit_enabled",
  ];

  return (
    <div className="mb-6 md:mb-12">
      <div className="mb-0 mt-6 md:mb-3 md:mt-8">
        <Typography.TitleMd>
          {t("events.edit_modal.advanced_options.attendees")}
        </Typography.TitleMd>
      </div>

      {attendeesFields.map(fieldName => (
        <FormItem
          key={fieldName}
          name={fieldName}
          tooltipText={t(`events.edit_modal.${fieldName}_tooltip`)}
          inlineReverse
          className="!pb-0"
        >
          <Form.ToggleSwitch variant="small" />
        </FormItem>
      ))}
      {rsvpLimitFieldEnabled && <RsvpLimit />}
    </div>
  );
};
