import { t } from "@/i18n-js/instance";
import { getEventRegistrationUrl } from "@/react/helpers/loginHelpers";
import { internalApi, userPaths } from "@/react/helpers/urlHelpers";
import { eventApi } from "@circle-react/api/eventApi";
import { useCommunitySignup } from "@circle-react/components/Events/hooks/useCommunitySignup";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { usePunditUserContext } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useEventRsvpData } from "./useEventRsvpData";

export const useEventRsvp = (event, onChange) => {
  const {
    currentUser,
    error,
    hasEventEnded,
    isConfirmed,
    isLoading,
    isUnconfirmed,
    role,
    setShouldShowConfirmation,
    shouldShowConfirmation,
    setIsLoading,
    rsvpStatus,
    ticketData,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  } = useEventRsvpData(event);
  const toast = useToast();

  const { currentCommunityMember } = usePunditUserContext();
  const { canUsersSignUp, userSignUpUrl } = useCommunitySignup();

  const addToCalendar = async () => {
    setIsLoading(true);
    try {
      await eventApi.addToCalendar({
        event,
        params: { role },
      });
      toast.success(t("add_to_calendar_success"));
    } catch (err) {
      error(err.message);
      console.error(t("add_to_calendar_failure"));
    }
    setIsLoading(false);
  };

  const downloadCalendar = () => {
    download(
      internalApi.events.downloadCalendar({
        spaceId: event.space_id,
        slug: event.slug,
        params: {},
      }),
    );
  };

  const download = url => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const fileUrl = window.URL.createObjectURL(blob);
        const filename = `event-${event.slug}.ics`;

        // Create a temporary <a> element to allow add the filename attribute
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", filename); // Specify the filename
        document.body.appendChild(link);
        link.click(); // Trigger the download
        link.remove(); // Remove the element

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(fileUrl);
      })
      .catch(err => {
        error(err.message);
        console.error("useEventRsvp - could not download - ", url);
      });
  };

  const declineInvite = async () => {
    try {
      await eventApi.unRsvp({ event, params: { role } });
      onChange({
        ...event,
        rsvped_event: false,
        rsvp_status: "no",
      });
    } catch (err) {
      error(err.message);
      console.error("useEventRsvp - could not decline - ", event?.slug);
    }
    setIsLoading(false);
  };

  const toggleRsvp = async () => {
    if (isLoading) {
      return;
    }

    if (!currentUser || !currentCommunityMember) {
      if (isPaidEvent(event)) {
        window.location.href = event.paywall_attributes.checkout_url;
      } else {
        window.location.href = getEventRegistrationUrl(
          canUsersSignUp,
          userSignUpUrl,
        );
      }
      return;
    }

    const isPaidEventWithoutTicket = isPaidEvent(event) && !rsvpStatus;
    const rsvpMethod =
      isPaidEventWithoutTicket || isUnconfirmed
        ? eventApi.rsvp
        : eventApi.unRsvp;
    setIsLoading(true);
    try {
      const response = await rsvpMethod({ event, params: { role } });
      if (response?.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        setShouldShowConfirmation(isUnconfirmed);
        onChange({
          ...event,
          rsvped_event: isUnconfirmed,
          rsvp_status: isUnconfirmed ? "yes" : "no",
        });
      }
    } catch (err) {
      error(err.message);
      console.error("useEventRsvp - could not rsvp - ", event?.slug);
    }
    setIsLoading(false);
  };

  const rsvpWhileJoiningEvent = async () => {
    if (isLoading) {
      return;
    }

    if (!currentUser) {
      error(
        t("log_in_to_perform_action", {
          action: t("events.rsvp.rsvp"),
        }),
      );
      window.location.href = userPaths.signIn({
        params: {
          post_login_redirect: window.location.href,
        },
      });
      return;
    }
    const response = await eventApi.rsvp({ event });
    if (response.ok) {
      if (response?.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        onChange({ ...event, rsvped_event: true });
      }
    }
  };

  const dismissConfirmation = () => setShouldShowConfirmation(false);
  const dismissUnrsvpConfirmation = () =>
    setShouldShowUnrsvpConfirmation(false);

  return {
    toggleRsvp,
    addToCalendar,
    downloadCalendar,
    declineInvite,
    loading: isLoading,
    confirmed: isConfirmed,
    rsvpStatus,
    showConfirmation: shouldShowConfirmation,
    dismissConfirmation,
    dismissUnrsvpConfirmation,
    eventEnded: hasEventEnded,
    rsvpWhileJoiningEvent,
    ticketData,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  };
};
