import PropTypes from "prop-types";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export const ActionButton = ({
  label,
  onClick,
  disabled = false,
  isLiked = false,
  className,
  ...rest
}) => (
  <button
    type="button"
    className={classNames(
      "focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
      className,
    )}
    onClick={event => {
      event.preventDefault();
      if (!disabled) onClick();
    }}
    aria-disabled={disabled}
    {...rest}
  >
    <span
      aria-checked={isLiked}
      className={classNames("hover:text-dark text-sm", {
        "text-brand font-semibold": isLiked,
        "text-default font-medium": !isLiked,
      })}
    >
      {label}
    </span>
  </button>
);

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLiked: PropTypes.bool,
  className: PropTypes.string,
};
