import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { LabelXxs } from "@circle-react-shared/uikit/Typography/components/Label/LabelXxs";

export const CategoryBackButton = ({ category, setCategory }) => (
  <button
    tabIndex={0}
    type="button"
    className="text-dark bg-primary hover:bg-secondary focus:bg-secondary flex w-full cursor-pointer items-center justify-between gap-4 px-5 py-2.5 transition-colors"
    onClick={() => setCategory("")}
  >
    <div className="flex items-center gap-3 text-gray-500">
      <Icon type="chevron-left" className="text-default" size={16} />
      <LabelXxs color="text-gray-500">
        {t(`view_only_masquerading.categories.${category}`)}
      </LabelXxs>
    </div>
  </button>
);
