import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface Action {
  label: string;
  icon: IconType;
  onClick: () => void;
  testId?: string;
}

interface ActionsDropdownProps {
  actions: Action[];
}

export const ActionsDropdown = ({ actions }: ActionsDropdownProps) => (
  <Dropdown
    size="large"
    button={
      <Button
        type="button"
        variant="secondary"
        data-testid="actions-dropdown-button"
        className="!px-2.5"
        large
      >
        <Icon
          type="16-menu-dots-horizontal"
          size={16}
          useWithFillCurrentColor
        />
      </Button>
    }
    menuButtonEl="div"
  >
    {actions.map(action => (
      <Dropdown.ItemWithLink
        key={action.label}
        linkType="button"
        onClick={action.onClick}
        data-testid={action.testId}
      >
        <Dropdown.IconContainer>
          <Icon type={action.icon} size={16} className="mr-2" />
          <Typography.LabelSm weight="regular" color="text-very-dark">
            {action.label}
          </Typography.LabelSm>
        </Dropdown.IconContainer>
      </Dropdown.ItemWithLink>
    ))}
  </Dropdown>
);
