import { MESSAGING_MODAL_PORTAL_ID } from "@circle-react/components/constants";

const checkIfMessagingModalIsOpen = () => {
  const element = document.getElementById(MESSAGING_MODAL_PORTAL_ID);
  return Boolean(element);
};

export const useDmsV3Enabled = () => ({
  isMessagingModalOpen: checkIfMessagingModalIsOpen(),
});
