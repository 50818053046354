import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type { MediaTranscoding } from "@circle-react/types/MediaTranscoding";

interface CreateParams {
  blobSignedId: string;
}

const create = ({ blobSignedId }: CreateParams): Promise<MediaTranscoding> =>
  reactQueryPost(internalApi.mediaTranscodings.create(), {
    blob_signed_id: blobSignedId,
  });

export const mediaTranscodingsApi = {
  create,
};
