import { RsvpStatusBadge } from "@/react/components/EventsV3/Modal/Tabs/People/List/RsvpStatusBadge";
import { TruncateText } from "@circle-react/components/shared/uikit/TruncateText";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface MemberItemProps {
  member: {
    name: string;
    avatar_url: string;
    rsvp_status: "yes" | "no";
    public_uid: string;
  };
}

export const MemberItem = ({ member }: MemberItemProps) => {
  const { name, avatar_url, rsvp_status: rsvpStatus, public_uid } = member;
  const profileLinkProps = useProfileLinkProps({
    public_uid,
  });

  return (
    <>
      <div className="hover:bg-secondary group flex h-14 items-center rounded-md px-2">
        <div className="mr-3">
          <a {...profileLinkProps} title={name}>
            <UserImage src={avatar_url} name={name} size="8" />
          </a>
        </div>
        <div className="w-full">
          <a {...profileLinkProps} title={name}>
            <TruncateText>
              <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>
            </TruncateText>
          </a>
        </div>
        <div className="mx-2 flex w-24 shrink-0 justify-end">
          <RsvpStatusBadge rsvpStatus={rsvpStatus} />
        </div>
      </div>
      <hr />
    </>
  );
};
