import { format } from "date-fns";
import { capitalize } from "lodash";
import { t } from "@/i18n-js/instance";
import { weekdayOccurrence } from "./index";

export const getDayName = (dateString: string) => {
  try {
    return capitalize(format(new Date(dateString), "EEEE"));
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getDayShortName = (dateString: string) => {
  try {
    return capitalize(format(new Date(dateString), "EEE")).substring(0, 2);
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getMonthName = (dateString: string) => {
  try {
    return capitalize(format(new Date(dateString), "MMMM"));
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getMonthShortName = (dateString: string) => {
  try {
    return capitalize(format(new Date(dateString), "MMM"));
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getMonthDayWithOrdinal = (dateString: string) => {
  try {
    return format(new Date(dateString), "do");
  } catch (error) {
    console.error(error);
    return "";
  }
};

const occurrenceToOrdinalMap: any = {
  1: t("date.ordinal.first"),
  2: t("date.ordinal.second"),
  3: t("date.ordinal.third"),
  4: t("date.ordinal.fourth"),
  5: t("date.ordinal.last"),
};

export const getWeekdayOccurrenceWithOrdinal = (dateString: string) => {
  try {
    const dayOccurrence = weekdayOccurrence(dateString);
    const dayName = getDayName(dateString);
    const ordinal: string = occurrenceToOrdinalMap[dayOccurrence] || "";
    return `${ordinal} ${dayName}`;
  } catch (error) {
    console.error(error);
    return "";
  }
};
