import { Body } from "@circle-react/components/ProfileModalV3/Body";
import { Sidebar } from "@circle-react/components/ProfileModalV3/Sidebar";
import { ProfileContextProvider } from "@circle-react/components/ProfileModalV3/context";
import { DeletedMemberMessage } from "@circle-react-shared/ProfileModal/DeletedMemberMessage";
import { Loader } from "./Loader";

interface ProfileV3Props {
  member?: {
    is_deleted: boolean;
  } | null;
  isLoading: boolean;
  isFullPageView?: boolean;
  onClose?: () => void;
}

export const ProfileV3 = ({
  member,
  isLoading,
  isFullPageView = false,
  onClose,
}: ProfileV3Props) => (
  <ProfileContextProvider
    isFullPageView={isFullPageView}
    member={member}
    isLoading={isLoading}
    onClose={onClose}
  >
    {member ? (
      member.is_deleted ? (
        <DeletedMemberMessage wrapperClassName="text-dark col-span-full" />
      ) : (
        <>
          <Sidebar />
          <Body />
        </>
      )
    ) : (
      <Loader />
    )}
  </ProfileContextProvider>
);
