import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const REQUEST_KEY = "post-show";

export const usePostResource = ({
  spaceId,
  postSlug,
  spaceSlug = "",
  queryOptions = {},
  context = "default",
  disabled = false,
}) => {
  const queryKey = [REQUEST_KEY, postSlug, spaceId || spaceSlug, context];

  const url = internalApi.posts.show({ spaceId, spaceSlug, postSlug });

  const fetchPost = () => reactQueryGet(url);

  const query = useQuery(queryKey, fetchPost, {
    enabled: !disabled && !!((spaceId || spaceSlug) && postSlug),
    staleTime: 0,
    ...queryOptions,
    retry: (_, error) => error?.status !== 404,
  });

  const queryClient = useQueryClient();
  const onPostChange = async (post, { refetch = true } = {}) => {
    if (!refetch) {
      return queryClient.setQueryData(queryKey, () => post);
    }
    return query.refetch();
  };

  return {
    ...query,
    post: query.data,
    onPostChange,
  };
};
