import PropTypes from "prop-types";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { EVENT_TYPES, RSVP_STATUS } from "../../helpers/constants";
import { ConfirmationModal } from "../ConfirmationModal";
import { UnrsvpConfirmationModal } from "../UnrsvpConfirmationModal";
import { useEventRsvp } from "../hooks/useEventRsvp";
import { RsvpButton } from "./RsvpButton";
import { RsvpRadio } from "./RsvpRadio";

export const RsvpAction = ({
  event,
  onChange,
  toggleRecurringRsvp,
  full,
  variant = "primary",
  detailsPage = false,
}) => {
  const {
    toggleRsvp,
    declineInvite,
    loading,
    showConfirmation,
    dismissConfirmation,
    dismissUnrsvpConfirmation,
    eventEnded,
    confirmed,
    rsvpStatus,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  } = useEventRsvp(event, onChange);

  const { hasSelfUpdateCalendarEnabled } = useEventsFeatureFlags();
  const { event_type, invited_attendee: invitedAttendee } = event;
  const isRecurring = event_type === EVENT_TYPES.RECURRING;
  const {
    event_setting_attributes: eventData,
    paywall_attributes: ticketData,
  } = event || {};
  const { rsvp_limit_reached: isRsvpLimitReached } = eventData || {};
  const isFreeRecurringEvent = isRecurring && !isPaidEvent(event);
  const isAttendeeInvited = rsvpStatus === RSVP_STATUS.INVITED;
  const commonProps = {
    confirmed,
    rsvpStatus,
    full,
    toggleRsvp:
      isFreeRecurringEvent && !invitedAttendee
        ? toggleRecurringRsvp
        : toggleRsvp,
    declineInvite,
    loading,
    paidEvent: isPaidEvent(event),
    setShouldShowUnrsvpConfirmation,
    showUnrsvpPopup: isPaidEvent(event) && !ticketData?.invited,
  };

  if (eventEnded) {
    return null;
  }

  const isRsvpActionNeeded = !rsvpStatus || isAttendeeInvited;

  return (
    <BlockInViewOnlyMode>
      {!isRsvpActionNeeded ? (
        <RsvpRadio {...commonProps} />
      ) : (
        <RsvpButton
          {...commonProps}
          variant={variant}
          isRsvpDisabled={isRsvpLimitReached}
          detailsPage={detailsPage}
        />
      )}
      {!isRecurring && (
        <ConfirmationModal
          event={event}
          show={showConfirmation}
          onClose={dismissConfirmation}
        />
      )}
      {confirmed && isPaidEvent(event) && (
        <UnrsvpConfirmationModal
          isOpen={shouldShowUnrsvpConfirmation}
          onClose={dismissUnrsvpConfirmation}
          toggleRsvp={toggleRsvp}
        />
      )}
      {confirmed && invitedAttendee && hasSelfUpdateCalendarEnabled && (
        <ConfirmationModal
          event={event}
          show={showConfirmation}
          onClose={dismissConfirmation}
        />
      )}
    </BlockInViewOnlyMode>
  );
};

RsvpAction.propTypes = {
  event: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    space_id: PropTypes.number.isRequired,
    event_type: PropTypes.string.isRequired,
    rsvped_event: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  full: PropTypes.bool,
  toggleRecurringRsvp: PropTypes.func,
  variant: PropTypes.string,
};
