import { useEffect } from "react";
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "react-query";

interface InfiniteFetchProps {
  lastPage:
    | {
        has_next_page: number;
        per_page: number;
        page: number;
      }
    | undefined;
  isCalendarView: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
}

const MAX_PAGE_LIMIT = 11; // Limiting to 10 api calls to avoid infinite loop as fallback

export const useCalendarViewInfiniteFetch = ({
  lastPage,
  isCalendarView,
  fetchNextPage,
}: InfiniteFetchProps) => {
  useEffect(() => {
    if (
      isCalendarView &&
      lastPage?.has_next_page &&
      lastPage?.page < MAX_PAGE_LIMIT
    ) {
      void fetchNextPage();
    }
  }, [lastPage, isCalendarView, fetchNextPage]);
};
