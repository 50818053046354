import { t } from "@/i18n-js/instance";
import { FormItem } from "@circle-react/components/LiveStreams/common/FormItem";
import { useLiveStreamsConfig } from "@circle-react/components/LiveStreams/hooks";
import { useCanCreateEvent } from "../../hooks/useCanCreateEvent";
import { RoomTypePicker } from "./RoomTypePicker";

export const RoomType = () => {
  const { config: liveStreamConfig } = useLiveStreamsConfig();
  const { canCreateEvent } = useCanCreateEvent();

  return (
    <div className="mx-auto w-auto">
      <FormItem
        name="room_type"
        rules={{
          required: t("live_streams.form.room_type_required_error"),
        }}
        hideLabel
      >
        <RoomTypePicker
          liveStreamConfig={liveStreamConfig}
          shouldDisableEventOption={!canCreateEvent}
        />
      </FormItem>
    </div>
  );
};
