import { useEffect, useState } from "react";
import { waitForTheElement } from "wait-for-the-element";

const TEN_SECONDS = 10000;

export const useWaitForDOMElement = (
  selector: string,
  timeout: number = TEN_SECONDS,
) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    const search = async () => {
      const element = await waitForTheElement(selector, { timeout });
      setElement(element);
    };

    void search();
  }, [selector, timeout]);

  return element;
};
