import Linkify from "linkifyjs/react";
import { t } from "@/i18n-js/instance";
import { useRecurringUnRsvpModal } from "@/react/components/Modals/RecurringUnRsvpModal";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useCurrentPostContext } from "@circle-react/contexts";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AddToCalendar } from "@circle-react-uikit/AddToCalendar";
import { Panel } from "@circle-react-uikit/Panel";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { AttendeesPreview } from "../AttendeesPreview";
import { useAttendeesPreview } from "../AttendeesPreview/hooks/useAttendeesPreview";
import { addToCalendarHelper } from "../helpers/addToCalendarHelper";
import { EVENT_TYPES, LOCATION_TYPES } from "../helpers/constants";
import { useRecurringFrequencyLabel } from "../hooks/useRecurringFrequencyLabel";
import { InPersonLocation } from "./InPersonLocation";
import { ListRecurringEventButton } from "./ListRecurringEventButton";
import { LiveLocation } from "./LiveLocation";
import { RecurringRsvpModal } from "./RecurringRsvpModal";
import { useRecurringRsvpModal } from "./RecurringRsvpModal/hooks/useRecurringRsvpModal";
import { RsvpAction } from "./RsvpAction";
import "./styles.scss";

const linkifyOptions = {
  validate: { url: (value: string) => /^[^A-Z]/.test(value) },
};

interface RsvpProps {
  onChange: () => void;
  isMinimal?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
export const Rsvp = ({ onChange, isMinimal = false }: RsvpProps) => {
  const { record: event, isUsedInMinimalTipTapViewModal } =
    useCurrentPostContext();
  const {
    event_type: eventType,
    event_setting_attributes: eventData,
    rsvped_event: rsvpedEvent,
    recurring_setting_attributes: recurringSetting,
    upcoming_occurrences_count: comingRecurrences,
  } = event || {};
  const { totalCount: attendeeCount } = useAttendeesPreview(event) || {};

  const { frequency: recurringFrequency } = recurringSetting || {};

  const { isRecurringRsvpOpen, openRecurringRsvpModal, onRecurringModalCose } =
    useRecurringRsvpModal({ event });

  const unRsvpModal = useRecurringUnRsvpModal();

  const {
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone_abbreviations: timeZoneAbbreviations,
    start_date: startDate,
    time_zone: timeZone,
    location_type: locationType,
    in_person_location: inPersonLocation,
    virtual_location_url: virtualLocationUrl,
    rsvp_disabled: rsvpDisabled,
    hide_attendees: hideAttendees,
    hide_location_from_non_attendees: hideLocationFromNonAttendees,
  } = eventData || {};

  const { label: recurringFrequencyLabel } = useRecurringFrequencyLabel({
    startsAt,
    frequency: recurringFrequency,
  });

  if (!eventData) {
    return <SkeletonLoader variant="right-sidebar" />;
  }

  const isEventFinished = !!endsAt && dateIsPast(endsAt);
  const isRecurring = eventType === EVENT_TYPES.RECURRING;
  const { rsvped_event: isEventConfirmed } = event;

  const openRecurringUnRsvpModal = () => {
    void unRsvpModal.show({ event, onChange });
  };

  const rsvpActionProps = {
    event,
    onChange,
    full: !isMinimal,
    ...(isRecurring && {
      toggleRecurringRsvp: isEventConfirmed
        ? openRecurringUnRsvpModal
        : openRecurringRsvpModal,
    }),
  };

  return (
    <Panel
      withBorder={!isMinimal}
      isUsedInMinimalTipTapViewModal={isUsedInMinimalTipTapViewModal}
      className={classNames({
        "lg:mb-12": !isMinimal,
        "!my-5": isUsedInMinimalTipTapViewModal,
      })}
    >
      <div
        className={classNames("rsvp-section", {
          "rsvp-section--minimal": isMinimal,
        })}
      >
        {!isEventFinished && !rsvpDisabled && (
          <div className="rsvp-section__button">
            <RsvpAction {...rsvpActionProps} />
          </div>
        )}
        <div
          className="rsvp-section__section rsvp-section__section-date"
          data-testid="event-date"
        >
          <Typography.LabelMd weight="semibold">
            {isRecurring
              ? t("events.rsvp.date")
              : t("events.rsvp.date_and_time")}
          </Typography.LabelMd>
          <div className="rsvp-section__section__details">
            <div>
              <Typography.LabelSm>{startDate}</Typography.LabelSm>
            </div>
            <div>
              <Typography.LabelSm>
                {" "}
                {`${dateToTimeString(startsAt, timeZone)} - ${dateToTimeString(
                  endsAt,
                  timeZone,
                )} ${timeZoneAbbreviations}`}
              </Typography.LabelSm>
            </div>
          </div>
          <div className="rsvp-section__section__footer">
            {!isEventFinished && (
              <AddToCalendar {...addToCalendarHelper(event)} />
            )}
            {isRecurring && comingRecurrences > 0 && (
              <div className="mt-2 lg:mt-4">
                <div>
                  <Typography.LabelSm>
                    {recurringFrequencyLabel}
                  </Typography.LabelSm>
                </div>
                <ListRecurringEventButton
                  event={event}
                  onChange={onChange}
                  buttonText={t("events.rsvp.show_all_events")}
                />
              </div>
            )}
          </div>
        </div>
        {(!hideLocationFromNonAttendees || rsvpedEvent) && (
          <div
            className="rsvp-section__section rsvp-section__section-location"
            data-testid="event-location"
          >
            <Typography.LabelMd weight="semibold">
              {t("events.rsvp.location")}
            </Typography.LabelMd>
            {isEventFinished &&
            (locationType === "live_stream" || locationType === "live_room") ? (
              <div className="rsvp-section__section__details">
                {t("events.ended")}
              </div>
            ) : (
              <>
                {locationType === "virtual" && (
                  <div className="rsvp-section__section__details rsvp-section__section__details--truncate text-dark">
                    {virtualLocationUrl ? (
                      <Linkify options={linkifyOptions}>
                        {virtualLocationUrl}
                      </Linkify>
                    ) : (
                      <Typography.BodyMd>
                        {t("events.locations.virtual")}
                      </Typography.BodyMd>
                    )}
                  </div>
                )}
                {locationType === "in_person" && (
                  <div className="rsvp-section__section__details">
                    <InPersonLocation serializedLocation={inPersonLocation} />
                  </div>
                )}
                {locationType === "tbd" && (
                  <div className="rsvp-section__section__details">
                    {t("events.rsvp.tbd")}
                  </div>
                )}
                {[
                  LOCATION_TYPES.LIVE_STREAM,
                  LOCATION_TYPES.LIVE_ROOM,
                ].includes(locationType) && (
                  <div className="rsvp-section__section__details text-dark">
                    <LiveLocation event={event} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!!attendeeCount && !rsvpDisabled && !hideAttendees && (
          <div className="rsvp-section__section rsvp-section__section-attendees">
            {!isMinimal && (
              <div className="lg:mb-2">
                <Typography.LabelMd weight="semibold">
                  {t("events.rsvp.attendees")}
                </Typography.LabelMd>
              </div>
            )}
            <div className="rsvp-section__section__details">
              <AttendeesPreview event={event} size="md" />
            </div>
          </div>
        )}
        {isMinimal && attendeeCount == 0 && (
          <div className="rsvp-section__section rsvp-section__section-attendees">
            <p className="rsvp-section__no-attendees">
              {t("events.rsvp.first_to_attend")}
            </p>
          </div>
        )}
      </div>
      <RecurringRsvpModal
        event={event}
        show={isRecurringRsvpOpen}
        onClose={onRecurringModalCose}
        onChange={onChange}
      />
    </Panel>
  );
};
