import { useRailbar } from ".";
import { useChatRoomStore } from "./store";
import { useScrollInfo } from "./useScrollInfo";

export const useChatMessageReaction = () => {
  const { chatRoomMessages, setChatRoomMessages, setScrollInfo } =
    useChatRoomStore();

  const { calculateScrollInfo } = useScrollInfo();
  const { parentMessage, updateParentMessage } = useRailbar();

  const onReactionWebsocketEvent = data => {
    const { event } = data;
    switch (event) {
      case "createReaction":
        handleReactionFromWebsocket(data);
        break;
      case "destroyReaction":
        handleReactionFromWebsocket(data);
        break;
      default:
        break;
    }
  };

  const createReactionFromMessage = reactionAttributes => {
    const { communityMemberId, emoji, reactionableId } = reactionAttributes;
    const updatedChatRoomMessages = structuredClone(chatRoomMessages);

    const chatRoomMessageIndex = updatedChatRoomMessages.findIndex(
      chatRoomMessage => chatRoomMessage.id === reactionableId,
    );

    if (chatRoomMessageIndex !== -1) {
      const existingReactionIndex = updatedChatRoomMessages[
        chatRoomMessageIndex
      ].reactions.findIndex(reaction => reaction.emoji === emoji);

      if (
        updatedChatRoomMessages[chatRoomMessageIndex].reactions[
          existingReactionIndex
        ]?.community_member_ids?.includes(communityMemberId)
      )
        return;

      if (existingReactionIndex !== -1) {
        updatedChatRoomMessages[chatRoomMessageIndex].reactions[
          existingReactionIndex
        ].community_member_ids.push(communityMemberId);
        updatedChatRoomMessages[chatRoomMessageIndex].reactions[
          existingReactionIndex
        ].count += 1;
      } else {
        updatedChatRoomMessages[chatRoomMessageIndex].reactions.push({
          emoji: emoji,
          community_member_ids: [communityMemberId],
          count: 1,
        });
      }
      setScrollInfo(calculateScrollInfo({ event: "reaction" }));
      setChatRoomMessages(updatedChatRoomMessages);

      if (parentMessage?.id === reactionableId) {
        const parentMessageCopy = chatRoomMessages.find(
          message => message.id === parentMessage.id,
        );
        if (parentMessageCopy) updateParentMessage({ ...parentMessageCopy });
      }
    }
  };

  const removeReactionFromMessage = reactionAttributes => {
    const { communityMemberId, emoji, reactionableId } = reactionAttributes;
    const updatedChatRoomMessages = structuredClone(chatRoomMessages);

    const chatRoomMessageIndex = updatedChatRoomMessages.findIndex(
      chatRoomMessage => chatRoomMessage.id === reactionableId,
    );

    if (chatRoomMessageIndex !== -1) {
      const existingReactionIndex = updatedChatRoomMessages[
        chatRoomMessageIndex
      ].reactions.findIndex(reaction => reaction.emoji === emoji);

      if (
        !updatedChatRoomMessages[chatRoomMessageIndex].reactions[
          existingReactionIndex
        ]?.community_member_ids?.includes(communityMemberId)
      )
        return;

      if (existingReactionIndex !== -1) {
        if (
          updatedChatRoomMessages[chatRoomMessageIndex].reactions[
            existingReactionIndex
          ].count === 1
        ) {
          updatedChatRoomMessages[chatRoomMessageIndex].reactions.splice(
            existingReactionIndex,
            1,
          );
        } else {
          updatedChatRoomMessages[chatRoomMessageIndex].reactions[
            existingReactionIndex
          ].community_member_ids = updatedChatRoomMessages[
            chatRoomMessageIndex
          ].reactions[existingReactionIndex].community_member_ids.filter(
            id => id !== communityMemberId,
          );
          updatedChatRoomMessages[chatRoomMessageIndex].reactions[
            existingReactionIndex
          ].count -= 1;
        }
      }
      setScrollInfo(calculateScrollInfo({ event: "reaction" }));
      setChatRoomMessages(updatedChatRoomMessages);

      if (parentMessage?.id === reactionableId) {
        const parentMessageCopy = chatRoomMessages.find(
          message => message.id === parentMessage.id,
        );
        if (parentMessageCopy) updateParentMessage({ ...parentMessageCopy });
      }
    }
  };

  const handleReactionFromWebsocket = data => {
    if (data.json_message.reactionable_type === "ChatRoomMessage") {
      const {
        community_member_id: communityMemberId,
        emoji,
        reactionable_id: reactionableId,
      } = data.json_message;

      if (data.event === "createReaction") {
        createReactionFromMessage({
          communityMemberId: communityMemberId,
          emoji: emoji,
          reactionableId: reactionableId,
        });
      } else if (data.event === "destroyReaction") {
        removeReactionFromMessage({
          communityMemberId: communityMemberId,
          emoji: emoji,
          reactionableId: reactionableId,
        });
      }
    }
  };

  return {
    createReactionFromMessage,
    removeReactionFromMessage,
    handleReactionFromWebsocket,
    onReactionWebsocketEvent,
  };
};
