import { Icon } from "@circle-react-shared/Icon";
import { LabelSm } from "@circle-react-shared/uikit/Typography/components/Label/LabelSm";

export const SearchCategory = ({ name, icon, onClick }) => (
  <li>
    <button
      tabIndex={0}
      type="button"
      className="text-dark bg-primary hover:bg-secondary focus:bg-secondary flex w-full cursor-pointer items-center justify-between gap-4 px-5 py-2.5 transition-colors"
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <Icon type={icon} className="text-default" size={16} />
        <LabelSm>{name}</LabelSm>
      </div>
      <Icon type="16-chevron-right" className="text-default" size={16} />
    </button>
  </li>
);
