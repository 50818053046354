import { noop } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import type { ReactionType } from "../Reaction";
import { EMOJIS } from "../constants";
import { useReactions } from "../useReactions";

export interface NewReactionProps {
  emoji: string;
  reactionableId: number;
  reactionableType: string;
  currentCommunityMemberId: number;
  onClose: () => void;
  reactions: ReactionType[];
}

export const NewReaction = ({
  emoji,
  reactionableId,
  reactionableType,
  currentCommunityMemberId,
  onClose,
  reactions,
}: NewReactionProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const renderedEmoji = EMOJIS[emoji];

  const { addReaction, removeReaction } = useReactions({
    currentCommunityMemberId,
    emoji,
    reactionableId,
  });

  const handleReaction = () => {
    const body = { emoji: emoji, [reactionableType]: reactionableId };

    const reaction = reactions.find(reaction => reaction.emoji === emoji);
    if (reaction) {
      const isReactedByMe = reaction.community_member_ids.includes(
        currentCommunityMemberId,
      );
      if (isReactedByMe) {
        removeReaction(body);
      } else {
        addReaction(body);
      }
    } else {
      addReaction(body);
    }

    onClose();
  };

  if (!renderedEmoji) return null;

  return (
    <button
      type="button"
      className="hover:bg-secondary focus-visible:!outline-secondary relative flex gap-1 rounded-md border-transparent px-2 py-0.5 text-sm focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
      onClick={isViewOnlyMasquerading ? noop : handleReaction}
    >
      {renderedEmoji}
    </button>
  );
};
