export const useBEMClasses = (blockElement, modifierNames) => {
  const modifiers = {};

  Object.keys(modifierNames).forEach(modifier => {
    modifiers[`${blockElement}--${modifier}`] = modifier;
  });

  return {
    modifiers,
  };
};
