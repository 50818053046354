import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface SpotlightedHeaderProps {
  isQueuedForSpotlight: boolean;
}

export const SpotlightedHeader = ({
  isQueuedForSpotlight,
}: SpotlightedHeaderProps) => (
  <div className="mb-1.5">
    <Typography.LabelXxs
      weight="semibold"
      color="text-very-dark"
      textTransform="uppercase"
    >
      {isQueuedForSpotlight
        ? t("live_streams.room.qa.questions.queued_up")
        : t("live_streams.room.qa.questions.spotlighted")}
    </Typography.LabelXxs>
  </div>
);
