import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

interface DisableLikesActionProps {
  post: Post;
  onChange: (post: Post, options: { refetch: boolean }) => void;
}

export const DisableLikesAction = ({
  post,
  onChange,
}: DisableLikesActionProps) => {
  const { is_liking_disabled: isLikingDisabled } = post;
  const translationRoot = isLikingDisabled
    ? "post.enable_liking"
    : "post.disable_liking";
  const { success, error } = useToast();

  const { mutate: toggleLikesDisabled } = useMutation<Post, ApiError, void>(
    () =>
      reactQueryPatch(
        internalApi.posts.update({
          spaceId: post.space_id,
          postSlug: post.slug,
        }),
        {
          post: {
            space_id: post.space_id,
            is_liking_disabled: !isLikingDisabled,
          },
        },
      ),
    {
      onSuccess: postData => {
        onChange(postData, { refetch: false });

        success(t(`${translationRoot}.alerts.success`));
      },
      onError: () => {
        error(t(`${translationRoot}.alerts.failure`));
      },
    },
  );

  return (
    <Dropdown.ItemLink onClick={() => toggleLikesDisabled()}>
      <ToggleSwitchWithLabel
        label={t("post.action_menu.disable_liking")}
        field="disable_liking"
        value={isLikingDisabled}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
