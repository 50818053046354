import PropTypes from "prop-types";
import { Profile } from "@/react/components/Profile/Profile";
import { MemberList } from "./MemberList";

export const Content = ({
  chatRoom,
  isDirect,
  refetchChatRoom,
  otherParticipant,
  member,
}) => {
  if (isDirect) {
    return (
      <Profile
        memberPublicId={otherParticipant?.user_public_uid}
        member={member}
        isFullPageProfile={false}
        isRenderedOnRailBar
        shouldHideMessageButton
        shouldHideBadge
        shouldFixTabsTop
      />
    );
  }
  return <MemberList chatRoom={chatRoom} refetchChatRoom={refetchChatRoom} />;
};

Content.propTypes = {
  participants: PropTypes.array,
  chatRoom: PropTypes.object,
  isDirect: PropTypes.bool,
  refetchChatParticipants: PropTypes.func,
  otherParticipant: PropTypes.object,
  member: PropTypes.object,
  refetchChatRoom: PropTypes.func,
};
