import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ModalManager } from "@circle-react/components/Layout/ModalManagerProvider";
import { EditMasqueradingSearchModal } from "./EditMasqueradingSearchModal";
import { ViewMasqueradingSearchModal } from "./ViewMasqueradingSearchModal";

const EditMasqueradingDrawer = ModalManager.create(() => (
  <EditMasqueradingSearchModal />
));

const ViewMasqueradingDrawer = ModalManager.create(() => (
  <ViewMasqueradingSearchModal />
));

export const useViewMasqueradingSearch = () => useModal(ViewMasqueradingDrawer);

export const useEditMasqueradingSearch = () => useModal(EditMasqueradingDrawer);
