import { t } from "@/i18n-js/instance";
import type { ChatRoom } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/ChatRoomTypes";
import { TippyV2 } from "@/react/components/shared/TippyV2";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { Icon } from "@circle-react-shared/Icon";

interface Sender {
  community_admin?: boolean;
  moderator?: boolean;
}

export const ModeratorTag = ({
  chatRoom,
  sender,
}: {
  chatRoom?: ChatRoom;
  sender?: Sender;
}) => {
  const { isChatSpace, isDmsPage } = useRailbar();
  const isGroupChat = isDmsPage && chatRoom?.chat_room_kind === "group_chat";

  const isAdmin = Boolean(sender?.community_admin);
  const isModerator = Boolean(sender?.moderator);
  const isAdminOrModerator = isAdmin || isModerator;

  if ((!isChatSpace && !isGroupChat) || !isAdminOrModerator) {
    return null;
  }

  return (
    <TippyV2
      className="flex items-center justify-center"
      interactive={false}
      content={t(isChatSpace && isAdmin ? "admin" : "moderator")}
    >
      <Icon
        type="14-moderator-icon"
        size={14}
        className={classNames(
          "!w-[14px]",
          isChatSpace && isAdmin ? "text-[#2563EB]" : "text-tertiary",
        )}
      />
    </TippyV2>
  );
};
