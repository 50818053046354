import { t } from "@/i18n-js/instance";
import { useCourseCustomLabels } from "@/react/components/Spaces/CourseSpace/Shared/useCourseCustomLabels";
import { NewConversation as MessagingNewConversation } from "../ChatWindowV2/NewConversation";

interface NewConversationProps {
  isLiveStream?: boolean;
  isInCourseSpace?: boolean;
  isMessaging?: boolean;
  chatRoomUuid?: string;
}

export const NewConversation = ({
  isLiveStream = false,
  isInCourseSpace = false,
  isMessaging = false,
  chatRoomUuid,
}: NewConversationProps) => {
  const { translatedLabels } = useCourseCustomLabels();
  if (isMessaging) {
    return <MessagingNewConversation chatRoomUuid={chatRoomUuid} />;
  }
  if (isLiveStream) {
    return (
      <div className="block px-5 py-4">
        <div className="text-darkest text-lg font-medium">
          {t("messaging.live_stream_start_conversation")}
        </div>
        <div className="text-dark">
          {t("messaging.live_stream_start_conversation_description")}
        </div>
      </div>
    );
  }
  if (isInCourseSpace) {
    return (
      <div className="block px-5 py-4">
        <div className="text-darkest text-lg font-medium">
          {t("messaging.course_lesson_chat_start_conversation")}
        </div>
        <div className="text-dark">
          {t("messaging.course_lesson_chat_start_conversation_description", {
            lessonSingular: translatedLabels.lesson.singular.toLowerCase(),
          })}
        </div>
      </div>
    );
  }
  return null;
};
