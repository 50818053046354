import { isFunction } from "lodash";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { DuplicatePostModal } from "@circle-react/components/Spaces/ImageSpace/DuplicatePostModal";
import { useDuplicatePost } from "@circle-react/components/Spaces/ImageSpace/DuplicatePostModal/hooks/useDuplicatePost";
import { useSpacesContext } from "@circle-react/contexts";
import type { ImagePost } from "@circle-react/types";

interface DuplicateImagePostModalProps {
  post: ImagePost;
  onSuccess?: (post: ImagePost) => void;
}

const ModalComponent = ({ post, onSuccess }: DuplicateImagePostModalProps) => {
  const modal = useModal();
  const { isLoading: isLoadingSpaces, helpers } = useSpacesContext();

  const { duplicate, isDuplicating } = useDuplicatePost({
    post,
    onSuccess: duplicatedPost => {
      isFunction(onSuccess) && onSuccess(duplicatedPost);
      modal.remove();
    },
  });

  return (
    <DuplicatePostModal
      post={post}
      spaces={helpers.imageSpacesWithPostCreationAllowed()}
      isOpen={modal.visible}
      isLoading={isLoadingSpaces}
      isSubmitting={isDuplicating}
      onSubmit={duplicate}
      onClose={modal.remove}
    />
  );
};

const DuplicateImagePostModal = ModalManager.create(
  (props: DuplicateImagePostModalProps) => <ModalComponent {...props} />,
);

export const useDuplicateImagePostModal = () =>
  useModal(DuplicateImagePostModal);
