import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@circle-react/api";
import { DeleteRecurringModal } from "@circle-react/components/Events/DeleteRecurringModal";
import { DuplicateEventModal } from "@circle-react/components/Events/ListViewItem/EventMenu/DuplicateEventModal";
import { PinEventToTopAction } from "@circle-react/components/Events/ListViewItem/EventMenu/PinEventToTopAction";
import { ShareEvent } from "@circle-react/components/Events/NewEventsSpace/EventDetailPage/ShareEvent";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { eventDeletionMessage } from "@circle-react/components/EventsV3/Modal/utils";
import { isRecurringFreeEvent } from "@circle-react/components/EventsV3/Modal/utils/helpers";
import { useEditEventModal } from "@circle-react/components/Modals/EditEventModal";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { Icon } from "@circle-react-shared/Icon";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useToast } from "@circle-react-uikit/ToastV2";

export const EventDropdownActions = ({ onChange, onPostDestroy }) => {
  const {
    post: event,
    canUpdatePost,
    canDuplicatePost,
    canDestroyPost,
    canManagePost,
    refetchPostDetails,
  } = useCurrentPostHelpers();

  const editEventModal = useEditEventModal();
  const { success, error } = useToast();
  const { refetch } = useCurrentSpaceContext();
  const isSmScreen = useSmScreenMediaQuery();
  const [shouldShowConfirmationModal, toggleConfirmationModal] = useBoolean();
  const [shouldShowDuplicateEventModal, toggleDuplicateEventModal] =
    useBoolean(false);
  const [isRecurringModalOpen, setIsRecurringModalOpen] = useBoolean();
  const { isViewOnlyMasquerading } = usePunditUserContext();

  const { contentPortalElement } = useModalData();

  const deleteMutation = useMutation(
    deleteMode =>
      eventApi.destroy({ event: event, params: { delete_mode: deleteMode } }),
    {
      onSuccess: () => {
        onPostDestroy(event.id);
        success(eventDeletionMessage(event), {
          duration: "short",
          shouldUseProgress: false,
        });
        refetch?.();
      },
      onError: () => error(t("event_dropdown_actions.delete_failed")),
    },
  );

  const onDeleteClick = () => {
    if (isRecurringFreeEvent(event)) {
      setIsRecurringModalOpen(true);
    } else {
      toggleConfirmationModal(true);
    }
  };

  const shouldDisplayDropdownActions =
    canUpdatePost || canDuplicatePost || canDestroyPost;

  if (!shouldDisplayDropdownActions) {
    return null;
  }

  if (isViewOnlyMasquerading) {
    return (
      <Icon
        type="menu-dots"
        aria-label={t("events.event_actions")}
        className="cursor-not-allowed"
      />
    );
  }

  return (
    <>
      <Dropdown
        direction="bottom-end"
        menuButtonEl="div"
        button={
          <IconButton
            name="20-menu-dots-horizontal"
            iconClassName="!text-default group-hover:!text-dark !w-5 !h-5"
            className="group"
            variant="secondary"
            iconSize={20}
            buttonSize={28}
            aria-label={t("events.event_actions")}
            dataTestId="action-more"
          />
        }
        className="z-10"
        transitionWrapperClassName="z-50"
        size="large"
        appendTo={contentPortalElement ?? document.body}
      >
        <BookmarkButton
          variant="dropdown"
          bookmarkType="event"
          record={event}
          onSuccess={refetchPostDetails}
        />
        {isSmScreen && (
          <ShareEvent
            onChange={onChange}
            className="w-full"
            buttonClassName="w-full"
            button={
              <Dropdown.ItemLink>
                {t("events.event_actions_dropdown.share_event")}
              </Dropdown.ItemLink>
            }
          />
        )}
        {canUpdatePost && (
          <Dropdown.ItemLink
            onClick={() => editEventModal.show({ event })}
            forceClose
          >
            {t("edit_event")}
          </Dropdown.ItemLink>
        )}
        {canDuplicatePost && (
          <Dropdown.ItemLink onClick={toggleDuplicateEventModal}>
            {t("duplicate_event")}
          </Dropdown.ItemLink>
        )}
        {canDestroyPost && (
          <Dropdown.ItemLink onClick={onDeleteClick} forceClose>
            <span className="text-v2-danger">{t("delete_event")}</span>
          </Dropdown.ItemLink>
        )}
        {canManagePost && (
          <>
            <Dropdown.Separator />
            <PinEventToTopAction event={event} canEdit={canUpdatePost} />
          </>
        )}
      </Dropdown>
      <ConfirmationModal
        isOpen={shouldShowConfirmationModal}
        title={t("delete_event_confirmation")}
        onClose={toggleConfirmationModal}
        onCancel={toggleConfirmationModal}
        onConfirm={deleteMutation.mutate}
        confirmVariant="danger"
        disabled={deleteMutation.isLoading}
        confirmText={deleteMutation.isLoading ? t("deleting") : t("confirm")}
      >
        {t("delete_event_descriptor")}
      </ConfirmationModal>
      <DuplicateEventModal
        event={event}
        show={shouldShowDuplicateEventModal}
        onClose={toggleDuplicateEventModal}
      />
      {!isPaidEvent(event) && (
        <DeleteRecurringModal
          show={isRecurringModalOpen}
          onConfirm={deleteMutation.mutate}
          onCloseModal={() => setIsRecurringModalOpen(false)}
        />
      )}
    </>
  );
};
