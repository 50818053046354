import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";

export const FormItem = ({
  appendTranslationRoot = "",
  children,
  ...props
}) => (
  <Form.Item
    hideBorder
    fullWidth
    hideDescription
    translationRoot={
      appendTranslationRoot
        ? `live_streams.form.${appendTranslationRoot}`
        : "live_streams.form"
    }
    {...props}
  >
    {children}
  </Form.Item>
);

FormItem.propTypes = {
  appendTranslationRoot: PropTypes.string,
  children: PropTypes.any,
};
