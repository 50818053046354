import flatten from "lodash/flatten";
import last from "lodash/last";
import uniqBy from "lodash/uniqBy";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useInfiniteQueryWrapper } from "@circle-react/hooks/reactQuery/useInfiniteQueryWrapper";

export const useLikesList = ({ id, isQueryEnabled, placeholderLikesCount }) => {
  const { data, isLoading, hasNextPage, fetchNextPage, mapPages } =
    useInfiniteQueryWrapper(
      internalApi.comments.userLikes({
        id,
        params: {
          per_page: 15,
        },
      }),
      {
        enabled: isQueryEnabled,
      },
    );
  const likedByMembers = uniqBy(flatten(mapPages(page => page.records)), "id");
  const totalLikesCount = data ? last(data.pages).count : placeholderLikesCount;

  return {
    likedByMembers,
    totalLikesCount,
    isLoading,
    hasNextPage,
    fetchNextPage,
  };
};
