import { isEqual } from "lodash";
import { cable } from "./cable";

// channel: [{ subscription, params }, { subscription, params, callbacks }, ...]
const subscriptionData = new Map();

export const addSubscription = ({ channel, ...params } = {}, callback) => {
  if (!channel || !callback) {
    return;
  }
  // Create new subscription data if it does not exist
  if (!subscriptionData.has(channel)) {
    subscriptionData.set(channel, []);
  }
  // Try to find subscription for the same channel and params
  const channelSubscriptions = subscriptionData.get(channel);
  let subscriptionObj = channelSubscriptions.find(
    ({ params: existingParams }) => isEqual(params, existingParams),
  );
  if (!subscriptionObj) {
    // Initialize subscription object
    subscriptionObj = {
      subscription: cable.subscribeTo(channel, params),
      params,
      callbacks: [callback],
    };
    channelSubscriptions.push(subscriptionObj);
    subscriptionObj.subscription.on("message", (...callbackParams) => {
      subscriptionObj.callbacks.forEach(subscriptionCallback =>
        subscriptionCallback(...callbackParams),
      );
    });
  } else {
    subscriptionObj.callbacks.push(callback);
  }

  return subscriptionObj;
};

export const removeSubscription = ({ channel, ...params } = {}, callback) => {
  if (!channel || !callback) {
    return;
  }
  // Try to find subscription for the same channel and params
  const channelSubscriptions = subscriptionData.get(channel);
  if (!channelSubscriptions) {
    // No subscriptions for channel, return
    return;
  }
  const subscriptionObj = channelSubscriptions.find(
    ({ params: existingParams }) => isEqual(params, existingParams),
  );
  if (!subscriptionObj) {
    // No subscription to channel with these params, return
    return;
  }
  subscriptionObj.callbacks = subscriptionObj.callbacks.filter(
    subscriptionCallback => subscriptionCallback !== callback,
  );
  if (!subscriptionObj.callbacks.length) {
    // Disconnect subscription
    subscriptionObj.subscription.disconnect();
    // Remove object from channelSubscriptions
    subscriptionData.set(
      channel,
      channelSubscriptions.filter(
        ({ params: existingParams }) => !isEqual(params, existingParams),
      ),
    );
  }
};
