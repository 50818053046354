import { useEffect } from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { useMutation } from "react-query";
import { MemoryRouter, Route, Switch, useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { postApi } from "@circle-react/api";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Content } from "@circle-react/components/QuickPostV2/Content";
import { Settings } from "@circle-react/components/QuickPostV2/Settings";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import {
  tiptapValidationSchema,
  trixValidationSchema,
} from "@circle-react/components/QuickPostV2/validation";
import { POST_FORM_CACHE } from "@circle-react/components/constants";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { afterSaveRedirectPath } from "@circle-react/helpers/postHelpers";
import { usePersistedFormFieldValues } from "@circle-react/hooks/utils/usePersistFormFields";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface PostForQuickPost {
  name: string;
  body: string;
  space_id: string;
  status: string;
  published_at?: string;
  [key: string]: any;
}

export const QuickPostV2Form = ({
  shouldSkipRedirectAfterSubmit,
  onSubmitSuccess,
}: {
  shouldSkipRedirectAfterSubmit?: boolean;
  onSubmitSuccess?: () => void;
}) => {
  const modal = useModal();
  const { isFullscreen, setIsSubmitting } = useQuickPostModalStore();
  const { success, error } = useToast();
  const history = useHistory();
  const { currentCommunitySettings } = usePunditUserContext();
  const isTiptapPostEnabled = !!currentCommunitySettings?.tiptap_post_enabled;
  const { onPostChange, helpers } = useCurrentPostContext();
  const getPostForTrixEditor = helpers?.postForTrixEditor;
  const isTiptapPost = Boolean(helpers?.isTiptapPost);
  const postForTrixEditor = getPostForTrixEditor && getPostForTrixEditor();
  const isEdit = !!postForTrixEditor.id;

  const shouldShowTipTap = isEdit ? isTiptapPost : isTiptapPostEnabled;

  const { resetValues: resetCachedValues } = usePersistedFormFieldValues({
    cacheKey: POST_FORM_CACHE,
  });

  const createPostMutation = useMutation(
    (formData: PostForQuickPost) =>
      postApi.createOrUpdate({
        post: {
          id: postForTrixEditor.id,
          slug: postForTrixEditor.slug,
          space_id: postForTrixEditor.space_id || formData.space_id,
        },
        formData,
      }),
    {
      onSuccess: async response => {
        const responseData: PostForQuickPost = await response.json();
        if (isFunction(onPostChange)) onPostChange(responseData);
        if (isFunction(onSubmitSuccess)) onSubmitSuccess();
        if (!isEdit) resetCachedValues();
        success(
          t(
            isEdit
              ? "quick_post.toasts.changes_saved"
              : `quick_post.toasts.${responseData.status}_saved`,
          ),
        );
        modal.remove();
        const redirectUrl = afterSaveRedirectPath({ post: responseData });
        if (!shouldSkipRedirectAfterSubmit) {
          history.push(redirectUrl);
        }
      },
      onError: () => error(t("quick_post.toasts.failed_to_create")),
    },
  );

  useEffect(() => {
    setIsSubmitting(createPostMutation.isLoading);
  }, [createPostMutation.isLoading, setIsSubmitting]);
  return (
    <Form
      id="quick-post-form"
      defaultValues={postForTrixEditor}
      mode="all"
      className="h-full"
      formClassName="h-full flex flex-col"
      onSubmit={data => createPostMutation.mutate(data)}
      validationSchema={
        shouldShowTipTap ? tiptapValidationSchema : trixValidationSchema
      }
    >
      <Modal.Content
        size={isFullscreen ? "screen" : "auto"}
        className={classNames("overflow-visible", {
          "max-h-none": isFullscreen,
          "h-full max-h-full max-w-[720px] !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh] lg:max-h-[550px]":
            !isFullscreen,
        })}
        animations="fadeIn"
      >
        <MemoryRouter>
          <Switch>
            <Route component={Content} path="/" exact />
            <Route component={Settings} path="/settings" />
          </Switch>
        </MemoryRouter>
      </Modal.Content>
    </Form>
  );
};
