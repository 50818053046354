import { useEffect, useMemo, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useEventSpaces } from "@circle-react/components/Events/hooks/useEventSpaces";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  ROOM_ACCESS_TYPES,
  ROOM_TYPES,
  ROOM_VIEW_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { isLiveLocation } from "../../../utils";

export const useLiveValuesWatch = event => {
  const { currentCommunity } = usePunditUserContext();
  const { setValue, getValues } = useFormContext();
  const prevLocationTypeRef = useRef(
    event?.event_setting_attributes?.location_type,
  );
  const locationType = getValues().event_setting_attributes?.location_type;
  const { spaces } = useEventSpaces();
  const spaceId = useWatch({ name: "space_id" });
  const space = spaces?.find(space => space.id === spaceId) || null;
  const isPrivateSpace = ["private", "secret"].includes(space?.visibility);
  const accessType = isPrivateSpace
    ? ROOM_ACCESS_TYPES.SECRET
    : ROOM_ACCESS_TYPES.OPEN;

  const liveStreamRoomSettingAttributes = useMemo(
    () => ({
      access_type: accessType,
      view_type: ROOM_VIEW_TYPES.GRID,
      recording_enabled: currentCommunity.live_stream_recording_enabled,
      auto_post_recording_enabled:
        currentCommunity.live_stream_auto_post_recording_enabled,
      room_type: ROOM_TYPES.STREAM,
      ...event?.event_setting_attributes?.live_stream_room_setting_attributes,
    }),
    [currentCommunity, event, accessType],
  );

  useEffect(() => {
    // Setting live_stream_room_setting_attributes only when locationType changes
    // and it's either a live room or live stream
    if (
      isLiveLocation(locationType) &&
      prevLocationTypeRef.current !== locationType
    ) {
      Object.entries(liveStreamRoomSettingAttributes).forEach(
        ([name, value]) => {
          setValue(
            `event_setting_attributes.live_stream_room_setting_attributes.${name}`,
            value,
            { shouldDirty: true, shouldTouch: true },
          );
        },
      );
      prevLocationTypeRef.current = locationType;
    }
  }, [locationType, liveStreamRoomSettingAttributes, setValue]);

  return { space };
};
