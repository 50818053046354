import { useCallback, useEffect, useRef, useState } from "react";
import type { RefObject } from "react";

interface CommentFormDynamics {
  commentsSectionRef: RefObject<HTMLDivElement>;
  dynamicMarginBottom: number;
}

const useResizeObserver = (
  ref: RefObject<HTMLDivElement>,
  callback: (height: number) => void,
) => {
  useEffect(() => {
    const currentRef = ref.current;
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        callback(entry.contentRect.height);
      }
    });

    const findAndObserveReplyDiv = () => {
      const targetElement = currentRef?.querySelector(
        ".post__reply.reply-to-post",
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    findAndObserveReplyDiv();

    const mutationObserver = new MutationObserver(findAndObserveReplyDiv);
    if (currentRef) {
      mutationObserver.observe(currentRef, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref, callback]);
};

const useEditorStateObserver = (
  ref: RefObject<HTMLDivElement>,
  setExpanded: (expanded: boolean) => void,
) => {
  const checkEditorState = useCallback(() => {
    const replyDiv = ref.current?.querySelector(".post__reply.reply-to-post");
    if (replyDiv) {
      const editor = replyDiv.querySelector(".ProseMirror");
      if (editor) {
        const content = editor.innerHTML;
        const isExpanded = document.activeElement === editor || !!content;
        setExpanded(isExpanded);
      }
    }
  }, [ref, setExpanded]);

  useEffect(() => {
    const currentRef = ref.current;

    const observerCallback = () => {
      checkEditorState();
    };

    const observer = new MutationObserver(observerCallback);
    if (currentRef) {
      observer.observe(currentRef, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
      });
    }

    checkEditorState();

    const handleFocus = () => checkEditorState();
    const handleBlur = () => checkEditorState();

    currentRef?.addEventListener("focusin", handleFocus);
    currentRef?.addEventListener("focusout", handleBlur);

    return () => {
      observer.disconnect();
      currentRef?.removeEventListener("focusin", handleFocus);
      currentRef?.removeEventListener("focusout", handleBlur);
    };
  }, [ref, setExpanded, checkEditorState]);
};

export const usePostModalCommentFormDynamic = (): CommentFormDynamics => {
  const [isCommentFormExpanded, setIsCommentFormExpanded] = useState(false);
  const [commentFormHeight, setCommentFormHeight] = useState(0);
  const commentsSectionRef = useRef<HTMLDivElement>(null);

  useResizeObserver(commentsSectionRef, setCommentFormHeight);
  useEditorStateObserver(commentsSectionRef, setIsCommentFormExpanded);

  const dynamicMarginBottom = isCommentFormExpanded
    ? commentFormHeight + 32 + 20
    : commentFormHeight + 32;

  return {
    commentsSectionRef,
    dynamicMarginBottom,
  };
};
