import { EventEmitter } from "events";

declare interface ChatEventsEmitter {
  on(event: "onBookmarkToggle", listener: (messageId: number) => any): this;
}

class ChatEventsEmitter extends EventEmitter {
  constructor() {
    super();
  }

  toggleMessageBookmark = (messageId: number) => {
    this.emit("onBookmarkToggle", messageId);
  };
}

export const chatEventsEmitter = new ChatEventsEmitter();
