import { useCallback, useState } from "react";
import { t } from "@/i18n-js/instance";
import { AiSummary } from "@/react/components/shared/AiSummary";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { toLocaleString } from "@circle-react/helpers/number";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { usePostConversationSummary } from "@circle-react/hooks/posts/usePostConversationSummary";
import type { Post } from "@circle-react/types/Post";

interface ConversationSummaryProps {
  post: Post;
  className?: string;
}

const LOCALE_ROOT = "post.conversation_summary";

export const ConversationSummary = ({
  post,
  className,
}: ConversationSummaryProps) => {
  const {
    conversationSummary,
    refetchConversationSummary,
    clearConversationSummary,
  } = usePostConversationSummary({ post });
  const [isExpanding, setIsExpanding] = useState(false);

  const handleExpandClick = () => {
    setIsExpanding(true);
    void refetchConversationSummary();
  };

  const handleCollapseClick = () => {
    setIsExpanding(false);
    clearConversationSummary();
  };

  const handleExpandComplete = useCallback(() => {
    setIsExpanding(false);
  }, []);

  const isCollapsed = !isExpanding && !conversationSummary;
  const isExpandCompleted = !isExpanding && Boolean(conversationSummary);
  const { currentCommunityMember } = usePunditUserContext();

  if (!isCommunityAdmin(currentCommunityMember)) {
    return null;
  }

  if (!post.conversation_summary_overview) {
    return null;
  }

  return (
    <div className={classNames("flex py-5 md:px-6", className)}>
      <AiSummary
        isCollapsed={isCollapsed}
        onCollapseClick={handleCollapseClick}
        onExpandClick={handleExpandClick}
        className="pb-4"
      >
        <AiSummary.Content closeButtonPosition="sidebar">
          <AiSummary.Header animate={isExpanding} isHidden={isExpandCompleted}>
            {t([LOCALE_ROOT, isCollapsed ? "title" : "loading"])}
          </AiSummary.Header>
          {isCollapsed && (
            <AiSummary.BodyText>
              {post.conversation_summary_overview}
            </AiSummary.BodyText>
          )}
          {conversationSummary?.content && (
            <AiSummary.BodyText
              animation="fade-in"
              onAnimationComplete={handleExpandComplete}
            >
              {conversationSummary.content}
            </AiSummary.BodyText>
          )}
        </AiSummary.Content>
        {post.comments_count && isExpandCompleted && (
          <AiSummary.Footer>
            <AiSummary.FooterNote>
              {t([LOCALE_ROOT, "comments_summarized"], {
                count: post.comments_count,
                formatted_count: toLocaleString(post.comments_count),
              })}
            </AiSummary.FooterNote>
          </AiSummary.Footer>
        )}
      </AiSummary>
    </div>
  );
};
