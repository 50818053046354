import { useEffect } from "react";

export const useAppendBodyClasses = (classNames = "") => {
  useEffect(() => {
    if (!classNames) {
      return;
    }
    const classNameList = classNames.split(" ");
    classNameList.forEach(className => {
      document.body.classList.add(className);
    });

    return () => {
      classNameList.forEach(className => {
        document.body.classList.remove(className);
      });
    };
  }, [classNames]);
};
