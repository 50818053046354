import { forwardRef } from "react";
import type { PaywallCurrency } from "@circle-react/types";
import { paywallCurrencyDirections } from "@circle-react/types";
import { Form } from "@circle-react-uikit/Form";
import type { InputOrArea, InputProps } from "@circle-react-uikit/Input";

export interface PaywallCurrencyAmountFormInputProps extends InputProps {
  currency: PaywallCurrency;
}
export const PaywallCurrencyAmountFormInput = forwardRef<
  InputOrArea,
  PaywallCurrencyAmountFormInputProps
>(({ currency, ...otherProps }, ref) => {
  const currencyCode = currency.code.toUpperCase();

  const prefixComponent =
    currency.direction === paywallCurrencyDirections.rtl ? (
      <div className="px-4 py-2">{currencyCode}</div>
    ) : null;
  const suffixComponent =
    currency.direction === paywallCurrencyDirections.ltr ? (
      <>{currencyCode}</>
    ) : null;

  return (
    <Form.Input
      {...otherProps}
      ref={ref}
      inputMode="numeric"
      prefixWrapperClassName="bg-primary! border-primary! rounded-l-lg! relative! -mr-px! inline-flex! items-center! space-x-2! border! sm:text-sm!"
      prefix={prefixComponent}
      suffix={suffixComponent}
    />
  );
});

PaywallCurrencyAmountFormInput.displayName = "PaywallCurrencyAmountFormInput";
