import { t } from "@/i18n-js/instance";
import { userIsAdminOrModeratorOfSpace } from "@/react/helpers/spaceHelpers";
import type { Space } from "@/react/types/Space";
import { Form } from "@circle-react-uikit/Form";

interface FieldsProps {
  space?: Space;
}
export const Fields = ({ space }: FieldsProps) => {
  const canChangeAuthor = !!space && userIsAdminOrModeratorOfSpace(space);
  return (
    <>
      <Form.Item
        variant="extra-bottom-margin"
        className="!pt-3"
        hideBorder
        name="slug"
        fullWidth
        translationRoot="post"
        hidePlaceholder
        hideDescription
        tooltipText={t("post.slug_description")}
      >
        <Form.Input name="slug" autoComplete="off" />
      </Form.Item>
      {canChangeAuthor && (
        <Form.Item
          variant="extra-bottom-margin"
          className="!pt-4"
          hideBorder
          name="user_id"
          translationRoot="post"
          fullWidth
          hideDescription
          tooltipText={t("post.user_id_description")}
        >
          <Form.CommunityMemberSelect
            name="user_id"
            direction="up"
            valueField="user_id"
          />
        </Form.Item>
      )}
    </>
  );
};
