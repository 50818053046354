import { create } from "zustand";

interface QuickPostModalState {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

export const useQuickPostModalStore = create<QuickPostModalState>(set => ({
  isFullscreen: false,
  toggleFullscreen: () => set(state => ({ isFullscreen: !state.isFullscreen })),
  isSubmitting: false,
  setIsSubmitting: (isSubmitting: boolean) => set({ isSubmitting }),
  isDirty: false,
  setIsDirty: (isDirty: boolean) => set({ isDirty }),
}));
