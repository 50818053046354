import { flatten, isNull } from "lodash";
import { uniqBy } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const DEFAULT_PER_PAGE = 10;

const queryKey = ["chat-threads-list"];

export const useChatThreadsList = () => {
  const { currentCommunityMember } = usePunditUserContext();

  const queryClient = useQueryClient();

  const updateQueryCache = newData => {
    queryClient.setQueryData(queryKey, oldData => ({
      ...oldData,
      pages: oldData.pages.map((page, index) => ({
        ...page,
        records: newData.slice(
          DEFAULT_PER_PAGE * index,
          DEFAULT_PER_PAGE * (index + 1),
        ),
      })),
    }));
  };

  const updateChatThreads = threadMessages => {
    const parentMessageId = threadMessages[0].parent_message_id;

    const updatedChatThreads = chatThreads.map(thread => {
      if (thread.parent_message.id === parentMessageId) {
        return {
          ...thread,
          replies: uniqBy([...threadMessages, ...thread.replies], "id"),
        };
      }
      return thread;
    });
    updateQueryCache(updatedChatThreads);
  };

  const fetchChatThreads = page =>
    reactQueryGet(
      internalApi.chatThreads.index({
        params: {
          page: page,
          per_page: DEFAULT_PER_PAGE,
        },
      }),
    );

  const {
    data: chatThreads = [],
    isLoading,
    refetch: refetchChatThreads,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchChatThreads(pageParam),
    {
      select: ({ pages = [] }) => flatten(pages.map(page => page.records)),
      enabled: !!currentCommunityMember?.policies?.can_access_threads_tab,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const fetchChatThread = async id => {
    try {
      const data = await reactQueryGet(
        internalApi.chatThreads.show({
          id,
        }),
      );
      updateQueryCache([data, ...chatThreads]);
    } catch (err) {
      console.error(err);
    }
  };

  const onEventReceive = eventData => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(eventData.json_message);
        break;
      case "updatedMessage":
        onUpdateMessage(eventData.json_message);
        break;
      case "deletedMessage":
        onDeleteMessage(eventData);
        break;
    }
  };

  const onNewMessage = newMessage => {
    if (newMessage?.unread_flow) {
      return;
    }
    const isNewThread = chatThreads.every(
      thread => thread.parent_message.id !== newMessage.parent_message_id,
    );

    if (isNewThread) {
      fetchChatThread(newMessage.chat_thread_id);
    } else {
      const updatedChatThreads = chatThreads.map(thread => {
        if (thread.parent_message.id === newMessage.parent_message_id) {
          const { parent_message, replies } = thread;
          return {
            ...thread,
            parent_message: {
              ...parent_message,
              replies_count: parent_message.replies_count + 1,
            },
            replies: [...replies, newMessage],
          };
        }
        return thread;
      });

      updateQueryCache(updatedChatThreads);
    }
  };

  const updateParentMessage = message => {
    const updatedChatThreads = chatThreads.map(thread => {
      if (thread.parent_message.id !== message.id) return thread;

      return {
        ...thread,
        parent_message: {
          ...thread.parent_message,
          ...message,
        },
      };
    });
    updateQueryCache(updatedChatThreads);
  };

  const onUpdateMessage = message => {
    if (isNull(message.parent_message_id)) {
      updateParentMessage(message);
    } else {
      const updatedChatThreads = chatThreads.map(thread => {
        if (thread.parent_message.id !== message.parent_message_id)
          return thread;

        const { replies } = thread;
        const updatedReplies = replies.map(reply => {
          if (reply.id === message.id) return message;
          return reply;
        });
        return {
          ...thread,
          replies: updatedReplies,
        };
      });
      updateQueryCache(updatedChatThreads);
    }
  };

  const onDeleteMessage = eventData => {
    const { id: deletedMessageId, parent_message } = eventData || {};
    const { id: parentMessageId, replies_count: repliesCount } =
      parent_message || {};

    const isDeletedThread = chatThreads.some(
      thread => thread.parent_message.id === deletedMessageId,
    );

    if (isDeletedThread) {
      updateQueryCache(
        chatThreads.filter(
          thread => thread.parent_message.id !== deletedMessageId,
        ),
      );
    } else if (repliesCount === 0) {
      updateQueryCache(
        chatThreads.filter(
          thread => thread.parent_message.id !== parentMessageId,
        ),
      );
    } else {
      const updatedChatThreads = chatThreads.map(thread => {
        if (thread.parent_message.id !== parentMessageId) return thread;

        const { replies } = thread;
        const updatedReplies = replies.filter(
          reply => reply.id !== deletedMessageId,
        );
        return {
          ...thread,
          parent_message,
          replies: updatedReplies,
        };
      });
      updateQueryCache(updatedChatThreads);
    }
  };

  const isChatThreadsAvailable = chatThreads.length > 0;

  return {
    chatThreads,
    setChatThreads: updateQueryCache,
    isLoading,
    refetchChatThreads,
    isChatThreadsAvailable,
    hasNextPage,
    fetchNextPage,
    onEventReceive,
    updateChatThreads,
  };
};
