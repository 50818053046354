import { t } from "@/i18n-js/instance";
import { getFrequencyOptions } from "../DetailsForm/EventTimeLocation/hooks/useRecurringSettings";

export const useRecurringFrequencyLabel = ({
  startsAt,
  frequency,
  firstLetterCapital = true,
  frequencyFirstLetterCapital = false,
  includeRepeatsOn = true,
}) => {
  if (!startsAt || !frequency) {
    return {
      label: "",
    };
  }

  const frequencyOptions = getFrequencyOptions({
    startsAt,
    firstLetterCapital: frequencyFirstLetterCapital,
  });

  const frequencyValue = frequencyOptions.find(f => f.value === frequency);
  const repeatsLabel = t(
    `events.recurring.${
      firstLetterCapital ? "frequencies_capital_letter" : "frequencies"
    }.repeats`,
  );

  const label =
    frequencyValue && includeRepeatsOn
      ? `${repeatsLabel} ${frequencyValue.label}`
      : frequencyValue.label;

  return {
    label,
  };
};
