import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = () => {
  const history = useHistory();
  const goBack = () => history.push("/");
  return (
    <div className="border-primary bg-primary sticky top-0 z-10 flex items-center justify-between border-b px-5 py-3.5 sm:rounded-t-2xl">
      <div className="flex items-center space-x-2">
        <IconButton
          name="20-arrow-left"
          iconSize={20}
          className="!p-0"
          iconClassName="!h-5 !w-5"
          onClick={goBack}
          ariaLabel={t("previous")}
        />
        <Typography.TitleSm weight="semibold">
          {t("quick_post_v2.settings")}
        </Typography.TitleSm>
      </div>
      <Button type="button" onClick={goBack} variant="circle">
        {t("quick_post_v2.done")}
      </Button>
    </div>
  );
};
