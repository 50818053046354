import { t } from "@/i18n-js/instance";
import { useCommunityPlanAddon } from "@circle-react/hooks/addons/useCommunityPlanAddon";
import { useToast } from "@circle-react-uikit/ToastV2";

const RECORDING_HOURS_ADDON_SLUG = "live_stream_recording_hours";

export const useRecordingHoursAddon = (
  onSuccess,
  options = { enabled: true },
) => {
  const { error } = useToast();
  const onPurchaseError = () =>
    error(t("live_streams.room.recording_hours_addon_error"));
  return useCommunityPlanAddon(
    RECORDING_HOURS_ADDON_SLUG,
    onPurchaseError,
    onSuccess,
    options,
  );
};
