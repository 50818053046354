import { useWebSocket } from "@/react/hooks/useWebSocket";

export interface UseTypingWebSocketProps {
  enabled: boolean;
  chatRoomUuid: string;
  isThread: boolean;
  currentCommunityMember: any;
  onMessageReceive: (message: ChannelMessage) => void;
}

export interface ChannelMessage {
  event: string;
  chat_room_participant_id: number;
  chat_room_participant_name: string;
  parent_message_id: number;
}

const THREADS_CHANNEL = "Chats::CommunityMemberThreadsChannel";
const ROOM_CHANNEL = "Chats::RoomChannel";

export const useTypingWebSocket = ({
  enabled,
  chatRoomUuid,
  isThread,
  currentCommunityMember,
  onMessageReceive,
}: UseTypingWebSocketProps) => {
  const channelName = isThread ? THREADS_CHANNEL : ROOM_CHANNEL;
  const subscriptionParams = isThread
    ? { community_member_id: currentCommunityMember?.id }
    : { chat_room_uuid: chatRoomUuid };
  const doesSubscriptionParamsExist = isThread
    ? Boolean(currentCommunityMember?.id)
    : Boolean(chatRoomUuid);
  const canCreateConnection = enabled && doesSubscriptionParamsExist;

  const webSocketConfig = {
    channel: channelName,
    canCreateConnection: canCreateConnection,
    ...subscriptionParams,
    onMessageReceive,
  };

  const subscriptionObj = useWebSocket(webSocketConfig);
  const subscription = subscriptionObj?.subscription;

  return { subscription };
};
