import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import type { Post } from "@/react/types/Post";
import { ALL_COMMENTS_ANCHOR } from "@circle-react/components/CommentsV3/constants";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { isPostDetailsPage } from "@circle-react/helpers/isPostDetailsPage";
import { isSharedPostView } from "@circle-react/helpers/postHelpers";
import { posts, postsPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import type { Event } from "@circle-react/types/Event";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";

interface ShowPageLinkProps extends ComponentPropsWithoutRef<"a"> {
  post: Post | Event;
  allComments?: boolean;
  forwardedRef?: any;
  children?: ReactNode;
  ariaLabel?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  shouldOpenPostModal?: boolean;
}

interface RouteParams {
  postSlug: string;
}

export const ShowPageLink = ({
  post,
  ariaLabel,
  allComments = false,
  forwardedRef,
  onClick,
  children,
  shouldOpenPostModal = false,
  ...passThroughProps
}: ShowPageLinkProps) => {
  const profileContext = useProfileContext();
  const profileContextMember = profileContext.member;
  const isWithinProfileContext = !!profileContextMember;

  const { postSlug: postSlugInUrl } = useParams<RouteParams>();
  const isIframeEmbed = useIsIframeEmbed();
  const { isInsideModal, onClose } = useModalData();

  const location = useLocation();
  const { params, hasNextPage } = usePostNavigationContext();

  const shouldShowPostModal = !isPostDetailsPage();

  const postHref = postsPath({
    spaceSlug: post.space_slug,
    slug: post.slug,
    hash: allComments ? ALL_COMMENTS_ANCHOR : "",
  });

  if (isIframeEmbed) {
    return (
      <a
        href={postHref}
        target="_blank"
        rel="noreferrer"
        aria-label={ariaLabel}
        {...passThroughProps}
        {...(forwardedRef && { ref: forwardedRef })}
      >
        {children}
      </a>
    );
  }

  if (isSharedPostView(post, { postSlugInUrl })) {
    // Using this to do a hard reload to handle authorization on the backend for now
    return (
      <a
        href={postHref}
        aria-label={ariaLabel}
        {...passThroughProps}
        {...(forwardedRef && { ref: forwardedRef })}
      >
        {children}
      </a>
    );
  }

  if (shouldOpenPostModal && shouldShowPostModal) {
    return (
      <Link
        to={{
          pathname: posts.show({
            slug: post.slug,
            spaceSlug: post.space_slug,
          }),
          state: {
            prevLocation: location,
            params,
            hasNextPage,
            isWithinProfileContext,
            profileContextMember,
          },
        }}
        aria-label={ariaLabel}
        {...passThroughProps}
        {...(forwardedRef && { ref: forwardedRef })}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      to={postHref}
      aria-label={ariaLabel}
      {...passThroughProps}
      {...(forwardedRef && { ref: forwardedRef })}
      onClick={isInsideModal ? onClose : onClick}
    >
      {children}
    </Link>
  );
};
