import { useHistory, useLocation } from "react-router-dom";
import { getAdminAudienceEditPathsWithStateV2 } from "@circle-react/helpers/urlHelpers";

export const useNavigateToAudienceEdit = () => {
  const location = useLocation();
  const history = useHistory();

  return ({ sqid }: { sqid: string }) => {
    history.push(
      getAdminAudienceEditPathsWithStateV2({
        sqid,
        previousLocation: location,
      }).index,
    );
  };
};
