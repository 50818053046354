import { useEffect } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import type { UseFormReturn } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { useCommunityMemberReply } from "./useCommunityMemberReply";
import { useEditorFocus } from "./useEditorFocus";
import { useEditorState } from "./useEditorState";
import { useFocusChangeHandler } from "./useFocusChangeHandler";

interface UseEditorInteractionsProps {
  editorRef?: RefObject<Editor>;
  autoFocus?: boolean;
  communityMemberForReply: any;
  currentCommunityMember: any;
  parentCommentId?: number;
  usedIn: any;
  toolbarPortalId: string;
  addToLocalSgidToObjectMap: (sgidToObjectMap: object) => void;
}

const hasTriggerFormSubmit = (
  form: UseFormReturn<any>,
): form is UseFormReturn<any> & { triggerFormSubmit: () => void } =>
  "triggerFormSubmit" in form;

export const useEditorInteractions = ({
  editorRef,
  autoFocus,
  parentCommentId,
  communityMemberForReply,
  currentCommunityMember,
  addToLocalSgidToObjectMap,
  usedIn,
  toolbarPortalId,
}: UseEditorInteractionsProps) => {
  const isUsedInMinimalPostModal = usedIn === "minimal-post-modal";
  const isReply = Boolean(parentCommentId);
  const isPostCommentFormInMinimalPostModal =
    isUsedInMinimalPostModal && !isReply;

  const {
    isEditorExpanded,
    editorKey,
    commentBoxRef,
    toolbarRef,
    formContext,
    isSubmitting,
    isSubmitDisabled,
    handleFocusChange,
    handleEditorFocus,
  } = useEditorState(isUsedInMinimalPostModal);

  const isSubmitShortcutEnabled = isPostCommentFormInMinimalPostModal
    ? isEditorExpanded && !isSubmitDisabled
    : !isSubmitDisabled;

  useEditorFocus(isPostCommentFormInMinimalPostModal, autoFocus, editorRef);

  useEffect(() => {
    toolbarRef.current = document.getElementById(toolbarPortalId);
  }, [toolbarPortalId, toolbarRef]);

  useFocusChangeHandler(
    isUsedInMinimalPostModal,
    toolbarRef,
    handleFocusChange,
  );

  useCommunityMemberReply(
    communityMemberForReply,
    currentCommunityMember,
    editorRef,
    addToLocalSgidToObjectMap,
  );

  useHotkeys(
    "meta+enter, ctrl+enter",
    () => {
      if (hasTriggerFormSubmit(formContext)) {
        formContext.triggerFormSubmit();
      }
    },
    {
      enableOnContentEditable: true,
      enabled: isSubmitShortcutEnabled,
    },
    [formContext, isSubmitShortcutEnabled],
  );

  return {
    isEditorExpanded,
    commentBoxRef,
    handleEditorFocus,
    editorKey,
    isSubmitDisabled,
    isSubmitting,
    isUsedInMinimalPostModal,
    isPostCommentFormInMinimalPostModal,
  };
};
