import PropTypes from "prop-types";
import { Dialog } from "@headlessui/react";
import classnames from "classnames";

/** @deprecated Please use a Modal component */
export const QPWrapper = ({
  open,
  onClose,
  children,
  version = 2,
  maxZIndexEnabled = false,
}) => {
  const invisibleCharacter = "&#8203;";
  return (
    <Dialog
      className={classnames(
        "z-150 standard-layout-modal fixed inset-0 md:overflow-y-auto",
        {
          "quick-post-wrapper--v2": version === 2,
        },
        { "z-2000": maxZIndexEnabled },
      )}
      open={open}
      onClose={onClose}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 transition-opacity" />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          style={{ content: invisibleCharacter }}
        />
        <div>{children}</div>
      </div>
    </Dialog>
  );
};

QPWrapper.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  version: PropTypes.number,
  children: PropTypes.node,
  maxZIndexEnabled: PropTypes.bool,
};
