import type { Post } from "@/react/types/Post";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { ShowPageLink } from "../PostViewListItem/ShowPageLink";

export const LastRepliedOrPosted = ({ post }: { post: Post }) => {
  const { last_replied_or_posted, post_details_loaded: hasPostDetailsLoaded } =
    post;

  if (!hasPostDetailsLoaded) return <SkeletonLoader variant="row" lg half />;

  if (!last_replied_or_posted) return null;

  return (
    <ShowPageLink
      post={post}
      className="!text-default focus-visible:outline-secondary inline-flex items-center gap-1 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      shouldOpenPostModal
    >
      {last_replied_or_posted.type === "replied" && (
        <Icon
          useWithFillCurrentColor
          type="arrow-solid-bottom-right"
          className="text-default !w-3.5"
          size={14}
        />
      )}
      <TippyV2
        interactive={false}
        content={getDetailedTimestamp(last_replied_or_posted.created_at)}
        className="inline-flex items-center gap-1"
      >
        <span className="meta-person-action text-xs">
          {`${last_replied_or_posted.user_name} ${last_replied_or_posted.type} `}
        </span>
        <span className="ago post--list__timestamp text-xs">
          {dateStringToTimeAgo(last_replied_or_posted.created_at)}
        </span>
      </TippyV2>
    </ShowPageLink>
  );
};
