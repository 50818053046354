import { useEffect, useState } from "react";
import type { ForwardedRef } from "react";
import { isFunction } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { usePunditUserContext } from "@circle-react/contexts";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import {
  useChatMessagesV2Api,
  useChatsV2Api,
} from "@circle-react/hooks/chatsV2";
import { scrollToMessage } from "../../helpers";
import { useDeleteMessageItemModal } from "../DeleteMessageItemModal";
import { useMessageActions } from "../hooks";

export interface UseMessageItemProps {
  chatRoom: any;
  chatThreadsEnabled?: boolean;
  currentParticipant: any;
  isInitialPage?: boolean;
  isLastMessage?: boolean;
  message: any;
  participants: any;
  prevDayRef: ForwardedRef<any>;
}

export interface UseMessageItemReturn {
  canUserModifyMessage: boolean;
  day: string;
  handleSubmit: (args: any) => void;
  id: string;
  isDayChange: boolean;
  isEditMessage: boolean;
  isViewOnlyMasquerading: boolean;
  setEditMessageId: (args: any) => void;
  shouldShowMessageActions: boolean;
  shouldShowRepliesBlock: boolean;
  toggleDeleteConfirmationModal: () => void;
}

export const useMessageItem = ({
  chatRoom,
  chatThreadsEnabled = false,
  currentParticipant,
  isInitialPage = false,
  isLastMessage = false,
  message,
  participants,
  prevDayRef,
}: UseMessageItemProps): UseMessageItemReturn => {
  const { isViewOnlyMasquerading }: any = usePunditUserContext();
  const { updateRichTextMessage }: any = useChatsV2Api();
  const { setScrollInfo, calculateScrollInfo }: any = useChatMessagesV2Api();
  const [editMessageId, setEditMessageId] = useState(null);
  const { id, sent_at, replies_count } = message;
  const { day } = formattedMessageSentAt(sent_at);

  const isDayChange = !isFunction(prevDayRef) && prevDayRef?.current !== day;

  if (prevDayRef && !isFunction(prevDayRef)) {
    prevDayRef.current = day;
  }

  const isEditMessage =
    !isViewOnlyMasquerading && id ? editMessageId === id : false;

  const deleteMessageItemModal = useDeleteMessageItemModal();

  const { canUserModifyMessage } = useMessageActions({
    message,
    chatRoom,
  });

  const shouldShowMessageActions =
    !isEditMessage && (canUserModifyMessage || chatThreadsEnabled);

  const handleEsc = () => setEditMessageId(null);

  useEffect(() => {
    if (isEditMessage && isLastMessage) {
      scrollToMessage(id);
    }
  }, [isEditMessage]);

  useHotkeys(
    "esc",
    handleEsc,
    {
      enabled: isEditMessage,
      enableOnContentEditable: true,
    },
    [isEditMessage],
  );

  const handleScrollInfo = (event: any) => {
    setScrollInfo(
      calculateScrollInfo({
        event: event,
      }),
    );
  };

  const handleSubmit = ({ body, attachments = [] }: any) => {
    updateRichTextMessage({
      chatRoomUuid: chatRoom.uuid,
      messageId: id,
      rich_text_body: {
        body,
        attachments,
      },
    });
    handleScrollInfo("messageUpdated");
    handleEsc();
  };

  const toggleDeleteConfirmationModal = () => {
    if (deleteMessageItemModal.visible) {
      void deleteMessageItemModal.hide();
    } else {
      void deleteMessageItemModal.show({
        chatRoom,
        currentParticipant,
        handleSubmit,
        isEditMessage,
        isInitialPage,
        message,
        messageId: id,
        participants,
        onConfirm: () => {
          handleScrollInfo("messageDeleted");
        },
        repliesCount: replies_count,
      });
    }
  };

  const shouldShowRepliesBlock = chatThreadsEnabled && replies_count > 0;

  return {
    canUserModifyMessage,
    day,
    handleSubmit,
    id,
    isDayChange,
    isEditMessage,
    isViewOnlyMasquerading,
    setEditMessageId,
    shouldShowMessageActions,
    shouldShowRepliesBlock,
    toggleDeleteConfirmationModal,
  };
};
