export const INTERVAL_OPTIONS = [
  "monthly",
  "annual",
  "quarter",
  "biannual",
  "biweekly",
  "weekly",
  "daily",
  "onetime",
];
