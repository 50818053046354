export const resolvePositionClassName = (
  galleryAspectRatio: number | undefined,
  containerRatio: number | undefined,
) => {
  let positionClassName: "w-full" | "h-full" | "h-full w-full" | undefined;

  if (galleryAspectRatio && containerRatio) {
    if (galleryAspectRatio > containerRatio) {
      positionClassName = "w-full";
    } else if (galleryAspectRatio < containerRatio) {
      positionClassName = "h-full";
    } else {
      positionClassName = "h-full w-full";
    }
  }

  return positionClassName;
};
