import classNames from "classnames";

export const SidebarLoader = ({
  isFullPageView,
}: {
  isFullPageView?: boolean;
}) => (
  <div
    className={classNames(
      "border-primary col-span-full w-full overflow-auto border-r p-4 md:col-span-4",
      {
        "!md:w-1/3": isFullPageView,
      },
    )}
  >
    <div className="flex flex-col items-center py-9">
      <div className="bg-tertiary h-24 w-24 animate-pulse rounded-full duration-300" />
      <div className="mt-7 flex flex-col items-center space-y-1.5">
        <div className="bg-tertiary h-4 w-32 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-4 w-48 animate-pulse rounded-md duration-300" />
      </div>
      <div className="mt-4 flex flex-col items-center space-y-2">
        <div className="bg-tertiary h-6 w-56 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-5 w-32 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-5 w-24 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary h-5 w-44 animate-pulse rounded-md duration-300" />
      </div>
      <div className="mt-8 flex items-center justify-between space-x-3">
        <div className="bg-tertiary h-[34px] w-24 animate-pulse rounded-full duration-300" />
        <div className="bg-tertiary h-[34px] w-[34px] animate-pulse rounded-full duration-300" />
      </div>
    </div>
  </div>
);
