import { canManagePost, canUpdatePost } from "@/react/helpers/postHelpers";
import { SECTIONS } from "@circle-react/components/EventsV3/Modal/utils/constants";
import { useEditEventModal } from "@circle-react/components/Modals/EditEventModal";
import { useAttendeesModal } from "../AttendeesModal";

export const useAttendeesPreview = event => {
  const {
    event_setting_attributes: eventSettingAttributes,
    event_attendees: eventAttendees,
    slug,
    space_slug: spaceSlug,
  } = event || {};
  const canManageEvent = canManagePost(event);
  const canUpdateEvent = canUpdatePost(event);
  const editEventModal = useEditEventModal();
  const attendeesModal = useAttendeesModal();
  const { records: attendees, count: totalCount } = eventAttendees || {};
  const { hide_attendees: hideAttendees, rsvp_disabled: rsvpDisabled } =
    eventSettingAttributes || {};

  if (rsvpDisabled || hideAttendees) {
    return null;
  }

  const handleAttendeesClick = () => {
    if (!spaceSlug || !slug) {
      return;
    }

    if (canUpdateEvent) {
      editEventModal.show({
        event: event,
        defaultSection: SECTIONS.people,
      });
    } else {
      attendeesModal.show({ event });
    }
  };

  return {
    attendees,
    totalCount,
    canManageEvent,
    handleAttendeesClick,
    canUpdateEvent,
  };
};
