import { EditPostLoader } from "@circle-react/components/QuickPostV2/EditPostLoader";
import type { PostForQuickPost } from "@circle-react/components/QuickPostV2/Form";
import { QuickPostV2Form } from "@circle-react/components/QuickPostV2/Form";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { usePostDetails } from "@circle-react/contexts/postsDetailsContext";
import { usePostResource } from "@circle-react/hooks/posts/usePostResource";

const POST_QUERY_CONTEXT = "edit-post-modal";

interface EditPostWrapperProps {
  post: PostForQuickPost;
  onSubmitSuccess?: () => void;
  onPostChange?: () => void;
  shouldSkipRedirectAfterSubmit?: boolean;
}

export const EditPostWrapper = ({
  post: stalePost,
  onSubmitSuccess,
  onPostChange,
  shouldSkipRedirectAfterSubmit,
}: EditPostWrapperProps) => {
  const { post, isLoading: isLoadingPost } = usePostResource({
    spaceId: stalePost.space_id,
    postSlug: stalePost.slug,
    queryOptions: { cacheTime: 0 },
    context: POST_QUERY_CONTEXT,
  });
  const { isLoading: isLoadingPostDetails } = usePostDetails(stalePost.id);

  if (isLoadingPost || isLoadingPostDetails) {
    return <EditPostLoader />;
  }

  return (
    <CurrentPostContextProvider post={post} onPostChange={onPostChange}>
      <QuickPostV2Form
        shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </CurrentPostContextProvider>
  );
};
