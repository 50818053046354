import { useMemo, useRef } from "react";
import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { PostMetaInfoRegular } from "./PostMetaInfoRegular";
import { PostMetaInfoSmall } from "./PostMetaInfoSmall";

export interface PostMetaInfoProps {
  doesPostHaveCoverImage?: boolean;
  hideAuthorHeadline?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  space?: Space;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  wrapperClassName?: string;
}

export const PostMetaInfo = ({
  doesPostHaveCoverImage = false,
  hideAuthorHeadline = false,
  isRenderedOutsideTheSpace = false,
  space,
  tagsLimit,
  tagsShowMoreLabelVariant = "numbers",
  wrapperClassName,
}: PostMetaInfoProps) => {
  const { post, isSharedPostView, posterJoinedDate } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const shouldHideMetaInfo = Boolean(post?.hide_meta_info);
  const communityMember = post?.community_member;
  const memberTags: any[] = post?.member_tags || [];
  const memberId = communityMember?.id;

  const visibleMemberTags = memberTags?.filter((memberTag: any) =>
    Boolean(memberTag?.is_public && memberTag?.display_locations?.post_bio),
  );

  const hasTags = useMemo(
    () => Boolean(memberId && visibleMemberTags?.length > 0),
    [memberId, visibleMemberTags],
  );

  const isSmScreen = useSmScreenMediaQuery();
  const containerRef = useRef<HTMLDivElement>(null);
  const pageLinkRef = useRef<HTMLAnchorElement>(null);

  if (shouldHideMetaInfo || !space) {
    return null;
  }

  const headline = isRenderedOutsideTheSpace
    ? `${t("post.posted_in")} ${space.name}`
    : communityMember?.headline;

  const isAdmin = Boolean(communityMember?.admin);
  const shouldDisplayMemberTags = !isSharedPostView && (hasTags || isAdmin);

  if (isSmScreen || usedIn === "full-post-modal") {
    return (
      <PostMetaInfoSmall
        communityMember={communityMember}
        containerRef={containerRef}
        doesPostHaveCoverImage={doesPostHaveCoverImage}
        hasTags={hasTags}
        headline={headline}
        hideAuthorHeadline={hideAuthorHeadline}
        isAdmin={isAdmin}
        pageLinkRef={pageLinkRef}
        post={post}
        shouldDisplayMemberTags={shouldDisplayMemberTags}
        tagsLimit={tagsLimit}
        tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
        visibleMemberTags={visibleMemberTags}
        wrapperClassName={wrapperClassName}
      />
    );
  }

  return (
    <PostMetaInfoRegular
      communityMember={communityMember}
      containerRef={containerRef}
      doesPostHaveCoverImage={doesPostHaveCoverImage}
      hasTags={hasTags}
      headline={headline}
      hideAuthorHeadline={hideAuthorHeadline}
      isAdmin={isAdmin}
      isSmScreen={isSmScreen}
      pageLinkRef={pageLinkRef}
      post={post}
      posterJoinedDate={posterJoinedDate}
      shouldDisplayMemberTags={shouldDisplayMemberTags}
      tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
      visibleMemberTags={visibleMemberTags}
      wrapperClassName={wrapperClassName}
    />
  );
};
