import { DEPRECATED_isValidURL } from "./isValidUrl";

export const openUrl = (url: string, target: string = "_blank") => {
  if (!window || !window.open) {
    return;
  }

  if (!DEPRECATED_isValidURL(url)) {
    return;
  }

  if (!url.match(/^https?:\/\//i)) {
    url = `http://${url}`;
  }

  const opennedWindow = window.open(url, target);

  if (opennedWindow) {
    opennedWindow.focus();
  }
};
