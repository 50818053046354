import { weekdayOccurrenceForCalendar } from "@circle-react/helpers/dateTimeHelpers";
import { getDayShortName } from "@circle-react/helpers/dateTimeHelpers/getName";
import { eventLocation } from "@circle-react/helpers/postHelpers";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import {
  EVENT_TYPES,
  LOCATION_TYPES,
  RECURRING_FREQUENCIES,
  WEEKDAYS_SHORT_NAMES,
} from "./constants";

export const addToCalendarHelper = (event, label) => {
  const {
    name: title,
    calendar_description: description,
    event_setting_attributes: eventSettings,
    rsvped_event: isAttending,
    slug,
    space_slug: spaceSlug,
    event_type: eventType,
    recurring_setting_attributes: recurringSettings,
  } = event || {};

  const {
    calendar_starts_at: startTime,
    calendar_ends_at: endTime,
    location_type: locationType,
    in_person_location,
    virtual_location_url,
    hide_location_from_non_attendees: hideLocationFromNonAttendees,
    start_date: startDate,
    event_recurrences: eventRecurrences,
    calendar_time_zone: timeZone,
  } = eventSettings || {};
  const canSeeLocation = !hideLocationFromNonAttendees || isAttending;

  const inPersonJSON = eventLocation(in_person_location);

  const isRecurring = eventType === EVENT_TYPES.RECURRING;

  const eventPath = `${window.location.origin}${postsPath({
    spaceSlug,
    slug,
  })}`;
  const liveLocation = isRecurring
    ? `${window.location.origin}/c/${spaceSlug}`
    : eventPath;

  const locations = {
    [LOCATION_TYPES.VIRTUAL]: virtual_location_url,
    [LOCATION_TYPES.IN_PERSON]: inPersonJSON?.name
      ? inPersonJSON?.name.concat(", ", inPersonJSON?.formatted_address)
      : inPersonJSON?.formatted_address,
    [LOCATION_TYPES.LIVE_STREAM]: liveLocation,
    [LOCATION_TYPES.LIVE_ROOM]: liveLocation,
    [LOCATION_TYPES.TBD]: "",
  };

  const location = canSeeLocation ? locations[locationType] : "";

  const getRecurringConfig = () => {
    switch (recurringSettings?.frequency) {
      case RECURRING_FREQUENCIES.DAILY:
        return {
          repeat: RECURRING_FREQUENCIES.DAILY,
          count: eventRecurrences,
        };
      case RECURRING_FREQUENCIES.WEEKDAY:
        return {
          repeat: RECURRING_FREQUENCIES.WEEKLY,
          count: eventRecurrences,
          byDay: WEEKDAYS_SHORT_NAMES,
        };
      case RECURRING_FREQUENCIES.WEEKLY:
        return {
          repeat: RECURRING_FREQUENCIES.WEEKLY,
          count: eventRecurrences,
          byDay: getDayShortName(startDate),
        };
      case RECURRING_FREQUENCIES.BI_WEEKLY:
        return {
          repeat: RECURRING_FREQUENCIES.WEEKLY,
          count: eventRecurrences,
          byDay: getDayShortName(startDate),
          interval: 2,
        };
      case RECURRING_FREQUENCIES.MONTHLY:
        return {
          repeat: RECURRING_FREQUENCIES.MONTHLY,
          count: eventRecurrences,
        };
      case RECURRING_FREQUENCIES.MONTHLY_WEEK_BASED:
        return {
          repeat: RECURRING_FREQUENCIES.MONTHLY,
          count: eventRecurrences,
          byDay: weekdayOccurrenceForCalendar(startDate),
        };
      case RECURRING_FREQUENCIES.ANNUALLY:
        return {
          repeat: RECURRING_FREQUENCIES.YEARLY,
          count: eventRecurrences,
        };
      default:
        return {};
    }
  };

  const recurring = isRecurring ? getRecurringConfig() : {};

  return {
    label,
    title,
    description,
    location,
    startTime,
    endTime,
    recurring,
    timeZone,
  };
};
