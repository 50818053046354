import { useLiveStreamsAddonsQuantities } from "./useLiveStreamsAddonsQuantities";
import { useLiveStreamsConfig } from "./useLiveStreamsConfig";
import { useLiveStreamsUsage } from "./useLiveStreamsUsage";

const SECONDS_IN_HOUR = 3600;

export const useUsageAndConfigLimits = () => {
  const { config, isLoading: isLoadingConfig }: any = useLiveStreamsConfig();
  const { usage, isLoading: isLoadingUsage }: any = useLiveStreamsUsage();
  const {
    refetch: refetchAddons,
    isLoading: isLoadingAddons,
    ...addons
  } = useLiveStreamsAddonsQuantities();
  const isLoading = isLoadingConfig || isLoadingUsage || isLoadingAddons;

  const monthlyHourLimit =
    parseInt(config?.live_stream_monthly_stream_hours) +
    parseInt(addons?.streamHoursAddonQuantity || 0);
  const monthlyHourUsage = (usage?.stream_duration || 0) / SECONDS_IN_HOUR;
  const storageLimit =
    parseInt(config?.live_stream_recording_hours) +
    parseInt(addons?.recordingHoursAddonQuantity || 0);
  const storageUsage = (usage?.recordings_duration || 0) / SECONDS_IN_HOUR;
  const hostLimit = config?.live_stream_max_host_capacity;
  const participantLimit =
    parseInt(config?.live_stream_max_spectator_capacity) +
    parseInt(addons?.attendeeSeatsAddonQuantity || 0);

  return {
    monthlyHourLimit,
    monthlyHourUsage,
    storageLimit,
    storageUsage,
    hostLimit,
    participantLimit,
    isLoading,
    refetchAddons,
  };
};
