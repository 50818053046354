import classNames from "classnames";
import { isNumber } from "lodash";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const TabItem = ({
  title,
  isActive,
  onClick,
  count,
}: {
  title: string;
  count?: number;
  isActive?: boolean;
  onClick?: () => void;
}) => (
  <button
    type="button"
    className={classNames(
      "bg-primary border-primary hover:bg-secondary focus-visible:outline-secondary text-darkest cursor-pointer rounded-full border px-4 py-2 text-sm transition-colors duration-75 focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
      {
        "border-secondary": isActive,
      },
    )}
    onClick={onClick}
  >
    <Typography.LabelSm color="text-darkest" weight="medium">
      {title}
      {isNumber(count) && count > 0 && <span className="ml-1">{count}</span>}
    </Typography.LabelSm>
  </button>
);
