import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface AttendeeProps {
  name: string;
  avatarUrl: string;
  email: string;
  rsvpDate: string;
}

export const Attendee = ({
  name,
  avatarUrl,
  email,
  rsvpDate,
}: AttendeeProps) => {
  const formattedDate = formatDateString(rsvpDate, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <tr>
      <td className="flex items-center pt-6">
        <div className="mr-4">
          <UserImage name={name} src={avatarUrl} size="8" />
        </div>
        <Typography.LabelSm weight="semibold" color="text-dark">
          {name}
        </Typography.LabelSm>
      </td>
      <td className="hidden pt-6 md:[display:revert]">
        <Typography.LabelSm color="text-light">{email}</Typography.LabelSm>
      </td>
      <td className="pt-6">
        <Typography.LabelSm color="text-light">
          {formattedDate}
        </Typography.LabelSm>
      </td>
    </tr>
  );
};
