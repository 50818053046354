import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isPostDetailsPage } from "@circle-react/helpers/isPostDetailsPage";
import { toLocaleString } from "@circle-react/helpers/number";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { useNavigateToPost } from "../PostModal/hooks/useNavigateToPost";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";

interface CommentCountLinkProps {
  count: number;
  toggleComment: () => void;
  hasPostDetailsLoaded: boolean;
  shouldForceSamePageInteractions?: boolean;
}

export const CommentCountLink = ({
  count,
  toggleComment,
  hasPostDetailsLoaded,
  shouldForceSamePageInteractions = false,
}: CommentCountLinkProps) => {
  const { post } = useCurrentPostHelpers();
  const location = useLocation();
  const { navigateToPost } = useNavigateToPost();
  const { params, hasNextPage } = usePostNavigationContext();

  const shouldShowPostModal = !isPostDetailsPage();

  if (!hasPostDetailsLoaded) {
    return <SkeletonLoader variant="row" lg long />;
  }

  return (
    <button
      type="button"
      className={classNames(
        "hover:!text-dark focus-visible:outline-secondary text-default p-0 text-sm font-medium transition-colors duration-150 ease-in-out focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
      )}
      data-testid="post-comment-count"
      onClick={() => {
        shouldShowPostModal && !shouldForceSamePageInteractions
          ? navigateToPost({
              newPost: post,
              prevLocation: location,
              params,
              hasNextPage,
              shouldFocusOnCommentsSection: true,
            })
          : toggleComment();
      }}
    >
      {t("post.comments", { count, formatted_count: toLocaleString(count) })}
    </button>
  );
};
