import PropTypes from "prop-types";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2/useChatParticipantsApi";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { Rail } from "@circle-react-uikit/Rail";
import { Content } from "./Content";
import { Header } from "./Header";
import "./styles.scss";

const RailBar = ({ space }) => {
  const {
    chatParticipants,
    isLoading: isChatParticipantsLoading,
    refetchChatParticipants,
    hasNextPage,
    fetchNextPage,
    totalCount,
  } = useChatParticipantsApi(space.chat_room_uuid);
  const { toggleShowRailBar } = useRailbar();
  const { ref: headerRef, height: headerHeight } = useElementHeight();

  return (
    <RailBarPortal
      isLoading={isChatParticipantsLoading}
      loaderVariant="rail-member-list"
    >
      <Rail.Header className="mb-4 border-none !p-0 sm:!px-6 md:mb-0 md:!py-4">
        <div ref={headerRef} className="w-full">
          <Header
            shouldHideMembersCount={space.hide_members_count}
            memberCount={totalCount}
            description={space.chat_room_description}
            onClose={toggleShowRailBar}
          />
        </div>
      </Rail.Header>
      <Rail.Content heightGap={headerHeight} noPadding>
        <Content
          spaceMembers={chatParticipants}
          hasMoreSpaceMembers={hasNextPage}
          space={space}
          refetchSpaceMembers={refetchChatParticipants}
          fetchNextPage={fetchNextPage}
        />
      </Rail.Content>
    </RailBarPortal>
  );
};

RailBar.propTypes = {
  space: PropTypes.object,
};

export { RailBar };
