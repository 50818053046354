import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  buildTransformInputValueToCurrencyAmountStrFunc,
  mapCurrencyAmountStrToInt,
} from "@circle-react/components/Paywalls/Admin/utils";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import type { PaywallCurrency } from "@circle-react/types";
import { Form } from "@circle-react-uikit/Form";

export interface PriceFieldProps {
  name: string;
  labelKey?: string;
  placeholder?: string;
  currency?: PaywallCurrency;
  isDisabled?: boolean;
}

export const PriceField = ({
  name,
  labelKey,
  placeholder,
  currency,
  isDisabled,
}: PriceFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  if (!currency) {
    return null;
  }

  const mapAmountIntToCurrencyAmountStr =
    buildTransformInputValueToCurrencyAmountStrFunc(currency, {
      amountInCents: true,
    });

  const amountErrorMessage = t(
    "settings.paywalls.modal.tabs.pricing.add_price_modal.field_errors.amount",
    {
      min: formatPaywallCurrencyAmount(currency, currency.min_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
      max: formatPaywallCurrencyAmount(currency, currency.max_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
    },
  );

  return (
    <Form.Item
      name={name}
      className={classNames({ "border-red-500": error }, "m-0 grow")}
      label={t(`events.create.form.paywall_attributes.${labelKey}`)}
      placeholder={placeholder || mapAmountIntToCurrencyAmountStr(0)}
      rules={{
        validate: {
          bounds: value => {
            const valueInt = mapCurrencyAmountStrToInt(value);
            const isValid =
              !isNaN(valueInt) &&
              valueInt >= currency?.min_charge_amount &&
              valueInt <= currency?.max_charge_amount;
            return isValid || amountErrorMessage;
          },
        },
      }}
      transformValue={mapAmountIntToCurrencyAmountStr}
      labelClassName="mb-2 text-sm font-semibold leading-5 tighter normal-case text-dark"
      labelWrapperClassName="m-0"
      errorClassName="mt-1 text-red-500 text-sm"
      hideDescription
      hideBorder
    >
      <Form.Input data-testid="ticket-price" disabled={isDisabled} />
    </Form.Item>
  );
};
