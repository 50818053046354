import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { t } from "@/i18n-js/instance";
import { dropdowListInOut } from "@circle-react/components/shared/uikit/animations";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { CheckIcon } from "../RsvpIcons";
import { MenuLabel } from "./MenuLabel";
import { MenuOption } from "./MenuOption";
import "./styles.scss";

export interface RsvpRadioProps {
  confirmed: boolean;
  full: boolean;
  toggleRsvp: () => void;
  loading: boolean;
  setShouldShowUnrsvpConfirmation: (value: boolean) => void;
  showUnrsvpPopup: boolean;
}

export const RsvpRadio = ({
  confirmed,
  full,
  toggleRsvp,
  loading,
  setShouldShowUnrsvpConfirmation,
  showUnrsvpPopup,
}: RsvpRadioProps) => {
  const attendingLabel = t("events.rsvp.attending");
  const notAttendingLabel = t("events.rsvp.not_attending");

  const { hasSelfUpdateCalendarEnabled } = useEventsFeatureFlags();
  const handleSelect = (attending: boolean) => {
    if (attending !== confirmed) {
      if (!attending && showUnrsvpPopup) {
        setShouldShowUnrsvpConfirmation(true);
      } else {
        toggleRsvp();
      }
    }
  };

  return (
    <div className={classNames("rsvp-radio", { "rsvp-radio--full": full })}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                "rsvp-radio__button !border-primary !rounded-2xl",
                {
                  "rsvp-radio__button--open": open,
                  "!rounded-full": hasSelfUpdateCalendarEnabled,
                },
              )}
            >
              {confirmed ? (
                <MenuLabel label={attendingLabel} renderIcon={<CheckIcon />} />
              ) : (
                <MenuLabel label={notAttendingLabel} />
              )}
              <span className="button-icon-container">
                <ChevronDownIcon className="button-icon" aria-hidden="true" />
              </span>
            </Menu.Button>

            <Transition as={Fragment} show={open} {...dropdowListInOut}>
              <Menu.Items static className="rsvp-radio__list">
                <MenuOption
                  key="rsvp-attending"
                  label={attendingLabel}
                  value={confirmed}
                  disabled={loading}
                  onClick={() => handleSelect(true)}
                />
                <MenuOption
                  key="rsvp-not-attending"
                  label={notAttendingLabel}
                  value={!confirmed}
                  disabled={loading}
                  onClick={() => handleSelect(false)}
                />
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
