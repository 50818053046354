import PropTypes from "prop-types";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Checkbox } from "@circle-react-uikit/Form/Checkbox";

export const Row = ({ id, name, date, time, value, onChange }) => (
  <li>
    <label className="m-0 flex items-center justify-between pr-1" htmlFor={id}>
      <div className="leading-none">
        <div>
          <Typography.LabelSm weight="semibold">{date}</Typography.LabelSm>
        </div>
        <div className="text-gray-500">
          <Typography.LabelXs>{time}</Typography.LabelXs>
        </div>
      </div>
      <Checkbox
        id={id}
        name={name}
        isFormItem={false}
        checked={value}
        onChange={e => onChange({ name, value: e.target.checked })}
        /* TODO Review props */
        option={{
          value: "",
          label: "",
        }}
      />
    </label>
  </li>
);

Row.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  value: PropTypes.string,
  time: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
