import { useImagePostModalStore } from "../../store";

export const useRemoveImage = () => {
  const { setUploadedImages, uploadedImages, setIsImagesDirty } =
    useImagePostModalStore();
  const removeImage = (image: any) => {
    const newUploadedImages = uploadedImages.filter(
      uploadedImage => uploadedImage !== image,
    );
    setUploadedImages(newUploadedImages);
    setIsImagesDirty(true);
  };

  return { removeImage };
};
