import { useEffect, useRef } from "react";
import {
  addSubscription,
  removeSubscription,
} from "../../channels/subscriptionManager";

export const useWebSocket = (
  {
    channel,
    onMessageReceive,
    canCreateConnection = true, // If we don't want to create a connection while certain conditions are not met, ex: community_member is not present?
    ...passThroughParams
  },
  deps = [],
) => {
  const onMessageReceiveRef = useRef(onMessageReceive);
  const subscriptionsByChannelRef = useRef({});

  useEffect(() => {
    onMessageReceiveRef.current = onMessageReceive;
  }, [channel, onMessageReceive]);

  useEffect(() => {
    if (!canCreateConnection) {
      return;
    }

    const subscriptionObj = addSubscription(
      { channel, ...passThroughParams },
      onMessageReceiveRef.current,
    );
    if (subscriptionObj) {
      subscriptionsByChannelRef.current = {
        ...subscriptionsByChannelRef.current,
        [channel]: subscriptionObj,
      };
    }

    return () => {
      removeSubscription(
        { channel, ...passThroughParams },
        onMessageReceiveRef.current,
      );
      subscriptionsByChannelRef.current[channel] = null;
    };
  }, [channel, canCreateConnection, passThroughParams, deps]);

  return subscriptionsByChannelRef.current[channel];
};
