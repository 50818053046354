import PropTypes from "prop-types";
import { useThreadsStore } from "@/react/hooks/chatsV2/store";
import { useChatThreadsApi } from "@circle-react/hooks/chatsV2/useChatThreadsApi";
import { TwoWayPagination } from "@circle-react-shared/Pagination/TwoWayPagination";

export const ChatThreadsBody = ({
  children,
  isLastPage,
  isFirstPage,
  setIsFirstPage,
  setIsLastPage,
  chatRoomUuid,
  parentMessage,
}) => {
  const { fetchChatMessages } = useChatThreadsApi();
  const { hasNextPage, hasPrevPage, isLoading, firstMessageId, lastMessageId } =
    useThreadsStore();
  return (
    <TwoWayPagination
      shouldShowPrevLoader={!isLastPage}
      shouldShowNextLoader={!isFirstPage}
      hasNextPage={hasNextPage}
      hasPrevPage={hasPrevPage}
      onNextPage={messageId => {
        fetchChatMessages({
          chatRoomUuid,
          id: messageId,
          previousPerPage: 0,
          nextPerPage: 20,
          parentMessage,
        });
        setIsFirstPage(false);
      }}
      onPrevPage={messageId => {
        fetchChatMessages({
          chatRoomUuid,
          id: messageId,
          parentMessage,
        });
        setIsLastPage(false);
      }}
      firstItemId={firstMessageId}
      lastItemId={lastMessageId}
      componentId="message-thread-scroll-view"
      isLoading={isLoading}
    >
      {children}
    </TwoWayPagination>
  );
};

ChatThreadsBody.propTypes = {
  isLastPage: PropTypes.bool.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
  setIsFirstPage: PropTypes.func.isRequired,
  setIsLastPage: PropTypes.func.isRequired,
  chatRoomUuid: PropTypes.string.isRequired,
  parentMessage: PropTypes.object,
  children: PropTypes.node,
};
