import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface UsageLimits {
  streaming_limit_reached: boolean;
  recording_limit_reached: boolean;
}

export const useLiveStreamsUsageLimits = () => {
  const { data: usageLimits, isLoading } = useQuery<UsageLimits>(
    internalApi.liveStreams.usageLimit(),
  );

  return {
    isLoading,
    isMonthlyHourLimitReached: usageLimits?.streaming_limit_reached,
    isStorageLimitReached: usageLimits?.recording_limit_reached,
  };
};
