import type { Comment } from "@/react/types/Comment";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export interface MemberAvatarProps {
  comment: Comment;
}

export const MemberAvatar = ({ comment }: MemberAvatarProps) => {
  const { community_member } = comment;

  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid ?? "",
    params: { show_back_link: true },
  });

  return (
    <div className="post__user flex items-start">
      <div className="post__avatar mr-3">
        <a
          className="user__avatar focus-visible:outline-secondary focus-visible:text-dark block h-10 w-10 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          {...profileLinkProps}
        >
          <ProfileHoverCard
            communityMemberId={community_member.id}
            name={community_member.name}
            publicUid={community_member.public_uid}
            headline={community_member.headline}
            avatarURL={community_member.avatar_url}
          >
            <UserImage
              src={community_member.avatar_url ?? ""}
              name={community_member.name ?? ""}
              size="10"
            />
          </ProfileHoverCard>
        </a>
      </div>
    </div>
  );
};
