import { useEffect, useMemo, useState } from "react";
import { defer, isNull, last, sortBy, uniqBy } from "lodash";
import { t } from "@/i18n-js/instance";
import { useThreadsStore } from "@/react/hooks/chatsV2/store";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import type { ChatRoom } from "@/react/types/Chat/ChatRoom";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";
import {
  useBookmarkToggle,
  useCanSendMessage,
  useChatParticipantsApi,
  useChatThreadReaction,
  useChatThreadsApi,
  useRailbar,
  useThreads,
  useUpdateMissingParticipants,
} from "@circle-react/hooks/chatsV2";
import { useChatRoomContext } from "@circle-react/hooks/chatsV2/useChatRoomContext";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { ChatBottomBanner } from "../../ChatBottomBanner";
import { MessageItem } from "../../ChatWindowV2/MessageItem/ThreadItem";
import {
  findMissingParticipantIds,
  getMessageHashFromUrl,
  scrollToMessage,
} from "../../ChatWindowV2/helpers";
import { RichTextMessageBox } from "../../sharedV2";
import { ChatThreadsBody } from "./ChatThreadBody";
import { RepliesMessageLine } from "./RepliesMessageLine";

interface ContentProps {
  parentMessage: ChatRoomMessage;
  chatRoom: ChatRoom;
}

export const Content = ({ parentMessage, chatRoom }: ContentProps) => {
  const [isLastPage, setIsLastPage] = useState(true);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [newRepliesCount, setNewRepliesCount] = useState(0);
  const [isUnreadMessageLineEnabled, setIsUnreadMessageLineEnabled] =
    useState(false);
  const { removeThreadIdUrlParam } = useThreads();
  const { chatParticipants: participants } = useChatParticipantsApi(
    chatRoom.uuid,
  );
  const { data: space } = useCurrentSpaceContext();
  const {
    chatMessages,
    setChatMessages,
    hasPrevPage,
    hasNextPage,
    isLoading,
    totalMessagesCount,
    metaData,
  } = useThreadsStore();

  const {
    fetchChatMessages,
    onEventReceive: onMessagesEventReceive,
    adjustScroller,
    isInitialPageLoaded,
  } = useChatThreadsApi();

  const { handleReactionFromWebsocket } = useChatThreadReaction();

  const onBookmarkToggle = useBookmarkToggle(chatMessages, setChatMessages);

  const { messageHighlightId, isLiveStream } = useRailbar();
  const { isMessaging } = useChatRoomContext();
  const messageHashId = getMessageHashFromUrl();
  const isThreadMessage = Number(messageHashId) !== messageHighlightId;
  const chatRoomUrl = isMessaging ? chatRoomPath(chatRoom) : "";

  const { current_participant } = chatRoom;
  const { canSendMessage, canParticipantsMessage } =
    useCanSendMessage(chatRoom);

  useEffect(() => {
    const fetchOptions = {
      chatRoomUuid: chatRoom.uuid,
      isInitialPage: true,
      parentMessage,
      id: null,
      nextPerPage: 0,
    };
    if (messageHighlightId && isThreadMessage) {
      fetchOptions.id = messageHighlightId;
      fetchOptions.nextPerPage = 20;
    }
    void fetchChatMessages(fetchOptions);

    return () => setNewRepliesCount(0);
  }, [parentMessage, chatRoom?.uuid]);

  useWebSocket(
    {
      channel: "Chats::RoomChannel",
      chat_room_uuid: chatRoom?.uuid,
      onMessageReceive: (data: any) => {
        const { event, json_message } = data;
        if (event === "newMessage") {
          if (
            !json_message?.parent_message_id ||
            json_message.parent_message_id !== parentMessage.id
          )
            return;
          setNewRepliesCount(count => count + 1);
        } else if (event === "deletedMessage") {
          setNewRepliesCount(count => count - 1);
        }

        onMessagesEventReceive(data, chatMessages, current_participant);
      },
      canCreateConnection: Boolean(chatRoom?.uuid),
    },
    [chatRoom?.uuid],
  );

  useEffect(() => {
    if (isInitialPageLoaded && messageHighlightId && isThreadMessage) {
      scrollToMessage(`thread-${messageHighlightId}`);
    }
  }, [isInitialPageLoaded, messageHighlightId, isThreadMessage]);

  useEffect(() => {
    if (isInitialPageLoaded && (!messageHighlightId || !isThreadMessage)) {
      defer(adjustScroller);
    }
  }, [chatMessages, isInitialPageLoaded, messageHighlightId]);

  const uniqChatRoomMessages = uniqBy(chatMessages, "id");
  const orderedChatRoomMessages = sortBy(uniqChatRoomMessages, "sent_at");

  const { last_read_message_id: lastReadMessageId } = metaData || {};
  const lastMessage: any = last(orderedChatRoomMessages);

  const missingParticipantIds = useMemo(
    () => findMissingParticipantIds(uniqChatRoomMessages, participants),
    [uniqChatRoomMessages, participants],
  );

  useUpdateMissingParticipants({
    uuid: chatRoom.uuid,
    ids: missingParticipantIds,
    enabled: Boolean(participants.length && missingParticipantIds.length),
  });

  useEffect(() => {
    if (!isLoading && isInitialPageLoaded) {
      const doesLastReadMessageIdExists = !isNull(lastReadMessageId);
      const isLastReadMessageIdNotLastMessageId =
        lastReadMessageId !== lastMessage?.id;
      setIsUnreadMessageLineEnabled(
        doesLastReadMessageIdExists && isLastReadMessageIdNotLastMessageId,
      );
    }
  }, [lastReadMessageId, isInitialPageLoaded, parentMessage.id, isLoading]);

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: (data: any) => {
        if (
          data.event === "createReaction" ||
          data.event === "destroyReaction"
        ) {
          handleReactionFromWebsocket(data);
        }
      },
      community_member_id: current_participant?.community_member_id,
      canCreateConnection: Boolean(current_participant?.community_member_id),
    },
    [current_participant?.community_member_id],
  );

  const repliesCount = totalMessagesCount + newRepliesCount;

  if (!isInitialPageLoaded) {
    return (
      <div id="message-thread-scroll-view">
        <SkeletonLoader variant="chat-thread" />
      </div>
    );
  }

  return (
    <div id="scrollWrapper" className="flex h-full grow flex-col border-r-0">
      <ChatThreadsBody
        isLastPage={isLastPage}
        isFirstPage={isFirstPage}
        setIsLastPage={setIsLastPage}
        setIsFirstPage={setIsFirstPage}
        chatRoomUuid={chatRoom.uuid}
        parentMessage={parentMessage}
      >
        {!hasPrevPage && (
          <>
            <MessageItem
              message={parentMessage}
              currentParticipant={current_participant}
              chatRoom={chatRoom}
              participants={participants}
              isLiveStream={isLiveStream}
              isParentMessage
              chatThreadsEnabled
              chatRoomUrl={chatRoomUrl}
            />
            {repliesCount > 0 && <RepliesMessageLine count={repliesCount} />}
          </>
        )}
        {orderedChatRoomMessages.map((message: any) => (
          <MessageItem
            key={message.id}
            message={message}
            currentParticipant={current_participant}
            chatRoom={chatRoom}
            chatRoomUrl={chatRoomUrl}
            chatThreadsEnabled
            isUnreadMessageLineEnabled={isUnreadMessageLineEnabled}
            lastReadMessageId={lastReadMessageId}
            participants={participants}
            isLiveStream={isLiveStream}
            onBookmarkToggle={onBookmarkToggle}
          />
        ))}
        {!hasNextPage &&
          (canSendMessage ? (
            <RichTextMessageBox
              chatRoom={chatRoom}
              parentMessageId={parentMessage.id}
              onClick={removeThreadIdUrlParam}
              spaceId={space?.id}
              portalTargetId="rail-content"
              participants={participants}
              messageBoxId={"threadsMessageTextBox" + String(parentMessage.id)}
            />
          ) : (
            <ChatBottomBanner className="mt-2">
              {canParticipantsMessage
                ? t("community_member.join_space_to_message")
                : t("community_member.messaging_disabled")}
            </ChatBottomBanner>
          ))}
      </ChatThreadsBody>
    </div>
  );
};
