import PropTypes from "prop-types";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { MobileEventsModalHeader } from "../../MobileEventsHeader";
import { Actions } from "./Actions";
import { Sections } from "./Sections";
import { Title } from "./Title";

export const EventsHeader = ({
  isOpen,
  isEditMode,
  isCreating,
  event,
  onPublish,
  validateAndSetCurrentSection,
  handleClose,
}) => {
  const actionsProps = {
    event,
    isEditMode,
    onPublish,
  };
  const isSmScreen = useSmScreenMediaQuery();

  if (isSmScreen) {
    return (
      <MobileEventsModalHeader
        isOpen={isOpen}
        isEditMode={isEditMode}
        handleClose={handleClose}
      />
    );
  }

  return (
    <FullScreenModal.HeaderContent>
      <Title
        event={event}
        isCreating={isCreating}
        isEditMode={isEditMode}
        actionsProps={actionsProps}
      />
      {isEditMode && (
        <>
          <FullScreenModal.HeaderContent.Navigation>
            <Sections
              event={event}
              onPublish={onPublish}
              onChangeSection={validateAndSetCurrentSection}
            />
          </FullScreenModal.HeaderContent.Navigation>
          <Actions {...actionsProps} className="hidden lg:flex" />
        </>
      )}
    </FullScreenModal.HeaderContent>
  );
};

EventsHeader.propTypes = {
  isOpen: PropTypes.bool,
  isEditMode: PropTypes.bool.isRequired,
  validateAndSetCurrentSection: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  event: PropTypes.object,
  isCreating: PropTypes.bool,
};
