import { noop } from "lodash";
import { useImagePostModalStore } from "../../store";

export const useHandleFileSelection = (callback = noop) => {
  const { addSelectedFiles } = useImagePostModalStore();
  const handleFileSelection = (files: any) => {
    files = files.map((file: any) => ({
      file,
      preview: URL.createObjectURL(file),
      crop: { x: 0, y: 0 },
      zoom: 1,
    }));
    addSelectedFiles(files);
    callback();
  };

  return {
    handleFileSelection,
  };
};
