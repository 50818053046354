import { t } from "@/i18n-js/instance";
import type { FlaggedContent } from "@circle-react/types";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot =
  "settings.moderation.review_flagged_content_modal.visibility_info";

interface VisibilityInfoProps {
  content?: FlaggedContent;
}

export const VisibilityInfo = ({ content }: VisibilityInfoProps) => {
  if (!content?.flagged_contentable) {
    return null;
  }

  const { flagged_for_approval_at, removed_at } = content.flagged_contentable;

  const visibilityMessage =
    flagged_for_approval_at || removed_at
      ? t([i18nRoot, "hidden_until_approved"])
      : t([i18nRoot, "visible_to_all_members"]);

  return (
    <div className="flex items-center gap-4 px-8 py-0">
      <Typography.LabelSm>{visibilityMessage}</Typography.LabelSm>
    </div>
  );
};
