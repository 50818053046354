import { usePunditUserContext } from "@circle-react/contexts";

export const useEventsFeatureFlags = () => {
  const { currentCommunitySettings } = usePunditUserContext();

  return {
    hasEventsThumbnailEnabled:
      !!currentCommunitySettings?.events_thumbnail_enabled,
    hasLimitUrlSharingEnabled:
      !!currentCommunitySettings?.limit_url_sharing_enabled,
    hasSelfUpdateCalendarEnabled:
      !!currentCommunitySettings?.self_updating_calendar_invites_enabled,
    hasAutoRsvpInvitedAttendeesEnabled:
      !!currentCommunitySettings?.auto_rsvp_invited_attendees_enabled,
  };
};
