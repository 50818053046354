import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useRecurringSettings } from "@circle-react/components/Events/DetailsForm/EventTimeLocation/hooks/useRecurringSettings";
import { RECURRING_ENDING } from "@circle-react/components/Events/helpers/constants";
import {
  browserTimeZone,
  getTimezoneShort,
} from "@circle-react/helpers/dateTimeHelpers";
import { DateRangePicker } from "@circle-react-uikit/DateRangePicker";
import { DateTimePicker } from "@circle-react-uikit/DateTimePicker";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common";
import {
  hasEventEnded,
  isDraft,
  isPublished,
  isRecurring,
} from "../../../utils";

export const DateAndTime = ({ event }) => {
  const {
    endsAtMaxDate,
    endsAfterMaxOccurrences,
    frequencyOptions,
    endingOptions,
    endsAtMinDate,
    endsOnValue,
    showRecurringFields,
  } = useRecurringSettings();

  const isDateFieldDisabled = event && hasEventEnded(event);

  // Note: We should remove isDraft(event) once we can change recurring settings on
  // draft events. Leaving shouldDisableRecurringFields as a placeholder for now that will
  // serve as the condition for disabling recurring event fields.
  const shouldDisableRecurringEventFields =
    (isDraft(event) || isPublished(event)) && isRecurring(event);

  return (
    <div className="my-6 md:mb-8 md:mt-16">
      <Typography.TitleMd>
        {t("events.create.form.date_and_time")}
      </Typography.TitleMd>
      <div className="flex flex-col items-stretch md:flex-row">
        <div className="w-full">
          <FormItem
            label={t("events.create_modal.event_setting_attributes.starts_at", {
              timeZone: getTimezoneShort(browserTimeZone),
            })}
            translationRoot="events.create.form"
          >
            <DateRangePicker
              startsAtConfig={{
                name: "event_setting_attributes.starts_at",
                minDate: new Date(),
              }}
              endsAtConfig={{ name: "event_setting_attributes.ends_at" }}
              durationConfig={{
                name: "event_setting_attributes.duration_in_seconds",
              }}
              disabled={isDateFieldDisabled}
            />
          </FormItem>
        </div>
      </div>
      <div className="flex items-stretch">
        <div className="w-full pr-0 md:w-1/2 md:pr-2">
          <FormItem
            name="recurring_setting_attributes.frequency"
            translationRoot="events.create.form"
          >
            <Form.AnimatedSelect
              fullWidth
              options={frequencyOptions}
              disabled={!!event}
              dataTestId="event-repeats"
            />
          </FormItem>
        </div>
      </div>

      <div className="flex flex-col items-stretch md:flex-row">
        {showRecurringFields && (
          <div className="w-full pr-0 md:w-1/2 md:pr-2">
            <FormItem
              name="recurring_setting_attributes.range_type"
              translationRoot="events.create.form"
            >
              <Form.AnimatedSelect
                fullWidth
                options={endingOptions}
                disabled={shouldDisableRecurringEventFields}
                dataTestId="event-ends"
              />
            </FormItem>
          </div>
        )}
        {showRecurringFields && (
          <div className="w-full pl-0 md:w-1/2 md:pl-2">
            {(!endsOnValue || endsOnValue === RECURRING_ENDING.ON) && (
              <div className="mt-0 flex items-end md:mt-7">
                <FormItem
                  name="recurring_setting_attributes.ends_at"
                  hideLabel
                  rules={{
                    required: t("events.create.form.ends_at_required"),
                  }}
                  translationRoot="events.create.form"
                >
                  <DateTimePicker
                    name="recurring_setting_attributes.ends_at"
                    minDate={new Date(endsAtMinDate)}
                    maxDate={new Date(endsAtMaxDate)}
                    disabled={shouldDisableRecurringEventFields}
                    dataTestId="recurring_event_ends_at"
                    datePlaceholder={t(
                      "events.create.form.recurring_setting_attributes.date_placeholder",
                    )}
                    timePlaceholder={t(
                      "events.create.form.recurring_setting_attributes.time_placeholder",
                    )}
                  />
                </FormItem>
              </div>
            )}
            {endsOnValue === RECURRING_ENDING.AFTER && (
              <div className="flex items-end">
                <div className="grow">
                  <FormItem
                    name="recurring_setting_attributes.occurrences"
                    rules={{
                      required:
                        !shouldDisableRecurringEventFields &&
                        t("events.create.form.occurrences_required"),
                      max: {
                        value: endsAfterMaxOccurrences,
                        message: t("events.create.form.max_occurrences_error"),
                      },
                    }}
                    translationRoot="events.create.form"
                  >
                    <Form.Input
                      type="number"
                      min={1}
                      max={endsAfterMaxOccurrences}
                      autoFocus
                      disabled={shouldDisableRecurringEventFields}
                      data-testid="event-occurrences"
                      customOpacity="70"
                    />
                  </FormItem>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

DateAndTime.propTypes = {
  event: PropTypes.object,
};
