import type { ChatRoom } from "@/react/types/Chat/ChatRoom";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";
import { Reactions } from "@circle-react/components/Reactions";
import { StopSpotlightButton } from "./StopSpotlightButton";

interface BottomActionsProps {
  message: ChatRoomMessage;
  chatRoom: ChatRoom;
  currentCommunityMemberId: number;
  isSpotlighted: boolean;
  isCurrentSpotlightedItem: boolean;
  isAdmin: boolean;
}

export const BottomActions = ({
  message,
  chatRoom,
  currentCommunityMemberId,
  isSpotlighted,
  isCurrentSpotlightedItem,
  isAdmin,
}: BottomActionsProps) => (
  <div className="mt-1.5 flex flex-wrap items-center gap-1">
    <Reactions
      reactions={message.reactions}
      currentCommunityMemberId={currentCommunityMemberId}
      reactionableId={message.id}
      reactionableType="chat_room_message"
    />
    {isSpotlighted && isAdmin && (
      <StopSpotlightButton
        message={message}
        chatRoom={chatRoom}
        isCurrentSpotlightedItem={isCurrentSpotlightedItem}
      />
    )}
  </div>
);
