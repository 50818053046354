import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";

interface MemberProfileWrapperProps {
  member: {
    name: string;
    public_uid: string;
  };
  children: React.ReactNode;
}

export const MemberProfileWrapper = ({
  member,
  children,
}: MemberProfileWrapperProps) => {
  const { name, public_uid } = member;
  const profileLinkProps = useProfileLinkProps({
    public_uid,
  });
  return (
    <a {...profileLinkProps} title={name}>
      {children}
    </a>
  );
};
