import { useState } from "react";
import { parseISO } from "date-fns";
import { t } from "@/i18n-js/instance";
import type { RewardLogEntry } from "@circle-react/components/ProfileModalV3/Body/Rewards/api";
import { useRevokePointAwardMutation } from "@circle-react/components/ProfileModalV3/Body/Rewards/api";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { RevokeConfirmationModal } from "./RevokeConfirmationModal";

const i18nRoot = "profile_drawer.rewards";

export const RewardItem = ({ reward }: { reward: RewardLogEntry }) => {
  const { currentCommunityMember } = usePunditUserContext();
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const toast = useToast();
  const {
    points,
    triggered_at,
    reason,
    id,
    event_name,
    revoked: isRevoked,
  } = reward;
  const dateTime = parseISO(triggered_at);
  const formattedDate = formattedDateTime({
    dateTime,
    format: dateFormats.short_month_and_date_with_year,
  });

  const { mutate: revokePoints, isLoading } = useRevokePointAwardMutation({
    pointAwardId: id,
  });

  const handleRevoke = () => {
    revokePoints(undefined, {
      onSuccess: () => {
        toast.success(t(`${i18nRoot}.revoke_success`));
        setIsRevokeModalOpen(false);
      },
      onError: () => {
        toast.error(t(`${i18nRoot}.revoke_error`));
      },
    });
  };

  const isRevokeEvent = event_name === "gamification.member.points_revoked";
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  return (
    <div className="border-primary mt-3 flex items-center justify-between border-b pb-3 pt-2 first:mt-0 last:border-b-0">
      <div className="flex flex-col">
        <div>
          <Typography.LabelSm weight="semibold" color="text-dark">
            {t(`${i18nRoot}.n_points`, {
              points: isRevokeEvent ? "-" + points : "+" + points,
            })}
          </Typography.LabelSm>
        </div>
        <div className="text-default flex items-center gap-1">
          <div>
            <Typography.LabelSm weight="regular" color="text-default">
              {t(`${i18nRoot}.${isRevokeEvent ? "revoked" : "rewarded"}`, {
                date: formattedDate,
              })}
            </Typography.LabelSm>
          </div>
          {reward.reason && (
            <>
              <div className="text-[10px]">{t("separator_dot")}</div>
              <Typography.LabelSm weight="regular" color="text-default">
                {reason}
              </Typography.LabelSm>
            </>
          )}
        </div>
      </div>
      {!isRevokeEvent && !isRevoked && isAdmin && (
        <Dropdown
          buttonClassName="flex items-center h-9 w-9 flex justify-center !rounded-lg p-1 transition-colors duration-150 hover:bg-tertiary focus-visible:outline-secondary focus-visible:rounded-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-tertiary"
          button={
            <Icon
              className="text-default"
              type="20-menu-dots-horizontal"
              size={20}
              aria-label={t(`${i18nRoot}.actions`)}
            />
          }
        >
          <Dropdown.ItemLink
            onClick={() => setIsRevokeModalOpen(true)}
            forceClose
          >
            {t(`${i18nRoot}.revoke_points`)}
          </Dropdown.ItemLink>
        </Dropdown>
      )}
      <RevokeConfirmationModal
        isOpen={isRevokeModalOpen}
        onClose={() => setIsRevokeModalOpen(false)}
        onConfirm={handleRevoke}
        points={points}
        isLoading={isLoading}
      />
    </div>
  );
};
