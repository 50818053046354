export const updateParentMessageOnNewMessage = ({
  chatMessages,
  setChatRoomMessages,
  newMessage,
}) => {
  const {
    parent_message_id: parentMessageId,
    sent_at: sentAt,
    sender,
  } = newMessage;

  const parentMessageIndex = chatMessages
    .map(message => message.id)
    .indexOf(parentMessageId);
  if (parentMessageIndex > -1) {
    const parentMessage = chatMessages[parentMessageIndex];

    const { thread_participants_preview = [], replies_count = 0 } =
      parentMessage;

    const updatedParentMessage = {
      ...parentMessage,
      replies_count: replies_count + 1,
      last_reply_at: sentAt,
    };

    if (
      sender &&
      thread_participants_preview.length < 3 &&
      !thread_participants_preview.some(
        avatar => avatar.community_member_id === sender.community_member_id,
      )
    ) {
      updatedParentMessage["thread_participants_preview"] = [
        ...thread_participants_preview,
        {
          ...sender,
        },
      ];
    }

    setChatRoomMessages([
      ...chatMessages.slice(0, parentMessageIndex),
      updatedParentMessage,
      ...chatMessages.slice(parentMessageIndex + 1),
    ]);
  }
};

export const isElementInViewPort = element => {
  if (!element) return false;

  var bounding = element.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= window.innerWidth &&
    bounding.bottom <= window.innerHeight
  );
};
