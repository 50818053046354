import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Topic } from "@circle-react/types/Topic";

export const TOPICS_QUERY_KEY = "topics";
export const COURSE_TOPICS_QUERY_KEY = "courseTopics";

interface UseTopicsProps {
  isCourseDirectory: boolean;
  spaceId: number | undefined;
}

interface TopicsResponse {
  records: Topic[];
}

export const useTopics = ({ isCourseDirectory, spaceId }: UseTopicsProps) => {
  const QUERY_KEY = isCourseDirectory
    ? [COURSE_TOPICS_QUERY_KEY]
    : [TOPICS_QUERY_KEY, spaceId];

  const { data, isLoading } = useQuery<TopicsResponse>(
    QUERY_KEY,
    () =>
      isCourseDirectory
        ? reactQueryGet(internalApi.courseTopics.index())
        : reactQueryGet(internalApi.topics.spaceTopics({ spaceId })),
    { enabled: isCourseDirectory || !!spaceId },
  );

  const topics = data?.records || [];

  return { topics, isLoading };
};
