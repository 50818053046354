import { useRef, useState } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import type { FileUploadState } from "@/react/hooks/useParallelDirectUploads";
import { CircularProgress } from "@circle-react-uikit/TipTap/AttachmentPreviews/CircularProgress";
import { Typography } from "@circle-react-uikit/Typography";
import { useImagePostModalStore } from "../../store";
import { CroppingOptions } from "./CroppingOptions";
import { MainImage } from "./MainImage";
import { Thumbnails } from "./Thumbnails";
import { ZoomSlider } from "./ZoomSlider";

interface CarouselProps {
  status: "idle" | "uploading" | "completed" | "error";
  overallProgress: number;
  fileStates: FileUploadState[];
  editingImageSignedId?: string;
  onDeleteAllImages: () => void;
}
export const Carousel = ({
  status,
  overallProgress,
  fileStates,
  editingImageSignedId,
  onDeleteAllImages,
}: CarouselProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { selectedFiles, uploadedImages } = useImagePostModalStore();
  const currentImage = selectedFiles[currentImageIndex];
  const prevLengthRef = useRef(selectedFiles.length);
  const prevLength = prevLengthRef.current;
  const newLength = selectedFiles.length;

  // if the last image is deleted, move to the new last image.
  if (newLength > 0 && currentImageIndex > newLength - 1) {
    setCurrentImageIndex(selectedFiles.length - 1);
  } // If new images are added, update the currentImageIndex to the first new image
  else if (newLength > prevLength) {
    setCurrentImageIndex(prevLength);
  }

  // update the reference to the new length
  prevLengthRef.current = newLength;

  if (!currentImage) return null;

  return (
    <>
      <div
        className={classnames(
          "border-primary flex grow flex-col overflow-hidden rounded-md border",
          { "bg-tertiary": status === "uploading" },
        )}
      >
        {status === "idle" && (
          <>
            <MainImage currentImage={currentImage} />
            <div
              className={classnames("border-primary flex border-t px-4 py-3", {
                "justify-between": currentImageIndex === 0,
                "justify-end": currentImageIndex > 0,
              })}
            >
              {uploadedImages.length === 0 && currentImageIndex === 0 && (
                <CroppingOptions />
              )}
              <ZoomSlider currentImage={currentImage} />
            </div>
          </>
        )}
        {(status === "uploading" || status === "completed") && (
          <div className="mb-12 mt-1 flex aspect-video flex-col items-center justify-center gap-4">
            <CircularProgress
              percentage={overallProgress}
              size={22}
              strokeWidth={1.75}
            />
            <Typography.LabelMd color="text-default">
              {t("image_space.post_modal.steps.adjust_images.uploading")}
            </Typography.LabelMd>
          </div>
        )}
        {status === "error" && (
          <div className="mb-12 mt-1 flex aspect-video flex-col items-center justify-center gap-4">
            <Typography.LabelMd color="text-default">
              {t("image_space.post_modal.steps.adjust_images.files_error")}
            </Typography.LabelMd>
          </div>
        )}
      </div>
      <Thumbnails
        currentImageIndex={currentImageIndex}
        selectedFiles={selectedFiles}
        setCurrentImageIndex={setCurrentImageIndex}
        fileStates={fileStates}
        status={status}
        editingImageSignedId={editingImageSignedId}
        onDeleteAllImages={onDeleteAllImages}
      />
    </>
  );
};
