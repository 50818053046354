import { PlainMessageBody } from "./PlainMessageBody";
import { TipTapMessageBody } from "./TipTapMessageBody";

export interface ContentBodyProps {
  message: any;
  isInitialPage?: boolean;
  isPinnedMessageAtTop?: boolean;
  shouldShowPreviewBlocks?: boolean;
}

const isRichTextBodyContentEmpty = (richTextBody: any) => {
  if (!richTextBody) {
    return true;
  }
  const { body } = richTextBody;
  const { content } = body;
  return content.length === 0;
};

export const ContentBody = ({
  message,
  isInitialPage = false,
  isPinnedMessageAtTop = false,
  shouldShowPreviewBlocks = true,
}: ContentBodyProps) => {
  const shouldShowRichTextBody: boolean = isPinnedMessageAtTop
    ? !isRichTextBodyContentEmpty(message.rich_text_body)
    : Boolean(message.rich_text_body);

  if (shouldShowRichTextBody) {
    return (
      <TipTapMessageBody
        rich_text_body={message.rich_text_body}
        isInitialPage={isInitialPage}
        isPinnedMessageAtTop={isPinnedMessageAtTop}
        editedAt={message.edited_at}
        chatRoomAccessArr={message.embedded_chat_message_access}
        shouldShowPreviewBlocks={shouldShowPreviewBlocks}
      />
    );
  }
  return (
    <PlainMessageBody
      body={message.body}
      isPinnedMessageAtTop={isPinnedMessageAtTop}
    />
  );
};
