import { useState } from "react";

export const useRecordingEnabled = () => {
  const [recordingEnabled, setRecordingEnabled] = useState();

  return {
    setRecordingEnabled,
    recordingEnabled,
  };
};
