import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";

const i18nRoot = "profile_drawer.rewards";

interface RevokeConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  points: number;
  isLoading: boolean;
}

export const RevokeConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  points,
  isLoading,
}: RevokeConfirmationModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Overlay />
    <Modal.Content size="md">
      <Modal.Header>
        <Modal.Title>{t(`${i18nRoot}.revoke_confirmation.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <Typography.BodyMd color="text-default">
            {t(`${i18nRoot}.revoke_confirmation.description`, {
              points: Math.abs(points),
            })}
          </Typography.BodyMd>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end gap-x-3">
          <Button variant="secondary" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button onClick={onConfirm} disabled={isLoading}>
            {t(`${i18nRoot}.revoke_confirmation.confirm`)}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);
