import type { ReactionType } from "../Reaction";
import { EMOJIS } from "../constants";
import { NewReaction } from "./NewReaction";

export interface PopoverContentProps {
  reactions: ReactionType[];
  currentCommunityMemberId: number;
  reactionableId: number;
  reactionableType: string;
  onClose: () => void;
}

export const PopoverContent = ({
  reactionableId,
  reactionableType,
  reactions,
  onClose,
  currentCommunityMemberId,
}: PopoverContentProps) => (
  <div
    className="bg-primary flex gap-1 rounded-lg p-1"
    data-testid="popover-content"
  >
    {Object.keys(EMOJIS).map(emoji => (
      <NewReaction
        key={emoji}
        emoji={emoji}
        reactionableId={reactionableId}
        reactionableType={reactionableType}
        reactions={reactions}
        onClose={onClose}
        currentCommunityMemberId={currentCommunityMemberId}
      />
    ))}
  </div>
);
