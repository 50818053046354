import { useRef } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { FileUploadState } from "@/react/hooks/useParallelDirectUploads";
import { useIsVisibleOnDOM } from "@/react/hooks/utils/useIsVisibleOnDOM";
import { Icon } from "@circle-react-shared/Icon";
import {
  SortableContext,
  SortableList,
} from "@circle-react-shared/uikit/SortableList";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { DragOverlay } from "@circle-react-uikit/SortableList/components/DragOverlay";
import { Item } from "@circle-react-uikit/SortableList/components/Item";
import { DeleteImageButton } from "../../CreatePost/Carousel/DeleteImageButton";
import { Scroller, scroll } from "../../Shared/Scroller";
import { useHandleFileSelection } from "../../UploadImages/hooks/useHandleFileSelection";
import {
  MAX_IMAGES,
  useMultiImageUpload,
} from "../../UploadImages/hooks/useMultiImageUpload";
import type { SelectedImage } from "../../store";
import { useImagePostModalStore } from "../../store";
import { useRemoveImage } from "../hooks/useRemoveImage";
import { LoadingThumbnail } from "./LoadingThumbnail";
import { ThumbnailImage } from "./ThumbnailImage";
import { ThumbnailImagePlaceholder } from "./ThumbnailImagePlaceholder";

interface ThumbnailsProps {
  selectedFiles: SelectedImage[];
  setCurrentImageIndex: Dispatch<SetStateAction<number>>;
  currentImageIndex: number;
  status: "idle" | "uploading" | "completed" | "error";
  fileStates: FileUploadState[];
  editingImageSignedId?: string;
  onDeleteAllImages: () => void;
}

export const Thumbnails = ({
  selectedFiles,
  setCurrentImageIndex,
  currentImageIndex,
  status,
  fileStates,
  editingImageSignedId,
  onDeleteAllImages,
}: ThumbnailsProps) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [leftHookRef, isLeftEndVisible] = useIsVisibleOnDOM<HTMLDivElement>();
  const [rightHookRef, isRightEndVisible] = useIsVisibleOnDOM<HTMLDivElement>();
  const { contentPortalElement } = useModalData();
  const { uploadedImages, setSelectedFiles } = useImagePostModalStore();
  const { handleFileSelection } = useHandleFileSelection(() => {
    setTimeout(
      () =>
        scroll({
          direction: "right",
          scrollableContainerRef,
        }),
      200,
    );
  });
  const { getInputProps, open } = useMultiImageUpload({
    handleFileSelection,
    selectedFiles,
    uploadedFilesCount: uploadedImages.length,
  });
  const { removeImage } = useRemoveImage();
  const dndFiles = selectedFiles.map(image => ({
    ...image,
    id: image.preview,
  }));
  const currentImage = dndFiles[currentImageIndex];

  const canAddMoreImages =
    !editingImageSignedId &&
    selectedFiles.length + uploadedImages.length < MAX_IMAGES;

  return (
    <div className="relative mt-5 flex">
      {!isLeftEndVisible && (
        <Scroller
          direction="left"
          scrollableContainerRef={scrollableContainerRef}
        />
      )}
      <SortableList
        shouldUseKeyboardSensor={false}
        distance={10}
        items={dndFiles}
        onSort={(images: any) => {
          const newIndex = images.indexOf(currentImage);

          if (newIndex !== currentImageIndex) {
            setCurrentImageIndex(newIndex);
          }
          setSelectedFiles(
            images.map((image: any) => ({ ...image, id: undefined })),
          );
        }}
        restrictToParentElement
      >
        <SortableContext>
          <div
            className="scrollbar-hide m-auto !flex items-center overflow-x-scroll px-1 pt-1"
            ref={scrollableContainerRef}
            data-testid="thumbnails-list"
          >
            <div className="h-px w-px" ref={leftHookRef} />
            {selectedFiles.map((image, index) =>
              status === "uploading" || status === "completed" ? (
                <LoadingThumbnail
                  fileStates={fileStates}
                  index={index}
                  key={image.preview}
                />
              ) : (
                <Item
                  tabIndex={-1}
                  item={dndFiles[index]}
                  disabled={uploadedImages.length === 1}
                  placeholder={ThumbnailImagePlaceholder}
                  className="group relative !w-auto shrink-0"
                  key={image.preview}
                  dataTestId="thumbnail-item"
                >
                  <DeleteImageButton
                    onClick={() => {
                      removeImage(image);
                      if (selectedFiles.length === 1) {
                        onDeleteAllImages();
                      }
                    }}
                    index={index}
                    currentImageIndex={currentImageIndex}
                  />
                  <ThumbnailImage
                    image={image}
                    index={index}
                    setCurrentImageIndex={setCurrentImageIndex}
                    currentImageIndex={currentImageIndex}
                  />
                </Item>
              ),
            )}
            {canAddMoreImages && (
              <button
                type="button"
                className="hover:bg-secondary text-default ml-1 flex h-12 w-12 shrink-0 cursor-pointer items-center justify-center rounded-md border border-dashed"
                onClick={() => status !== "uploading" && open()}
              >
                <input {...getInputProps()} data-testid="upload_more_images" />
                <Icon type="20-add-lg" size={20} />
              </button>
            )}
            <div className="h-px w-px" ref={rightHookRef} />
          </div>
        </SortableContext>
        <DragOverlay portalTo={contentPortalElement}>
          <ThumbnailImagePlaceholder isOverlay />
        </DragOverlay>
      </SortableList>
      {!isRightEndVisible && (
        <Scroller
          direction="right"
          scrollableContainerRef={scrollableContainerRef}
        />
      )}
    </div>
  );
};
