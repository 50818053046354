import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { isPublishedPost } from "@/react/helpers/postHelpers";
import type { Space } from "@/react/types/Space";
import { Form } from "@circle-react-uikit/Form";

interface TogglesProps {
  space?: Space;
}

export const Toggles = ({ space }: TogglesProps) => {
  const { post } = useCurrentPostHelpers();
  const { watch } = useFormContext();
  const isCommentsDisabled = watch("is_comments_disabled");
  const isEditingPublishedPost = Boolean(post.id) && isPublishedPost(post);

  const conditionalOptions: any = {
    hide_meta_info: true,
    is_comments_disabled: true,
    is_comments_closed: !isCommentsDisabled,
    is_liking_disabled: true,
    is_truncation_disabled: true,
    pin_to_top: isEditingPublishedPost,
    hide_from_featured_areas: !space?.hide_from_featured_areas,
    "gallery.downloadable_images": true,
  };

  const options = Object.keys(conditionalOptions).filter(
    (key: any) => conditionalOptions[key],
  );

  return (
    <>
      {options.map(name => (
        <Form.Item
          id={`post_${name}`}
          key={name}
          name={name}
          label={t(`post.${name}`)}
          labelClassName="!font-normal !p-0"
          className="mb-3 !p-0"
          hideBorder
          hideDescription
          inlineReverse
        >
          <Form.ToggleSwitch name={name} variant="small" />
        </Form.Item>
      ))}
    </>
  );
};
