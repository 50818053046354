import { LOCATION_TYPES } from "@/react/components/Events/EditForm/helpers";
import { wasMinutesAgo } from "@/react/helpers/dateTimeHelpers";

export const useLiveStreamIssue = event => {
  const { event_setting_attributes: eventData, policies } = event || {};

  const {
    starts_at: startsAt,
    location_type: locationType,
    live_stream_slug: liveStreamSlug,
  } = eventData || {};

  const { can_manage_post: canManagePost, can_update_post: canUpdatePost } =
    policies || {};

  const startsIn20Minutes = wasMinutesAgo(startsAt, 20);
  const isLiveEvent = [
    LOCATION_TYPES.LIVE_STREAM,
    LOCATION_TYPES.LIVE_ROOM,
  ].includes(locationType);
  const isCreated = !!liveStreamSlug;

  return {
    issueCreatingLive:
      startsIn20Minutes && isLiveEvent && !isCreated && canManagePost,
    canCreateLive: canManagePost || canUpdatePost,
  };
};
