import { createContext, useContext, useEffect, useState } from "react";
import { noop } from "lodash";
import { useLiveStreamHoursAvailable } from "@circle-react/components/LiveStreams/hooks";
import { useStreamHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useRecordingHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useRecordingEnabled } from "@circle-react/components/LiveStreams/hooks/useRecordingEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";

interface AddonsContextProps {
  streamingHoursAvailable: boolean;
  recordingHoursReached?: boolean;
  totalStreamingHours: number;
  totalRecordingHours: number;
  limitsReachedIsOpen?: boolean;
  limitsReachedOnClose: () => void;
  setRecordingEnabled: (isRecordingEnabled: boolean) => void;
  recordingEnabled?: boolean;
  limitsReachedOnOpen: () => void;
  shouldUpgradeAddons?: boolean;
  purchaseRecordingsAddonWhenLimitReached: () => Promise<void>;
  purchaseStreamingAddonWhenLimitReached: () => Promise<void>;
}

const AddonsContext = createContext<AddonsContextProps>({
  streamingHoursAvailable: false,
  recordingHoursReached: false,
  totalStreamingHours: 0,
  totalRecordingHours: 0,
  limitsReachedIsOpen: false,
  limitsReachedOnClose: noop,
  setRecordingEnabled: noop,
  recordingEnabled: false,
  limitsReachedOnOpen: noop,
  shouldUpgradeAddons: false,
  purchaseRecordingsAddonWhenLimitReached: () => Promise.resolve(),
  purchaseStreamingAddonWhenLimitReached: () => Promise.resolve(),
});
AddonsContext.displayName = "AddonsContext";

export const AddonsProvider = ({ children }: any) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const {
    streamingHoursAvailable: hasStreamingHoursAvailable,
    recordingHoursAvailable: isRecordingHoursAvailable,
    streamingTotalPlanHours,
    recordingTotalPlanHours,
    isLoading: isLoadingUsage,
  } = useLiveStreamHoursAvailable({ enabled: isAdmin });

  const { recordingEnabled: isRecordingEnabled, setRecordingEnabled = noop } =
    useRecordingEnabled();

  const hasRecordingHoursReached =
    !isLoadingUsage && isRecordingEnabled && !isRecordingHoursAvailable;

  const {
    purchaseAsync: purchaseStreamingAddon,
    currentQuantity: streamHoursAddonQuantity,
    unitSize: streamingAddonUnitSize,
  } = useStreamHoursAddon(null, { enabled: isAdmin });

  const {
    purchaseAsync: purchaseRecordingAddon,
    currentQuantity: recordingHoursAddonQuantity,
    unitSize: recordingAddonUnitSize,
  } = useRecordingHoursAddon(null, { enabled: isAdmin });

  const totalStreamingHours =
    Number(streamingTotalPlanHours) + Number(streamHoursAddonQuantity);

  const totalRecordingHours =
    Number(recordingTotalPlanHours) + Number(recordingHoursAddonQuantity);

  const [isLimitsReachedIsOpen, setIsLimitsReachedIsOpen] = useState<
    boolean | undefined
  >(false);

  useEffect(() => {
    setIsLimitsReachedIsOpen(
      !isLoadingUsage &&
        (!hasStreamingHoursAvailable || hasRecordingHoursReached),
    );
  }, [isLoadingUsage, hasStreamingHoursAvailable, hasRecordingHoursReached]);

  const limitsReachedOnClose = () => {
    setIsLimitsReachedIsOpen(false);
  };

  const limitsReachedOnOpen = () => {
    setIsLimitsReachedIsOpen(true);
  };

  const shouldUpgradeAddons =
    !hasStreamingHoursAvailable || hasRecordingHoursReached;

  const purchaseRecordingsAddonWhenLimitReached = async () => {
    if (!isRecordingEnabled) return;
    if (isRecordingHoursAvailable) return;

    await purchaseRecordingAddon(recordingAddonUnitSize);
  };

  const purchaseStreamingAddonWhenLimitReached = async () => {
    if (hasStreamingHoursAvailable) return;

    await purchaseStreamingAddon(streamingAddonUnitSize);
  };

  const value: AddonsContextProps = {
    streamingHoursAvailable: hasStreamingHoursAvailable,
    recordingHoursReached: hasRecordingHoursReached,
    totalStreamingHours,
    totalRecordingHours,
    limitsReachedIsOpen: isLimitsReachedIsOpen,
    limitsReachedOnClose,
    setRecordingEnabled,
    recordingEnabled: isRecordingEnabled,
    limitsReachedOnOpen,
    shouldUpgradeAddons,
    purchaseRecordingsAddonWhenLimitReached,
    purchaseStreamingAddonWhenLimitReached,
  };

  return (
    <AddonsContext.Provider value={value}>{children}</AddonsContext.Provider>
  );
};

export const useAddonsContext = (): AddonsContextProps => {
  const context = useContext(AddonsContext);

  if (context === undefined) {
    throw new Error("useAddonsContext must be used within a AddonsProvider");
  }

  return context;
};
