import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { ShareDropdown } from "./ShareDropdown";

interface ShareEventProps {
  onChange: () => void;
  button?: ReactNode;
}

export const ShareEvent = ({ onChange, button }: ShareEventProps) => {
  const { showShareOnWebOptions, post, getSharePostLink, sharePostData } =
    useCurrentPostHelpers({ onChangeSharePost: onChange });
  const isSmScreen = useSmScreenMediaQuery();

  const { data: space } = useCurrentSpaceContext();

  const Component = button ?? <Icon type="16-share" size={20} />;

  if (!showShareOnWebOptions(space)) {
    return null;
  }

  return (
    <Dropdown
      size="extra-large"
      direction="bottom-end"
      menuButtonEl="button"
      className="w-full"
      buttonClassName="w-full focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 md:p-1 hover:bg-tertiary rounded group flex items-center"
      button={
        isSmScreen ? (
          Component
        ) : (
          <Typography.LabelSm
            weight="medium"
            color="text-default group-hover:text-dark"
          >
            {t("post.share.title")}
          </Typography.LabelSm>
        )
      }
    >
      <ShareDropdown
        post={post}
        getSharePostLink={getSharePostLink}
        sharePostData={sharePostData}
      />
    </Dropdown>
  );
};
