import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import type { Post } from "@circle-react/types/Post";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { AuthorProfileWrapper } from "./AuthorProfileWrapper";

export interface PostUserInfoProps {
  post: Post;
}

export const PostUserInfo = ({ post }: PostUserInfoProps) => {
  const { community_member } = post;
  const profileContext = useProfileContext();
  const profileContextMember = profileContext.member;
  const isWithinProfileContext = !!profileContextMember;

  return (
    <div className="post__avatar">
      <ProfileHoverCard
        communityMemberId={community_member.id}
        publicUid={community_member.public_uid}
        avatarURL={community_member.avatar_url}
        name={community_member.name ?? ""}
        headline={community_member.headline}
        disabled={isWithinProfileContext}
      >
        <AuthorProfileWrapper post={post} className="mr-3">
          <UserImage
            src={community_member.avatar_url}
            name={community_member.name ?? ""}
            size="10"
          />
        </AuthorProfileWrapper>
      </ProfileHoverCard>
    </div>
  );
};
