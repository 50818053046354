import { usePunditUserContext } from "@circle-react/contexts";

export function useIsActivityScoreEnabled() {
  const { currentCommunity } = usePunditUserContext();
  return Boolean(currentCommunity?.activity_score_ui_enabled);
}

export function useIsActivityScoreEnabledOrUpgradable() {
  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();

  const isAdmin = currentCommunityMember?.is_admin;
  const isActivityScoreEnabled = useIsActivityScoreEnabled();
  const isUpgradeFlowsBetaFlagEnabled =
    currentCommunitySettings?.upgrade_flows_for_activity_scores_enabled;

  // [TODO][UpgradeFlowsForActivityScore][AfterRelease] The statement should always return true because we aim to display the activity score chips at all times (including when they are disabled). This is to encourage the user either to upgrade or to activate the feature
  return Boolean(
    isAdmin && (isActivityScoreEnabled || isUpgradeFlowsBetaFlagEnabled),
  );
}

export function useIsCommunityAiIncludedInCurrentPlan() {
  const { currentCommunity } = usePunditUserContext();

  return Boolean(currentCommunity?.community_ai_feature_flag_enabled);
}

export function useShouldShowCommunityAiModal() {
  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();

  const isAdmin = currentCommunityMember?.is_admin;
  const isUpgradeFlowsBetaFlagEnabled =
    currentCommunitySettings?.upgrade_flows_for_activity_scores_enabled;
  const isCommunityAiDisabled = !useIsCommunityAiIncludedInCurrentPlan();
  const isActivityScoreUiDisabled = !useIsActivityScoreEnabled();

  return (
    isAdmin &&
    isUpgradeFlowsBetaFlagEnabled &&
    (isCommunityAiDisabled || isActivityScoreUiDisabled)
  );
}
