import classnames from "classnames";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useImagePostModalStore } from "../store";
import { MultiImageDropZone } from "./MultiImageDropZone";
import { useHandleFileSelection } from "./hooks/useHandleFileSelection";
import { useMultiImageUpload } from "./hooks/useMultiImageUpload";

interface UploadImagesProps {
  onClose: (skipDirtyValidation?: boolean) => void;
  onSelection: () => void;
}

export const UploadImages = ({ onClose, onSelection }: UploadImagesProps) => {
  const { selectedFiles, uploadedImages } = useImagePostModalStore();
  const { handleFileSelection } = useHandleFileSelection(onSelection);
  const { isDragActive, ...dropZoneProps } = useMultiImageUpload({
    handleFileSelection,
    selectedFiles,
    uploadedFilesCount: uploadedImages.length,
  });
  return (
    <Modal
      isOpen
      onClose={onClose}
      contentPortalId="upload-images-modal-portal"
    >
      <Modal.Overlay />
      <Modal.Content
        size="xl"
        className={classnames("aspect-square", {
          "!bg-light-blue": isDragActive,
        })}
      >
        <Modal.Header>
          <Modal.Title size="sm"> </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton
              onClick={onClose}
              className={classnames({ "!bg-light-blue": isDragActive })}
            />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="flex cursor-pointer flex-col items-center justify-center !p-0">
          <MultiImageDropZone
            isDragActive={isDragActive}
            {...dropZoneProps}
            className="pb-14"
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
