import PropTypes from "prop-types";
import { Header, useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { SelectRadioGroup } from "@circle-react-uikit/SelectRadioGroup";

export const Sections = ({ onChangeSection }) => {
  const { sections, currentSection, setCurrentSection } = useCustomizeUIData();
  const radioOptions = sections.map(value => ({
    value,
    label: value,
  }));

  const handleSelectRadioOnChange = section => {
    onChangeSection(section, setCurrentSection);
  };

  return (
    <Header.Actions className="items-center justify-center">
      <SelectRadioGroup
        value={currentSection}
        onChange={handleSelectRadioOnChange}
        options={radioOptions}
      />
    </Header.Actions>
  );
};

Sections.propTypes = {
  event: PropTypes.object,
  onPublish: PropTypes.func.isRequired,
  onChangeSection: PropTypes.func.isRequired,
};
