import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const getCourseSectionsKey = courseId => `course-sections-${courseId}`;

export const useCourseSections = (courseId, options = {}) =>
  useQuery(
    getCourseSectionsKey(courseId),
    () => reactQueryGet(internalApi.courses.listSections({ courseId })),
    options,
  );

export const getLessonKey = lessonId => `lesson-${lessonId}`;

export const useLesson = ({ courseId, lessonId, sectionId, options = {} }) =>
  useQuery(
    getLessonKey(lessonId),
    () =>
      reactQueryGet(
        internalApi.courses.getLesson({ courseId, lessonId, sectionId }),
      ),
    options,
  );
