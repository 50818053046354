import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";
import { getDayShortName } from "./getName";

export const distanceBetweenDates = (date1: Date, date2: Date) =>
  Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));

export const dateIsPast = (dateString: string) => {
  const now = new Date();
  const date = new Date(dateString);
  return !date.valueOf() || date < now;
};

export const getElapsedSeconds = (dateString: string) => {
  const now = new Date();
  const date = new Date(dateString);
  return Math.floor((now.getTime() - date.getTime()) / 1000);
};

export const secondsToTimeString = (seconds: number) => {
  const addPadding = (number: number) => ("0" + number).slice(-2);

  const isLessThanOneHour = seconds >= 0 && seconds < 3600;

  if (isLessThanOneHour) {
    const mins = Math.floor(seconds / 60);
    const rest = seconds - mins * 60;
    return `${addPadding(mins)}:${addPadding(rest)}`;
  }

  const hours = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds - hours * 3600) / 60);
  const rest = seconds - mins * 60 - hours * 3600;
  return `${addPadding(hours)}:${addPadding(mins)}:${addPadding(rest)}`;
};

export const wait = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const minimumDelay = async <Type>(
  promise: Promise<Type>,
  ms = 1500,
): Promise<Type> => {
  const waitPromise = wait(ms);
  const [result] = await Promise.all([promise, waitPromise]);
  return result;
};

export const addDays = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const addDaysToDate = (startDate: string, days: number) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() + days);
  return date;
};

export const roundToNextHour = (date: Date) => {
  date.setMinutes(date.getMinutes() + 60);
  date.setMinutes(0, 0, 0);

  return date;
};

export const addSecondsToDate = (date: string, seconds: number) => {
  const newDate = date ? new Date(date) : new Date();
  newDate.setSeconds(newDate.getSeconds() + seconds);
  return newDate;
};

export const wasMinutesAgo = (date: string, minutes = 25) =>
  new Date().getTime() >= new Date(date).getTime() - minutes * 60 * 1000;

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezoneShort = (timeZone?: string, date = new Date()) => {
  const locale = getCurrentBcp47Locale();
  const formatter = new Intl.DateTimeFormat([locale, "en-US"], {
    timeZone: timeZone,
    timeZoneName: "short",
  });

  const parts = formatter.formatToParts(date);

  return parts.find(part => part.type == "timeZoneName")?.value || "";
};

export const getDateFromString = (dateStr: string) => {
  if (!dateStr) return new Date();

  return new Date(dateStr);
};

export const weekdayOccurrence = (startDate: string) => {
  const date = new Date(startDate);
  return Math.ceil(date.getDate() / 7);
};

export const weekdayOccurrenceForCalendar = (startDate: string) => {
  const dayOccurrence = weekdayOccurrence(startDate);
  const calendarDayNumber = dayOccurrence == 5 ? "-1" : dayOccurrence;
  return `${calendarDayNumber}${getDayShortName(startDate)}`;
};

export const getNextQuarter = (time: Date) => {
  const quarter = 15 * 60 * 1000; // 15 minutes in milliseconds
  return new Date(Math.ceil(time.getTime() / quarter) * quarter);
};

export const isMidnight = (date: Date) =>
  date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;

export const ONE_DAY_FROM_NOW = new Date(Date.now() + 24 * 60 * 60 * 1000);
