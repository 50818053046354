import { useEffect } from "react";
import type { RefObject } from "react";

export const useFocusChangeHandler = (
  isUsedInMinimalPostModal: boolean,
  toolbarRef: RefObject<HTMLElement | null>,
  setIsEditorExpanded: (value: boolean) => void,
) => {
  useEffect(() => {
    if (!isUsedInMinimalPostModal) return;

    const handleFocusChange = () => {
      /*
       * We need to defer the check to ensure that the activeElement is updated.
       * We check if the active element is a direct child of the comment box to reply to a post and not a comment.
       */
      setTimeout(() => {
        const activeElement = document.activeElement;
        const isToolbarFocused = toolbarRef.current?.contains(activeElement);
        const postReplyElement = document.querySelector(
          ".post__reply:not(.reply-to-parent)",
        );
        const isChildOfPostReply = postReplyElement?.contains(activeElement);

        if (isToolbarFocused || isChildOfPostReply) {
          setIsEditorExpanded(true);
        } else {
          setIsEditorExpanded(false);
        }
      }, 0);
    };

    document.addEventListener("focusin", handleFocusChange);
    document.addEventListener("focusout", handleFocusChange);

    return () => {
      document.removeEventListener("focusin", handleFocusChange);
      document.removeEventListener("focusout", handleFocusChange);
    };
  }, [isUsedInMinimalPostModal, toolbarRef, setIsEditorExpanded]);
};
