import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useClickAway } from "react-use";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { viewOnlyMasqueradeAs } from "@circle-react/api/masqueradingApi";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { Modal } from "@circle-react-uikit/ModalV2";
import { EmptySearch } from "./EmptySearch";
import { NoResults } from "./NoResults";
import { SearchInput } from "./SearchInput";
import { SearchResult } from "./SearchResult";

export const EditMasqueradingSearchModal = () => {
  const drawer = useModal();
  const modalBodyRef = useRef<any>(null);
  const resultsContainerRef = useRef<any>(null);

  useClickAway(modalBodyRef, () => {
    drawer.remove();
  });

  // These are parameters we will use to trigger new search request
  const [requestQuery, setRequestQuery] = useState("");

  // These will be set after search request is finished and we need to render results
  const [results, setResults] = useState<any[]>([]);
  const [hasNoResults, setHasNoResults] = useState(false);

  const debouncedRequestQuery = useDebouncedValue(requestQuery, 500);

  const { data, isLoading } = useQuery({
    queryKey: ["masquerading/entities", debouncedRequestQuery],
    queryFn: () =>
      reactQueryGet(
        internalApi.masquerading.entities.index({
          query: debouncedRequestQuery,
          members_only: true,
        }),
      ),
    refetchOnWindowFocus: false,
    enabled: !!debouncedRequestQuery,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (!debouncedRequestQuery) {
      setHasNoResults(false);
      setResults([]);
    }
  }, [debouncedRequestQuery]);

  useEffect(() => {
    if (data) {
      const results = data.records;
      const category = data.category;
      const query = data.query;

      if (category || query) {
        setHasNoResults(results.length === 0);
        setResults(results);
      } else {
        setHasNoResults(false);
        setResults([]);
      }
    }
  }, [data]);

  const handleClick = (entityId: any, entityType: any) =>
    viewOnlyMasqueradeAs({
      entityId: entityId,
      entityType: entityType,
      mode: "edit",
    })
      .then(data => window.open(data.masquerade_url, "_self"))
      .catch(error => {
        console.error(error);
      });

  return (
    <Modal isOpen={drawer.visible} onClose={drawer.remove}>
      <Modal.Content
        position="top"
        className="border-primary w-full rounded-[0px] sm:rounded-lg sm:border md:!mt-[3.25rem] md:w-[335px]"
        size="xl"
      >
        <div ref={modalBodyRef}>
          <SearchInput
            isLoading={isLoading}
            query={requestQuery}
            setQuery={setRequestQuery}
          />
          {results.length > 0 ? (
            <div
              className="overflow-y-auto md:max-h-96"
              ref={resultsContainerRef}
            >
              <ul className="list-none">
                {results.map(result => (
                  <SearchResult
                    key={`${result.type}-${result.id}`}
                    id={result.id}
                    type={result.type}
                    name={result.name}
                    avatarUrl={result.avatar_url}
                    onClick={handleClick}
                  />
                ))}
              </ul>
            </div>
          ) : hasNoResults ? (
            <NoResults setQuery={setRequestQuery} />
          ) : (
            <EmptySearch />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
