import { t } from "@/i18n-js/instance";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useThreads } from "@circle-react/hooks/chatsV2/useThreads";
import { Typography } from "@circle-react-uikit/Typography";
import { ParticipantsAvatarBlock } from "./ParticipantsAvatarBlock";

export interface RepliesBlockProps {
  message: any;
  chatRoom: any;
}

export const RepliesBlock = ({ message, chatRoom }: RepliesBlockProps) => {
  const { openThreadsRailbar } = useRailbar();
  const { replies_count, last_reply_at } = message;
  const { removeThreadIdUrlParam } = useThreads();

  return (
    <button
      type="button"
      className="hover:border-primary hover:bg-primary group -mt-2 ml-[62px] flex h-[30px] max-w-fit cursor-pointer items-center rounded-2xl border border-transparent px-0.5 md:mr-0 hover:md:mr-[30px]"
      onClick={() => {
        removeThreadIdUrlParam();
        openThreadsRailbar(message, chatRoom);
      }}
    >
      <div
        className="flex items-center justify-between"
        data-testid="replies-block"
      >
        <div className="flex items-center space-x-1.5">
          <ParticipantsAvatarBlock
            avatars={message.thread_participants_preview}
          />
          <Typography.LabelXs weight="medium">
            {replies_count === 1
              ? t("threads.one_reply")
              : t("threads.many_replies", { count: replies_count })}
          </Typography.LabelXs>
          {last_reply_at && (
            <div className="flex items-center pr-2.5 leading-none group-hover:hidden">
              <Typography.LabelXs color="text-timestamp" weight="medium">
                {dateStringToTimeAgo(last_reply_at)}
              </Typography.LabelXs>
            </div>
          )}
          <div className="hidden pr-2.5 leading-none group-hover:block">
            <Typography.LabelXs color="text-timestamp" weight="medium">
              {t("messaging.view_thread")}
            </Typography.LabelXs>
          </div>
        </div>
      </div>
    </button>
  );
};
