import { useFormContext } from "react-hook-form";
import { Config } from "@circle-react/components/QuickPostV2/Settings/Form/Config";
import { Meta } from "@circle-react/components/QuickPostV2/Settings/Form/Meta";
import { Toggles } from "@circle-react/components/QuickPostV2/Settings/Form/Toggles";
import { useSpacesContext } from "@circle-react/contexts";

export const hasSpacesFindByIdHelper = (
  obj: any,
): obj is { helpers: { findById: (number: any) => null } } =>
  typeof obj?.helpers?.findById === "function";

export const SettingsForm = () => {
  const { watch } = useFormContext();
  const created_at = watch("created_at");
  const spaceId = watch("space_id");
  const spacesContext = useSpacesContext();
  let space: any;
  if (hasSpacesFindByIdHelper(spacesContext)) {
    space = spacesContext.helpers.findById(spaceId);
  }
  return (
    <div className="mx-auto max-w-3xl px-5 pb-5 pt-2">
      <Toggles space={space} />
      <div className="my-4" />
      <Config space={space} />
      {!!created_at && <Meta />}
    </div>
  );
};
