import { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { InPersonLocation } from "@circle-react/components/EventsV3/Modal/Tabs/BasicInfo/Location/InPersonLocation";
import { LiveStreamLocation } from "@circle-react/components/EventsV3/Modal/Tabs/BasicInfo/Location/LiveStreamLocation";
import { VirtualLocation } from "@circle-react/components/EventsV3/Modal/Tabs/BasicInfo/Location/VirtualLocation";
import {
  LOCATION_TYPES,
  TICKET_TYPES,
} from "@circle-react/components/EventsV3/Modal/utils";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import type { Event } from "@circle-react/types/Event";

interface LocationContentProps {
  event: Event;
  setLocationType: (locationType: string) => any;
}

export const LocationContent = ({
  setLocationType,
  event,
}: LocationContentProps) => {
  const isEditMode = !!event;
  const { setRecordingEnabled } = useAddonsContext();
  const { setValue } = useFormContext();
  const hideLocationName =
    "event_setting_attributes.hide_location_from_non_attendees";

  const locationType = useWatch({
    name: "event_setting_attributes.location_type",
  });
  const isRecordingEnabled: boolean = useWatch({
    name: "event_setting_attributes.live_stream_room_setting_attributes.recording_enabled",
  });
  const ticketType = useWatch({
    name: "event_setting_attributes.ticket_type",
  });

  const prevTicketTypeRef = useRef(
    event?.event_setting_attributes?.ticket_type,
  );

  const isDefaultHideLocationFieldValue =
    isEditMode && prevTicketTypeRef.current == ticketType
      ? event?.event_setting_attributes?.hide_location_from_non_attendees
      : ticketType === TICKET_TYPES.PAID;

  useEffect(() => {
    if ([LOCATION_TYPES.TBD, LOCATION_TYPES.IN_PERSON].includes(locationType))
      return;
    setValue(hideLocationName, isDefaultHideLocationFieldValue, {
      shouldDirty: true,
    });
  }, [ticketType, setValue, locationType]);

  useEffect(() => {
    setLocationType(locationType);
  }, [locationType, setLocationType]);

  useEffect(
    () => setRecordingEnabled(isRecordingEnabled),
    [isRecordingEnabled, setRecordingEnabled],
  );
  const roomTypeOverride =
    locationType === LOCATION_TYPES.LIVE_ROOM
      ? ROOM_TYPES.CONFERENCE
      : ROOM_TYPES.STREAM;

  switch (locationType) {
    case LOCATION_TYPES.IN_PERSON:
      return <InPersonLocation />;
    case LOCATION_TYPES.VIRTUAL:
      return <VirtualLocation />;
    case LOCATION_TYPES.LIVE_ROOM:
    case LOCATION_TYPES.LIVE_STREAM:
      return (
        <LiveStreamLocation
          recordingEnabled={isRecordingEnabled}
          showRecordingToggle
          roomTypeOverride={roomTypeOverride}
          event={event}
        />
      );
    case LOCATION_TYPES.TBD:
    default:
      return null;
  }
};
