import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { COMMENTS_BASE_QUERY_KEY } from "../CommentsList/useCommentsList";

const COMMENTS_HIERARCHY_QUERY_KEY = "hierarchy";

export const getCommentHierarchyKey = ({ postId, id, usedIn = null }) => [
  COMMENTS_BASE_QUERY_KEY,
  postId,
  COMMENTS_HIERARCHY_QUERY_KEY,
  id,
  usedIn,
];

export const useCommentHierarchy = ({
  postId,
  id,
  postCommentsClosed,
  postLikingDisabled,
  usedIn,
}) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => getCommentHierarchyKey({ postId, id, usedIn }),
    [postId, id, usedIn],
  );

  useEffect(() => {
    queryClient.removeQueries(queryKey);
  }, [postCommentsClosed, postLikingDisabled, queryClient, queryKey]);

  // `cacheTime: 0` ensures that comments are always fully reloaded on every
  // mount, i.e., whenever the comment section is opened. This is done to cope
  // with the current comment highlighting and anchor link behavior.
  const { data: comment, isLoading } = useQuery(
    queryKey,
    () =>
      reactQueryGet(
        internalApi.comments.commentHierarchy({
          postId: postId,
          id: id,
        }),
      ),
    {
      cacheTime: 0,
    },
  );

  const updateQueryData = commentData =>
    queryClient.setQueryData(queryKey, {
      ...commentData,
      replies: comment.replies,
    });

  return {
    comment,
    isLoading,
    updateQueryData,
  };
};
