import Sqids from "sqids";

const ALPHABET =
  /* cspell:disable-next-line */
  "vRx5keXBhG3b0Z1nJgPiNMqsItjLuE2dOWz9DQCmScylaUfKFV7HA4pw6o8YTr";
const MIN_LENGTH = 8;

export const sqid = new Sqids({
  minLength: MIN_LENGTH,
  alphabet: ALPHABET,
});
