import { createContext, useContext, useEffect, useState } from "react";
import type { JSXElementConstructor, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { getMessageHashFromUrl } from "@circle-react/components/Chat/ChatWindowV2/helpers/helpers";
import { ChatRoomMessageSearch } from "@circle-react/components/Chat/MessagingV2/ChatRoomMessageSearch";
import { ChatThreads } from "@circle-react/components/Chat/MessagingV2/ChatThreads";
import { RailBar as MessagingRailbar } from "@circle-react/components/Chat/MessagingV2/RailBar";
import { RailBar as SpaceRailbar } from "@circle-react/components/Spaces/ChatSpace/RailBar";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { useSmOrMdOrLgScreenMediaQuery } from "../useMediaQuery";
import { useBoolean } from "../utils/useBoolean";

const RAILBAR_TYPES = {
  PARTICIPANTS: "PARTICIPANTS",
  CHAT_THREADS: "CHAT_THREADS",
  CHAT_ROOM_MESSAGE_SEARCH: "CHAT_ROOM_MESSAGE_SEARCH",
} as const;

export type RailbarType = keyof typeof RAILBAR_TYPES;

export interface RailbarContextValue {
  chatRoom: any;
  closeThreadsRailbar: () => void;
  isCourseSpace: boolean;
  isLiveStream: boolean;
  isDrawer: boolean;
  messageHighlightId: any;
  openThreadsRailbar: (message: any, chatRoom: any) => void;
  parentMessage: any;
  RailbarComponent: JSXElementConstructor<any>;
  setMessageHighlightId: (messageId: any) => void;
  shouldShowRailBar: boolean;
  toggleShowRailBar: () => void;
  type: RailbarType;
  updateParentMessage: (message: any) => void;
  isChatSpace: boolean;
  isDmsPage: boolean;
  toggleChatRoomMessageSearchRailbar: () => void;
  isMessageSearchRailbarOpen: boolean;
  EmbeddedRailbarComponent: JSXElementConstructor<any>;
  shouldShowEmbeddedRailBar: boolean;
  toggleShowEmbeddedRailBar: () => void;
  messageHashId: string | undefined;
}

const initialState = {
  chatRoom: null,
  closeThreadsRailbar: () => {},
  isCourseSpace: false,
  isLiveStream: false,
  isDrawer: false,
  messageHighlightId: null,
  openThreadsRailbar: () => {},
  parentMessage: null,
  RailbarComponent: () => null,
  setMessageHighlightId: () => {},
  shouldShowRailBar: false,
  toggleShowRailBar: () => {},
  type: RAILBAR_TYPES.PARTICIPANTS,
  updateParentMessage: () => {},
  isChatSpace: false,
  isDmsPage: false,
  toggleChatRoomMessageSearchRailbar: () => {},
  isMessageSearchRailbarOpen: false,
  EmbeddedRailbarComponent: () => null,
  shouldShowEmbeddedRailBar: false,
  toggleShowEmbeddedRailBar: () => {},
  messageHashId: undefined,
};

const RailbarContext = createContext<RailbarContextValue>(initialState);
RailbarContext.displayName = "RailbarContext";

export interface RailbarContextProviderProps {
  isChatSpace?: boolean;
  children: ReactNode;
  isLiveStream?: boolean;
  isCourseSpace?: boolean;
  isDrawer?: boolean;
}

export const RailbarContextProvider = ({
  isChatSpace = false,
  children,
  isLiveStream = false,
  isCourseSpace = false,
  isDrawer = false,
}: RailbarContextProviderProps) => {
  const messageHashId = getMessageHashFromUrl();
  const { message_id: messageThreadId } = useRouterQueryParams();
  const [type, setType] = useState<RailbarType>(initialState.type);
  const [shouldShowRailBar, toggleShowRailBar, setShowRailBar] =
    useBoolean(false);
  const [
    shouldShowEmbeddedRailBar,
    toggleShowEmbeddedRailBar,
    setShowEmbeddedRailBar,
  ] = useBoolean(false);

  const [parentMessage, setParentMessage] = useState<any>(
    initialState.parentMessage,
  );
  const [chatRoom, setChatRoom] = useState<any>(initialState.chatRoom);
  const [messageHighlightId, setMessageHighlightId] =
    useState<any>(messageThreadId);

  const location = useLocation();
  const isDmsPage = location.pathname.startsWith("/messages");

  const isSmallOrMediumDevice = useSmOrMdOrLgScreenMediaQuery();
  const isMessageSearchRailbarOpen =
    type === RAILBAR_TYPES.CHAT_ROOM_MESSAGE_SEARCH;

  useEffect(() => {
    setShowRailBar(!isSmallOrMediumDevice);
  }, [isSmallOrMediumDevice]);

  const openThreadsRailbar = (message: any, chatRoom: any) => {
    setParentMessage(message);
    setChatRoom(chatRoom);
    if (isMessageSearchRailbarOpen && messageThreadId) {
      setShowEmbeddedRailBar(true);
    } else {
      setShowEmbeddedRailBar(false);
      setType(RAILBAR_TYPES.CHAT_THREADS);
      setShowRailBar(true);
    }
  };

  const closeThreadsRailbar = () => {
    setParentMessage(null);
    setChatRoom(null);
    setType(RAILBAR_TYPES.PARTICIPANTS);
    isSmallOrMediumDevice && setShowRailBar(false);
  };

  const toggleChatRoomMessageSearchRailbar = () => {
    setType(
      type === RAILBAR_TYPES.CHAT_ROOM_MESSAGE_SEARCH
        ? RAILBAR_TYPES.PARTICIPANTS
        : RAILBAR_TYPES.CHAT_ROOM_MESSAGE_SEARCH,
    );
    setShowRailBar(true);
  };

  const updateParentMessage = (message: any) => {
    setParentMessage(message);
  };

  const getParticipantsRailbarType = () => {
    if (isChatSpace) return SpaceRailbar;
    return MessagingRailbar;
  };

  const RAILBAR_COMPONENTS: Record<RailbarType, JSXElementConstructor<any>> = {
    [RAILBAR_TYPES.PARTICIPANTS]: getParticipantsRailbarType(),
    [RAILBAR_TYPES.CHAT_THREADS]: ChatThreads,
    [RAILBAR_TYPES.CHAT_ROOM_MESSAGE_SEARCH]: ChatRoomMessageSearch,
  };

  const RailbarComponent = RAILBAR_COMPONENTS[type];

  const EmbeddedRailbarComponent =
    RAILBAR_COMPONENTS[RAILBAR_TYPES.CHAT_THREADS];

  const value: RailbarContextValue = {
    chatRoom,
    closeThreadsRailbar,
    isCourseSpace,
    isLiveStream,
    isChatSpace,
    isDrawer,
    messageHighlightId,
    openThreadsRailbar,
    parentMessage,
    RailbarComponent,
    EmbeddedRailbarComponent,
    setMessageHighlightId,
    shouldShowRailBar,
    toggleShowRailBar,
    type,
    updateParentMessage,
    isDmsPage,
    toggleChatRoomMessageSearchRailbar,
    isMessageSearchRailbarOpen,
    shouldShowEmbeddedRailBar:
      shouldShowEmbeddedRailBar && isMessageSearchRailbarOpen,
    toggleShowEmbeddedRailBar,
    messageHashId,
  };

  return (
    <RailbarContext.Provider value={value}>{children}</RailbarContext.Provider>
  );
};

export const useRailbar = () => useContext(RailbarContext);
