import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";

interface ActiveSpaceMembersCountProps {
  spaceId?: number;
  enabled?: boolean;
}

export const useActiveSpaceMembersCount = ({
  spaceId,
  enabled = true,
}: ActiveSpaceMembersCountProps) => {
  const url = internalApi.spaces.activeMembersCount({ id: spaceId });
  const query = useQuery<{ members_count: number }>(url, {
    enabled: !!spaceId && enabled,
  });

  return {
    ...query,
    count: query.data?.members_count || 0,
  };
};
