import { useEffect, useMemo, useState } from "react";
import { endOfDay } from "date-fns";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  EVENT_TYPES,
  RECURRING_ENDING,
  RECURRING_FREQUENCIES,
} from "@circle-react/components/Events/helpers/constants";
import type {
  EventType,
  RecurringEnding,
  RecurringFrequency,
} from "@circle-react/components/Events/helpers/constants";
import { addDaysToDate } from "@circle-react/helpers/dateTimeHelpers";
import {
  getDayName,
  getMonthDayWithOrdinal,
  getMonthName,
  getWeekdayOccurrenceWithOrdinal,
} from "@circle-react/helpers/dateTimeHelpers/getName";

const startsAtFieldName = "event_setting_attributes.starts_at";
const frequencyFieldName = "recurring_setting_attributes.frequency";
const eventTypeFieldName = "event_type";
const rangeTypeFieldName = "recurring_setting_attributes.range_type";
const endsAtFieldName = "recurring_setting_attributes.ends_at";
const occurrencesFieldName = "recurring_setting_attributes.occurrences";
const i18nRoot = "events.recurring";

interface GetFrequencyOptionsProps {
  startsAt: string;
  firstLetterCapital?: boolean;
}

interface FrequencyOption {
  label: string;
  value: RecurringFrequency | boolean;
}

type FrequencyOptions = FrequencyOption[];

export const getFrequencyOptions = ({
  startsAt,
  firstLetterCapital = true,
}: GetFrequencyOptionsProps): FrequencyOptions => {
  const frequenciesKey = firstLetterCapital
    ? "frequencies_capital_letter"
    : "frequencies";

  const startsAtDate = new Date(startsAt);
  const dayNumber = startsAtDate.getDate();
  const dayName = getDayName(startsAt);
  const monthName = getMonthName(startsAt);
  const monthDayWithOrdinal = getMonthDayWithOrdinal(startsAt);
  const weekDayNumberWithOrdinal = getWeekdayOccurrenceWithOrdinal(startsAt);

  return [
    {
      label: t(`${i18nRoot}.${frequenciesKey}.daily`, {
        dayName,
      }),
      value: RECURRING_FREQUENCIES.DAILY,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.weekday`, {
        dayName,
      }),
      value: RECURRING_FREQUENCIES.WEEKDAY,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.weekly`, {
        dayName,
      }),
      value: RECURRING_FREQUENCIES.WEEKLY,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.bi_weekly`, {
        dayName,
      }),
      value: RECURRING_FREQUENCIES.BI_WEEKLY,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.monthly`, {
        dayNumberWithOrdinal: weekDayNumberWithOrdinal,
      }),
      value: RECURRING_FREQUENCIES.MONTHLY_WEEK_BASED,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.monthly`, {
        dayNumberWithOrdinal: monthDayWithOrdinal,
      }),
      value: RECURRING_FREQUENCIES.MONTHLY,
    },
    {
      label: t(`${i18nRoot}.${frequenciesKey}.annually`, {
        monthName,
        dayNumber,
      }),
      value: RECURRING_FREQUENCIES.ANNUALLY,
    },
  ];
};

const endsOnMaxDatesValues: any = {
  [RECURRING_FREQUENCIES.DAILY]: 30 * 2, // Two months
  [RECURRING_FREQUENCIES.WEEKDAY]: 30 * 3, // Three months
  [RECURRING_FREQUENCIES.WEEKLY]: 30 * 12, // One year
  [RECURRING_FREQUENCIES.BI_WEEKLY]: 30 * 24, // two year
  [RECURRING_FREQUENCIES.MONTHLY]: 30 * 24, // Two years
  [RECURRING_FREQUENCIES.MONTHLY_WEEK_BASED]: 30 * 24, // Two years
  [RECURRING_FREQUENCIES.ANNUALLY]: 30 * 12 * 10, // Ten years
};

const endsAfterMaxOccurrencesValues: any = {
  [RECURRING_FREQUENCIES.DAILY]: 60,
  [RECURRING_FREQUENCIES.WEEKDAY]: 60,
  [RECURRING_FREQUENCIES.WEEKLY]: 52,
  [RECURRING_FREQUENCIES.BI_WEEKLY]: 52,
  [RECURRING_FREQUENCIES.MONTHLY]: 24,
  [RECURRING_FREQUENCIES.MONTHLY_WEEK_BASED]: 24,
  [RECURRING_FREQUENCIES.ANNUALLY]: 10,
};

interface EndingOption {
  label: string;
  value: RecurringEnding;
}

type EndingOptions = EndingOption[];

export const useRecurringSettings = () => {
  const { setValue } = useFormContext();

  const startsAt: string = useWatch({ name: startsAtFieldName });
  const rangeTypeValue: RecurringEnding | undefined = useWatch({
    name: rangeTypeFieldName,
  });

  const recurringFrequency: RecurringFrequency | undefined = useWatch({
    name: frequencyFieldName,
  });

  const eventType: EventType | undefined = useWatch({
    name: eventTypeFieldName,
  });

  const [shouldShowRecurringFields, setShowRecurringFields] = useState(true);
  const [endsAtMaxDate, setEndsAtMaxDate] = useState<Date | undefined>();
  const [endsAfterMaxOccurrences, setEndsAfterMaxOccurrences] = useState<
    number | undefined
  >();

  const [endsAtMinDate, setEndsAtMinDate] = useState<string | undefined>();

  const dontRepeatOption: FrequencyOption = useMemo(
    () => ({
      label: t(`${i18nRoot}.does_not_repeat`),
      value: false,
    }),
    [],
  );

  const [frequencyOptions, setFrequencyOptions] = useState<FrequencyOptions>([
    dontRepeatOption,
  ]);

  const endingOptions: EndingOptions = [
    {
      label: t(`${i18nRoot}.ending.on_day`),
      value: RECURRING_ENDING.ON,
    },
    {
      label: t(`${i18nRoot}.ending.after`),
      value: RECURRING_ENDING.AFTER,
    },
  ];

  useEffect(() => {
    const frequencyOptions = getFrequencyOptions({ startsAt });
    const options = [dontRepeatOption, ...frequencyOptions];
    setFrequencyOptions(options);
    setEndsAtMinDate(startsAt);
  }, [startsAt, dontRepeatOption]);

  useEffect(() => {
    setShowRecurringFields(Boolean(recurringFrequency));
  }, [recurringFrequency]);

  useEffect(() => {
    if (rangeTypeValue == RECURRING_ENDING.ON && recurringFrequency) {
      setValue(occurrencesFieldName, null);
      const maxDate = addDaysToDate(
        startsAt,
        endsOnMaxDatesValues[recurringFrequency],
      );
      setEndsAtMaxDate(endOfDay(maxDate));
    } else if (rangeTypeValue == RECURRING_ENDING.AFTER && recurringFrequency) {
      setValue(endsAtFieldName, null);
      setEndsAfterMaxOccurrences(
        endsAfterMaxOccurrencesValues[recurringFrequency],
      );
    }
  }, [rangeTypeValue, recurringFrequency, startsAt, setValue]);

  return {
    endsAtMaxDate,
    endsAfterMaxOccurrences,
    isRecurringEvent: eventType === EVENT_TYPES.RECURRING,
    frequencyOptions,
    endingOptions,
    endsAtMinDate,
    endsOnValue: rangeTypeValue,
    showRecurringFields: shouldShowRecurringFields,
  };
};
