import type { ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useRailbar } from "@circle-react/hooks/chatsV2";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2/store";
import { shouldHightlightMessage } from "../helpers";
import { doesActiveMessageIdMatches } from "../helpers/helpers2";
import { useRedirectToMessage } from "./hooks";

export interface MessageItemWrapperProps {
  children: ReactNode;
  id: string;
  isEditMessage?: boolean;
  isPinned?: boolean;
  isPinnedMessageAtTop?: boolean;
  message: any;
  messageHashId?: string;
  shouldShowMessageActions?: boolean;
  shouldShowRepliesBlock?: boolean;
}

export const MessageItemWrapper = ({
  children,
  id,
  isEditMessage = false,
  isPinned = false,
  isPinnedMessageAtTop = false,
  message,
  messageHashId,
  shouldShowMessageActions = false,
  shouldShowRepliesBlock = false,
}: MessageItemWrapperProps) => {
  const { messageHighlightId } = useRailbar();
  const { handlePinnedMessage } = useRedirectToMessage();
  const { activeMessageId } = useChatRoomStore();
  const isSpotlighted = message.spotlighted;

  const shouldActiveMessageIdMatches = doesActiveMessageIdMatches({
    activeMessageId,
    id: message.id,
    isMessageThread: false,
  });
  if (isPinnedMessageAtTop) {
    return (
      <button
        type="button"
        id="pinned"
        data-testid="message-item"
        className={classNames(
          "group/action bg-primary relative mx-2 flex cursor-pointer flex-col rounded-md text-left",
          {
            "hover:bg-secondary-dark":
              !activeMessageId && shouldShowMessageActions,
            "!bg-secondary-dark":
              isEditMessage || shouldActiveMessageIdMatches || isSpotlighted,
            "bg-message-highlight mt-2": isPinned,
            "pb-2": shouldShowRepliesBlock,
          },
        )}
        onClick={() => {
          if (isEditMessage) return;
          handlePinnedMessage(message);
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <div
      id={id}
      data-testid="message-item"
      className={classNames(
        "group/action bg-primary relative mx-2 flex flex-col rounded-md text-left",
        {
          "hover:bg-secondary-dark":
            !activeMessageId && shouldShowMessageActions,
          "!bg-secondary-dark":
            isEditMessage || shouldActiveMessageIdMatches || isSpotlighted,
          "bg-message-highlight": isPinned,
          "message-highlight": shouldHightlightMessage({
            id,
            messageHashId,
            messageHighlightId,
          }),
          "pb-2": shouldShowRepliesBlock,
        },
      )}
    >
      {children}
    </div>
  );
};
