import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useInvalidateEventQueries } from "@circle-react/components/Events/hooks/useInvalidateEventQueries";
import {
  isPaidEvent,
  isPublished,
} from "@circle-react/components/EventsV3/Modal/utils";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { ROOM_ACCESS_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { posts } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useEventsContext } from "../EditEventContext";
import {
  EVENT_TYPES,
  LOCATION_TYPES,
  RECURRING_EDIT_MODE,
  STATUS,
  TICKET_TYPES,
} from "../utils";
import { useCreateOrUpdateEventMutation } from "./useCreateOrUpdateEventMutation";

const EDIT_MODE_FIELD_NAME = "recurring_setting_attributes.edit_mode";
const STATUS_FIELD_NAME = "status";
const FORCE_DIRTY_FIELD_NAME = "force_dirty";

interface UseEventFormActionsProps {
  onClose: () => void;
  event: Event | null;
  isDirty: boolean;
  isEditMode: boolean;
  setEvent: (event: Event | null) => void;
  shouldClearEventStateOnHide?: boolean;
  onEventChange: (event: Event | null) => Promise<any>;
  isLiveLocation: boolean;
}

export const useEventFormActions = ({
  onClose,
  event,
  isDirty,
  isEditMode,
  setEvent,
  shouldClearEventStateOnHide,
  onEventChange,
  isLiveLocation,
}: UseEventFormActionsProps) => {
  const history = useHistory();
  const { success, error } = useToast();
  const invalidateEventQueries = useInvalidateEventQueries();
  const { pathname } = useLocation();
  const mutation = useCreateOrUpdateEventMutation(event);
  const isCreating = !event && mutation.isLoading;
  const { setIsUpdatingAttendees } = useEventsContext();

  const setValueRef =
    useRef<(name: string, value: any, options?: any) => void | null>(null);
  const triggerFormSubmitRef = useRef<() => Promise<void>>(null);

  const {
    purchaseRecordingsAddonWhenLimitReached,
    purchaseStreamingAddonWhenLimitReached,
    shouldUpgradeAddons,
  } = useAddonsContext();

  const isAllEventsPage = pathname === "/events";
  const handleClose = () => {
    if (!isDirty || confirm(t("events.edit_modal.close_warning"))) {
      onClose();
      if (shouldClearEventStateOnHide) {
        setEvent(null);
      }
    }
  };

  const onSubmit = async (formData: any, { reset }: any) => {
    try {
      let eventType = event?.event_type;

      if (!isEditMode) {
        const {
          recurring_setting_attributes: { frequency },
        } = formData;
        eventType = frequency ? EVENT_TYPES.RECURRING : EVENT_TYPES.SINGLE;
      } else {
        delete formData[FORCE_DIRTY_FIELD_NAME];
      }

      const requiresPriceConversion = isEditMode
        ? formData?.paywall_attributes?.price_amount
        : isPaidEvent(formData);

      if (requiresPriceConversion) {
        const {
          paywall_attributes: { price_amount },
        } = formData;
        const amount: number = mapCurrencyAmountStrToInt(price_amount);
        formData.paywall_attributes.price_amount = amount;
      }

      // Workaround to set live stream access_type as secret for paid events.
      if (
        !isEditMode &&
        formData?.event_setting_attributes?.ticket_type == TICKET_TYPES.PAID &&
        [LOCATION_TYPES.LIVE_ROOM, LOCATION_TYPES.LIVE_STREAM].includes(
          formData?.event_setting_attributes?.location_type,
        )
      ) {
        formData.event_setting_attributes.live_stream_room_setting_attributes.access_type =
          ROOM_ACCESS_TYPES.SECRET;
      }

      // Refresh attendees if Auto-RSVP toggle is changed
      const autoRsvpToggleStatus =
        formData?.event_setting_attributes?.auto_rsvp_invited_attendees;
      handleAutoRsvpToggle(autoRsvpToggleStatus);

      await handleAddonsUpgrade();

      const mutationOptions: any = {
        onSuccess: (newEvent: any) => handleMutationSuccess(newEvent, reset),
        onError: handleMutationError,
      };

      mutation.mutate({ ...formData, event_type: eventType }, mutationOptions);
    } catch (err) {
      handleSubmissionError(err);
    }
  };

  const handleAutoRsvpToggle = (autoRsvpToggleStatus?: boolean) => {
    if (autoRsvpToggleStatus === undefined) return;
    if (isPublished(event) && !autoRsvpToggleStatus) return;

    setIsUpdatingAttendees(true);
  };

  const handleAddonsUpgrade = async () => {
    if (isLiveLocation && shouldUpgradeAddons) {
      await purchaseRecordingsAddonWhenLimitReached();
      await purchaseStreamingAddonWhenLimitReached();
      success(t("settings.billing.add_ons.changes_succeeded"));
    }
  };

  const handleMutationSuccess = (newEvent: any, reset: any) => {
    const successMessage = event
      ? t("events.saved_successfully")
      : t("events.created_successfully");
    success(successMessage);

    invalidateEventQueries({ shouldInvalidateAllEvents: isAllEventsPage });

    if (isAllEventsPage) {
      void onEventChange(event);
    }

    if (eventIsDraftAndNewEventIsPublished(newEvent)) {
      onClose();
      setEvent(null);
      redirectToNewEvent(newEvent);
    } else {
      resetAndSetEvent(newEvent, reset);
    }
  };

  const eventIsDraftAndNewEventIsPublished = (newEvent: any) =>
    event?.status === STATUS.DRAFT && newEvent?.status === STATUS.PUBLISHED;

  const redirectToNewEvent = (newEvent: any) => {
    history.push(
      posts.show({
        spaceSlug: newEvent.space_slug,
        slug: newEvent.slug,
      }),
    );
  };

  const resetAndSetEvent = (newEvent: any, reset: any) => {
    reset({ ...newEvent, body: newEvent.body_for_editor });
    setEvent(newEvent);
  };

  const handleMutationError = () => {
    const errorMessage = event
      ? t("events.save_error")
      : t("events.create_error");
    error(errorMessage);
  };

  const handleSubmissionError = (err: any) => {
    if (err instanceof Error) {
      console.error(err.message);
    }
  };

  const onSave = (editMode = RECURRING_EDIT_MODE.CURRENT) => {
    if (setValueRef.current && triggerFormSubmitRef.current) {
      setValueRef.current(EDIT_MODE_FIELD_NAME, editMode, {
        shouldDirty: true,
      });
      setValueRef.current(FORCE_DIRTY_FIELD_NAME, true, { shouldDirty: true });
      void triggerFormSubmitRef.current();
    }
  };

  const onPublish = (editMode: any) => {
    if (setValueRef.current) {
      setValueRef.current(STATUS_FIELD_NAME, STATUS.PUBLISHED, {
        shouldDirty: true,
      });
    }
    onSave(editMode);
  };

  return {
    handleClose,
    onSubmit,
    onSave,
    onPublish,
    isCreating,
    setValueRef,
    triggerFormSubmitRef,
    mutation,
  };
};
