import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useLiveStreamsConfig } from "@circle-react/components/LiveStreams/hooks";
import { useCommunityMemberPickerContext } from "@circle-react/components/shared/CommunityMemberPicker/CommunityMemberPickerContext";
import { INITIAL_HOST_NUMBER } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Typography } from "@circle-react-uikit/Typography";

export const CohostsObserver = ({
  setIsLimitReached,
  initialCohostQuantity = 1,
  shouldDisplayDisabledSearchMessage = false,
}) => {
  const { selectionState } = useCommunityMemberPickerContext();
  const { memberCount } = selectionState || {};
  const count = memberCount + initialCohostQuantity || 1;
  const { config } = useLiveStreamsConfig();
  const hostCapacity = config?.live_stream_max_host_capacity;
  const isAtCapacity = count >= hostCapacity;

  useEffect(() => {
    if (!hostCapacity) {
      return;
    }
    if (count <= hostCapacity - INITIAL_HOST_NUMBER) {
      setIsLimitReached(false);
    } else {
      setIsLimitReached(true);
    }
  }, [count, hostCapacity, setIsLimitReached]);

  return (
    <div className="mb-4 text-center">
      <div>
        <Typography.LabelSm weight={isAtCapacity ? "medium" : "normal"}>
          {t("live_streams.room.cohosts_counter_message", {
            count: Math.min(count, hostCapacity),
            hostCapacity,
          })}
        </Typography.LabelSm>
      </div>
      {shouldDisplayDisabledSearchMessage && (
        <div>
          <Typography.LabelSm>
            {t("live_streams.room.cohosts_disable_search_message")}
          </Typography.LabelSm>
        </div>
      )}
    </div>
  );
};

CohostsObserver.propTypes = {
  setIsLimitReached: PropTypes.func.isRequired,
  initialCohostQuantity: PropTypes.number,
  shouldDisplayDisabledSearchMessage: PropTypes.bool,
};
