import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { useFormSections } from "../../../hooks";

export const useFormSteps = roomType => {
  const { sectionTitles } = useFormSections(roomType);
  const {
    currentSection,
    setCurrentSection,
    formId,
    isSaving,
    onExit,
    onSave,
  } = useCustomizeUIData();
  const currentIndex = sectionTitles.indexOf(currentSection);
  const currentStep = currentIndex + 1;
  const totalSteps = sectionTitles?.length || 0;
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === totalSteps;
  const next = () => {
    if (currentIndex === totalSteps - 1) {
      return;
    }
    setCurrentSection(sectionTitles[currentIndex + 1]);
  };
  const prev = () => {
    if (currentIndex === 0) {
      return;
    }
    setCurrentSection(sectionTitles[currentIndex - 1]);
  };

  return {
    formId,
    isSaving,
    currentStep,
    totalSteps,
    isFirstStep,
    isLastStep,
    next,
    prev,
    onSave,
    onExit,
  };
};
