import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Event } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { useEventSummary } from "../hooks/useEventSummary";
import { CopyEventUrlLink } from "./CopyEventUrlLink";
import { CopyPublicEventUrlLink } from "./CopyPublicEventUrlLink";
import { CopyRoomUrlLink } from "./CopyRoomUrlLink";
import { ViewEventLink } from "./ViewEventLink";

interface SummaryProps {
  event: Event;
}

export const Summary = ({ event }: SummaryProps) => {
  const {
    name,
    startsAt,
    endsAt,
    timeZoneAbbreviations,
    timeZone,
    dayOfMonth,
    shortMonthString,
    sharePostEnabled,
  } = useEventSummary(event);

  const isSmScreen = useSmScreenMediaQuery();

  if (isSmScreen) return null;

  return (
    <>
      <Typography.TitleXl>{name}</Typography.TitleXl>
      <div className="mt-4">
        <div className="flex items-center">
          <Icon type="16-calendar" size={16} className="mr-3" />
          <Typography.LabelSm>
            {`${shortMonthString} ${dayOfMonth} \u00B7 ${dateToTimeString(
              startsAt,
              timeZone,
            )} - ${dateToTimeString(
              endsAt,
              timeZone,
            )}  ${timeZoneAbbreviations}`}
          </Typography.LabelSm>
        </div>
      </div>
      <div className="mt-7 flex items-center">
        <div className="mr-3">
          <ViewEventLink event={event} />
        </div>
        <div className="mr-3">
          <CopyEventUrlLink event={event} />
        </div>
        {sharePostEnabled && (
          <div className="mr-3">
            <CopyPublicEventUrlLink event={event} />
          </div>
        )}
        <div className="mr-3">
          <CopyRoomUrlLink event={event} />
        </div>
      </div>
    </>
  );
};
