import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const viewOnlyMasqueradeAs = ({
  entityId,
  entityType = "CommunityMember",
  mode = "view",
}) =>
  reactQueryPost(
    internalApi.masquerading.masquerades.create({
      entityId,
      entityType,
      mode,
    }),
    {
      method: "POST",
    },
  );
