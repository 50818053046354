import { useMemo } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@/react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import { POST_STATUS_SCHEDULED } from "@/react/helpers/postHelpers";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Icon } from "@circle-react-shared/Icon";
import { ClearButton } from "@circle-react-uikit/ClearButton";

export const ScheduleDate = ({
  publishedAt,
  updatePublishedAt,
  status,
  revertToDraftState,
}) => {
  const [datePickerVisible, togglePickerVisibility, setPickerVisibility] =
    useBoolean(false);

  const formattedDate = useMemo(() => {
    if (!publishedAt) {
      return null;
    }
    return formattedDateTime({
      dateTime: new Date(publishedAt),
      format: "date_time_with_short_timezone",
    });
  }, [publishedAt]);

  if (status !== POST_STATUS_SCHEDULED) {
    return null;
  }

  const onScheduleDateChange = date => {
    updatePublishedAt(date);
    setPickerVisibility(false);
  };

  const onButtonCLick = event => {
    event && event.preventDefault();
    togglePickerVisibility();
  };

  const onClearClick = event => {
    event && event.preventDefault();
    revertToDraftState();
    setPickerVisibility(false);
  };

  const onDatePickerCancel = () => {
    event && event.preventDefault();
    setPickerVisibility(false);
  };

  return (
    <DatePicker
      value={publishedAt}
      datePickerVisible={datePickerVisible}
      onCancel={onDatePickerCancel}
      onSave={onScheduleDateChange}
    >
      <ClearButton onButtonClick={onButtonCLick} onClick={onClearClick}>
        <span className="flex items-center gap-1.5">
          <Icon type="16-calendar" className="text-default" size={16} />
          <span className="font-medium">{formattedDate}</span>
        </span>
      </ClearButton>
    </DatePicker>
  );
};

ScheduleDate.propTypes = {
  publishedAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  updatePublishedAt: PropTypes.func.isRequired,
  revertToDraftState: PropTypes.func.isRequired,
};
