import { useMutation } from "react-query";
import { eventApi } from "@circle-react/api";
import type { Event, EventFormData } from "@circle-react/types/Event";

export const useCreateOrUpdateEventMutation = (event: Event | null = null) =>
  useMutation((formData: EventFormData) =>
    event
      ? eventApi.update({
          event,
          formData: {
            ...formData,
            event_type: event.event_type,
          },
          forReactQuery: true,
        })
      : eventApi.create({ formData, forReactQuery: true }),
  );
