import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const REQUEST_KEY = "PROFILE_SPACES";
export const useProfileSpaces = ({
  memberPublicId,
  params = { per_page: 12 },
}) => {
  const queryKey = [REQUEST_KEY, memberPublicId, params];
  const fetchSpaces = page =>
    reactQueryGet(
      internalApi.profile.spaces({
        memberPublicId,
        params: { page, ...params },
      }),
    );

  const infiniteSpacesQuery = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchSpaces(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const spaces = useMemo(
    () =>
      infiniteSpacesQuery.isLoading
        ? []
        : uniqBy(
            flatten(infiniteSpacesQuery.data.pages.map(page => page.spaces)),
            "id",
          ),
    [infiniteSpacesQuery.data],
  );
  return { ...infiniteSpacesQuery, spaces };
};
