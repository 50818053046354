import type { ReactNode } from "react";
import { createContext, useContext } from "react";

interface PostNavigationContextProps {
  params: Record<string, unknown> | string;
  hasNextPage?: boolean;
}

export const PostNavigationContext = createContext<PostNavigationContextProps>({
  params: {},
  hasNextPage: false,
});

PostNavigationContext.displayName = "PostNavigationContext";

interface PostNavigationProviderProps {
  children: ReactNode;
  params?: Record<string, unknown> | string;
  hasNextPage?: boolean;
}

export const PostNavigationProvider = ({
  children,
  params = {},
  hasNextPage = false,
}: PostNavigationProviderProps) => (
  <PostNavigationContext.Provider value={{ params, hasNextPage }}>
    {children}
  </PostNavigationContext.Provider>
);

export const usePostNavigationContext = () => {
  const context = useContext(PostNavigationContext);

  if (!context) {
    throw new Error(
      "usePostNavigationContext must be used within a PostNavigationProvider",
    );
  }

  return context;
};
