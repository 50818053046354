import { createContext, useContext, useState } from "react";
import { noop } from "lodash";
import {
  useEditMasqueradingSearch,
  useViewMasqueradingSearch,
} from "../components/ViewOnlyMasquerading/masquerading";

const initialState = {
  isBannerVisible: false,
  showBanner: noop,
  hideBanner: noop,
  openViewMasqueradingSearch: noop,
  openEditMasqueradingSearch: noop,
};
export const MasqueradeContext = createContext(initialState);
MasqueradeContext.displayName = "MasqueradeContext";

export const MasqueradeContextProvider = ({ children }: any) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const viewMasqueradingSearch = useViewMasqueradingSearch();
  const editMasqueradingSearch = useEditMasqueradingSearch();
  const showBanner = () => {
    setIsBannerVisible(true);
    void viewMasqueradingSearch.show();
  };
  const hideBanner = () => setIsBannerVisible(false);
  const openViewMasqueradingSearch = () => void viewMasqueradingSearch.show();
  const openEditMasqueradingSearch = () => void editMasqueradingSearch.show();

  const value = {
    isBannerVisible,
    showBanner,
    hideBanner,
    openViewMasqueradingSearch,
    openEditMasqueradingSearch,
  };

  return (
    <MasqueradeContext.Provider value={value}>
      {children}
    </MasqueradeContext.Provider>
  );
};

export const useMasqueradeContext = () => {
  const context = useContext(MasqueradeContext);
  if (!context) {
    throw new Error(
      "useMasqueradeContext must be used within a MasqueradeContextProvider",
    );
  }
  return context;
};
