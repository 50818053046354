import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";

const REPORT_REASONS = [
  "harassment",
  "spam",
  "incorrect_location",
  "against_guidelines",
  "other",
];

export const ReportModal = ({ show, onClose, onReportSubmit }) => {
  const reportReasonOptions = REPORT_REASONS.map(reason => ({
    value: reason,
    label: t(`flagged_contents.report_reasons.${reason}`),
  }));

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      title={t("comments.report.form.title")}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">{t("comments.report.form.title")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onReportSubmit}>
            <Form.Item
              name="reported_reason_type"
              label={t("flagged_contents.form.reported_reason_type")}
              description={t(
                "flagged_contents.form.reported_reason_type_description",
              )}
              hideBorder
            >
              <Form.SelectNative options={reportReasonOptions} />
            </Form.Item>
            <Form.Item
              name="reported_reason_body"
              label={t("flagged_contents.form.reported_reason_body")}
              description={t(
                "flagged_contents.form.reported_reason_body_description",
              )}
              hideBorder
              fullWidth
            >
              <Form.TextArea />
            </Form.Item>
            <div className="py-4">
              <Button type="submit" variant="primary">
                {t("report")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

ReportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReportSubmit: PropTypes.func.isRequired,
};
