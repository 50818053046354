import { useQueryClient } from "react-query";

export const useChatThreadListReaction = () => {
  const queryClient = useQueryClient();

  const refetchChatThreads = () => {
    void queryClient.invalidateQueries(["chat-threads-list"]);
  };
  const createReactionFromThreadList = () => {
    refetchChatThreads();
  };

  const removeReactionFromThreadList = () => {
    refetchChatThreads();
  };

  const handleReactionFromWebsocket = data => {
    if (data.json_message.reactionable_type === "ChatRoomMessage") {
      if (data.event === "createReaction") {
        createReactionFromThreadList();
      } else if (data.event === "destroyReaction") {
        removeReactionFromThreadList();
      }
    }
  };

  return {
    createReactionFromThreadList,
    removeReactionFromThreadList,
    handleReactionFromWebsocket,
  };
};
