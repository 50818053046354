import { useEffect } from "react";
import { isFunction } from "lodash";
import { useWatch } from "react-hook-form";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";

export const useRoomType = (setRoomType = null) => {
  const roomType = useWatch({
    name: "room_type",
    defaultValue: ROOM_TYPES.STREAM,
  });
  useEffect(() => {
    if (isFunction(setRoomType)) {
      setRoomType(roomType);
    }
  }, [roomType, setRoomType]);

  return roomType;
};
