import { useRef, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useIsVisibleOnDOM } from "@/react/hooks/utils/useIsVisibleOnDOM";
import { Icon } from "@circle-react-shared/Icon";
import {
  SortableContext,
  SortableList,
} from "@circle-react-shared/uikit/SortableList";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { DragOverlay } from "@circle-react-uikit/SortableList/components/DragOverlay";
import { Item } from "@circle-react-uikit/SortableList/components/Item";
import { AdjustImages } from "../../AdjustImages";
import { Scroller, scroll } from "../../Shared/Scroller";
import { useHandleFileSelection } from "../../UploadImages/hooks/useHandleFileSelection";
import {
  MAX_IMAGES,
  useMultiImageUpload,
} from "../../UploadImages/hooks/useMultiImageUpload";
import { useImagePostModalStore } from "../../store";
import { useRemoveImage } from "../hooks/useRemoveImage";
import { DeleteImageButton } from "./DeleteImageButton";
import { ThumbnailImage } from "./ThumbnailImage";
import { ThumbnailImagePlaceholder } from "./ThumbnailImagePlaceholder";

interface ThumbnailsProps {
  setCurrentImageIndex: Dispatch<SetStateAction<number>>;
  currentImageIndex: number;
}

export const Thumbnails = ({
  setCurrentImageIndex,
  currentImageIndex,
}: ThumbnailsProps) => {
  const [isAdjustImagesOpen, setIsAdjustImagesOpen] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [leftHookRef, isLeftEndVisible] = useIsVisibleOnDOM<HTMLDivElement>();
  const [rightHookRef, isRightEndVisible] = useIsVisibleOnDOM<HTMLDivElement>();
  const { contentPortalElement } = useModalData();
  const {
    uploadedImages,
    setUploadedImages,
    selectedFiles,
    resetSelectedFiles,
    setIsImagesDirty,
  } = useImagePostModalStore();
  const { handleFileSelection } = useHandleFileSelection(() =>
    setIsAdjustImagesOpen(true),
  );
  const { getInputProps, open } = useMultiImageUpload({
    handleFileSelection,
    selectedFiles,
    uploadedFilesCount: uploadedImages.length,
  });
  const { removeImage } = useRemoveImage();

  const dndImages = uploadedImages.map(image => ({
    ...image,
    id: image.signed_id,
    originalId: image.id,
  }));
  const currentImage = dndImages[currentImageIndex];

  return (
    <div className="relative mt-5 flex">
      {!isLeftEndVisible && (
        <Scroller
          direction="left"
          scrollableContainerRef={scrollableContainerRef}
        />
      )}
      <SortableList
        shouldUseKeyboardSensor={false}
        distance={10}
        items={dndImages}
        onSort={(images: any) => {
          const newIndex = images.indexOf(currentImage);

          if (newIndex !== currentImageIndex) {
            setCurrentImageIndex(newIndex);
          }
          setUploadedImages(
            images.map((image: any) => ({ ...image, id: image.originalId })),
          );
          setIsImagesDirty(true);
        }}
        restrictToParentElement
      >
        <SortableContext>
          <div
            className="scrollbar-hide m-auto !flex items-center overflow-x-scroll px-1 pt-1"
            ref={scrollableContainerRef}
          >
            <div className="h-px w-px" ref={leftHookRef} />
            {uploadedImages.map((image, index) => (
              <Item
                tabIndex={-1}
                item={dndImages[index]}
                disabled={uploadedImages.length === 1}
                placeholder={ThumbnailImagePlaceholder}
                className="group relative !w-auto shrink-0"
                key={image.signed_id}
                dataTestId="thumbnail-item"
              >
                <DeleteImageButton
                  onClick={() => removeImage(image)}
                  index={index}
                  currentImageIndex={currentImageIndex}
                />
                <ThumbnailImage
                  image={image}
                  index={index}
                  currentImageIndex={currentImageIndex}
                  onClick={setCurrentImageIndex}
                />
              </Item>
            ))}
            {uploadedImages.length < MAX_IMAGES && (
              <>
                <button
                  type="button"
                  className="hover:bg-secondary text-default ml-1 flex h-12 w-12 shrink-0 cursor-pointer items-center justify-center rounded-md border border-dashed"
                  onClick={() => {
                    resetSelectedFiles();
                    open();
                  }}
                >
                  <input {...getInputProps()} />
                  <Icon type="20-add-lg" size={20} />
                </button>
                {isAdjustImagesOpen && (
                  <AdjustImages
                    onClose={() => setIsAdjustImagesOpen(false)}
                    onCompleted={() =>
                      setTimeout(
                        () =>
                          scroll({
                            direction: "right",
                            scrollableContainerRef,
                          }),
                        200,
                      )
                    }
                    isOnTop
                  />
                )}
              </>
            )}
            <div className="h-px w-px" ref={rightHookRef} />
          </div>
        </SortableContext>
        <DragOverlay portalTo={contentPortalElement}>
          <ThumbnailImagePlaceholder isOverlay />
        </DragOverlay>
      </SortableList>
      {!isRightEndVisible && (
        <Scroller
          direction="right"
          scrollableContainerRef={scrollableContainerRef}
        />
      )}
    </div>
  );
};
