import type { RefObject } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react/components/shared/Icon";

interface ScrollerProps {
  direction: "left" | "right";
  scrollableContainerRef: RefObject<HTMLDivElement>;
}

export const scroll = ({
  direction,
  scrollableContainerRef,
}: ScrollerProps) => {
  const offset = direction === "left" ? -200 : 200;
  scrollableContainerRef?.current?.scroll({
    left: scrollableContainerRef?.current.scrollLeft + offset,
    behavior: "smooth",
  });
};

export const Scroller = ({
  direction,
  scrollableContainerRef,
}: ScrollerProps) => {
  const handleScroll = () => scroll({ direction, scrollableContainerRef });

  const isToLeft = direction === "left";
  const isToRight = direction === "right";

  return (
    <div
      className={classNames("absolute z-30 flex h-full items-center", {
        "-left-px": isToLeft,
        "-right-px": isToRight,
      })}
    >
      {isToRight && (
        <div className="from-primary h-full w-8 bg-gradient-to-l to-transparent" />
      )}
      <button
        type="button"
        onClick={handleScroll}
        className="bg-primary group flex h-full cursor-pointer items-center px-2 py-1"
      >
        <Icon
          size={16}
          className={classNames(
            {
              "-rotate-90": isToRight,
              "rotate-90": isToLeft,
            },
            "text-light group-hover:text-darkest",
          )}
          type="caret-down-bold"
        />
      </button>
      {isToLeft && (
        <div className="from-primary h-full w-8 bg-gradient-to-r to-transparent" />
      )}
    </div>
  );
};
