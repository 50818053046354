import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { useDownloadCSV } from "./hooks/useDownloadCSV";

export const DownloadCSVButton = ({ event }) => {
  const onDownloadCSV = useDownloadCSV(event);

  return (
    <Button
      onClick={onDownloadCSV}
      variant="link"
      className="!text-dark !flex !items-center !px-0 !text-sm !font-normal"
    >
      <span className="mr-2">{t("events.edit_modal.people.download_csv")}</span>
      <Icon type="download" size={20} />
    </Button>
  );
};

DownloadCSVButton.propTypes = {
  event: PropTypes.object.isRequired,
};
