import { useState } from "react";
import classnames from "classnames";
import { AdjustImages } from "../../AdjustImages";
import { MultiImageDropZone } from "../../UploadImages/MultiImageDropZone";
import { useHandleFileSelection } from "../../UploadImages/hooks/useHandleFileSelection";
import { useMultiImageUpload } from "../../UploadImages/hooks/useMultiImageUpload";
import { useImagePostModalStore } from "../../store";

export const EmptyImages = () => {
  const [isAdjustImagesOpen, setIsAdjustImagesOpen] = useState(false);
  const { selectedFiles, uploadedImages } = useImagePostModalStore();
  const { handleFileSelection } = useHandleFileSelection(() =>
    setIsAdjustImagesOpen(true),
  );
  const { isDragActive, ...dropZoneProps } = useMultiImageUpload({
    handleFileSelection,
    selectedFiles,
    uploadedFilesCount: uploadedImages.length,
  });
  return (
    <div
      className={classnames(
        "flex h-full w-full flex-col items-center justify-center rounded-md px-4 text-center",
        { "!bg-light-blue": isDragActive },
      )}
    >
      <MultiImageDropZone
        isDragActive={isDragActive}
        {...dropZoneProps}
        className="pb-0"
      />
      {isAdjustImagesOpen && (
        <AdjustImages onClose={() => setIsAdjustImagesOpen(false)} isOnTop />
      )}
    </div>
  );
};
