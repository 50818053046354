import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useRemoveCohostMutation = (event, onSuccess) => {
  const { success, error } = useToast();
  const queryClient = useQueryClient();
  const removeCohostMutation = useMutation(
    attendeeId =>
      eventApi.removeAttendeeById({
        event,
        attendeeId,
        forReactQuery: true,
      }),
    {
      onSuccess: () => {
        success(t("events.event_attendee_deleted"));
        queryClient.invalidateQueries("events-people-cohost");
        queryClient.invalidateQueries("events-people-participant");
        onSuccess && onSuccess();
      },
      onError: () => error(t("events.can_not_delete_event_attendee")),
    },
  );

  return {
    removeCohostMutation,
  };
};
