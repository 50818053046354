import { useEffect, useState } from "react";
import { COMMENT_ANCHOR_PREFIX } from "./constants";
import { useCommentFromLocationHash } from "./useCommentFromLocationHash";

export const useJumpToComment = ({ enabled = true }) => {
  const [hasJumpedToComment, setHasJumpedToComment] = useState(false);
  const { commentId } = useCommentFromLocationHash();

  useEffect(() => {
    if (enabled && commentId && !hasJumpedToComment) {
      // The delay fixes an issue with incorrect scrollTo position and ensures
      // that the current comment form focus logic doesn't break this behavior.
      // It still causes the page to "jump around".
      const timeoutId = setTimeout(() => {
        setHasJumpedToComment(true);

        const element = document.querySelector(
          `${COMMENT_ANCHOR_PREFIX}${commentId}`,
        );

        if (element) {
          element.scrollIntoView();
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [enabled, commentId, hasJumpedToComment]);
};
