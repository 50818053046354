import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { TruncateText } from "@circle-react/components/shared/uikit/TruncateText";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { SCROLLABLE_TARGET_ID } from "../../../utils";
import { useEntityPreviewsApi } from "./hooks/useEntityPreviewsApi";

export interface MembersPreviewProps {
  spaceIds: any[];
  spaceGroupIds: any[];
  memberTagIds: any[];
  memberIds: any[];
}

export const MembersPreview = ({
  spaceIds,
  spaceGroupIds,
  memberTagIds,
  memberIds,
}: MembersPreviewProps) => {
  const { records, fetchNextPage, hasNextPage } = useEntityPreviewsApi(
    spaceIds,
    spaceGroupIds,
    memberTagIds,
    memberIds,
  );

  return (
    <InfiniteScroll
      next={fetchNextPage}
      hasMore={hasNextPage}
      scrollableTarget={SCROLLABLE_TARGET_ID}
      loader={<LoaderRow />}
      dataLength={records?.length || 0}
    >
      <div className="flex w-full flex-col">
        {records?.map(({ id, avatar_url, name, email }: any) => (
          <div
            className="hover:bg-secondary group flex h-12 items-center rounded-md border-none px-2"
            key={id}
          >
            <div className="mr-3">
              <UserImage src={avatar_url} name={name} size="8" />
            </div>
            <div className="w-1/3">
              <TruncateText>
                <Typography.LabelSm weight="semibold">
                  {name}
                </Typography.LabelSm>
              </TruncateText>
            </div>
            <div className="flex grow justify-end">
              <TruncateText>
                <Typography.LabelSm>{email}</Typography.LabelSm>
              </TruncateText>
            </div>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
};
