import { forwardRef } from "react";
import { noop } from "lodash";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useChatRoomContext } from "@circle-react/hooks/chatsV2/useChatRoomContext";
import { showDateChangeLine, showUnreadMessageLine } from "../helpers";
import { MessageActions } from "./MessageActions";
import { MessageBlock } from "./MessageBlock";
import { MessageIndicatorLines } from "./MessageIndicatorLines";
import { MessageItemWrapper } from "./MessageItemWrapper";
import { RepliesBlock } from "./RepliesBlock";
import { useMessageItem } from "./hooks";

export interface MessageItemProps {
  chatRoom: any;
  chatThreadsEnabled?: boolean;
  currentParticipant: any;
  disableUnreadMessageLine?: boolean;
  displayDayChange?: boolean;
  isInitialPage?: boolean;
  isLastMessage?: boolean;
  isParentMessage?: boolean;
  isPinned?: boolean;
  isPinnedMessageAtTop?: boolean;
  message: any;
  messageBodyClassName?: string;
  messageHashId?: string;
  onBookmarkToggle?: (messageId: any) => void;
  participants: any[];
  roles?: any;
  setUnreadMessageFlow?: (value: boolean) => void;
  unreadMessageId?: string;
  isLiveStream?: boolean;
  messagesContainerId?: string;
}

export const MessageItem = forwardRef<any, MessageItemProps>(
  (
    {
      chatRoom,
      chatThreadsEnabled = false,
      currentParticipant,
      disableUnreadMessageLine = false,
      displayDayChange = false,
      isInitialPage = false,
      isLastMessage = false,
      isParentMessage = false,
      isPinned = false,
      isPinnedMessageAtTop = false,
      message,
      messageBodyClassName,
      messageHashId,
      onBookmarkToggle,
      participants,
      roles = {},
      setUnreadMessageFlow,
      unreadMessageId,
      isLiveStream,
      messagesContainerId,
    },
    prevDayRef,
  ) => {
    const {
      canUserModifyMessage,
      day,
      handleSubmit,
      id,
      isDayChange,
      isEditMessage,
      isViewOnlyMasquerading,
      setEditMessageId,
      shouldShowMessageActions,
      shouldShowRepliesBlock,
      toggleDeleteConfirmationModal,
    } = useMessageItem({
      chatRoom,
      chatThreadsEnabled,
      currentParticipant,
      isInitialPage,
      isLastMessage,
      message,
      participants,
      prevDayRef,
    });

    const { isMessaging } = useChatRoomContext();
    const chatRoomUrl = isMessaging ? chatRoomPath(chatRoom) : "";

    return (
      <>
        <MessageIndicatorLines
          showDateChangeLine={showDateChangeLine(displayDayChange, isDayChange)}
          showUnreadMessageLine={showUnreadMessageLine({
            id,
            isPinned,
            disableUnreadMessageLine,
            unreadMessageId,
          })}
          day={day}
        />
        <MessageItemWrapper
          isPinnedMessageAtTop={isPinnedMessageAtTop}
          id={id}
          shouldShowMessageActions={shouldShowMessageActions}
          isEditMessage={isEditMessage}
          isPinned={isPinned}
          messageHashId={messageHashId}
          shouldShowRepliesBlock={shouldShowRepliesBlock}
          message={message}
        >
          <MessageBlock
            isModal={false}
            handleSubmit={handleSubmit}
            currentParticipant={currentParticipant}
            message={message}
            isInitialPage={isInitialPage}
            isEditMessage={isEditMessage}
            toggleDeleteConfirmationModal={
              isViewOnlyMasquerading ? noop : toggleDeleteConfirmationModal
            }
            isPinned={isPinned}
            roles={roles}
            isParentMessage={isParentMessage}
            chatRoom={chatRoom}
            isPinnedMessageAtTop={isPinnedMessageAtTop}
            participants={participants}
            messageBodyClassName={messageBodyClassName}
            chatRoomUrl={chatRoomUrl}
          />
          <MessageActions
            isPinned={isPinned}
            setEditMessageId={setEditMessageId}
            chatRoom={chatRoom}
            toggleDeleteConfirmationModal={
              isViewOnlyMasquerading ? noop : toggleDeleteConfirmationModal
            }
            message={message}
            currentParticipant={currentParticipant}
            canUserModifyMessage={canUserModifyMessage}
            chatThreadsEnabled={chatThreadsEnabled}
            isParentMessage={isParentMessage}
            isPinnedMessageAtTop={isPinnedMessageAtTop}
            onBookmarkToggle={onBookmarkToggle}
            setUnreadMessageFlow={setUnreadMessageFlow}
            isLiveStream={isLiveStream}
            messagesContainerId={messagesContainerId}
            chatRoomUrl={chatRoomUrl}
          />
          {shouldShowRepliesBlock && (
            <RepliesBlock message={message} chatRoom={chatRoom} />
          )}
        </MessageItemWrapper>
      </>
    );
  },
);

MessageItem.displayName = "MessageItem";
