import copyToClipboard from "copy-to-clipboard";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

interface ShareableLinkProps {
  label?: string;
  link?: string;
}

export const ShareableLink = ({
  label = t("share_link.label"),
  link = window.location.href,
}: ShareableLinkProps) => {
  const { success } = useToast();

  const handleOnClick = () => {
    copyToClipboard(link);
    success(t("share_link.copied"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };

  return (
    <button
      type="button"
      className="flex cursor-pointer items-center"
      onClick={handleOnClick}
    >
      <Typography.LabelSm weight="medium">{label}</Typography.LabelSm>
      <Icon type="16-copy" size={16} className="ml-1" aria-hidden />
    </button>
  );
};
