import { useFormContext } from "react-hook-form";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { ToggleItem } from "@circle-react/components/QuickPostV2/Settings/Form/ToggleItem";
import { isPublishedPost } from "@circle-react/helpers/postHelpers";

export const Toggles = ({ space }: any) => {
  const { post } = useCurrentPostHelpers();
  const { watch } = useFormContext();
  const isCommentsDisabled = watch("is_comments_disabled");
  const isEditingPublishedPost = Boolean(post.id) && isPublishedPost(post);
  return (
    <>
      <ToggleItem name="hide_meta_info" />
      <ToggleItem name="is_comments_disabled" />
      {!isCommentsDisabled && <ToggleItem name="is_comments_closed" />}
      <ToggleItem name="is_liking_disabled" />
      <ToggleItem name="is_truncation_disabled" />
      {isEditingPublishedPost && (
        <>
          <ToggleItem name="pin_to_top" />
          {!space?.hide_right_sidebar && <ToggleItem name="pinned_at" />}
        </>
      )}
      {!space?.hide_from_featured_areas && (
        <ToggleItem name="hide_from_featured_areas" />
      )}
    </>
  );
};
