import PropTypes from "prop-types";
import { noop } from "lodash";
import { Icon } from "@circle-react/components/shared/Icon";
import { useSmOrMdOrLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = ({
  title,
  memberCount,
  isDirect,
  description,
  onClose = noop,
}) => {
  const hasDescription = Boolean(!isDirect && description);
  const isSmOrMdScreen = useSmOrMdOrLgScreenMediaQuery();

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full items-center justify-between">
        <Typography.LabelMd as="h2" weight="semibold">
          {title}
        </Typography.LabelMd>
        {isSmOrMdScreen ? (
          <button type="submit" onClick={onClose}>
            <Icon
              type="close-v2"
              className="text-dark mr-1 mt-2.5"
              useWithFillCurrentColor
            />
          </button>
        ) : (
          <>
            {!isDirect && (
              <div className="flex">
                <div className="text-default mr-2 pb-0.5">
                  <Typography.LabelSm weight="medium">
                    {memberCount}
                  </Typography.LabelSm>
                </div>
                <Icon
                  type="space-chat-members"
                  className="text-dark"
                  useWithFillCurrentColor
                />
              </div>
            )}
          </>
        )}
      </div>
      {hasDescription && (
        <div className="text-default whitespace-pre-line">
          <Typography.BodySm>{description}</Typography.BodySm>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  memberCount: PropTypes.number,
  isDirect: PropTypes.bool,
  description: PropTypes.string,
  onClose: PropTypes.func,
};
