export default function EmptyState({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <div className="profile-drawer__empty">
      <div className="profile-drawer__empty__title">{title}</div>
      <div className="profile-drawer__empty__subtitle">{subtitle}</div>
    </div>
  );
}
