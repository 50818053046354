import { t } from "@/i18n-js/instance";
import { useAppendBodyClasses } from "@circle-react/hooks/useAppendBodyClasses";
import { Form } from "@circle-react-uikit/Form";
import { FormItem } from "../../../../Common";
import "./styles.scss";

export const InPersonLocation = () => {
  useAppendBodyClasses("location-picker-z-index-override");

  return (
    <>
      <FormItem
        name="event_setting_attributes.in_person_location"
        variant="extra-bottom-margin"
        translationRoot="events.create.form"
        rules={{
          required: t(
            "events.create_modal.event_setting_attributes.location_address_required_error",
          ),
        }}
      >
        <Form.LocationPicker autoFocus />
      </FormItem>
      <FormItem
        name="event_setting_attributes.hide_location_from_non_attendees"
        inlineReverse
        translationRoot="events.create.form"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
    </>
  );
};
