import { useEffect, useState } from "react";
import { omit } from "lodash";
import { INTERVAL_OPTION_MAPPER } from "../INTERVAL_OPTION_MAPPER";

const DEFAULT_INTERVAL_OPTION = "monthly";
const ONETIME = "onetime";
const SUBSCRIPTION = "subscription";

const generateTempId = () => `temp-id-${Math.floor(Math.random() * 1000000)}`;

export const usePaywallPrices = (paywallPrices, pricesMaxCount) => {
  const [currentPaywallPrices, setCurrentPaywallPrices] = useState(
    paywallPrices || [],
  );
  const [intervalOption, setIntervalOption] = useState(DEFAULT_INTERVAL_OPTION);
  const [amount, setAmount] = useState("");
  const [changes, setChanges] = useState([]);
  const [hasSubscriptionPrices, setHasSubscriptionPrices] = useState(false);

  useEffect(() => {
    const calculateChanges = () => {
      // Get added elements
      const added = currentPaywallPrices
        .filter(paywallPrice => !!paywallPrice.tempId)
        .map(filtered => omit(filtered, ["tempId", "interval_label"]));

      // Get removed elements
      const removed = paywallPrices
        .filter(
          paywallPrice =>
            !currentPaywallPrices.some(
              currPaywallPrice => currPaywallPrice.id === paywallPrice.id,
            ),
        )
        .map(removedPaywallPrice => ({
          ...removedPaywallPrice,
          _destroy: "1",
        }));

      // Check for preferred element changes
      const prevPreferred = paywallPrices.find(
        paywallPrice => paywallPrice.preferred,
      );
      const currPreferred = currentPaywallPrices.find(
        paywallPrice => paywallPrice.preferred,
      );
      const edited = [];

      // eslint-disable-next-line sonarjs/no-collapsible-if -- Disabled to set CI to fail on this issue on new files, PR #6718
      if (prevPreferred?.id && prevPreferred?.id !== currPreferred?.id) {
        // Check that prevPreferred still exists
        if (
          currentPaywallPrices.find(
            paywallPrice => paywallPrice.id === prevPreferred?.id,
          )
        ) {
          edited.push({
            id: prevPreferred.id,
            preferred: false,
          });
        }
      }

      // Insert new preferred if it is not new
      if (
        typeof currPreferred?.id !== "undefined" &&
        prevPreferred?.id !== currPreferred?.id
      ) {
        edited.push({
          id: currPreferred.id,
          preferred: true,
        });
      }

      const changes = [...added, ...removed, ...edited];
      setChanges(changes.map(change => JSON.stringify(change)));

      setHasSubscriptionPrices(
        currentPaywallPrices.some(paywallPrice => paywallPrice.interval),
      );
    };

    calculateChanges();
  }, [currentPaywallPrices]);

  const onIntervalOptionChange = event => {
    setIntervalOption(event.target.value);
  };

  const onAmountChange = event => {
    const amount = event.target.value;
    if (!amount) {
      setAmount("");
      return;
    }
    const validatedAmount = amount < 1 ? 1 : amount;
    setAmount(validatedAmount);
  };

  const resetValues = () => {
    setIntervalOption(DEFAULT_INTERVAL_OPTION);
    setAmount("");
  };

  const onAdd = event => {
    event.preventDefault();
    if (
      !intervalOption ||
      !amount ||
      currentPaywallPrices.length > pricesMaxCount
    ) {
      return;
    }

    const intervalOptionValues = INTERVAL_OPTION_MAPPER[intervalOption];
    const price_type = !intervalOptionValues.interval ? ONETIME : SUBSCRIPTION;
    const integerAmount = Math.round(amount * 100);

    setCurrentPaywallPrices([
      ...currentPaywallPrices,
      {
        tempId: generateTempId(),
        amount: integerAmount,
        interval: intervalOptionValues.interval,
        interval_count: intervalOptionValues.interval_count,
        interval_label: I18n.t(
          `settings.paywall_prices.form.interval_select_options.${intervalOptionValues.interval_label}_label`,
        ),
        price_type,
        preferred: false,
      },
    ]);
    resetValues();
  };

  const onRemove = atIndex => {
    if (atIndex < 0 || atIndex >= currentPaywallPrices.length) {
      return;
    }
    const newCurrentPaywallPrices = [...currentPaywallPrices];
    newCurrentPaywallPrices.splice(atIndex, 1);
    setCurrentPaywallPrices(newCurrentPaywallPrices);
  };

  const onSetPreferred = atIndex => {
    if (atIndex < 0 || atIndex >= currentPaywallPrices.length) {
      return;
    }

    const newCurrentPaywallPrices = [...currentPaywallPrices];
    const prevPreferredIndex = newCurrentPaywallPrices.findIndex(
      paywallPrice => paywallPrice.preferred,
    );

    // eslint-disable-next-line sonarjs/no-collapsible-if -- Disabled to set CI to fail on this issue on new files, PR #6718
    if (prevPreferredIndex !== atIndex) {
      if (typeof prevPreferredIndex !== "undefined") {
        newCurrentPaywallPrices[prevPreferredIndex] = {
          ...newCurrentPaywallPrices[prevPreferredIndex],
          preferred: false,
        };
      }
    }

    newCurrentPaywallPrices[atIndex] = {
      ...newCurrentPaywallPrices[atIndex],
      preferred: true,
    };
    setCurrentPaywallPrices(newCurrentPaywallPrices);
  };

  return {
    currentPaywallPrices,
    intervalOption,
    onIntervalOptionChange,
    amount,
    onAmountChange,
    onAdd,
    onRemove,
    onSetPreferred,
    changes,
    hasSubscriptionPrices,
  };
};
