import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT } from "@circle-react-uikit/Rail";
import {
  APP_HEADER_HEIGHT,
  LIVE_STEAM_HEADER_HEIGHT,
  getCoursesHeaderHeight,
  getHeightWhenMessagingModalIsOpen,
  isBookmarksBarVisible,
  isMobileBottomNavVisible,
} from "./helpers";

export const calculateScrollHeight = ({
  searchInputClientHeight = 0,
  footerClientHeight = 0,
  isSmOrMdScreen = false,
  isLiveStream = false,
  isInCourseSpace = false,
  isDrawer = false,
  isMessagingModalOpen = false,
}) => {
  let height = footerClientHeight;

  if (searchInputClientHeight) {
    height += searchInputClientHeight;
  }

  if (isMobileBottomNavVisible()) {
    height += BOTTOM_NAV_HEIGHT;
  }

  if (!isInCourseSpace && isBookmarksBarVisible()) {
    height += 40;
  }

  if (isSmOrMdScreen) {
    height +=
      isLiveStream || isInCourseSpace || isDrawer
        ? HEADER_HEIGHT
        : APP_HEADER_HEIGHT + HEADER_HEIGHT;
  } else {
    if (isLiveStream) {
      height = LIVE_STEAM_HEADER_HEIGHT;
    } else if (isInCourseSpace) {
      height = getCoursesHeaderHeight();
    } else if (isDrawer) {
      height = HEADER_HEIGHT;
    }
  }

  if (isMessagingModalOpen) {
    height = getHeightWhenMessagingModalIsOpen(height);
  }

  return height;
};
