import { noop } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";

export const embeddableChatApiQueryKey = chatRoomUuid =>
  `embeddable-chat-room-${chatRoomUuid}`;

export const useEmbeddableChatApi = chatRoomUuid => {
  const queryClient = useQueryClient(reactQueryConfig);

  const updateQueryCache = newData => {
    queryClient.setQueryData(
      embeddableChatApiQueryKey(chatRoomUuid),
      (oldData = {}) => ({
        ...oldData,
        chat_room: newData,
      }),
    );
  };

  const {
    isLoading,
    refetch: refetchChatRoom,
    data: { chat_room: chatRoom } = {},
  } = useQuery({
    queryKey: embeddableChatApiQueryKey(chatRoomUuid),
    queryFn: () =>
      reactQueryGet(internalApi.chatRooms.show({ uuid: chatRoomUuid })),
    enabled: !!chatRoomUuid && chatRoomUuid !== "new",
    refetchOnWindowFocus: false,
  });

  const onChatRoomDetailsUpdated = (eventData = {}) => {
    const { chat_room } = eventData;
    updateQueryCache({
      ...(chatRoom || {}),
      chat_room_name: chat_room.chat_room_name,
      chat_room_description: chat_room.chat_room_description,
      chat_room_show_history: chat_room.chat_room_show_history,
      pinned_message: chat_room.pinned_message,
    });
  };

  const onEventReceive = eventData => {
    if (eventData.event === "chatRoomUpdated") {
      onChatRoomDetailsUpdated(eventData);
    }
    if (
      eventData.event === "updatedMessage" &&
      chatRoom.pinned_message?.id === eventData.json_message.id
    ) {
      updateQueryCache({
        ...(chatRoom || {}),
        pinned_message: eventData.json_message,
      });
    }
    if (
      eventData.event === "deletedMessage" &&
      chatRoom.pinned_message?.id === eventData.id
    ) {
      updateQueryCache({
        ...(chatRoom || {}),
        pinned_message: null,
      });
    }
  };

  return {
    isLoading,
    chatRoom,
    refetchChatRoom: chatRoomUuid ? refetchChatRoom : noop,
    onEventReceive,
  };
};
