import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Header, useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { isDraft, isRecurring } from "../../utils";
import { SubmitEditButton } from "../SubmitEditButton";
import { PreviewEventLink } from "./PreviewEventLink";

export const Actions = ({ event, onPublish, className }) => {
  const { isSaving } = useCustomizeUIData();

  const isEventDraft = isDraft(event);

  return (
    <Header.Actions
      className={classNames(
        "justify-between px-5 pb-6 pt-2 md:justify-end md:p-0",
        className,
      )}
    >
      {event && <PreviewEventLink event={event} isEventDraft={isEventDraft} />}
      {isEventDraft && (
        <SubmitEditButton
          variant="circle"
          disabled={isSaving}
          onEditModeConfirm={onPublish}
          isRecurringEvent={isRecurring(event)}
          isPublishing
        >
          {t("events.edit_modal.buttons.publish")}
        </SubmitEditButton>
      )}
    </Header.Actions>
  );
};

Actions.propTypes = {
  event: PropTypes.object,
  onPublish: PropTypes.func.isRequired,
  className: PropTypes.string,
};
