import PropTypes from "prop-types";
import { Popover } from "@headlessui/react";
import { noop } from "lodash";
import { createPortal } from "react-dom";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { Rail } from "@circle-react-uikit/Rail";

export const RailBarPortal = ({ position = "header", children, ...props }) => {
  const { shouldShowRailBar = true, toggleShowRailBar = noop } = useRailbar();

  const selectorQuery = `#rail-bar-${position}`;
  const portalElement = document.querySelector(selectorQuery);
  if (!portalElement) {
    return null;
  }
  return (
    <>
      {createPortal(
        <Popover>
          <Popover.Panel as="div" static={shouldShowRailBar}>
            <Rail toggleShowRailBar={toggleShowRailBar} {...props}>
              {children}
            </Rail>
          </Popover.Panel>
        </Popover>,
        portalElement,
      )}
    </>
  );
};

RailBarPortal.propTypes = {
  position: PropTypes.oneOf(["header", "content", "portal", "drawer"]),
  children: PropTypes.node,
};
