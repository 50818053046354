import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ModerationInfoModal } from "./ModerationInfoModal";
import { Regular } from "./components/Regular";
import { Short } from "./components/Short";

export const ModerationInfo = Object.assign(
  {},
  {
    Regular,
    Short,
  },
);

export const useModerationInfoModal = () => useModal(ModerationInfoModal);
