import type { ImagePost } from "@/react/types";
import { Carousel } from "../FeedView/Carousel";
import { PostDetails } from "./PostDetails";

interface ContentProps {
  onPostChange: (post: ImagePost, options: { refetch: boolean }) => void;
  onPostDestroy: (postId: number) => void;
  startingImageIndex?: number;
  autofocusReplyForm: boolean;
}
export const Content = ({
  onPostChange,
  onPostDestroy,
  startingImageIndex,
  autofocusReplyForm,
}: ContentProps) => (
  <>
    <div className="flex max-h-full w-full flex-col overflow-auto lg:h-full lg:w-3/5 xl:w-2/3">
      <Carousel startingImageIndex={startingImageIndex} />
    </div>
    <div className="border-primary flex w-full flex-col lg:h-full lg:w-2/5 lg:overflow-scroll lg:border-l xl:w-1/3">
      <PostDetails
        onPostChange={onPostChange}
        onPostDestroy={onPostDestroy}
        autofocusReplyForm={autofocusReplyForm}
      />
    </div>
  </>
);
