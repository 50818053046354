import { INTERVAL_OPTION_MAPPER } from "./INTERVAL_OPTION_MAPPER";

export const addButtonLocked = (
  amount,
  intervalOption,
  currentPaywallPrices,
  pricesMaxCount,
) => {
  const duplicatedCurrentPrice = currentPaywallPrices.find(
    paywallPrice =>
      paywallPrice.amount === Math.round(amount * 100) &&
      paywallPrice.interval ===
        INTERVAL_OPTION_MAPPER[intervalOption].interval &&
      paywallPrice.interval_count ===
        INTERVAL_OPTION_MAPPER[intervalOption].interval_count,
  );

  return (
    amount.length < 1 ||
    currentPaywallPrices.length > pricesMaxCount - 1 ||
    !!duplicatedCurrentPrice
  );
};
