import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ReportsList } from "@circle-react/components/SettingsApp/Moderation/ReportFlaggedContentModal/ReportsList";
import { VisibilityInfo } from "@circle-react/components/SettingsApp/Moderation/ReportFlaggedContentModal/VisibilityInfo";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Button } from "@circle-react-uikit/Button";
import { useAproveRejectContent } from "../useApproveRejectContent";

const contentPortalId = "review-modal-content";
const i18nRoot = "settings.moderation.review_flagged_content_modal";

export interface ModerationInfoModalProps {
  flaggedContentId: string;
  flaggedContentType: "post" | "comment";
}

const ModerationInfoModalComponent = ({
  flaggedContentId,
  flaggedContentType,
}: ModerationInfoModalProps) => {
  const modal = useModal();

  const {
    approveFlaggedContent,
    rejectFlaggedContent,
    flaggedContent,
    isApproving,
    isRejecting,
  } = useAproveRejectContent(flaggedContentId, flaggedContentType);

  const CONTENT_TYPES_MAP = {
    POST: "post",
    COMMENT: "comment",
  };

  const { flagged_contentable, reports = [], status } = flaggedContent ?? {};

  const { type = "" } = flagged_contentable ?? {};

  const contentType = type.toLowerCase();
  const reportsCount = reports?.length;

  const onClickApprove = async () => {
    approveFlaggedContent();
    await modal.hide();
  };

  const onClickReject = async () => {
    rejectFlaggedContent();
    await modal.hide();
  };

  if (
    contentType !== CONTENT_TYPES_MAP.POST &&
    contentType !== CONTENT_TYPES_MAP.COMMENT
  ) {
    return null;
  }

  const shouldShowApproveButton = status === null || status === "rejected";
  const shouldShowRejectButton = status === null || status === "approved";

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      contentPortalId={contentPortalId}
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="sm">
            {t("flagged_contents.moderation_info.reports_title", {
              count: reportsCount,
            })}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>

        <Modal.Body className="flex flex-col !p-0">
          <VisibilityInfo content={flaggedContent} />
          <ReportsList reports={reports} />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          <>
            {shouldShowApproveButton && (
              <Button
                variant="secondary"
                onClick={onClickApprove}
                disabled={isApproving}
              >
                {isApproving
                  ? t([i18nRoot, "approving"])
                  : t([i18nRoot, "approve"])}
              </Button>
            )}
            {shouldShowRejectButton && (
              <Button
                variant="danger"
                onClick={onClickReject}
                disabled={isRejecting}
              >
                {isRejecting
                  ? t([i18nRoot, "rejecting"])
                  : t([i18nRoot, "reject"])}
              </Button>
            )}
          </>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const ModerationInfoModal = ModalManager.create(
  (props: ModerationInfoModalProps) => (
    <ModerationInfoModalComponent {...props} />
  ),
);
