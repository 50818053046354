import linkifyHtml from "linkifyjs/html";

export const linkifyTrixContent = (content: string): string =>
  linkifyHtml(content, {
    defaultProtocol: "https",
    target: {
      url: "_blank",
    },
    ignoreTags: [
      "a",
      "style",
      "script",
      "img",
      "iframe",
      "action-text-attachment",
      "video",
      "audio",
    ],
    validate: {
      url: (value: string) => /^[^A-Z]/.test(value),
    },
  });

export const setNoViewDownload = (trixContentElement: Element): void => {
  trixContentElement
    .querySelectorAll("video")
    .forEach((videoElement: HTMLVideoElement) => {
      videoElement.setAttribute("controlsList", "nodownload");
    });
};

interface MakeLinksOptions {
  containerElement: Element;
  shouldIncludeInternalLinks?: boolean;
}

export const makeAllLinksOpenInNewWindow = ({
  containerElement,
  shouldIncludeInternalLinks = false,
}: MakeLinksOptions): void => {
  containerElement
    .querySelectorAll("a")
    .forEach((element: HTMLAnchorElement) => {
      if (
        element.tagName === "A" &&
        !element.isContentEditable &&
        (shouldIncludeInternalLinks || element.host !== window.location.host)
      ) {
        element.setAttribute("target", "_blank");
        element.setAttribute("rel", "noreferrer");
      }
    });
};

export const transformDataBackgroundImage = (
  trixContentElement: Element,
): void => {
  trixContentElement
    .querySelectorAll("[data-background-image]")
    .forEach((element: Element) => {
      const backgroundImage =
        element.getAttribute("data-background-image") || "";
      const image = document.createElement("img");
      image.src = backgroundImage;
      element.setAttribute(
        "style",
        `background-image: url("${backgroundImage}")`,
      );
      image.onload = function () {
        const parentElement = element.parentElement;
        if (parentElement) {
          parentElement.classList.add("show");
          parentElement.classList.remove("hide");
        }
      };
    });
};

export function setupAttachmentDownload(trixContentElement: Element): void {
  trixContentElement
    .querySelectorAll("[data-target~='downloadAttachment']")
    .forEach((element: Element) => {
      element.addEventListener("click", (event: Event) => {
        if (event.currentTarget instanceof HTMLElement) {
          event.preventDefault();
          event.stopPropagation();
          const dataset = event.currentTarget.dataset;
          if (dataset.url && dataset.fileName && dataset.logUrl) {
            downloadAttachment({
              url: dataset.url,
              fileName: dataset.fileName,
              logUrl: dataset.logUrl,
            });
          }
        }
      });
    });
}

export function downloadAttachment(dataset: {
  url: string;
  fileName: string;
  logUrl: string;
}): void {
  const { url, fileName, logUrl } = dataset;
  download(url, fileName);
  trackDownload(logUrl);
}

function download(url: string, filename: string): void {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = filename;
  anchor.setAttribute("download", filename);
  anchor.click();
}

function trackDownload(url: string): void {
  void fetch(url, { method: "POST" });
}
