import { useModerationInfoModal } from "..";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { WarningNotice } from "@circle-react-uikit/Notice/WarningNotice";
import { Typography } from "@circle-react-uikit/Typography";
import type { ModerationInfoBaseProps } from "./shared";
import { getContentTranslation, shouldRenderContent } from "./shared";

export const Short = ({ record, contentType }: ModerationInfoBaseProps) => {
  const moderationModal = useModerationInfoModal();

  if (!shouldRenderContent(record)) return null;

  const contentTranslation = getContentTranslation(contentType);
  const shortText = t("flagged_contents.moderation_info.short_content", {
    content: contentTranslation,
  });

  return (
    <button
      type="button"
      onClick={() =>
        moderationModal.show({
          flaggedContentId: record.id,
          flaggedContentType: contentType,
        })
      }
      className="dark:bg-primary cursor-pointer rounded-lg bg-amber-50"
    >
      <WarningNotice
        className="!py-2"
        iconEl={<Icon className="text-v2-warning" type="20-info" size={20} />}
      >
        <Typography.LabelSm>{shortText}</Typography.LabelSm>
      </WarningNotice>
    </button>
  );
};
