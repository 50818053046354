import { useState } from "react";
import { useWebSocket } from "@circle-react/hooks/useWebSocket";
import type {
  MediaTranscoding,
  MediaTranscodingChannelResponse,
  MediaTranscodingStatus,
} from "@circle-react/types/MediaTranscoding";

interface MediaTranscodingOptions {
  enabled?: boolean;
  signedId?: string;
  mediaTranscoding?: MediaTranscoding;
  onSuccess?: (hlsUrl: string | null) => void;
  onError?: () => void;
}

const isTranscodingInProgress = (status: MediaTranscodingStatus) =>
  status === "pending" || status === "started";

export const useMediaTranscoding = ({
  enabled = true,
  signedId,
  mediaTranscoding,
  onSuccess,
  onError,
}: MediaTranscodingOptions) => {
  const [status, setStatus] = useState<MediaTranscodingStatus>(
    mediaTranscoding?.status ?? "pending",
  );

  const shouldListenForTranscodingUpdates =
    enabled && Boolean(signedId) && isTranscodingInProgress(status);

  useWebSocket({
    canCreateConnection: shouldListenForTranscodingUpdates,
    channel: "MediaTranscodingChannel",
    blob_signed_id: signedId,
    onMessageReceive: (data: MediaTranscodingChannelResponse) => {
      if (data.event !== "transcodingStatusUpdated") {
        return;
      }

      setStatus(data.json_message.status);

      if (data.json_message.status === "succeeded") {
        onSuccess?.(data.json_message.hls_url);
      } else if (data.json_message.status === "failed") {
        onError?.();
      }
    },
  });

  return { status };
};
