import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const OnboardingComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnboardingPages" */ "@circle-react/routes/Authentication/OnboardingPages"
    ),
);

export const OnboardingPage = () => (
  <Suspense fallback={<PageLoader />}>
    <OnboardingComponent />
  </Suspense>
);
