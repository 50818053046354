import differenceInSeconds from "date-fns/differenceInSeconds";
import differenceInWeeks from "date-fns/differenceInWeeks";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import pt from "javascript-time-ago/locale/pt";
import { t } from "@/i18n-js/instance";
import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(pt);

export const dateToTimeAgoFormats = {
  default: "default",
  short: "short",
} as const;

const isJustNow = (date: Date, threshold = 60) => {
  const now = new Date();
  return differenceInSeconds(now, date) < threshold;
};

const isCurrentWeek = (date: Date) => {
  const now = new Date();
  return differenceInWeeks(now, date) === 0;
};

export const dateStringToTimeAgo = (dateString: string, options?: Options) =>
  dateToTimeAgo(new Date(dateString), options);

interface Options {
  format?: "default" | "short";
}

export const dateToTimeAgo = (date: Date, options?: Options) => {
  const format = options?.format || "default";
  const locale = getCurrentBcp47Locale();
  const timeAgo = new TimeAgo(locale);

  if (format === "short") {
    if (isJustNow(date)) {
      return t("time_format.just_now");
    }

    if (isCurrentWeek(date)) {
      return timeAgo.format(date, "mini");
    }

    return timeAgo.format(date, "twitter-minute-now");
  }

  return timeAgo.format(date);
};
