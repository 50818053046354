import { useWatch } from "react-hook-form";
import { isPaymentProcessorEnabled } from "@/react/helpers/communityHelpers";
import { StripeConnect } from "@circle-react/components/EventsV3/Modal/Tabs/BasicInfo/Ticket/StripeConnect";
import { usePunditUserContext } from "@circle-react/contexts";
import { TICKET_TYPES } from "../../../../utils";
import { PaidTicket } from "../PaidTicket";

export const TicketContent = ({ event }) => {
  const { currentCommunity } = usePunditUserContext();

  const ticketType = useWatch({
    name: "event_setting_attributes.ticket_type",
  });

  if (ticketType === TICKET_TYPES.FREE) {
    return <null />;
  }

  if (
    ticketType === TICKET_TYPES.PAID &&
    !isPaymentProcessorEnabled(currentCommunity)
  ) {
    return <StripeConnect />;
  }

  return <PaidTicket event={event} />;
};
