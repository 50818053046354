import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { abbreviateNumber } from "@circle-react/helpers/number";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import type { Post } from "@circle-react/types/Post";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ShowPageLink } from "../PostViewListItem/ShowPageLink";

export interface CommentButtonProps {
  post: Post;
  comments_count: number;
  className?: string;
  showCount?: boolean;
  onCommentsClick?: () => void;
  iconClassName: "text-default" | "text-dark";
}

export const CommentButton = ({
  post,
  comments_count,
  className,
  showCount = true,
  onCommentsClick,
  iconClassName = "text-default",
}: CommentButtonProps) => (
  <div className={classnames("col-span-1", className)}>
    <TippyV2
      placement="bottom"
      content={t("post.comment_description")}
      interactive={false}
    >
      <ShowPageLink
        post={post}
        allComments
        ariaLabel={t("post.comment_description")}
        shouldOpenPostModal
        className={classnames(
          "action-item action-comment text-default focus-visible:outline-secondary group flex items-center justify-center rounded-md text-center transition-colors focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          {
            "hover:text-dark p-1": !isImagePost(post),
          },
        )}
        onClick={onCommentsClick}
      >
        <span className="action__icon inline-flex align-text-top">
          <Icon
            aria-hidden
            type="24-comment"
            className={classnames("group-hover:text-dark mr-1", iconClassName)}
            useWithFillCurrentColor
          />
        </span>
        {showCount && (
          <span className="count text-default group-hover:text-dark text-base font-medium tracking-tight">
            {abbreviateNumber(comments_count)}
          </span>
        )}
      </ShowPageLink>
    </TippyV2>
  </div>
);
