import { useLocation } from "react-router-dom";
import { COMMENT_ANCHOR_PREFIX } from "./constants";

export const useCommentFromLocationHash = () => {
  const { hash } = useLocation();
  const commentIdFromHash = hash.split(COMMENT_ANCHOR_PREFIX)[1];
  const commentId =
    (commentIdFromHash && parseInt(commentIdFromHash, 10)) || null;

  return { commentId };
};
