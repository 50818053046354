import { t } from "@/i18n-js/instance";
import { Pagination } from "@/react/components/EventsV3/Modal/Tabs/People/Pagination";
import { useEventAttendeesApi } from "@/react/components/EventsV3/Modal/hooks/useEventAttendeesApi";
import { ATTENDEE_ROLES } from "@/react/components/EventsV3/Modal/utils";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { Event } from "@circle-react/types/Event";
import { Modal } from "@circle-react-uikit/ModalV2";
import { MemberItem } from "./MemberItem";

interface AttendeesModalProps {
  event: Event;
}

const AttendeesModalComponent = ({ event }: any) => {
  const modal = useModal();
  const {
    records,
    fetchPrevPage,
    fetchNextPage,
    hasPrevPage,
    hasNextPage,
    totalRecordCount,
    totalConfirmedRecordCount,
    startRecordNumber,
    endRecordNumber,
    resetPage,
  } = useEventAttendeesApi(event, ATTENDEE_ROLES.PARTICIPANT);

  return (
    <Modal
      isOpen={modal.visible}
      onClose={() => {
        resetPage();
        void modal.hide();
      }}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm" className="text-center">
            {totalConfirmedRecordCount} {t("events.attendees")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="overflow-y-auto md:!px-6">
          <div>
            {records?.length > 0 && (
              <div className="flex w-full flex-col">
                {records?.map((member: any) => (
                  <MemberItem key={member.id} member={member} />
                ))}
              </div>
            )}
            {totalRecordCount > 0 && (
              <Pagination
                startRecordNumber={startRecordNumber}
                endRecordNumber={endRecordNumber}
                totalRecordCount={totalRecordCount}
                fetchPrevPage={fetchPrevPage}
                fetchNextPage={fetchNextPage}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
              />
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const AttendeesModal = ModalManager.create((props: AttendeesModalProps) => (
  <AttendeesModalComponent {...props} />
));

export const useAttendeesModal = () => useModal(AttendeesModal);
