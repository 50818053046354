import SparkMD5 from "spark-md5";
import { directUploadPath } from "@circle-react/helpers/urlHelpers";

export const getUploadInfo = async file => {
  const checksum = await createFileChecksum(file);
  const authenticity_token = document.querySelector(
    "meta[name=csrf-token]",
  ).content;

  const response = await fetch(directUploadPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify({
      blob: {
        filename: file.name,
        content_type: file.type,
        byte_size: file.size,
        checksum: checksum,
      },
    }),
  });

  return await response.json();
};

const createFileChecksum = async file =>
  new Promise((resolve, reject) => {
    const chunkSize = 2097152; // 2MB
    const chunkCount = Math.ceil(file.size / chunkSize);
    var chunkIndex = 0;

    const md5Buffer = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    const readNextChunk = () => {
      if (chunkIndex < chunkCount || (chunkIndex === 0 && chunkCount === 0)) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const fileSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        const bytes = fileSlice.call(file, start, end);
        fileReader.readAsArrayBuffer(bytes);
        chunkIndex++;
        return true;
      }
      return false;
    };

    fileReader.addEventListener("load", event => {
      md5Buffer.append(event.target.result);

      if (!readNextChunk()) {
        const binaryDigest = md5Buffer.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    });

    fileReader.addEventListener("error", () =>
      reject(`Error reading ${file.name}`),
    );

    readNextChunk();
  });
