import { t } from "@/i18n-js/instance";
import {
  communitySignupUrl,
  shouldShowSignupLink,
} from "@circle-react/helpers/communityHelpers";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { Typography } from "@circle-react-uikit/Typography";

interface CommentsBanner {
  isIframeEmbed?: boolean;
  containerClassNames: string;
  currentCommunity: any;
}

export const CommentsBanner = ({
  isIframeEmbed,
  containerClassNames,
  currentCommunity,
}: CommentsBanner) => {
  const blankTarget = isIframeEmbed
    ? { target: "_blank", rel: "noreferrer" }
    : {};

  const doesCommunityHavePrivateSignUpUrl = Boolean(
    currentCommunity?.private_sign_up_url,
  );

  const hasSignupLink =
    shouldShowSignupLink(currentCommunity) || doesCommunityHavePrivateSignUpUrl;

  const signupUrl = doesCommunityHavePrivateSignUpUrl
    ? currentCommunity.private_sign_up_url
    : communitySignupUrl(currentCommunity);

  return (
    <div className={containerClassNames}>
      <div className="mb-3 text-center">
        <Typography.LabelLg weight="semibold">
          {t("login_to_comment")}
        </Typography.LabelLg>
      </div>
      <div className="flex items-center justify-center gap-4">
        <a
          className="focus-visible:outline-secondary border-hover bg-primary text-dark hover:text-darkest focus-visible:text-darkest rounded-md border px-[18px] py-[6px] text-sm font-semibold transition-colors duration-150 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          href={newSessionPath({
            params: { post_login_redirect: window.location.href },
          })}
          {...blankTarget}
        >
          {t("log_in")}
        </a>
        {hasSignupLink && (
          <a
            className="signup-link btn btn-primary focus-visible:!outline-secondary !bg-brand !text-brand-button !h-auto !rounded-md !px-[18px] !py-[6px] !text-sm !font-bold !leading-5 !transition-colors !duration-150 hover:!opacity-90 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
            href={signupUrl}
            {...blankTarget}
          >
            {doesCommunityHavePrivateSignUpUrl
              ? currentCommunity.private_sign_up_label
              : t("sign_up")}
          </a>
        )}
      </div>
    </div>
  );
};
