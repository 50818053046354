import { useState } from "react";
import classnames from "classnames";
import type { UploadedImage } from "../../store";

interface ThumbnailImageProps {
  image: UploadedImage;
  index: number;
  currentImageIndex: number;
  onClick: (index: number) => void;
}

export const ThumbnailImage = ({
  image,
  index,
  currentImageIndex,
  onClick,
}: ThumbnailImageProps) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <button
      type="button"
      onClick={() => onClick(index)}
      className={classnames(
        "cursor-pointer touch-none select-none rounded-lg border-2 border-transparent p-0.5",
        {
          "border-secondary": index === currentImageIndex && !isLoading,
          "bg-tertiary mx-1 h-12 w-12 animate-pulse": isLoading,
        },
      )}
    >
      <img
        loading="eager"
        src={image.url}
        className={classnames("h-12 w-12 rounded-md object-cover", {
          hidden: isLoading,
        })}
        onLoad={() => setIsLoading(false)}
        alt={image.filename}
      />
    </button>
  );
};
