import { ThreeUserRow } from "@circleco/ui-kit";

interface Avatar {
  id: number;
  name: string;
  avatar_url: string;
}

export interface ParticipantsAvatarBlockProps {
  avatars: Avatar[];
}

export const ParticipantsAvatarBlock = ({
  avatars = [],
}: ParticipantsAvatarBlockProps) => {
  const avatarItems = avatars.slice(0, 3);
  const avatarArr = avatarItems.map(({ name, avatar_url }: Avatar) => ({
    name: name,
    src: avatar_url,
  }));
  return (
    <div className="border-tertiary isolate flex items-center space-x-1 rounded-lg md:justify-center">
      <ThreeUserRow size="5" users={avatarArr} isFullRounded />
    </div>
  );
};
