import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useEventSpaces } from "@circle-react/components/Events/hooks/useEventSpaces";
import { FormTopicsPicker } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/FormTopicsPicker";
import type { Event } from "@circle-react/types";
import { SpaceSelect } from "@circle-react-shared/SpaceSelect";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common";

interface BasicProps {
  event: Event;
  canCreateTopics?: boolean;
  requireTopicSelection?: boolean;
}

export const Basic = ({
  event,
  canCreateTopics,
  requireTopicSelection,
}: BasicProps) => {
  const { spaces } = useEventSpaces();

  const isEditMode = !!event;
  const nameFieldClasses = isEditMode
    ? "w-full pr-0 md:pr-2 md:w-1/2"
    : "w-full";
  const topicsRules = requireTopicSelection
    ? { required: t("events.create.form.topics_required_error") }
    : { required: false };

  const { getValues } = useFormContext();
  const spaceId = getValues("space_id");

  return (
    <div className="my-6 md:my-8">
      <Typography.TitleMd>
        {t("events.create.form.event_name")}
      </Typography.TitleMd>
      <div className="flex flex-col items-stretch gap-2 md:flex-row">
        <div className={nameFieldClasses}>
          <FormItem
            name="name"
            rules={{
              required: t("events.create.form.name_required_error"),
              maxLength: {
                value: 255,
                message: t("events.create.form.name_length_error"),
              },
            }}
            placeholder={t("events.create.form.name_placeholder")}
            translationRoot="events.create.form"
          >
            <Form.Input autoFocus data-testid="event-title" />
          </FormItem>
        </div>
        {isEditMode && (
          <div className="w-full pl-0 md:w-1/2 md:pl-2">
            <FormItem
              name="slug"
              hidePlaceholder
              rules={{
                maxLength: {
                  value: 255,
                  message: t("events.create.form.slug_length_error"),
                },
              }}
              tooltipText={t("events.edit_modal.slug_tooltip", {
                url: window.location.origin,
              })}
            >
              <Form.Input />
            </FormItem>
          </div>
        )}
      </div>
      <div className="flex flex-col items-stretch md:flex-row">
        <div className="w-full pr-0 md:w-1/2 md:pr-2">
          <FormItem
            name="space_id"
            rules={{
              required: t("events.create.form.space_required_error"),
            }}
            translationRoot="events.create.form"
          >
            <SpaceSelect
              spaces={spaces}
              name="space_id"
              variant="form-input"
              dropdownClassName="!top-[100%] -mt-1"
            />
          </FormItem>
        </div>
        <div className="w-full pl-0 md:w-1/2 md:pl-2">
          <FormItem name="user_id" translationRoot="events.create.form">
            <Form.CommunityMemberSelect
              name="user_id"
              dropdownClassName="!top-[93px]"
              direction="down"
              valueField="user_id"
              hideIcon
            />
          </FormItem>
        </div>
      </div>
      <div className="flex flex-col items-stretch md:flex-row">
        <FormItem
          name="topics"
          tooltipText={t("events.edit_modal.topics_tooltip")}
          rules={topicsRules}
        >
          <FormTopicsPicker
            containerClassName="bg-primary border-primary rounded-md border"
            name="topics"
            spaceId={spaceId}
            creatable={canCreateTopics}
          />
        </FormItem>
      </div>
    </div>
  );
};
