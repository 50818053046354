import { Loader } from "@circle-react-shared/uikit/Loader";
import { ScheduledEmailItem } from "./ScheduledEmailItem";
import { useScheduledEmailContext } from "./useScheduledEmailContext";

export const ScheduledEmailList = () => {
  const { isLoading, scheduledEmailList } = useScheduledEmailContext();

  if (isLoading) return <Loader />;

  if (!scheduledEmailList || scheduledEmailList.length === 0) return null;

  return (
    <>
      {scheduledEmailList.map(scheduledEmail => (
        <ScheduledEmailItem
          key={scheduledEmail.id}
          scheduledEmail={scheduledEmail}
        />
      ))}
    </>
  );
};
