import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";

interface NavigationArrowProps {
  direction: "prev" | "next";
  onClick: () => void;
}

export const NavigationArrow = ({
  direction,
  onClick,
}: NavigationArrowProps) => (
  <IconButton
    name={`20-chevron-${direction === "prev" ? "left" : "right"}`}
    iconSize={20}
    onClick={onClick}
    iconClassName="!text-gray-600"
    className={classnames(
      "absolute top-1/2 z-[2] hidden -translate-y-1/2 rounded-full border border-gray-300 bg-white hover:!bg-[#F7F9FA] focus:!bg-[#F7F9FA] sm:group-hover:flex",
      {
        "left-4": direction === "prev",
        "right-4": direction === "next",
      },
    )}
    ariaLabel={direction === "prev" ? t("previous") : t("next")}
  />
);
