import PropTypes from "prop-types";
import { PEOPLE_TABS } from "../../../utils";
import { Attendees } from "../Attendees";
import { Cohosts } from "../Cohosts";

export const PeopleBody = ({ event, currentTab }) => {
  switch (currentTab?.key) {
    case PEOPLE_TABS.COHOSTS:
      return <Cohosts event={event} />;
    case PEOPLE_TABS.ATTENDEES:
    default:
      return <Attendees event={event} />;
  }
};

PeopleBody.propTypes = {
  currentTab: PropTypes.object,
  event: PropTypes.object.isRequired,
  isUpdatingAttendees: PropTypes.bool,
};
