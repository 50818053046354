import { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { ROOM_ACCESS_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLiveStreamsFeatureFlags } from "@circle-react/hooks/useLiveStreamsFeatureFlags";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { AccessType, FormItem, ViewType } from "../../Common";
import { LimitsReached } from "./LimitsReached";

export const Details = ({ roomType }) => {
  const recordingEnabled = useWatch({
    name: "recording_enabled",
  });

  const { limitsReachedIsOpen, setRecordingEnabled } = useAddonsContext();
  const { isLimitUrlSharingEnabled } = useLiveStreamsFeatureFlags();

  const { watch, setValue } = useFormContext();
  const accessType = watch("access_type");
  const shouldHideLimitUrlSharingToggle =
    accessType === ROOM_ACCESS_TYPES.PUBLIC_STREAM ||
    accessType === ROOM_ACCESS_TYPES.OPEN;
  const limitUrlSharingName = "limit_url_sharing";
  useEffect(() => {
    if (shouldHideLimitUrlSharingToggle) {
      setValue(limitUrlSharingName, false, { shouldDirty: true });
    }
  }, [accessType, setValue]);

  useEffect(() => {
    setRecordingEnabled(recordingEnabled);
  }, [recordingEnabled]);
  return (
    <>
      {limitsReachedIsOpen && <LimitsReached roomType={roomType} />}
      <Typography.TitleMd weight="bold">
        {t("live_streams.form.details")}
      </Typography.TitleMd>
      <div className="mb-8 mt-4">
        <div className="flex items-start">
          <div className="w-1/2 pr-3">
            <FormItem
              name="name"
              rules={{
                required: t(
                  `live_streams.form.${roomType}.name_required_error`,
                ),
                maxLength: {
                  value: 255,
                  message: t(`live_streams.form.${roomType}.name_length_error`),
                },
              }}
            >
              <Form.Input autoFocus />
            </FormItem>
          </div>
          <div className="w-1/2 pl-3">
            <FormItem
              name="slug"
              tooltipText={`${window.location.protocol}//${window.location.hostname}/live/custom-url-slug`}
              rules={{
                maxLength: {
                  value: 255,
                  message: t("live_streams.form.slug_length_error"),
                },
              }}
            >
              <Form.Input />
            </FormItem>
          </div>
        </div>
        <div className="mt-3 w-full">
          <FormItem
            name="description"
            resizeDisabled
            rules={{
              maxLength: {
                value: 255,
                message: t(
                  `live_streams.form.${roomType}.description_length_error`,
                ),
              },
            }}
          >
            <Form.Input variant="textarea" maxLength={255} rows="6" />
          </FormItem>
        </div>
        <div className="mt-3 flex flex-col">
          <FormItem
            name="recording_enabled"
            appendTranslationRoot={roomType}
            tooltipText={t(
              `live_streams.form.${roomType}.recording_enabled_tooltip`,
            )}
            inlineReverse
          >
            <Form.ToggleSwitch name="recording_enabled" variant="small" />
          </FormItem>
          <FormItem
            name="mute_on_join"
            appendTranslationRoot={roomType}
            inlineReverse
          >
            <Form.ToggleSwitch name="mute_on_join" variant="small" />
          </FormItem>
          <FormItem
            name="hide_participants_list"
            appendTranslationRoot={roomType}
            inlineReverse
            tooltipText={t(
              `live_streams.form.${roomType}.hide_participants_list_tooltip`,
            )}
          >
            <Form.ToggleSwitch name="hide_participants_list" variant="small" />
          </FormItem>
          {isLimitUrlSharingEnabled && !shouldHideLimitUrlSharingToggle && (
            <FormItem
              name={limitUrlSharingName}
              appendTranslationRoot={roomType}
              tooltipText={t(
                `live_streams.form.${roomType}.limit_url_sharing_tooltip`,
              )}
              inlineReverse
            >
              <Form.ToggleSwitch name={limitUrlSharingName} variant="small" />
            </FormItem>
          )}
        </div>
      </div>
      <Typography.TitleMd weight="bold">
        {t("live_streams.form.access")}
      </Typography.TitleMd>
      <div className="my-4">
        <div className="flex items-center">
          <div className="w-1/2 pr-3">
            <FormItem name="view_type" labelClassName="mb-3 text-lg">
              <ViewType roomType={roomType} />
            </FormItem>
          </div>
          <div className="w-1/2 pl-3">
            <FormItem name="access_type" labelClassName="mb-3 text-lg">
              <AccessType roomType={roomType} />
            </FormItem>
          </div>
        </div>
      </div>
    </>
  );
};

Details.propTypes = {
  roomType: PropTypes.string,
};
