import classNames from "classnames";
import { ProfileHeader } from "@circle-react/components/ProfileModalV3/Body/ProfileHeader";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";

export const Sidebar = () => {
  const { isLoading, isFullPageView } = useProfileContext();
  if (isLoading) return null;
  return (
    <div
      className={classNames(
        "border-primary bg-primary overflow-auto border-r",
        isFullPageView ? "w-full md:w-1/3" : "col-span-full md:col-span-4",
      )}
    >
      <ProfileHeader />
    </div>
  );
};
