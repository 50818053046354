import { useMutation } from "react-query";
import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const useMarkChatRoomAsRead = () => {
  const { mutateAsync: markChatRoomAsRead } = useMutation(uuid => {
    const url = internalApi.chatRooms.markAllAsRead({
      uuid,
    });
    return reactQueryPost(url);
  });

  return { markChatRoomAsRead };
};
