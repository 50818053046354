import type { ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { IconProps, IconType } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface ProfileInfoItemProps {
  iconType?: IconType;
  iconProps?: Partial<IconProps>;
  info?: ReactNode;
  dataTestId?: string;
  className?: string;
  iconClassName?: string;
  color?: string;
}

export const ProfileInfoItem = ({
  iconType,
  iconProps = {},
  info,
  dataTestId,
  className = "",
  iconClassName = "",
  color = "text-default",
}: ProfileInfoItemProps) => {
  if (!info) return null;

  return (
    <div
      className={classNames(
        "flex items-start space-x-1.5 whitespace-pre-line [word-break:break-word]",
        className,
      )}
      data-testid={dataTestId}
    >
      {iconType ? (
        <Icon
          useWithFillCurrentColor
          className={iconClassName}
          type={iconType}
          size={16}
          {...iconProps}
        />
      ) : null}{" "}
      <Typography.LabelSm color={color} weight="regular">
        {info}
      </Typography.LabelSm>
    </div>
  );
};
