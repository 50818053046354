import { useCallback, useState } from "react";

export const useElementHeight = () => {
  const [height, setHeight] = useState();

  const ref = useCallback(node => {
    if (!node) {
      return;
    }
    setHeight(node.clientHeight);
  }, []);

  return {
    ref,
    height,
  };
};
