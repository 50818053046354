import { Typography } from "@circle-react-uikit/Typography";

interface GridItemProps {
  property: string;
  number: string;
  red?: boolean;
}

export const GridItem = ({ property, number, red = false }: GridItemProps) => {
  const bgColor = red ? "bg-red-500" : "bg-green-700";
  const textColor = red ? "text-red-500" : "text-green-700";

  const completion = parseFloat(number) * 10;

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex justify-between">
        <Typography.BodyXs>{property}</Typography.BodyXs>
        <Typography.LabelXxs weight="bold" color={textColor}>
          {number}
        </Typography.LabelXxs>
      </div>
      <div className="bg-tertiary h-1 w-full rounded">
        <div
          className={`h-full rounded ${bgColor}`}
          style={{ width: `${completion}%` }}
        ></div>
      </div>
    </div>
  );
};
