import classNames from "classnames";
import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { CreatableOption } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/CreatableOption";
import { LoadingStateOption } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/LoadingStateOption";
import { Option } from "@circle-react-shared/uikit/Combobox/Option";
import { Options } from "@circle-react-shared/uikit/Combobox/Options";
import { OptionsScrollableContainer } from "@circle-react-shared/uikit/Combobox/OptionsScrollableContainer";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const DropdownOptions = ({
  shouldShowDropdown,
  popperElement,
  styles,
  attributes,
  isLoading,
  filter,
  filteredTopics,
  shouldShowCreateTopic,
  createTopicMutation,
}: any) => (
  <div
    className={classNames("z-10 max-w-[min(90vw,288px)]", {
      invisible: !shouldShowDropdown,
    })}
    ref={popperElement}
    style={styles.popper}
    {...attributes.popper}
  >
    <Options className="!relative" dataTestId="topics-options-list">
      <OptionsScrollableContainer>
        {isLoading && <LoadingStateOption />}
        {filteredTopics.map((topic: any) => (
          <Option option={topic.id} key={topic.id}>
            <div className="flex items-center justify-between">
              <Typography.LabelSm>
                {truncate(topic.name, {
                  length: 25,
                })}
              </Typography.LabelSm>
              {topic.admin_only && (
                <Typography.LabelXs color="text-light">
                  {t("quick_post_v2.topics.admin_only")}
                </Typography.LabelXs>
              )}
            </div>
          </Option>
        ))}
        {shouldShowCreateTopic && (
          <CreatableOption
            createTopicMutation={createTopicMutation}
            filter={filter}
          />
        )}
      </OptionsScrollableContainer>
    </Options>
  </div>
);
