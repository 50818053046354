/* eslint-disable @typescript-eslint/no-non-null-assertion -- TODO: fix this */
import { useParallelDirectUploads } from "@/react/hooks/useParallelDirectUploads";
import type { Crop, SelectedImage } from "../../store";
import { useImagePostModalStore } from "../../store";

export interface CroppedImageResult {
  croppedImage: File;
  croppedArea: Crop;
}
const cropImage = (
  image: SelectedImage,
  galleryAspectRatio: number,
): Promise<CroppedImageResult> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (image.croppedAreaPixels) {
      // Discard any cropping if the aspect ratio is too different from the gallery aspect ratio
      // This happens when the user crops an image, then changes the gallery aspect ratio, so
      // the image needs to be re-cropped with the default cropping area (setting undefined)
      const { width, height } = image.croppedAreaPixels;
      const aspectRatio = parseFloat((width / height).toFixed(2));
      if (aspectRatio !== parseFloat(galleryAspectRatio.toFixed(2))) {
        image.croppedAreaPixels = undefined;
      }
    }

    img.src = URL.createObjectURL(image.file);
    img.onload = () => {
      const croppedArea =
        image.croppedAreaPixels ||
        defaultCenteredCroppedArea(img, galleryAspectRatio);
      canvas.width = croppedArea.width;
      canvas.height = croppedArea.height;

      ctx?.drawImage(
        img,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        croppedArea.width,
        croppedArea.height,
      );

      canvas.toBlob(blob => {
        if (blob) {
          const croppedImage = new File([blob], image.file.name, {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          resolve({ croppedImage, croppedArea });
        }
      }, "image/jpeg");
    };
    img.onerror = err => {
      reject(err);
    };
  });

const defaultCenteredCroppedArea = (
  img: HTMLImageElement,
  targetAspectRatio: number,
) => {
  const originalAspectRatio = img.width / img.height;

  let x, y, width, height;

  if (originalAspectRatio > targetAspectRatio) {
    // Keep the height, adjust the width
    width = Math.round(img.height * targetAspectRatio);
    height = img.height;
    x = Math.round((img.width - width) / 2);
    y = 0;
  } else {
    // Keep the width, adjust the height
    width = img.width;
    height = Math.round(img.width / targetAspectRatio);
    x = 0;
    y = Math.round((img.height - height) / 2);
  }

  return { x, y, width, height };
};

export const useUploadImages = () => {
  const {
    selectedFiles,
    setSelectedFiles,
    galleryAspectRatio,
    setUploadedImages,
    addUploadedImages,
    uploadedImages,
    setIsImagesDirty,
  } = useImagePostModalStore();
  const { status, overallProgress, reset, fileStates, uploadFiles } =
    useParallelDirectUploads();

  const uploadImages = () => {
    if (!galleryAspectRatio) {
      throw new Error("Gallery aspect ratio not set");
    }
    const croppingPromises = selectedFiles.map(file =>
      cropImage(file, galleryAspectRatio),
    );

    Promise.all(croppingPromises)
      .then(croppedImageResults => {
        setSelectedFiles(
          selectedFiles.map((file, index) => ({
            ...file,
            croppedAreaPixels: croppedImageResults[index].croppedArea,
          })),
        );
        uploadFiles(croppedImageResults.map(result => result.croppedImage));
      })
      .catch((err: string) => {
        throw new Error(`Error processing images: ${err}`);
      });
  };

  const updateStoredImages = (editingImageSignedId: any) => {
    if (editingImageSignedId) {
      const selectedFile = selectedFiles[0];
      const editedImage = {
        signed_id: fileStates[0].response.signed_id,
        url: fileStates[0].response.blob_url,
        original_url: fileStates[0].response.blob_url,
        filename: fileStates[0].response.filename,
        width: selectedFile.croppedAreaPixels!.width,
        height: selectedFile.croppedAreaPixels!.height,
      };
      setUploadedImages(
        uploadedImages.map(image =>
          image.signed_id === editingImageSignedId
            ? {
                ...image,
                ...editedImage,
              }
            : image,
        ),
      );
    } else {
      addUploadedImages(
        fileStates.map((file, index) => ({
          signed_id: file.response.signed_id,
          url: file.response.blob_url,
          original_url: file.response.blob_url,
          filename: file.response.filename,
          width: selectedFiles[index].croppedAreaPixels!.width,
          height: selectedFiles[index].croppedAreaPixels!.height,
        })),
      );
    }
    setIsImagesDirty(true);
  };

  return {
    uploadImages,
    overallProgress,
    resetUploads: reset,
    status,
    fileStates,
    updateStoredImages,
  };
};
