import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { ASPECT_RATIOS, FORMATS } from "../../constants";
import { useImagePostModalStore } from "../../store";

const formatsData = [
  {
    format: FORMATS.SQUARE,
    iconType: "16-image-square",
  },
  {
    format: FORMATS.PORTRAIT,
    iconType: "16-image-portrait",
  },
  {
    format: FORMATS.LANDSCAPE,
    iconType: "16-image-landscape",
  },
] as const;

export const CroppingOptions = () => {
  const { galleryAspectRatio, setGalleryAspectRatio } =
    useImagePostModalStore();

  const selectedFormat = Object.entries(ASPECT_RATIOS).find(
    ([, aspectRatio]) => aspectRatio === galleryAspectRatio,
  )?.[0];

  const translationBase =
    "image_space.post_modal.steps.adjust_images.cropping_options";

  return (
    <div className="text-default flex justify-between">
      {formatsData.map(({ format, iconType }) => (
        <TippyV2 content={t(`${translationBase}.${format}`)} key={format}>
          <IconButton
            onClick={() => {
              setGalleryAspectRatio(ASPECT_RATIOS[format]);
            }}
            className={classnames({
              "bg-tertiary": selectedFormat === format,
            })}
            name={iconType}
            iconSize={16}
            buttonSize={28}
            iconClassName={classnames({
              "!text-dark": selectedFormat === format,
            })}
            ariaLabel={t(`${translationBase}.${format}`)}
          />
        </TippyV2>
      ))}
    </div>
  );
};
