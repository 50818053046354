import { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";
import { RECURRING_EDIT_MODE } from "../../utils";
import { EditRecurringModal } from "../EditRecurringModal";

export const SubmitEditButton = ({
  onEditModeConfirm,
  isRecurringEvent = false,
  isPublishing = false,
  children,
  isStartsAtDirty,
  shouldHideAllEventOption,
  ...passthroughProps
}) => {
  const [isRecurringModalOpen, setIsRecurringModalOpen] = useState(false);

  const onClickHandler = () => {
    if (isRecurringEvent) {
      setIsRecurringModalOpen(true);
    } else {
      onEditModeConfirm(RECURRING_EDIT_MODE.CURRENT);
    }
  };

  const onConfirmHandler = editMode => {
    setIsRecurringModalOpen(false);
    onEditModeConfirm(editMode);
  };

  return (
    <>
      <Button type="button" onClick={onClickHandler} {...passthroughProps}>
        {children}
      </Button>
      <EditRecurringModal
        show={isRecurringModalOpen}
        onConfirm={onConfirmHandler}
        onCloseModal={() => setIsRecurringModalOpen(false)}
        isPublishing={isPublishing}
        showCurrentOptionOnly={isStartsAtDirty}
        shouldHideAllEventOption={shouldHideAllEventOption}
      />
    </>
  );
};

SubmitEditButton.propTypes = {
  onEditModeConfirm: PropTypes.func,
  isRecurringEvent: PropTypes.bool,
  isPublishing: PropTypes.bool,
  children: PropTypes.node,
  isStartsAtDirty: PropTypes.bool,
  shouldHideAllEventOption: PropTypes.bool,
};
