const BASE_API_URL = "https://api.giphy.com/v1/gifs";
/* cspell:disable-next-line */
const api_key = "OvDGLovptOPNSO5JjplEIssLF1x8Yxc3";

const getUrl = (path, options = {}) => {
  const url = new URL(`${BASE_API_URL}/${path}`);
  for (const [key, value] of Object.entries(options)) {
    url.searchParams.set(key, value);
  }
  return url.toString();
};
export const search = (q, options = {}) =>
  fetch(getUrl("search", { ...options, q, api_key })).then(response =>
    response.json(),
  );

export const trending = (options = {}) =>
  fetch(getUrl("trending", { ...options, api_key })).then(response =>
    response.json(),
  );
