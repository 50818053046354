import {
  selectFirstSpotlightItem,
  useLiveStore,
} from "@/react/components/LiveStreams/Room/store";

export const useSpotlightStatus = (message: any) => {
  const { spotlighted: isSpotlighted } = message;
  const currentSpotlightedItem = useLiveStore(selectFirstSpotlightItem);
  const isCurrentSpotlightedItem =
    currentSpotlightedItem?.type === "ChatRoomMessage" &&
    currentSpotlightedItem?.spotlightable?.id === message.id;

  return {
    isSpotlighted,
    isCurrentSpotlightedItem,
  };
};
