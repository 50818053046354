/*
 * The Checkout component is mounted independently via checkout/show.html.erb
 * using the react_mount_controller.js. Since it is not part of the MainApp, it
 * does not include the BrowserRouter provider from react-router-dom. As a result,
 * the useRouteMatch() method fails because the router context is missing. And hence,
 * using useRouteMatch() for `isPostDetails` fails in the checkout page scenario, and hence
 * we are using a REGEX based pattern matching for the post details page route.
 */
export const isPostDetailsPage = (): boolean => {
  const { pathname } = window.location;
  return /^\/c\/[^/]+\/[^/]+\/?$/.test(pathname);
};
