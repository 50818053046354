import classnames from "classnames";

export const ThumbnailImage = ({
  image,
  index,
  setCurrentImageIndex,
  currentImageIndex,
}: any) => (
  <button
    type="button"
    onClick={() => setCurrentImageIndex(index)}
    className={classnames(
      "shrink-0 cursor-pointer touch-none select-none rounded-lg border-2 border-transparent p-0.5",
      {
        "border-secondary": index === currentImageIndex,
      },
    )}
    key={image.preview}
  >
    <img
      loading="eager"
      src={image.preview}
      className="h-12 w-12 rounded-md object-cover"
      alt={image.file.name}
    />
  </button>
);
