import { omit, pick } from "lodash";

const defaultValues = (post: any) =>
  pick(post, [
    "tiptap_body",
    "hide_meta_info",
    "is_comments_disabled",
    "is_comments_closed",
    "is_liking_disabled",
    "is_truncation_disabled",
    "pin_to_top",
    "pinned_at",
    "hide_from_featured_areas",
    "gallery.downloadable_images",
    "slug",
    "user_id",
    "status",
    "published_at",
    "topics",
  ]);

const formPayload = ({ payload, space, uploadedImages }: any) => ({
  post: {
    ...omit(payload, ["gallery"]),
    space_id: space?.id,
    gallery_attributes: {
      ...payload.gallery,
      images_attributes: uploadedImages.map((image: any) => ({
        id: image.id,
        image: image.signed_id,
        width: image.width,
        height: image.height,
      })),
    },
  },
});

export { defaultValues, formPayload };
