import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { PostViewListItem } from "@/react/components/PostsV3/PostViewListItem";
import type { ImagePost } from "@/react/types";
import { COMMENTS_BASE_QUERY_KEY } from "@circle-react/components/CommentsV3/CommentsList/useCommentsList";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { useCurrentPostContext } from "@circle-react/contexts";
import { ModalCloseButton } from "@circle-react-uikit/ModalV2/ModalCloseButton";
import { Typography } from "@circle-react-uikit/Typography";

interface PostDetailsProps {
  onPostChange: (post: ImagePost, options: { refetch: boolean }) => void;
  onPostDestroy: (postId: number) => void;
  autofocusReplyForm: boolean;
}
export const PostDetails = ({
  onPostChange,
  onPostDestroy,
  autofocusReplyForm,
}: PostDetailsProps) => {
  const modal = useModal();
  const { record: post } = useCurrentPostContext();
  const queryClient = useQueryClient();

  return (
    <>
      <div className="border-primary flex justify-between border-y p-6 pb-5 sm:border-t-0">
        <Typography.TitleSm weight="semibold">
          {t("image_space.grid_view.post_details")}
        </Typography.TitleSm>
        <ModalCloseButton
          onClick={() => {
            void queryClient.invalidateQueries([
              COMMENTS_BASE_QUERY_KEY,
              post.id,
            ]);
            modal.remove();
          }}
        />
      </div>
      <PostViewListItem
        defaultShowComment
        autofocusReplyForm={autofocusReplyForm}
        onPostChange={onPostChange}
        onPostDestroy={(postId: number) => {
          modal.remove();
          onPostDestroy(postId);
        }}
        hideShareLink
        hidePinnedToTopIcon
        hideBookmarkButton
        tagsLimit={1}
        tagsShowMoreLabelVariant="numbers"
        metaInfoWrapperClassName="!pr-0"
      />
    </>
  );
};
