import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import EmptyState from "@circle-react/components/Profile/EmptyState";
import { PROFILE_SCROLLABLE_TARGET_ID } from "@circle-react/components/ProfileModalV3/Body";
import { RewardItem } from "@circle-react/components/ProfileModalV3/Body/Rewards/RewardItem";
import { useRewardsLog } from "@circle-react/components/ProfileModalV3/Body/Rewards/api";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { LoaderRow } from "@circle-react-shared/LoaderRow";

export const ProfileRewards = () => {
  const { member } = useProfileContext();
  const { rewards, fetchNextPage, hasNextPage, isLoading } = useRewardsLog(
    member.id,
  );

  if (isLoading) {
    return <LoaderRow />;
  }

  if (rewards.length < 1)
    return (
      <EmptyState
        title={t("profile_drawer.rewards.empty_state.title")}
        subtitle={t("profile_drawer.rewards.empty_state.subtitle")}
      />
    );

  return (
    <InfiniteScroll
      style={{ overflow: "initial" }}
      scrollThreshold={0.5}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<LoaderRow />}
      dataLength={rewards.length}
      scrollableTarget={PROFILE_SCROLLABLE_TARGET_ID}
    >
      {rewards.map(reward => (
        <RewardItem key={reward.id + reward.event_name} reward={reward} />
      ))}
    </InfiniteScroll>
  );
};
