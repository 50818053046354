import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";

export interface SupportedCurrencyChooserProps {
  formItemName: string;
  formItemTranslationRoot: string;
  formItemRules?: object;
  formItemTooltipText?: string;
  disabled?: boolean;
  markDefaultCurrencyDirty?: boolean;
}
export const SupportedCurrencyChooser = ({
  formItemName,
  formItemTranslationRoot,
  formItemRules,
  formItemTooltipText,
  disabled,
  markDefaultCurrencyDirty = false,
}: SupportedCurrencyChooserProps) => {
  const { watch, setValue } = useFormContext();

  const currencyId = watch(formItemName);

  const { data, isLoading: isLoadingCommunitySettings } =
    usePunditUserContext();

  const communitySettings = data?.current_community_settings;

  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();

  useEffect(() => {
    if (!currencyId) {
      const defaultCurrency = (paywallsCurrencies || []).find(
        currency => currency.is_default,
      );
      if (defaultCurrency) {
        if (markDefaultCurrencyDirty) {
          setValue(formItemName, `${defaultCurrency.id}`, {
            shouldDirty: true,
          });
        } else {
          setValue(formItemName, `${defaultCurrency.id}`);
        }
      }
    }
  }, [formItemName, setValue, paywallsCurrencies, currencyId]);

  if (
    isLoadingCommunitySettings ||
    isLoadingPaywallsCurrencies ||
    !paywallsCurrencies
  ) {
    return <Loader />;
  }

  if (!communitySettings?.currencies_enabled) {
    return null;
  }

  const currencyOptions = paywallsCurrencies.map(currency => ({
    label: currency.code.toUpperCase(),
    value: `${currency.id}`,
  }));

  return (
    <Form.Item
      fullWidth={false}
      hideBorder
      hideDescription
      hidePlaceholder
      name={formItemName}
      rules={formItemRules}
      translationRoot={formItemTranslationRoot}
      tooltipText={formItemTooltipText}
    >
      <Form.SelectNative
        name={formItemName}
        options={currencyOptions}
        disabled={disabled}
      />
    </Form.Item>
  );
};
