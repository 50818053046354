import { useRef, useState } from "react";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";
import {
  useChatStore,
  useUnreadChatRoomUuids,
} from "@circle-react/hooks/chatsV2";
import { useChatRoomParticipant } from "./useChatRoomParticipant";

export const useChatHeaderApi = ({ communityId }) => {
  const { setUnreadChatRoomUuids } = useChatStore();
  const unreadChatRoomUuids = useUnreadChatRoomUuids({ communityId });
  const [newUnreadChatRoomBatch, setnewUnreadChatRoomBatch] = useState([]);
  const [chatRoomUuid, setChatRoomUuid] = useState(null);
  const unreadChatRoomUuidsRef = useRef(unreadChatRoomUuids);
  const newUnreadChatRoomBatchRef = useRef(newUnreadChatRoomBatch);

  const mutation = useChatRoomParticipant(chatRoomUuid);

  const onNewMessage = async (
    unreadChatRoomUuidsRef,
    eventData,
    communityMemberId,
  ) => {
    const latestChatRoomMessage = eventData.json_message;
    const { chat_room_uuid, chat_room_kind, sender, embedded } =
      latestChatRoomMessage;
    const openChatRoomUuid = window.location.pathname?.split("messages/")[1];

    const { community_member_id } = sender;
    const isChatRoomOpen = openChatRoomUuid === chat_room_uuid;
    const isDirectChatRoom = chat_room_kind === DIRECT_CHAT_ROOM;

    if (embedded === true || isChatRoomOpen) {
      return;
    }

    setChatRoomUuid(chat_room_uuid);
    const { chat_room_participant: currentChatRoomParticipant } =
      isDirectChatRoom ? {} : await mutation.mutateAsync();

    //community_member_id: sender of the message
    //communityMemberId: current logged in community member
    if (
      community_member_id === communityMemberId ||
      currentChatRoomParticipant?.preferences?.muted
    ) {
      return;
    }

    const messageChatRoom = unreadChatRoomUuidsRef.find(
      uuid => uuid === chat_room_uuid,
    );

    if (!messageChatRoom) {
      setnewUnreadChatRoomBatch([
        ...newUnreadChatRoomBatchRef.current,
        chat_room_uuid,
      ]);
      setTimeout(() => handleTimeoutOver(), 2000);
    }
  };

  const handleTimeoutOver = () => {
    setUnreadChatRoomUuids(communityId, [
      ...new Set([
        ...unreadChatRoomUuidsRef.current,
        ...newUnreadChatRoomBatchRef.current,
      ]),
    ]);
  };

  const onChatRoomRead = (unreadChatRoomUuidsRef, eventData) => {
    const { chat_room_uuid } = eventData;
    const updatedUnreadChatRoomUuidsRef = unreadChatRoomUuidsRef.filter(
      uuid => uuid !== chat_room_uuid,
    );

    const updatednewUnreadChatRoomBatch =
      newUnreadChatRoomBatchRef.current.filter(uuid => uuid !== chat_room_uuid);

    setnewUnreadChatRoomBatch(updatednewUnreadChatRoomBatch);
    setUnreadChatRoomUuids(communityId, updatedUnreadChatRoomUuidsRef);
  };

  const onEventReceive = (
    unreadChatRoomUuidsRef,
    eventData,
    communityMemberId,
  ) => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(unreadChatRoomUuidsRef, eventData, communityMemberId);
        break;
      case "chatRoomRead":
        onChatRoomRead(unreadChatRoomUuidsRef, eventData);
    }
  };

  const fetchUnreadChatRoomUuids = communityIdParam => {
    reactQueryGet(
      internalApi.chatRooms.unreadChatRooms({
        params: {
          community_id: communityIdParam,
        },
      }),
    )
      .then(data => {
        if (data.chat_room_uuids) {
          setUnreadChatRoomUuids(communityIdParam, data.chat_room_uuids);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return {
    unreadChatRoomUuids,
    onEventReceive,
    fetchUnreadChatRoomUuids,
    unreadChatRoomUuidsRef,
    newUnreadChatRoomBatch,
    newUnreadChatRoomBatchRef,
    setUnreadChatRoomUuids,
  };
};
