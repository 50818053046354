import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { STATUS } from "../helpers/constants";
import "./styles.scss";

export const Counter = ({ startsAt, endsAt, status = null }) => {
  // Check that date is in the future

  const isDraft = status === STATUS.DRAFT;
  const finished = dateIsPast(endsAt);
  const started = dateIsPast(startsAt);

  let label = null;
  if (started && !finished && !isDraft) {
    label = t("events.details.in_progress");
  } else if (isDraft) {
    label = t("events.details.draft");
  } else if (finished) {
    label = t("events.details.finished", {
      time_string: dateStringToTimeAgo(endsAt),
    });
  } else {
    label = t("events.details.starts", {
      time_string: dateStringToTimeAgo(startsAt),
    });
  }

  return (
    <div
      className={classNames("event-counter-badge", {
        "event-counter-badge--finished": finished,
        "event-counter-badge--draft": isDraft,
      })}
    >
      {`${label}`}
    </div>
  );
};

Counter.propTypes = {
  startsAt: PropTypes.string.isRequired,
  endsAt: PropTypes.string.isRequired,
  status: PropTypes.string,
};
