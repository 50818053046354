import { t } from "@/i18n-js/instance";

export const LOCATION_TYPES = {
  VIRTUAL: "virtual",
  IN_PERSON: "in_person",
  TBD: "tbd",
  LIVE_STREAM: "live_stream",
  LIVE_ROOM: "live_room",
} as const;

export type LocationType = (typeof LOCATION_TYPES)[keyof typeof LOCATION_TYPES];

export const LOCATION_TYPE_TAGS: any = {
  virtual: t("events.location_types.virtual"),
  in_person: t("events.location_types.in_person"),
  tbd: t("events.location_types.tbd"),
  live_stream: t("events.location_types.live_stream"),
  live_room: t("events.location_types.live_room"),
};

export const EVENT_TYPES = {
  SINGLE: "single",
  RECURRING: "recurring",
} as const;

export type EventType = (typeof EVENT_TYPES)[keyof typeof EVENT_TYPES];

export const RECURRING_FREQUENCIES = {
  DAILY: "daily",
  WEEKDAY: "weekday",
  WEEKLY: "weekly",
  BI_WEEKLY: "bi_weekly",
  MONTHLY: "monthly",
  MONTHLY_WEEK_BASED: "monthly_weekday_based",
  ANNUALLY: "annually",
  YEARLY: "yearly",
} as const;

export type RecurringFrequency =
  (typeof RECURRING_FREQUENCIES)[keyof typeof RECURRING_FREQUENCIES];

export const RECURRING_ENDING = {
  ON: "on",
  NEVER: "never",
  AFTER: "after",
} as const;

export type RecurringEnding =
  (typeof RECURRING_ENDING)[keyof typeof RECURRING_ENDING];

export const RECURRING_EDIT_MODE = {
  CURRENT: "current",
  REMAINING: "remaining",
  ALL: "all",
} as const;

export type RecurringEditMode =
  (typeof RECURRING_EDIT_MODE)[keyof typeof RECURRING_EDIT_MODE];

export const STATUS = {
  PUBLISHED: "published",
  DRAFT: "draft",
} as const;

export const RSVP_STATUS = {
  INVITED: "invited",
} as const;

export const WEEKDAYS_SHORT_NAMES = ["MO", "TU", "WE", "TH", "FR"] as const;

export type Status = (typeof STATUS)[keyof typeof STATUS];
