import classNames from "classnames";
import copyToClipboard from "copy-to-clipboard";
import { t } from "@/i18n-js/instance";
import { communityMemberProfilePath } from "@circle-react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-shared/uikit/Button";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Icon } from "../shared/Icon";

interface CopyProfileLinkProps {
  member: {
    public_uid: string;
  };
  large?: boolean;
}

export const CopyProfileLink = ({
  member: { public_uid },
  large,
}: CopyProfileLinkProps) => {
  const { success } = useToast();
  const profilePath: string = communityMemberProfilePath({ public_uid });

  return (
    <TippyV2
      placement="bottom"
      content={t("post.share.copy_link_to_profile")}
      interactive={false}
    >
      <div>
        <Button
          type="button"
          variant="secondary"
          aria-label={t("post.share.copy_link_to_profile")}
          onClick={() => {
            copyToClipboard(window.location.origin + profilePath);
            success(t("post.share.link_copied"), {
              duration: "short",
              shouldUseProgress: false,
            });
          }}
          data-testid="copy-link-profile"
          large={large}
          className={classNames({
            "!px-2.5": large,
          })}
        >
          <Icon type="link" size={16} useWithFillCurrentColor />
        </Button>
      </div>
    </TippyV2>
  );
};
