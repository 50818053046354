import { useMutation } from "react-query";
import { eventApi } from "@circle-react/api/eventApi";
import { useInvalidateEventQueries } from "@circle-react/components/Events/hooks/useInvalidateEventQueries";
import { eventDeletionMessage } from "@circle-react/components/EventsV3/Modal/utils";
import { isRecurringFreeEvent } from "@circle-react/components/EventsV3/Modal/utils/helpers";
import { useEditEventModal } from "@circle-react/components/Modals/EditEventModal";
import { usePunditUserContext } from "@circle-react/contexts";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useEventMenu = (event, onDestroy) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const invalidateQueries = useInvalidateEventQueries();
  const editEventModal = useEditEventModal();
  const { policies } = event || {};
  const {
    can_destroy_post: canDelete,
    can_update_post: canEdit,
    can_duplicate_post: canDuplicate,
  } = policies || {};
  const isPinned = event.pinned_at_top_of_space;
  const { success, error } = useToast();
  const [showDuplicateEventModal, toggleDuplicateEventModal] =
    useBoolean(false);
  const [showConfirmationModal, toggleConfirmationModal] = useBoolean();
  const [isRecurringModalOpen, setIsRecurringModalOpen] = useBoolean();

  const onEdit = () => {
    if (!canEdit || isViewOnlyMasquerading) {
      return;
    }
    editEventModal.show({ event });
  };

  const onDuplicate = toggleDuplicateEventModal;

  const deleteMutation = useMutation(
    deleteMode =>
      eventApi.destroy({ event: event, params: { delete_mode: deleteMode } }),
    {
      onSuccess: () => {
        onDestroy(event.id);
        success(eventDeletionMessage(event));
        invalidateQueries();
      },
      onError: () => error(I18n.t("event_dropdown_actions.delete_failed")),
    },
  );

  const onDeleteClickHandler = () => {
    if (isViewOnlyMasquerading) {
      return;
    }

    if (isRecurringFreeEvent(event)) {
      setIsRecurringModalOpen(true);
    } else {
      toggleConfirmationModal(true);
    }
  };

  const onPinToTop = async () => {
    const response = await eventApi.pin({
      event,
      formData: { pin_to_top: true, is_pinned: !isPinned },
    });

    if (response.ok) {
      success(I18n.t(isPinned ? "event_unpinned" : "event_pinned"));
      invalidateQueries({ shouldInvalidateHomePagePosts: true });
    } else {
      error(I18n.t(isPinned ? "event_unpinned_error" : "event_pinned_error"));
    }
  };

  return {
    canEdit,
    onEdit,
    canDelete,
    canDuplicate,
    onDelete: deleteMutation.mutate,
    onPinToTop,
    onDuplicate,
    onDeleteClickHandler,
    showDuplicateEventModal,
    showConfirmationModal,
    toggleConfirmationModal,
    isRecurringModalOpen,
    setIsRecurringModalOpen,
  };
};
