import { useCurrentPostContext } from "@circle-react/contexts";
import { Body } from "./Body";
import { EngagementActions } from "./EngagementActions";
import { Header } from "./Header";

export const MainContent = ({ onPostChange, shouldOpenInNewTab }) => {
  const { record: post } = useCurrentPostContext();

  if (!post || !post.id) {
    return null;
  }

  const { updated_at: updatedAt } = post;

  const onChange = (updates = {}) => {
    onPostChange({ ...post, ...updates });
  };

  return (
    <div
      key={updatedAt}
      className="profile-posts__post bg-primary flex flex-col space-y-3 p-5 md:px-6"
    >
      <Header />
      <Body />
      <EngagementActions
        onChange={onChange}
        shouldOpenInNewTab={shouldOpenInNewTab}
      />
    </div>
  );
};
