import { ONLINE_STATUS } from "@/react/components/constants";

export const shouldShowMessageOption = (currentCommunityMember, member) => {
  const isCurrentCommunityMemberProfile =
    currentCommunityMember?.id == member.id;
  const isCommunityMemberDeactivated = member.is_deleted;
  return (
    !isCommunityMemberDeactivated &&
    !isCurrentCommunityMemberProfile &&
    currentCommunityMember?.messaging_enabled &&
    member.messaging_enabled
  );
};

export const shouldShowRemoveOption = (currentCommunityMember, member) => {
  const isCurrentCommunityMemberProfile =
    currentCommunityMember?.id == member.id;
  return !isCurrentCommunityMemberProfile && currentCommunityMember?.is_admin;
};

export const sortParticipants = (participants = []) => {
  const moderators = [];
  const nonModerators = [];
  participants.forEach(participant => {
    if (participant.admin) {
      moderators.push(participant);
    } else {
      nonModerators.push(participant);
    }
  });
  return [...moderators, ...nonModerators];
};

export const mapParticipantsPerStatus = participants => {
  const onlineParticipants = [];
  const offlineParticipants = [];
  const idleParticipants = [];

  participants
    .filter(({ deleted_at }) => !deleted_at)
    .forEach(participant => {
      if (participant.status === ONLINE_STATUS["online"]) {
        onlineParticipants.push(participant);
      } else if (participant.status === ONLINE_STATUS["idle"]) {
        idleParticipants.push(participant);
      } else {
        offlineParticipants.push(participant);
      }
    });

  return {
    onlineParticipants: [...onlineParticipants, ...idleParticipants],
    offlineParticipants,
  };
};
