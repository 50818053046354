import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useCustomizeUIData } from "@circle-react/components/shared/CustomizeUI";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-uikit/Typography";
import { Details, People, RoomType } from "../../Sections";
import { useFormSections } from "../../hooks";
import { useRoomType } from "../../hooks/useRoomType";

export const Body = ({ setRoomType, triggerValidationRef }) => {
  // Note: currentSection is already internationalized
  const { currentSection } = useCustomizeUIData();
  const roomType = useRoomType(setRoomType);
  const { sections } = useFormSections(roomType);
  const { trigger } = useFormContext();
  const shouldAllowMaxWidth = currentSection === sections.roomType;

  useEffect(() => {
    if (triggerValidationRef && trigger) {
      triggerValidationRef.current = trigger;
    }
  }, [triggerValidationRef, trigger]);

  return (
    <div
      className={classNames("mx-auto py-14", {
        "max-w-screen-md": !shouldAllowMaxWidth,
        "max-w-max": shouldAllowMaxWidth,
      })}
    >
      <Typography.TitleXl weight="bold">{currentSection}</Typography.TitleXl>
      <div className="mt-8">
        {currentSection === sections.roomType && <RoomType />}
        {currentSection === sections.details && <Details roomType={roomType} />}
        {currentSection === sections.people && <People />}
      </div>
    </div>
  );
};

Body.propTypes = {
  setRoomType: PropTypes.func,
  triggerValidationRef: PropTypes.shape({
    current: PropTypes.func,
  }),
};
