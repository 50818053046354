import copyToClipboard from "copy-to-clipboard";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useToast } from "@circle-react-uikit/ToastV2";
import {
  getChatRoomMessageRedirectionHash,
  getChatRoomUrl,
  getChatThreadMessageRedirectionHash,
} from "../helpers";
import { getCopyLinkUrl } from "../helpers";

export interface MessageTimestampProps {
  message: any;
  chatRoom: any;
  chatRoomUrl?: string;
  isParentMessage?: boolean;
  isMessageThread?: boolean;
  isRenderedOnHeader?: boolean;
}

export const MessageTimestamp = ({
  message,
  chatRoom,
  chatRoomUrl = "",
  isParentMessage = false,
  isMessageThread = false,
  isRenderedOnHeader = false,
}: MessageTimestampProps) => {
  const isThreadsListingPage = window.location.href.includes("threads");
  const { id, sent_at: sentAt, parent_message_id: parentMessageId } = message;
  const { day, time } = formattedMessageSentAt(sentAt);
  const timeAgo = dateStringToTimeAgo(sentAt);
  const toast = useToast();

  const getMessageTime = () => {
    if (isMessageThread) {
      return isParentMessage ? `${day} at ${time}` : timeAgo;
    }
    return time;
  };

  const copyLink = () => {
    const link = getCopyLinkUrl({
      id,
      parentMessageId,
      chatRoomUrl,
    });
    copyToClipboard(link);
    toast.success(t("messaging.actions.copy_link_success"));
  };

  if (isThreadsListingPage || isRenderedOnHeader) {
    return (
      <Link
        to={getRouteWithPrevLocation({
          url: getChatRoomUrl(chatRoom, message),
          hash: isParentMessage
            ? getChatRoomMessageRedirectionHash(message)
            : getChatThreadMessageRedirectionHash(message),
          search: isParentMessage ? "" : `message_id=${message.id}`,
        })}
        className={classNames({
          "inline-flex flex-row items-center": isRenderedOnHeader,
        })}
      >
        <span className="text-light inline cursor-pointer text-xs hover:underline">
          {id ? getMessageTime() : t("messaging.sending")}
        </span>
      </Link>
    );
  }
  return (
    <button
      type="button"
      className={classNames(
        "text-timestamp inline min-w-fit text-sm font-medium",
        {
          "hover:underline": id,
        },
      )}
      onClick={copyLink}
    >
      {id ? getMessageTime() : t("messaging.sending")}
    </button>
  );
};
