import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export const LimitsReached = ({ roomType }) => {
  const {
    streamingHoursAvailable,
    recordingHoursReached,
    totalStreamingHours,
    totalRecordingHours,
    limitsReachedIsOpen: isOpen,
    limitsReachedOnClose: onClose,
  } = useAddonsContext();

  return (
    <Modal
      title={t("live_streams.form.limits_reached")}
      isOpen={isOpen}
      onClose={onClose}
      className="z-[2100]"
    >
      <Modal.Overlay />
      <Modal.Content className="!overflow-visible">
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {t("live_streams.form.limits_reached")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!overflow-visible text-justify">
          <ul className="text-default list-outside pl-4 text-sm font-normal">
            {!streamingHoursAvailable && (
              <li className="text-dark text-xs font-normal">
                <Typography.BodyXs>
                  {t(
                    `live_streams.form.${roomType}.upgrade_streaming_hours_label_v3`,
                    {
                      hours: totalStreamingHours,
                    },
                  )}
                </Typography.BodyXs>
              </li>
            )}
            {recordingHoursReached && (
              <li className="text-dark mt-2 text-xs font-normal">
                <Typography.BodyXs>
                  {t(
                    `live_streams.form.${roomType}.upgrade_recording_hours_label_v3`,
                    {
                      hours: totalRecordingHours,
                    },
                  )}
                </Typography.BodyXs>
              </li>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="circle" full large onClick={onClose}>
            {t("live_streams.form.close")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

LimitsReached.propTypes = {
  roomType: PropTypes.string,
};
