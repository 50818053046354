import type { ComponentPropsWithRef, ReactNode } from "react";
import { forwardRef } from "react";
import classnames from "classnames";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";

export interface PostViewListItemWrapperProps
  extends ComponentPropsWithRef<"div"> {
  doesPostHaveCoverImage?: boolean;
  className?: string;
  children: ReactNode;
}

export const PostViewListItemWrapper = forwardRef<
  HTMLDivElement,
  PostViewListItemWrapperProps
>(
  (
    { doesPostHaveCoverImage = false, className, children, ...rest },
    containerRef,
  ) => {
    const isSmScreen = useSmScreenMediaQuery();
    return (
      <div
        className={classnames(
          "post__user flex items-stretch",
          {
            "md:!mb-2 md:!mt-1": isSmScreen && doesPostHaveCoverImage,
          },
          className,
        )}
        ref={containerRef}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

PostViewListItemWrapper.displayName = "PostViewListItemWrapper";
