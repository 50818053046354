import { useState } from "react";
import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { Fields } from "./Fields";
import { Toggles } from "./Toggles";

interface OptionsProps {
  space?: Space;
}
export const Options = ({ space }: OptionsProps) => {
  const [isToggleOpened, setIsToggleOpened] = useState(false);

  return (
    <div className="border-primary border-b">
      <button
        type="button"
        className="flex w-full items-center justify-between px-6 py-5"
        onClick={() => setIsToggleOpened(!isToggleOpened)}
        aria-expanded={isToggleOpened}
      >
        <Typography.LabelMd weight="semibold">
          {t("image_space.post_modal.steps.create_post.more_options_label")}
        </Typography.LabelMd>
        <Icon
          type={isToggleOpened ? "cheveron-up" : "cheveron-down"}
          size={20}
        />
      </button>
      {isToggleOpened && (
        <div className="mb-5 px-6">
          <Toggles space={space} />
          <Fields space={space} />
        </div>
      )}
    </div>
  );
};
