import { useImagePostModalStore } from "../../store";

export const useRemoveImage = () => {
  const { setSelectedFiles, selectedFiles } = useImagePostModalStore();
  const removeImage = (file: any) => {
    const newSelectedFiles = selectedFiles.filter(
      (selectedFile: any) => selectedFile !== file,
    );
    setSelectedFiles(newSelectedFiles);
  };

  return { removeImage };
};
