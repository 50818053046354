import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { LOCATION_TYPES } from "@circle-react/components/Events/helpers/constants";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { Button } from "@circle-react-uikit/Button";
import { isRecurring } from "../../utils";
import { SubmitEditButton } from "../SubmitEditButton";

export const EventsFooter = ({
  event,
  isCreating,
  isDirty,
  onSubmit,
  locationType,
  isStartsAtDirty,
  shouldHideAllEventOption,
}) => {
  const { isSaving, onExit } = useCustomizeUIData();

  const {
    streamingHoursAvailable,
    limitsReachedOnOpen,
    recordingHoursReached,
  } = useAddonsContext();

  const handleUpgrade = event => {
    event.preventDefault();
    limitsReachedOnOpen();
  };

  const isLiveLocation =
    locationType === LOCATION_TYPES.LIVE_STREAM ||
    locationType === LOCATION_TYPES.LIVE_ROOM;

  return (
    <>
      {!isCreating && (
        <div className="ml-auto flex space-x-4">
          {isLiveLocation &&
          !streamingHoursAvailable &&
          recordingHoursReached ? (
            <div className="flex justify-start">
              <button
                type="button"
                className="mr-1 cursor-pointer underline"
                onClick={handleUpgrade}
              >
                {t("live_streams.form.two_upgrades")}
              </button>
              <p>{t("live_streams.form.included")}</p>
            </div>
          ) : isLiveLocation &&
            (!streamingHoursAvailable || recordingHoursReached) ? (
            <div className="flex justify-start">
              <button
                type="button"
                className="mr-1 cursor-pointer underline"
                onClick={handleUpgrade}
              >
                {t("live_streams.form.one_upgrade")}
              </button>
              <p>{t("live_streams.form.included")}</p>
            </div>
          ) : null}
          {isDirty && (
            <>
              <Button type="button" onClick={onExit} variant="secondary">
                {t("cancel")}
              </Button>
              <SubmitEditButton
                type="submit"
                variant="circle"
                disabled={isSaving}
                onEditModeConfirm={onSubmit}
                isRecurringEvent={isRecurring(event)}
                isStartsAtDirty={isStartsAtDirty}
                shouldHideAllEventOption={shouldHideAllEventOption}
              >
                {event ? t("save_changes") : t("save_draft")}
              </SubmitEditButton>
            </>
          )}
        </div>
      )}
    </>
  );
};

EventsFooter.propTypes = {
  event: PropTypes.object,
  isCreating: PropTypes.bool,
  isDirty: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  locationType: PropTypes.string,
  isStartsAtDirty: PropTypes.bool,
  shouldHideAllEventOption: PropTypes.bool,
};
