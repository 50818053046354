import { t } from "@/i18n-js/instance";

const displayPriceType = (priceType: string, intervalLabel: string) =>
  priceType === "subscription"
    ? `${intervalLabel} ${t("settings.paywall_prices.subscription_item_text")}`
    : intervalLabel;

const amountFormatter = (amount: number) => {
  amount = amount / 100;

  return (
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: amount % 1 == 0 ? 0 : 2,
    }).format(amount) + " USD"
  );
};

const displayAmount = (
  interval: string,
  intervalCount: number,
  amount: number,
) => {
  let formattedAmount = amountFormatter(amount);

  if (!interval) {
    return formattedAmount;
  }
  formattedAmount += " / ";
  formattedAmount += t(`settings.paywall_prices.interval_labels.${interval}`, {
    count: intervalCount,
  });
  return formattedAmount;
};

export interface PaywallPriceElementProps {
  priceType: string;
  interval: string;
  intervalCount: number;
  intervalLabel: string;
  amount: number;
  preferred: boolean;
  allowPreferredAction: boolean;
  onRemove: () => void;
  onSetPreferred: () => void;
}

export const PaywallPriceElement = ({
  priceType,
  interval,
  intervalCount,
  intervalLabel,
  amount,
  preferred,
  allowPreferredAction,
  onRemove,
  onSetPreferred,
}: PaywallPriceElementProps) => (
  <div className="price-list__element">
    <div className="price-list__element__info">
      <div className="price-list__element__info--type">
        {displayPriceType(priceType, intervalLabel)}
      </div>
      <div className="price-list__element__info--amount">
        {displayAmount(interval, intervalCount, amount)}
      </div>
    </div>
    <div className="price-list__element__actions">
      {!preferred && allowPreferredAction && (
        <button
          type="button"
          className="price-list__element__actions__action btn-link label-negative focus-visible:!outline-secondary focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
          onClick={onSetPreferred}
        >
          {t("settings.paywall_prices.buttons.make_preferred")}
        </button>
      )}
      {preferred && (
        <button
          type="button"
          className="price-list__element__actions__action label-positive focus-visible:!outline-secondary focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
          onClick={onSetPreferred}
        >
          {t("settings.paywall_prices.form.preferred_label")}
        </button>
      )}
      <button
        type="button"
        className="price-list__element__actions__danger btn-link focus-visible:!outline-secondary focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
        onClick={onRemove}
      >
        {t("settings.paywall_prices.buttons.remove")}
      </button>
    </div>
  </div>
);
