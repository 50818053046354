import { useCallback } from "react";
import { flatten, noop, uniqBy } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { useInfiniteQueryHelpers } from "@/react/hooks/reactQuery/useInfiniteQueryHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { isPostOrEventOrImageSpace } from "@circle-react/helpers/spaceHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import { useCalendarViewInfiniteFetch } from "./useCalendarViewInfiniteFetch";

const REQUEST_KEY = "posts";

type PostListLayoutTypes =
  | "calendar"
  | "pinned_post_sidebar"
  | "posts"
  | "cards"
  | "list"
  | "thumbnail"
  | "feed"
  | "masonry"
  | "grid";

export interface PostListParams {
  per_page?: number;
  only_pinned?: boolean;
  used_on?: PostListLayoutTypes;
  sort?: any;
  include_top_pinned_post?: boolean;
  topics?: string[];
  filter_date?: {
    start_date?: string;
    end_date?: string;
  };
  status?: string;
  past_events?: boolean;
}

export const usePostList = (
  params: PostListParams | string = {
    per_page: 12, // 12 is multiple of 4, 3, 2 and 1 hence we always will have full rows
  },
  isEnabled: boolean = true,
  onSuccess: any = noop,
) => {
  const queryClient = useQueryClient();
  const { removeRecord, findAndReplaceRecord } = useInfiniteQueryHelpers();
  const currentSpaceContext = useCurrentSpaceContext();
  const { data: space } = currentSpaceContext;

  // Remove undefined params to avoid API errors
  const transformedParams: PostListParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => typeof value !== "undefined"),
  );

  const queryKey = [REQUEST_KEY, space?.id, transformedParams];

  const fetchPage = (page: number) =>
    reactQueryGet(
      internalApi.posts.index({
        spaceId: space?.id,
        params: { page, ...transformedParams },
      }),
    );

  const isCalendarView = transformedParams?.used_on === "calendar";

  const { data, hasNextPage, isLoading, refetch, fetchNextPage } =
    useInfiniteQuery(queryKey, ({ pageParam = 1 }) => fetchPage(pageParam), {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      keepPreviousData: isCalendarView,
      enabled: isEnabled && !!space?.id && isPostOrEventOrImageSpace(space),
      onSuccess,
    });

  const onPostChange = useCallback(
    async (post, { refetch = true } = {}) => {
      let fetchedPost = post;
      if (refetch) {
        try {
          const url = internalApi.posts.show({
            spaceId: post.space_id,
            postSlug: post.slug,
          });
          fetchedPost = await queryClient.fetchQuery(url);
        } catch (err) {
          console.error(err);
        } finally {
          findAndReplaceRecord(fetchedPost, queryKey);
        }
      } else {
        findAndReplaceRecord(fetchedPost, queryKey);
      }
    },
    [findAndReplaceRecord, queryKey],
  );

  const onPostDestroy = useCallback(
    postId => {
      removeRecord(postId, queryKey);
    },
    [removeRecord],
  );

  let posts = [];
  if (!isLoading && data) {
    posts = uniqBy(flatten(data.pages.map(page => page.records)), "id");
  }
  const mapPages = (callback: any) => {
    if (isLoading || !data) return [];
    return data.pages.map(callback);
  };

  const lastPage = data?.pages[data?.pages.length - 1];
  useCalendarViewInfiniteFetch({ lastPage, isCalendarView, fetchNextPage });

  return {
    mapPages,
    hasNextPage,
    refetch,
    isLoading,
    fetchNextPage,
    posts,
    onPostChange,
    onPostDestroy,
  };
};

export const usePostListPinnedOnSidebar = (postsPerPage?: number) =>
  usePostList({
    only_pinned: true,
    per_page: postsPerPage || 50,
    used_on: "pinned_post_sidebar",
  });
