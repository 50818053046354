import { ROOM_STATUS } from "@circle-react/helpers/liveStreamRoomHelpers";
import { EVENT_TYPES, STATUS } from "../helpers/constants";

export const SECTIONS = {
  OVERVIEW: "overview",
  DETAILS: "details",
  DATE_AND_TIME: "date_time",
  LOCATION: "location",
  PEOPLE: "people",
  NOTIFICATIONS: "notifications",
  ADVANCED_OPTIONS: "advanced_options",
};

export const LOCATION_TYPES = {
  VIRTUAL: "virtual",
  IN_PERSON: "in_person",
  TBD: "tbd",
  LIVE_STREAM: "live_stream",
  LIVE_ROOM: "live_room",
};

export const TABS = {
  OVERVIEW: {
    EVENT_INFO: "event_info",
    SHAREABLE_LINKS: "shareable_links",
  },
  DETAILS: {
    INFORMATION: "information",
    DESCRIPTION: "description",
    IMAGES: "images",
  },
  DATE_AND_TIME: null,
  LOCATION: null,
  PEOPLE: {
    ATTENDEES: "attendees",
    COHOSTS: "cohosts",
  },
  NOTIFICATIONS: null,
  ADVANCED_OPTIONS: {
    PERMISSIONS: "permissions",
    ATTENDEES: "attendees",
    META_TAGS: "meta_tags",
  },
};

export const getDefaultValuesForEdit = event => ({
  ...event,
  body: event.body_for_editor,
});

export const isDraft = event => event?.status === STATUS.DRAFT;
export const isPublished = event => event?.status === STATUS.PUBLISHED;
export const isRecurring = event => event?.event_type === EVENT_TYPES.RECURRING;
export const isSingle = event => event?.event_type === EVENT_TYPES.SINGLE;

const streamStatus = event =>
  event?.event_setting_attributes?.live_stream_room_setting_attributes
    ?.stream_status;
export const isStreamEnded = event => streamStatus(event) === ROOM_STATUS.ENDED;
export const isStreamBackstage = event =>
  streamStatus(event) === ROOM_STATUS.BACKSTAGE;
export const isStreamLive = event => streamStatus(event) === ROOM_STATUS.LIVE;
