import { isNull } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import { useChatStore } from "@circle-react/hooks/chatsV2";

export const useUnreadChatRoomUuids = ({
  communityId: communityIdParam,
}: any) => {
  const { currentCommunity } = usePunditUserContext();
  const communityId = communityIdParam || currentCommunity?.id;
  const { unreadChatRoomUuids } = useChatStore();

  if (isNull(communityId)) return [];
  return unreadChatRoomUuids[communityId] || [];
};
