import copyToClipboard from "copy-to-clipboard";
import { t } from "@/i18n-js/instance";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useMobileEventActions = (event: any) => {
  const { slug: eventSlug, space_slug: spaceSlug } = event || {};
  const { success } = useToast();

  const path = postsPath({
    spaceSlug,
    slug: eventSlug,
  });

  const onViewEvent = () => {
    window.open(path, "_blank");
  };

  const eventUrl = `${window.location.origin}${path}`;

  const onCopyLink = (link: string) => {
    copyToClipboard(link);
    success(t("share_link.copied"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };

  const liveStreamSlug = event?.event_setting_attributes?.live_stream_slug;
  const roomPath = liveStreamPaths.room({ roomSlug: liveStreamSlug });
  const host = window.location.origin;
  const liveRoomUrl = `${host}${roomPath}`;
  const publicLiveRoomUrl = `${host}${liveStreamPaths.publicRoom({
    roomSlug: liveStreamSlug,
  })}`;

  return {
    onViewEvent,
    onCopyLink,
    eventUrl,
    liveRoomUrl,
    publicLiveRoomUrl,
  };
};
