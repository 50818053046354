import { t } from "@/i18n-js/instance";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types";
import { ShareableLink } from "../ShareableLink";

interface CopyEventUrlLinkProps {
  event: Event;
}

export const CopyEventUrlLink = ({ event }: CopyEventUrlLinkProps) => {
  const { slug: eventSlug, space_slug: spaceSlug } = event || {};
  const path = postsPath({
    spaceSlug,
    slug: eventSlug,
  });
  const link = `${window.location.origin}${path}`;

  return (
    <ShareableLink
      label={t("events.edit_modal.overview.copy_url")}
      link={link}
    />
  );
};
