import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const NewPasswordComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "NewPasswordPage" */ "@circle-react/components/OnboardingPage/ForgotPasswordPage"
    ),
);

export const NewPasswordPage = () => (
  <Suspense fallback={<PageLoader />}>
    <NewPasswordComponent />
  </Suspense>
);
