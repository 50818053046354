import { useCallback, useEffect, useReducer, useRef } from "react";

export const useReducerWithThunk = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getStateRef = useRef(() => state);
  useEffect(() => {
    getStateRef.current = () => state;
  }, [state]);
  const customDispatch = useCallback(action => {
    if (typeof action === "function") {
      action(customDispatch, getStateRef.current);
    } else {
      dispatch(action);
    }
  }, []);
  return [state, customDispatch];
};
