import { useMutation } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export const useCreateTopicMutation = ({ onSuccess, spaceId }: any) => {
  const toast = useToast();
  return useMutation({
    mutationFn: async (name: string) =>
      reactQueryPost(internalApi.settings.topics.create(), {
        topic: {
          name,
          space_ids: [spaceId],
        },
      }),
    onSuccess,
    onError: (err: { errorDetails: string }) => {
      if (err.errorDetails === "Name has already been taken") {
        toast.error(
          "A topic with this name already exists. Add it to your space instead from the customize space screen.",
        );
      }
    },
  });
};
