import type { ImagePost } from "@/react/types";
import { GridPostItem } from "./GridPostItem";

interface MasonryPostItemProps {
  post: ImagePost;
  onPostChange: (post: ImagePost) => void;
  onPostDestroy: (postId: number) => void;
}
export const MasonryPostItem = ({
  post,
  onPostChange,
  onPostDestroy,
}: MasonryPostItemProps) => (
  <GridPostItem
    post={post}
    onPostChange={onPostChange}
    onPostDestroy={onPostDestroy}
    isSquareGrid={false}
  />
);
