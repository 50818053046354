import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { CircularProgress } from "@circle-react-uikit/TipTap/AttachmentPreviews/CircularProgress";
import { Typography } from "@circle-react-uikit/Typography";

export const LoadingThumbnail = ({ fileStates, index }: any) => (
  <div className="shrink-0 p-1">
    <div className="bg-tertiary flex h-12 w-12 items-center justify-center rounded-md">
      {fileStates[index].status === "completed" && (
        <Icon
          type="20-checkmark-circle-fill"
          size={20}
          className="text-default"
        />
      )}
      {fileStates[index].status === "uploading" && (
        <CircularProgress
          percentage={fileStates[index].progress}
          size={18}
          strokeWidth={1.5}
        />
      )}
      {fileStates[index].status === "error" && (
        <Typography.LabelXs color="text-default">
          {t("image_space.post_modal.steps.adjust_images.file_error")}
        </Typography.LabelXs>
      )}
    </div>
  </div>
);
