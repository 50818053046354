import { useLevelColors } from "@circle-react/components/Leaderboard/colorHelper";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useThemeContext } from "@circle-react/providers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

const LARGE_AVATAR_SIZE = 104;
const SMALL_AVATAR_SIZE = 64;
const PROGRESS_STROKE_WIDTH = 4;

export const AvatarWithProgress = ({
  avatarUrl,
  name,
  progress = 0,
  level,
  size = "large",
}: {
  avatarUrl?: string;
  name: string;
  progress?: number;
  level?: number;
  size?: "large" | "small";
}) => {
  const { isDarkAppearance } = useThemeContext();
  const BACKGROUND_COLOR = isDarkAppearance ? "#42464D" : "#F0F3F5";
  const AVATAR_SIZE = size === "large" ? LARGE_AVATAR_SIZE : SMALL_AVATAR_SIZE;
  const radius = (AVATAR_SIZE - PROGRESS_STROKE_WIDTH) / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (progress / 100) * circumference;
  const levelColors = useLevelColors(level || 1);

  return (
    <div
      className="relative flex items-center justify-center rounded-full"
      style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}
    >
      <svg
        className="absolute"
        width={AVATAR_SIZE}
        height={AVATAR_SIZE}
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          cx={AVATAR_SIZE / 2}
          cy={AVATAR_SIZE / 2}
          r={radius}
          stroke={BACKGROUND_COLOR}
          strokeWidth={PROGRESS_STROKE_WIDTH}
          fill="none"
        />
        <circle
          cx={AVATAR_SIZE / 2}
          cy={AVATAR_SIZE / 2}
          r={radius}
          stroke={levelColors.progress}
          strokeWidth={PROGRESS_STROKE_WIDTH}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          strokeLinecap="round"
        />
      </svg>
      <div
        className="bg-secondary absolute z-0 rounded-full"
        style={{
          height: AVATAR_SIZE - 2 * PROGRESS_STROKE_WIDTH,
          width: AVATAR_SIZE - 2 * PROGRESS_STROKE_WIDTH,
        }}
      >
        <UserImage
          isFullRounded
          src={avatarUrl}
          name={name}
          size={size === "large" ? "24" : "14"}
        />
      </div>
      {level && (
        <div
          className={classNames(
            "absolute -bottom-1 -right-1 z-10 flex items-center justify-center rounded-full",
            {
              "h-8 w-8": size === "large",
              "h-6 w-6": size === "small",
            },
            levelColors.bg,
          )}
        >
          <Typography.LabelSm weight="bold">
            <span className={levelColors.text}>{level}</span>
          </Typography.LabelSm>
        </div>
      )}
    </div>
  );
};
