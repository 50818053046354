import classNames from "classnames";
import { isImagePost } from "@/react/helpers/postHelpers";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { MinimalTiptapContent } from "./MinimalTipTapContent";
import { MinimalTrixContent } from "./MinimalTrixContent";

export const MinimalPostBody = ({ noPadding = false }) => {
  const { post, isTiptapPost, isPostBodyEmpty } = useCurrentPostHelpers();

  const { updated_at: updatedAt } = post;

  if (isPostBodyEmpty) {
    return null;
  }

  return (
    <div
      key={updatedAt}
      className={classNames("post__body", {
        "!p-0": noPadding,
        "px-5 pb-5 md:px-6": isImagePost(post),
      })}
      data-testid="post-body"
    >
      <div
        className="post__inside trix-v2 w-full"
        data-testid="post-body-inside"
      >
        {isTiptapPost ? <MinimalTiptapContent /> : <MinimalTrixContent />}
      </div>
    </div>
  );
};
