import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useThemeContext } from "@/react/providers";
import { shouldRenderLockIcon } from "@circle-react/components/Spaces/utils";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePunditUserContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { viewSpacePath } from "@circle-react/helpers/urlHelpers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";

export const SpaceCard = ({
  onClose,
  space: {
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
    hide_members_count: shouldHideMembersCount,
    name,
    slug,
    member_count,
    is_private,
    show_lock_icon_for_non_members,
    is_space_member,
  },
  shouldOpenInNewTab,
}) => {
  const { currentAppearance } = useThemeContext();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const shouldUseLockIcon = shouldRenderLockIcon({
    shouldShowLockIconForNonMembers:
      show_lock_icon_for_non_members && !is_space_member,
    isPrivate: is_private,
    isAdmin,
  });

  return (
    <Link
      to={viewSpacePath({ spaceSlug: slug })}
      onClick={e => (isIntendedOnNewTab(e) ? false : onClose())}
      className="profile-spaces__item"
      target={shouldOpenInNewTab && "_blank"}
    >
      <EmojiRenderer
        wrapperClassName="profile-spaces__emoji self-start"
        className="gated-or-not with-emoji"
        emoji={emoji}
        customEmojiUrl={customEmojiUrl}
        customEmojiDarkUrl={customEmojiDarkUrl}
        appearance={currentAppearance}
        shouldUseLockIcon={shouldUseLockIcon}
        lockIcon={
          <span className="gated-or-not">
            <Icon type="lock" useWithFillCurrentColor className="text-dark" />
          </span>
        }
      />
      <div className="profile-spaces__details">
        <p className="profile-spaces__title">{name}</p>
        {!shouldHideMembersCount && (
          <p className="profile-spaces__members">
            {t("profiles.members", { count: member_count })}
          </p>
        )}
      </div>
    </Link>
  );
};

SpaceCard.propTypes = {
  space: PropTypes.shape({
    emoji: PropTypes.string,
    custom_emoji_url: PropTypes.string,
    custom_emoji_dark_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    member_count: PropTypes.number.isRequired,
    is_private: PropTypes.bool.isRequired,
  }),
};
