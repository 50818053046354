import { t } from "@/i18n-js/instance";

export interface ModerationInfoBaseProps {
  record: {
    unresolved_flagged_reports_count: number;
    id: string;
  };
  contentType: "post" | "comment";
}

export const getContentTranslation = (contentType: "post" | "comment") =>
  contentType === "post"
    ? t("flagged_contents.moderation_info.post")
    : t("flagged_contents.moderation_info.comment");

export const shouldRenderContent = (
  record: ModerationInfoBaseProps["record"],
) => Boolean(record.unresolved_flagged_reports_count);
