import { t } from "@/i18n-js/instance";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export interface RoleIconProps {
  role: IconType;
}

export const RoleIcon = ({ role }: RoleIconProps) => (
  <TippyV2 placement="top" content={t(`live_roles.${role}`)}>
    <Icon type={role} size={16} className="-ml-1 -mt-1" />
  </TippyV2>
);
