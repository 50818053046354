import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const DiscoverSignInComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DiscoverSignInPage" */ "@circle-react/components/Discover/SignInPage"
    ),
);

export const DiscoverSignInPage = () => (
  <Suspense fallback={<PageLoader />}>
    <DiscoverSignInComponent />
  </Suspense>
);
