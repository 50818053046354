import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMemberToMemberMessagingEnabled } from "@circle-react/hooks/useIsMessagingEnabled";

interface Member {
  community_member_id: number;
  messaging_enabled: boolean;
}

export const useMessageActions = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isMemberToMemberMessagingEnabled =
    useIsMemberToMemberMessagingEnabled();

  const shouldShowMessageOption = (member: Member) => {
    const isCurrentCommunityMemberProfile =
      currentCommunityMember?.id === member.community_member_id;
    return (
      !isCurrentCommunityMemberProfile &&
      currentCommunityMember?.messaging_enabled &&
      (member.messaging_enabled || currentCommunityMember.is_admin) &&
      isMemberToMemberMessagingEnabled
    );
  };

  const shouldShowRemoveOption = (member: Member) => {
    const isCurrentCommunityMemberProfile =
      currentCommunityMember?.id === member.community_member_id;
    return !isCurrentCommunityMemberProfile && currentCommunityMember?.is_admin;
  };

  return { shouldShowMessageOption, shouldShowRemoveOption };
};
