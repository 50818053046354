import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { TipTapBlockEditor } from "@circle-react-shared/uikit/TipTapBlockEditor";

export const DisplayTiptapContent = ({ tiptapBody }) => {
  const isPostFormat = tiptapBody?.format === "post";
  return isPostFormat ? (
    <TipTapBlockEditor
      readOnly
      value={tiptapBody.body}
      sgidToObjectMap={tiptapBody.sgids_to_object_map}
      inlineAttachments={tiptapBody.inline_attachments}
      openInternalLinksInCurrentTab
      disabledExtensions={["cta"]}
    />
  ) : (
    <TiptapEditor
      type="comment"
      editable={false}
      rich_text_body={tiptapBody}
      openInternalLinksInCurrentTab
    />
  );
};
