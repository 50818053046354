export const STEPS = {
  UPLOAD_IMAGES: "upload_images",
  ADJUST_IMAGES: "adjust_images",
  CREATE_POST: "create_post",
} as const;

export const FORMATS = {
  SQUARE: "square",
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
} as const;

export const ASPECT_RATIOS = {
  [FORMATS.SQUARE]: 1,
  [FORMATS.LANDSCAPE]: 16 / 9,
  [FORMATS.PORTRAIT]: 4 / 5,
};
