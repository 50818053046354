export class TrixContentAttachmentPreviewHelperService {
  constructor(trixContentContainerElement) {
    this.element = trixContentContainerElement;
  }

  addAttachmentClickEventHandler(callback) {
    this.element.addEventListener("click", event => {
      const attachment = event.target.closest("action-text-attachment");
      if (!attachment) {
        return;
      }
      const attachmentAttributes = this.parseAttachmentAttributes(attachment);
      if (this.isPreviewableAttachment(attachmentAttributes)) {
        const attachments = this.getSimilarAttachments(attachmentAttributes);
        const currentIndex = attachments.findIndex(
          att => att.sgid === attachmentAttributes.sgid,
        );
        callback({ attachments, currentIndex });
      }
    });
  }

  getSimilarAttachments(attachment) {
    const filterFunc =
      (this.isImageAttachment(attachment) && this.isImageAttachment) ||
      (this.isPdfAttachment(attachment) && this.isPdfAttachment);
    return [...this.element.querySelectorAll("action-text-attachment")]
      .map(this.parseAttachmentAttributes)
      .filter(filterFunc);
  }

  isPreviewableAttachment(attachment) {
    return (
      this.isPdfAttachment(attachment) || this.isImageAttachment(attachment)
    );
  }

  isImageAttachment({ contentType }) {
    return ["image/png", "image/jpeg", "image/jpg", "image/gif"].includes(
      contentType,
    );
  }

  isPdfAttachment({ contentType }) {
    return ["application/pdf"].includes(contentType);
  }

  parseAttachmentAttributes(attachment) {
    return {
      sgid: attachment.getAttribute("sgid"),
      contentType: attachment.getAttribute("content-type"),
      url: attachment.querySelector("[data-target~='blob-container']")?.dataset
        ?.blobUrl,
      filename: attachment.getAttribute("filename"),
      filesize: attachment.getAttribute("filesize"),
      width: attachment.getAttribute("width"),
      height: attachment.getAttribute("height"),
    };
  }
}
