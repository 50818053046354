import { createPortal } from "react-dom";
import { t } from "@/i18n-js/instance";
import { FreeTrial } from "./FreeTrial";
import { INTERVAL_OPTIONS } from "./INTERVAL_OPTIONS";
import { PaywallPriceElement } from "./PaywallPriceElement";
import { addButtonLocked } from "./addButtonLocked";
import { usePaywallPrices } from "./hooks/usePaywallPrices";
import "./styles.scss";

export const PaywallPrices = ({
  name,
  paywall_prices: paywallPrices,
  prices_max_count: pricesMaxCount,
  trial_days: trialDays,
}) => {
  const {
    currentPaywallPrices,
    intervalOption,
    onIntervalOptionChange,
    amount,
    onAmountChange,
    onAdd,
    onRemove,
    onSetPreferred,
    changes,
    hasSubscriptionPrices,
  } = usePaywallPrices(paywallPrices, pricesMaxCount);

  const container = document.getElementById("paywall-free-trial-container");

  return (
    <div className="paywall-prices-container">
      <div className="add-new-price" data-testid="add-new-price">
        <select
          className="add-new-price__element"
          value={intervalOption}
          onChange={onIntervalOptionChange}
        >
          {INTERVAL_OPTIONS.map(option => (
            <option key={option} value={option}>
              {t(
                `settings.paywall_prices.form.interval_select_options.${option}_label`,
              )}
            </option>
          ))}
        </select>
        <span className="amount-icon">$</span>
        <input
          type="number"
          step=".01"
          min="1"
          placeholder={t("settings.paywall_prices.amount")}
          className="form-control add-new-price__element--short rounded-none rounded-r-md"
          value={amount}
          onChange={onAmountChange}
        />
        <button
          type="submit"
          disabled={addButtonLocked(
            amount,
            intervalOption,
            currentPaywallPrices,
            pricesMaxCount,
          )}
          className="add-new-price__element--button btn btn-circle"
          onClick={onAdd}
        >
          {t("settings.paywall_prices.buttons.add")}
        </button>
      </div>
      <div className="price-list">
        {currentPaywallPrices.map(
          (
            {
              id,
              tempId,
              price_type,
              interval,
              interval_count,
              interval_label,
              amount,
              preferred,
            },
            index,
          ) => (
            <PaywallPriceElement
              key={id || tempId}
              priceType={price_type}
              interval={interval}
              intervalLabel={interval_label}
              intervalCount={interval_count}
              amount={amount}
              preferred={preferred}
              allowPreferredAction={currentPaywallPrices.length > 1}
              onRemove={() => onRemove(index)}
              onSetPreferred={() => onSetPreferred(index)}
            />
          ),
        )}
      </div>
      {changes.map(change => (
        <input key={change} name={`${name}[]`} type="hidden" value={change} />
      ))}
      {createPortal(
        <FreeTrial
          trialDays={trialDays}
          hasSubscriptionPrices={hasSubscriptionPrices}
        />,
        container,
      )}
    </div>
  );
};
