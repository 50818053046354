import { UserGroupIcon } from "@circle-react/components/shared/UserGroupIcon";
import type { Event } from "@circle-react/types/Event";
import { useAttendeesPreview } from "./hooks/useAttendeesPreview";

interface AttendeesPreviewProps {
  event: Event;
  size?: "lg" | "md";
}

export const AttendeesPreview = ({
  event,
  size = "md",
}: AttendeesPreviewProps) => {
  const { attendees, totalCount, handleAttendeesClick } =
    useAttendeesPreview(event) || {};

  if (!attendees?.length) {
    return null;
  }

  return (
    <button type="button" onClick={handleAttendeesClick}>
      <UserGroupIcon users={attendees} totalCount={totalCount} size={size} />
    </button>
  );
};
