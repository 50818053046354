import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import type { ImagePost } from "@/react/types";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useFullPostModal } from "../FullPostModal";

interface OpenFullPostButtonProps {
  post: ImagePost;
  children: ReactNode;
  onPostChange: (post: ImagePost) => void;
  onPostDestroy: (postId: number) => void;
}
export const OpenFullPostButton = ({
  post,
  children,
  onPostChange,
  onPostDestroy,
}: OpenFullPostButtonProps) => {
  const fullPostModal = useFullPostModal();
  const { data: space } = useCurrentSpaceContext();
  return (
    <button
      type="button"
      className="focus-visible:outline-secondary peer w-full focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      aria-label={t("image_space.grid_view.open_full_post")}
      onClick={() =>
        fullPostModal.show({
          post,
          spaceId: space?.id,
          onPostChange,
          onPostDestroy,
        })
      }
    >
      {children}
    </button>
  );
};
