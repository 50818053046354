import { t } from "@/i18n-js/instance";
import { useCommunityPlanAddon } from "@circle-react/hooks/addons/useCommunityPlanAddon";
import { useToast } from "@circle-react-uikit/ToastV2";

const ATTENDEE_SEATS_ADDON_SLUG = "live_stream_spectators";

export const useAttendeeSeatsAddon = (options = { enabled: true }) => {
  const { error } = useToast();
  const onPurchaseError = () =>
    error(t("live_streams.room.attendees_addon_quantity_error"));
  return useCommunityPlanAddon(
    ATTENDEE_SEATS_ADDON_SLUG,
    onPurchaseError,
    null,
    options,
  );
};
