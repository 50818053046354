import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { POST_STATUS_PUBLISHED } from "@/react/helpers/postHelpers";
import { Button } from "@circle-react-uikit/Button";

export const SimpleSubmitButton = ({ submitDisabled, post, isSubmitting }) => {
  const isEditMode = !!post.id;
  const translationKey = ["quick_post", "scheduled_post_ui"];
  translationKey.push(isSubmitting ? "submitting_button" : "submit_button");
  if (post.status !== POST_STATUS_PUBLISHED) {
    translationKey.push("publish_post");
  } else {
    translationKey.push(isEditMode ? "save" : "post");
  }
  const submitButtonText = t(translationKey.join("."));
  return (
    <Button type="submit" variant="primary" disabled={submitDisabled}>
      {submitButtonText}
    </Button>
  );
};

SimpleSubmitButton.propTypes = {
  post: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
