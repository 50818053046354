import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const Pagination = ({
  startRecordNumber,
  endRecordNumber,
  totalRecordCount,
  fetchPrevPage,
  fetchNextPage,
  hasPrevPage,
  hasNextPage,
}) => (
  <div className="flex h-16 w-full items-center justify-between">
    <div className="flex items-end">
      <div>
        <Typography.LabelSm>
          {t("events.edit_modal.pagination.showing")}
        </Typography.LabelSm>
      </div>
      <div className="mx-1">
        <Typography.LabelSm weight="semibold">
          {startRecordNumber}-{endRecordNumber}
        </Typography.LabelSm>
      </div>
      <div className="mr-1">
        <Typography.LabelSm>
          {t("events.edit_modal.pagination.of")}
        </Typography.LabelSm>
      </div>
      <div>
        <Typography.LabelSm weight="semibold">
          {totalRecordCount}
        </Typography.LabelSm>
      </div>
    </div>
    {(hasPrevPage || hasNextPage) && (
      <div className="flex items-center">
        <Button
          variant="secondary"
          type="button"
          className="mr-3"
          disabled={!hasPrevPage}
          onClick={fetchPrevPage}
        >
          {t("events.edit_modal.pagination.previous")}
        </Button>
        <Button
          variant="secondary"
          type="button"
          disabled={!hasNextPage}
          onClick={fetchNextPage}
        >
          {t("events.edit_modal.pagination.next")}
        </Button>
      </div>
    )}
  </div>
);

Pagination.propTypes = {
  startRecordNumber: PropTypes.number,
  endRecordNumber: PropTypes.number,
  totalRecordCount: PropTypes.number,
  fetchPrevPage: PropTypes.func.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasPrevPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
};
