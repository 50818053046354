const ENVS = Object.freeze({
  PRODUCTION: "production",
  DEVELOPMENT: "development",
  STAGING: "staging",
});

export const isProductionEnv = () => window.RAILS_ENV === ENVS.PRODUCTION;

export const isStagingEnv = () => window.RAILS_ENV === ENVS.STAGING;

export const isDevelopmentEnv = () => window.RAILS_ENV === ENVS.DEVELOPMENT;
