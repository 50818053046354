import { t } from "@/i18n-js/instance";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";

interface DeleteScheduledEmailProps {
  shouldShowConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
}

export const DeleteScheduledEmail = ({
  shouldShowConfirmationModal,
  toggleConfirmationModal,
  handleSubmit,
  isLoading,
}: DeleteScheduledEmailProps) => {
  const onConfirmHandler = async () => {
    await handleSubmit();
    toggleConfirmationModal();
  };

  return (
    <ActionModal
      isOpen={shouldShowConfirmationModal}
      onClose={toggleConfirmationModal}
      onAction={onConfirmHandler}
      actionText={t("scheduled_emails.delete.email")}
      title={t("scheduled_emails.delete.modal_title")}
      size="sm"
      actionVariant="danger"
      disabled={isLoading}
    >
      <div className="my-4 text-center">
        {t("scheduled_emails.delete.modal_description")}
      </div>
    </ActionModal>
  );
};
