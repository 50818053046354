import { useFormContext } from "react-hook-form";

export const useEventLocationType = event => {
  const { watch } = useFormContext();
  const { event_setting_attributes: formEventData } = watch();
  const { event_setting_attributes: eventData } = event || {};
  const locationType = formEventData?.location_type || eventData?.location_type;

  return {
    locationType,
    formEventData,
    eventData,
  };
};
