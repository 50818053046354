import * as HoverCard from "@radix-ui/react-hover-card";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useProfileDrawer } from "@circle-react/components/Drawers/ProfileDrawer";
import { AvatarWithProgress } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/AvatarWithProgress";
import { LevelBadge } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/LevelBadge";
import type { ProfileHoverCardProps } from "@circle-react/components/Profile/HoverCard";
import { MessageButton } from "@circle-react/components/Profile/HoverCard/MessageButton";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface CardContentProps extends Omit<ProfileHoverCardProps, "children"> {
  closeCard: () => void;
}

export const CardContent = ({
  communityMemberId,
  publicUid,
  messagingEnabled,
  avatarURL,
  name = "",
  headline = "",
  closeCard,
}: CardContentProps) => {
  const drawer = useProfileDrawer();
  const { data: gamificationSettings } = useGamificationSettings();
  const { currentCommunityMember } = usePunditUserContext();

  const isCurrentCommunityMember =
    currentCommunityMember?.id === communityMemberId;

  const shouldShowMessageButton =
    !isCurrentCommunityMember &&
    currentCommunityMember?.messaging_enabled &&
    messagingEnabled;

  const { isLoading, data } = useGamificationMember({
    member_ids: [communityMemberId],
  });

  const gamificationMember = data?.find(
    member => Number(member.community_member_id) === Number(communityMemberId),
  );

  const shouldShowLevelBadge = !!gamificationSettings?.enabled;

  return (
    <HoverCard.Content
      className="border-primary bg-primary z-[1059] w-full max-w-[320px] rounded-xl border p-6 shadow-xl"
      sideOffset={5}
      side="right"
    >
      <div
        className={classNames(
          "flex justify-between",
          headline ? "items-start" : "items-center",
        )}
      >
        <div className="text-dark mr-4 truncate">
          <Typography.LabelLg weight="semibold">
            <span className="text-dark">{name}</span>
          </Typography.LabelLg>
          <br />
          {headline && (
            <div className="text-light truncate">
              <Typography.LabelSm weight="regular">
                <span className="text-light">{headline}</span>
              </Typography.LabelSm>
            </div>
          )}
          {shouldShowLevelBadge && (
            <div className="mt-2">
              {isLoading ? (
                <div className="bg-tertiary mb-2 h-7 w-32 animate-pulse rounded-full text-center text-transparent md:text-left" />
              ) : (
                gamificationMember?.current_level && (
                  <LevelBadge
                    level={gamificationMember?.current_level}
                    levelName={gamificationMember?.current_level_name}
                  />
                )
              )}
            </div>
          )}
        </div>
        <div className="flex-1">
          <AvatarWithProgress
            avatarUrl={avatarURL}
            name={name}
            size="small"
            progress={gamificationMember?.level_progress}
            level={gamificationMember?.current_level}
          />
        </div>
      </div>
      <div className="mt-4 space-y-2" />
      <div className="mt-4 flex w-full space-x-2">
        {publicUid && (
          <Button
            className="!border-primary flex items-center justify-center space-x-2 !px-3 !py-1.5 !font-medium"
            full={!shouldShowMessageButton}
            variant="secondary"
            onClick={() => {
              void drawer.show({
                memberPublicId: publicUid,
              });
              closeCard();
            }}
          >
            <Icon
              type="16-user"
              useWithFillCurrentColor
              size={16}
              aria-hidden
            />
            <span>{t("view_profile")}</span>
          </Button>
        )}
        {shouldShowMessageButton && (
          <MessageButton
            closeCard={closeCard}
            communityMemberId={communityMemberId}
          />
        )}
      </div>
    </HoverCard.Content>
  );
};
