import { t } from "@/i18n-js/instance";

export const generateHoverDetails = (
  communityMembers: any[],
  emoji: string,
  currentCommunityMemberId: number,
  count: number,
) => {
  const limit = 5;

  const reactedByMeIndex = communityMembers.findIndex(
    communityMember => communityMember.id === currentCommunityMemberId,
  );
  if (reactedByMeIndex !== -1) {
    communityMembers.splice(reactedByMeIndex, 1);
    communityMembers.unshift({
      id: currentCommunityMemberId,
      full_name: t("reactions.you"),
    });
  }

  const names = communityMembers.map(
    communityMember => communityMember.full_name,
  );

  if (count > limit) {
    return (
      names.join(", ") +
      ` ${t("reactions.and")} ${count - limit} ${t(
        "reactions.more_reacted_with",
      )} :${emoji}:`
    );
  }
  return (
    names.join(", ").replace(/,([^,]*)$/, ` ${t("reactions.and")}$1`) +
    ` ${t("reactions.reacted_with")} :${emoji}:`
  );
};
