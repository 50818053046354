import { usePunditUserContext } from "@circle-react/contexts";

export const useLiveStreamsFeatureFlags = () => {
  const { currentCommunitySettings, isLoading } = usePunditUserContext();

  return {
    isLoading,
    isLiveStreamsHlsEnabled:
      !!currentCommunitySettings?.live_streams_hls_enabled,
    isLiveStreamsLimitsEnabled:
      !!currentCommunitySettings?.live_streams_limits_enabled,
    isLivekitEnabled: !!currentCommunitySettings?.livekit_enabled,
    isLiveMobileWebEnabled: !!currentCommunitySettings?.live_mobile_web_enabled,
    isLiveMobileWebRTCEnabled:
      !!currentCommunitySettings?.live_mobile_webrtc_enabled,
    isPublicLiveStreamsEnabled:
      !!currentCommunitySettings?.public_live_streams_enabled,
    isLimitUrlSharingEnabled:
      !!currentCommunitySettings?.limit_url_sharing_enabled,
    isLargeLiveStreamsEnabled:
      !!currentCommunitySettings?.large_live_streams_enabled,
  };
};
