import { useState } from "react";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useDeleteHandler = ({ comment, onDelete }) => {
  const { id, post_id: postId } = comment;
  const { success, error } = useToast();
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  const openDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(true);
  const closeDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(false);

  const { mutate: deleteComment, isLoading: isDeletingComment } = useMutation(
    () => reactQueryDelete(internalApi.comments.destroy({ postId, id })),
    {
      onSuccess: () => {
        success(t("comments.delete.alerts.success"), {
          duration: "short",
          shouldUseProgress: false,
        });
        closeDeleteConfirmModal();
        onDelete({ id });
      },
      onError: () => {
        error(t("comments.delete.alerts.failure"));
        closeDeleteConfirmModal();
      },
    },
  );

  return {
    isDeleteConfirmModalOpen,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
    deleteComment,
    isDeletingComment,
  };
};
