import classNames from "classnames";
import { PostLoader } from "@circle-react/components/ProfileModalV3/Loader/Post";
import { SidebarLoader } from "@circle-react/components/ProfileModalV3/Loader/Sidebar";
import { TabBarLoader } from "@circle-react/components/ProfileModalV3/Loader/TabBar";

export const Loader = ({ isFullPageView }: { isFullPageView?: boolean }) => (
  <>
    <SidebarLoader isFullPageView={isFullPageView} />
    <div
      className={classNames(
        "col-span-12 flex flex-col overflow-auto px-4 pb-9 md:col-span-8 md:p-8",
        {
          "!md:pl-14 pl-4": isFullPageView,
        },
      )}
    >
      <TabBarLoader />
      <div className="flex flex-col space-y-4">
        <PostLoader />
        <PostLoader />
        <PostLoader />
        <PostLoader />
      </div>
    </div>
  </>
);
