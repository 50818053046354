import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import type { ScheduledEmail } from "@circle-react-shared/ScheduledEmailModal";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ScheduledEmailDropdownActions } from "./ScheduledEmailDropdownActions";

export const ScheduledEmailItem = ({
  scheduledEmail,
}: {
  scheduledEmail: ScheduledEmail;
}) => {
  const { scheduled_at: scheduledAt, metadata, subject } = scheduledEmail;
  const { send_to: sendTo } = metadata;
  const { getValues } = useFormContext();
  const sendReminderEnabled = getValues(
    "event_setting_attributes.send_email_reminder",
  );

  const disabledTextColor = !sendReminderEnabled ? "text-gray-400" : "";

  return (
    <div
      className={classNames(
        "border-primary flex items-center gap-3 border-b py-4",
        disabledTextColor,
      )}
    >
      <div className="bg-tertiary flex h-12 w-12 items-center justify-center rounded-lg">
        <Icon type="16-email" size={16} />
      </div>
      <div className="grow">
        <Typography.LabelMd weight="semibold" color={disabledTextColor}>
          {subject}
        </Typography.LabelMd>
        <div>
          <Typography.LabelSm weight="medium" color={disabledTextColor}>
            {t("events.edit_modal.email.to", {
              send_to: t(`scheduled_emails.form.${sendTo}`),
            })}
            {t("events.edit_modal.email.separator")}
          </Typography.LabelSm>
          <Typography.LabelSm
            weight="medium"
            color={disabledTextColor || "text-light"}
          >
            {t("events.edit_modal.email.scheduled_at", {
              scheduled_at:
                scheduledAt &&
                formattedDateTime({
                  dateTime: new Date(scheduledAt),
                  format: dateFormats.short_date_at_short_time,
                }),
            })}
          </Typography.LabelSm>
        </div>
      </div>
      <ScheduledEmailDropdownActions scheduledEmail={scheduledEmail} />
    </div>
  );
};
