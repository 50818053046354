import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";

export const FormItem = ({
  children,
  name,
  rules = {},
  tooltipText,
  hidePlaceholder = false,
  placeholder,
  inlineReverse = false,
  translationRoot = "live_streams.form",
  appendTranslationRoot,
  hideLabel = false,
  ...props
}) => (
  <Form.Item
    name={name}
    hideDescription
    translationRoot={
      appendTranslationRoot
        ? `${translationRoot}.${appendTranslationRoot}`
        : translationRoot
    }
    rules={rules}
    hideBorder
    fullWidth
    hidePlaceholder={hidePlaceholder}
    placeholder={placeholder}
    tooltipText={tooltipText}
    inlineReverse={inlineReverse}
    hideLabel={hideLabel}
    {...props}
  >
    {children}
  </Form.Item>
);

FormItem.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  tooltipText: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  inlineReverse: PropTypes.bool,
  translationRoot: PropTypes.string,
  appendTranslationRoot: PropTypes.string,
  hideLabel: PropTypes.bool,
};
