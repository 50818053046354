import { flatten } from "lodash";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PARTICIPANTS_DEFAULT_PER_PAGE } from "./useChatParticipantsApi";

export const useUpdateMissingParticipants = ({
  uuid: chatRoomUuid,
  ids,
  enabled,
  shouldUpdateCache = true,
}) => {
  const queryClient = useQueryClient();

  const updateQueryCache = newParticipants => {
    queryClient.setQueryData(
      ["chat-participants", chatRoomUuid],
      (oldData = { pages: [] }) => {
        const oldParticipants = flatten(
          (oldData.pages || []).map(page => page.records),
        );
        const allParticipants = [...oldParticipants, ...newParticipants];
        const pagesCount = Math.ceil(
          allParticipants.length / PARTICIPANTS_DEFAULT_PER_PAGE,
        );
        if (pagesCount > oldData.pages.length) {
          oldData.pages = Array.from({ length: pagesCount }).fill(
            oldData.pages[pagesCount] ||
              oldData.pages[oldData.pages.length - 1],
          );
        }
        return {
          ...oldData,
          pages: oldData.pages.map((page, index) => ({
            ...(page || {}),
            records: allParticipants.slice(
              PARTICIPANTS_DEFAULT_PER_PAGE * index,
              PARTICIPANTS_DEFAULT_PER_PAGE * (index + 1),
            ),
          })),
        };
      },
    );
  };

  const { isLoading, data: participants = [] } = useQuery(
    ["chatroom", "missing-participants", ids],
    () =>
      reactQueryGet(
        internalApi.chatRoomParticipants.index({
          uuid: chatRoomUuid,
          params: { ids },
        }),
      ),
    {
      enabled,
      select: ({ records }) => records,
      onSuccess: missingParticipants => {
        shouldUpdateCache && updateQueryCache(missingParticipants);
      },
      onError: err => {
        console.error("err:", err);
      },
    },
  );

  return { isLoading, participants };
};
