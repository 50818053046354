import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const CreateUserProfileComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateUserProfilePage" */ "@circle-react/components/OnboardingPage/CreateUserProfile/CreateUserProfile"
    ),
);

export const CreateUserProfilePage = () => (
  <Suspense fallback={<PageLoader />}>
    <CreateUserProfileComponent />
  </Suspense>
);
