import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LoadingStateOption = () => (
  <div className="px-5 py-1.5">
    <Typography.LabelSm color="text-light">
      {t("quick_post_v2.topics.loading")}
    </Typography.LabelSm>
  </div>
);
