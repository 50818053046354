import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";

interface Params {
  uuid: string;
}

interface QueryData {
  pages: any[];
  pageParams: number[];
  pagesList: any[];
}

interface Response {
  records: ChatRoomMessage[];
  has_next_page: boolean;
  page: number;
}

interface MessageSearchProps {
  debouncedValue: string;
  isDmsPage: boolean;
  currentSpace: any;
}

export const useMessageSearch = ({
  debouncedValue,
  isDmsPage,
  currentSpace,
}: MessageSearchProps) => {
  const { uuid } = useParams<Params>();
  const chatRoomUuid = isDmsPage ? uuid : currentSpace?.chat_room_uuid;
  const fetchPage = (pageParam = 1) =>
    reactQueryGet(
      internalApi.search.chatRoomMessages.index({
        params: {
          page: pageParam,
          query: debouncedValue,
        },
        chat_room_uuid: chatRoomUuid ?? "",
      }),
    );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    Response,
    unknown,
    QueryData
  >(
    ["chat-room-message-search", debouncedValue, chatRoomUuid],
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      enabled: !!debouncedValue,
      getNextPageParam: (lastPage: any) =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      select: ({ pages = [] }): QueryData => ({
        pages: flatten(pages.map(page => page.records)),
        pageParams: pages.map(page => page.page),
        pagesList: pages,
      }),
    },
  );

  const messages = data?.pages ?? [];
  return { messages, fetchNextPage, hasNextPage, isLoading };
};
