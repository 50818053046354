import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const index = (includeTraded = false) =>
  reactQueryGet(
    internalApi.supportedCurrencies.index({
      ...(includeTraded ? { include_traded: true } : {}),
    }),
  );

export const supportedCurrenciesApi = {
  index,
};
