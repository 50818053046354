import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import "./styles.scss";

export const EventCoverImage = () => (
  <div className="create-event-details__cover-image">
    <label>{t("events.create.form.cover_image")}</label>
    <CoverImageInput
      name="cover_image"
      alt=""
      customAspectRatioHelpText={t("image_upload.recommended_size_event_cover")}
      showAddCoverBtn
      customAddCoverBtn={
        <div className="create-event-details__cover-image-add">
          <div className="create-event-details__cover-image-add__icon-wrapper">
            <Icon type="plus-big" viewBox="0 0 20 20" />
          </div>
          <span className="text-dark font-semibold">
            {t("events.create.form.cover_image_upload")}
          </span>
          <span className="text-light mt-1 text-sm">
            {t("events.create.form.event_cover_image_recommended_size")}
          </span>
        </div>
      }
    />
  </div>
);
