import type { MouseEvent, ReactNode } from "react";
import { useState } from "react";
import Tippy from "@tippyjs/react";
import type { TippyProps } from "@tippyjs/react";
import { first } from "lodash";
import { renderToString } from "react-dom/server";
import Flatpickr from "react-flatpickr";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import type { ButtonVariant } from "@circle-react-uikit/Button";
import "./styles.scss";

export interface DatePickerProps {
  cancelButtonText?: string;
  children?: ReactNode;
  className?: string;
  datePickerVisible?: boolean;
  minDate?: Date;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  onClickOutside?: TippyProps["onClickOutside"];
  onSave: (date: string) => void;
  placement?: TippyProps["placement"];
  saveButtonVariant?: ButtonVariant;
  value: string;
}

export const DatePicker = ({
  cancelButtonText = t("quick_post.scheduled_post_ui.cancel"),
  children,
  className,
  datePickerVisible,
  minDate = new Date(),
  onCancel,
  onClickOutside,
  onSave,
  placement = "top-end",
  saveButtonVariant = "primary",
  value,
}: DatePickerProps) => {
  const [date, setDate] = useState<string>(value);

  return (
    <Tippy
      placement={placement}
      className={className}
      onClickOutside={onClickOutside}
      content={
        <div
          className="bg-primary border-primary rounded-lg border shadow-xl"
          style={{
            maxWidth: "284px",
          }}
        >
          <Flatpickr
            options={{
              enableTime: true,
              dateFormat: "F d, Y G:i K",
              disableMobile: true,
              inline: true,
              altInput: true,
              altInputClass: "hidden",
              minDate,
              animate: false,
              prevArrow: renderToString(
                <Icon type="20-chevron-left" size={20} />,
              ),
              nextArrow: renderToString(
                <Icon type="20-chevron-right" size={20} />,
              ),
              minuteIncrement: 1,
            }}
            value={value}
            onValueUpdate={dates => {
              if (first(dates)) {
                setDate(dates[0].toISOString());
              }
            }}
          />
          <div className="border-primary flex gap-3 border-t px-5 py-3">
            <Button type="button" variant="secondary" onClick={onCancel} full>
              {cancelButtonText}
            </Button>
            <Button
              type="button"
              variant={saveButtonVariant}
              disabled={!date}
              onClick={event => {
                event && event.preventDefault();
                onSave(date);
              }}
              full
            >
              {t("quick_post.scheduled_post_ui.save")}
            </Button>
          </div>
        </div>
      }
      visible={datePickerVisible}
      allowHTML
      interactive
      duration={0}
      arrow={false}
      delay={0}
      theme="modal-footer-actions"
      appendTo="parent"
    >
      <div>{children}</div>
    </Tippy>
  );
};
