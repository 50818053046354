import type { RefObject } from "react";
import classnames from "classnames";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { AuthorHeadline } from "./AuthorHeadline";
import { AuthorProfileWrapper } from "./AuthorProfileWrapper";
import { PostUserInfo } from "./PostUserInfo";
import { PostViewListItemWrapper } from "./PostViewListItemWrapper";

export interface PostMetaInfoSmallProps {
  communityMember: any;
  containerRef: RefObject<HTMLDivElement>;
  doesPostHaveCoverImage?: boolean;
  hasTags?: boolean;
  headline: any;
  hideAuthorHeadline?: boolean;
  isAdmin?: boolean;
  pageLinkRef: RefObject<HTMLAnchorElement>;
  post: any;
  shouldDisplayMemberTags?: boolean;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  visibleMemberTags: any[];
  wrapperClassName?: string;
}

export const PostMetaInfoSmall = ({
  communityMember,
  containerRef,
  doesPostHaveCoverImage = false,
  hasTags = false,
  headline,
  hideAuthorHeadline = false,
  isAdmin = false,
  pageLinkRef,
  post,
  shouldDisplayMemberTags = false,
  tagsLimit,
  tagsShowMoreLabelVariant,
  visibleMemberTags,
  wrapperClassName,
}: PostMetaInfoSmallProps) => (
  <PostViewListItemWrapper
    doesPostHaveCoverImage={doesPostHaveCoverImage}
    ref={containerRef}
    className={wrapperClassName}
    data-testid="post-meta-info-small"
  >
    <PostUserInfo post={post} />
    <button
      type="button"
      className="post__bio focus-visible:outline-secondary flex flex-col justify-between gap-1 self-center text-left focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      onClick={event => {
        if (!(event.target instanceof HTMLAnchorElement)) {
          pageLinkRef.current && pageLinkRef.current.click();
        }
      }}
    >
      <div
        className={classnames(
          "author flex flex-wrap items-center gap-x-2 gap-y-1",
          {
            "no-headline": !communityMember?.headline && post.name,
            "has-tags": hasTags,
          },
        )}
      >
        <div className="author__name inline-flex">
          <AuthorProfileWrapper
            post={post}
            className="!text-dark text-sm font-semibold leading-5"
          >
            {communityMember?.name}
          </AuthorProfileWrapper>
        </div>
        {shouldDisplayMemberTags && (
          <div className="author__tags member-tags !m-0">
            <MemberTags
              memberTags={visibleMemberTags}
              isAdmin={isAdmin}
              limit={tagsLimit}
              showMoreLabelVariant={tagsShowMoreLabelVariant}
            />
          </div>
        )}
      </div>
      {!hideAuthorHeadline && (
        <AuthorHeadline headline={headline} post={post} ref={pageLinkRef} />
      )}
    </button>
  </PostViewListItemWrapper>
);
