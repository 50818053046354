import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { RecurringRsvpModal } from "../RecurringRsvpModal";
import { useRecurringRsvpModal } from "../RecurringRsvpModal/hooks/useRecurringRsvpModal";

export const ListRecurringEventButton = ({ event, onChange, buttonText }) => {
  const { isRecurringRsvpOpen, openRecurringRsvpModal, onRecurringModalCose } =
    useRecurringRsvpModal({ event });

  return (
    <>
      <button type="submit" onClick={openRecurringRsvpModal}>
        <Typography.LabelSm weight="medium" color="text-link">
          {buttonText}
        </Typography.LabelSm>
      </button>
      <RecurringRsvpModal
        event={event}
        show={isRecurringRsvpOpen}
        onClose={onRecurringModalCose}
        onChange={onChange}
        eventRsvpedByDefault={false}
      />
    </>
  );
};
