import { createContext, useContext, useState } from "react";
import { noop } from "lodash";

interface EventsContextProp {
  isUpdatingAttendees: boolean;
  setIsUpdatingAttendees: (param: boolean) => void;
  isEmailNotificationsEnabled: boolean;
  setIsEmailNotificationsEnabled: (param: boolean) => void;
}

const EventsContext = createContext<EventsContextProp>({
  isUpdatingAttendees: false,
  setIsUpdatingAttendees: noop,
  isEmailNotificationsEnabled: false,
  setIsEmailNotificationsEnabled: noop,
});

EventsContext.displayName = "EventsContext";

export const useEventsContext = () => useContext(EventsContext);

export const EditEventContext = ({ children }: any) => {
  const [isUpdatingAttendees, setIsUpdatingAttendees] = useState(false);
  const [isEmailNotificationsEnabled, setIsEmailNotificationsEnabled] =
    useState(true);

  return (
    <EventsContext.Provider
      value={{
        isUpdatingAttendees,
        setIsUpdatingAttendees,
        isEmailNotificationsEnabled,
        setIsEmailNotificationsEnabled,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
