import { useState } from "react";
import classnames from "classnames";
import type { GalleryImage } from "@/react/types/GalleryImage";
import type { UsedInOptions } from "@circle-react/contexts";
import { ImageOptions } from "./ImageOptions";
import type { ZoomLevels } from "./hooks/useZoom";

interface ImageProps {
  image: GalleryImage;
  currentImageIndex: number;
  index: number;
  positionClassName?: string;
  containerWidth?: number;
  usedIn: UsedInOptions;
  shouldAnimate: boolean;
  zoomLevel: ZoomLevels;
  zoomPosition: [number, number];
}
export const Image = ({
  image,
  positionClassName,
  containerWidth,
  currentImageIndex,
  index,
  usedIn,
  shouldAnimate,
  zoomLevel,
  zoomPosition,
}: ImageProps) => {
  const [src, setSrc] = useState<string | undefined>();
  const [loading, setLoading] = useState<"eager" | "lazy">("lazy");

  // load one image ahead of current one, this way users don't download the whole gallery at once
  if (!src && currentImageIndex + 1 >= index) {
    setSrc(image.url);
    if (index > 1) setLoading("eager");
  }

  // when editing an image, the src in the state is the old one, so we need to update it
  if (src && src !== image.url) {
    setSrc(image.url);
  }

  return (
    <div
      className={classnames(
        "relative flex h-full w-full shrink-0 grow-0 basis-full items-center justify-center",
        {
          "transition-transform duration-300": shouldAnimate,
          "z-[1]": currentImageIndex === index,
        },
      )}
      style={{
        transform: containerWidth
          ? `translateX(-${containerWidth * currentImageIndex}px)`
          : undefined,
      }}
    >
      {usedIn === "full-post-modal" && currentImageIndex === index && (
        <ImageOptions image={image} />
      )}
      {usedIn !== "mobile-full-carousel-modal" && (
        <div
          className="carousel-image absolute inset-0 scale-110 bg-cover bg-center blur-lg"
          style={{ backgroundImage: src && `url(${src})` }}
        />
      )}
      <img
        className={classnames(
          "carousel-image relative select-none",
          {
            "transition-transform": zoomLevel,
          },
          positionClassName,
        )}
        draggable={false}
        loading={loading}
        alt=""
        src={src}
        style={{
          transform:
            zoomLevel && zoomLevel > 1
              ? `translate(${zoomPosition[0]}px, ${zoomPosition[1]}px) scale(${zoomLevel})`
              : undefined,
        }}
      />
    </div>
  );
};
