import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";
import { isElementInViewPort } from "./helper";

const UnreadChatThreadsContext = createContext(initialState);
UnreadChatThreadsContext.displayName = "UnreadChatThreadsContext";

const initialState = {
  chatThreadIds: [],
};

export const UnreadChatThreadsProvider = ({ children }) => {
  const { currentCommunityMember } = usePunditUserContext();
  const [chatThreadIds, setChatThreadIds] = useState([]);
  const [isUnreadThreadFlow, setUnreadThreadFlow] = useState(false);
  const { error } = useToast();
  const { pathname } = useLocation();
  const isThreadsPage = pathname === "/threads";

  useWebSocket(
    {
      channel: "Chats::CommunityMemberThreadsChannel",
      onMessageReceive: data => {
        onEventReceive(data);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: Boolean(currentCommunityMember?.id),
    },
    [currentCommunityMember?.id],
  );

  const { isLoading, refetch: fetchUnreadChatThreads } = useQuery(
    ["thread_messages", "unread", currentCommunityMember?.id],
    () => reactQueryGet(internalApi.chatThreads.unreadChatThreads()),
    {
      enabled: !!currentCommunityMember?.policies?.can_access_threads_tab,
      onSuccess: data => {
        setChatThreadIds(data.chat_thread_ids);
      },
      onError: err => {
        error(err.message);
      },
    },
  );

  const onEventReceive = eventData => {
    if (eventData.event === "chatThreadRead") {
      onChatThreadRead(eventData.chat_thread_id);
    } else if (eventData.event === "newMessage") {
      onNewMessage(eventData.json_message);
    }
  };

  const onChatThreadRead = readThreadId => {
    if (chatThreadIds.includes(readThreadId)) {
      const updatedChatThreadIds = chatThreadIds.filter(
        threadId => threadId !== Number(readThreadId),
      );
      setChatThreadIds(updatedChatThreadIds);
    }
  };

  const onNewMessage = message => {
    if (message?.unread_flow) {
      setUnreadThreadFlow(true);
    }
    const isSenderCurrentCommunityMember =
      message.sender?.community_member_id === currentCommunityMember?.id;
    const isNotThreadMessage = !message.chat_thread_id;
    const alreadyUnreadThread = chatThreadIds.includes(message.chat_thread_id);

    if (
      isNotThreadMessage ||
      isSenderCurrentCommunityMember ||
      alreadyUnreadThread
    )
      return;

    if (isThreadsPage) {
      const threadId = message.chat_thread_id;
      const threadElement = document.getElementById(threadId);
      if (isElementInViewPort(threadElement)) return;
    }
    setChatThreadIds(prevValues => [...prevValues, message.chat_thread_id]);
  };

  const value = {
    fetchUnreadChatThreads,
    unreadChatThreadIds: chatThreadIds,
    unreadThreadsCount: chatThreadIds.length,
    isLoading,
    onEventReceive,
    isUnreadThreadFlow,
  };

  return (
    <UnreadChatThreadsContext.Provider value={value}>
      {children}
    </UnreadChatThreadsContext.Provider>
  );
};

export const useUnreadChatThreads = () => useContext(UnreadChatThreadsContext);

UnreadChatThreadsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
