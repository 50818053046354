import { t } from "@/i18n-js/instance";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const SendMessageConfirmationModal = ({
  shouldShowConfirmationModal,
  toggleConfirmationModal,
  handleSubmit,
  chatParticipantsCount,
}: any) => (
  <ConfirmationModal
    isOpen={shouldShowConfirmationModal}
    onClose={toggleConfirmationModal}
    onConfirm={() => {
      handleSubmit(true);
      toggleConfirmationModal();
    }}
    confirmText={t("send")}
    title={t("messaging.send_group_message.title", {
      participants_count: chatParticipantsCount,
    })}
  >
    <div className="mb-6">{t("messaging.send_group_message.description")}</div>
  </ConfirmationModal>
);
