import { removeProtocolFromUrlString } from "@circle-react/helpers/profileHelpers";
import type { IconType } from "@circle-react-shared/Icon";
import { ProfileInfoContainer } from "./ProfileInfoContainer";

export interface ProfileLinkContainerProps {
  link?: string;
  iconType?: IconType;
}

export const ProfileLinkContainer = ({
  link,
  iconType,
}: ProfileLinkContainerProps) => {
  if (!link) return null;

  return (
    <ProfileInfoContainer
      iconType={iconType}
      info={
        <a href={link} target="_blank" rel="noopener noreferrer">
          {removeProtocolFromUrlString(link)}
        </a>
      }
    />
  );
};
