import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import { LoaderRow } from "@/react/components/shared/LoaderRow";
import { Typography } from "@circle-react-uikit/Typography";
import { MessageResultItem } from "./MessageResultItem";

interface MessageSearchResultsProps {
  messages: any[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

export const MessageSearchResults = ({
  messages,
  fetchNextPage,
  hasNextPage,
}: MessageSearchResultsProps) => (
  <div className="p-6">
    <div className="mb-2">
      <Typography.LabelXs color="text-dark" weight="medium">
        {messages.length} {t("chat_space.search_messages.results")}
      </Typography.LabelXs>
    </div>
    <InfiniteScroll
      style={{ overflow: "initial", height: "100%" }}
      scrollThreshold={0.8}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<LoaderRow />}
      scrollableTarget="scrollableMessageSearch"
      dataLength={messages?.length || 0}
      className="pb-6"
    >
      <div className="space-y-2">
        {messages.map(message => (
          <MessageResultItem key={message.id} message={message} />
        ))}
      </div>
    </InfiniteScroll>
  </div>
);
