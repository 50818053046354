import { usePunditUserContext } from "@circle-react/contexts";

export const BlockInViewOnlyMode = ({ children }) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();

  if (isViewOnlyMasquerading) {
    return (
      <div className="!cursor-not-allowed">
        <div className="!pointer-events-none">{children}</div>
      </div>
    );
  }
  return children;
};
