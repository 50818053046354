import { useLayoutEffect, useRef } from "react";

export const useScrollIntoView = <T extends HTMLElement>(
  shouldScrollIntoView?: boolean,
) => {
  const elementRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    const element = elementRef.current;

    if (shouldScrollIntoView && element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [shouldScrollIntoView]);

  return elementRef;
};
