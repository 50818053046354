import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-uikit/Button";
import {
  chatRoomPath,
  getRouteWithPrevLocation,
} from "../../helpers/urlHelpers";
import { useChatRoom } from "../../hooks/chatsV2";
import { useSmOrMdScreenMediaQuery } from "../../hooks/useMediaQuery";
import { DIRECT_CHAT_ROOM } from "../constants";
import { Icon } from "../shared/Icon";

interface ChatIconProps {
  communityMemberId: number;
  showIcon?: boolean;
  onClick?: (chatRoomUuid: string) => void;
  shouldAutoNavigate?: boolean;
  variant?: "button" | "icon";
}

export const ChatIcon = ({
  communityMemberId,
  shouldAutoNavigate = true,
  showIcon = true,
  onClick = noop,
  variant = "button",
}: ChatIconProps) => {
  const history = useHistory();
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { findOrCreateChatRoom } = useChatRoom();
  const isMobile = useSmOrMdScreenMediaQuery();

  const handleOnClick = async () => {
    const chatRoomUuid = await findOrCreateChatRoom({
      kind: DIRECT_CHAT_ROOM,
      community_member_ids: [communityMemberId],
    });
    onClick?.(chatRoomUuid);
    if (shouldAutoNavigate) {
      history.push(
        getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
      );
    }
  };

  return (
    <TippyV2
      placement="bottom"
      content={t("post.share.message")}
      interactive={false}
      disabled={variant !== "icon"}
    >
      <Button
        variant="secondary"
        onClick={isViewOnlyMasquerading ? noop : handleOnClick}
        aria-label={t("post.share.message")}
        data-testid="chat-button"
      >
        {showIcon && (
          <Icon
            type="16-message"
            size={16}
            useWithFillCurrentColor
            aria-hidden
          />
        )}
        {!isMobile && variant !== "icon" && (
          <span className="ml-1">{t("message")}</span>
        )}
      </Button>
    </TippyV2>
  );
};
