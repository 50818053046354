import type { ReactionType } from "./Reaction";
import { Reaction } from "./Reaction";
import { PopoverWrapper } from "./popover/PopoverWrapper";

export interface ReactionsProps {
  reactions: ReactionType[];
  currentCommunityMemberId: number;
  reactionableId: number;
  reactionableType: string;
}

export const Reactions = ({
  reactions = [],
  currentCommunityMemberId,
  reactionableId,
  reactionableType,
}: ReactionsProps) => {
  if (reactions.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-1">
      {reactions.map(reaction => (
        <Reaction
          key={reaction.emoji}
          reaction={reaction}
          currentCommunityMemberId={currentCommunityMemberId}
          reactionableId={reactionableId}
          reactionableType={reactionableType}
        />
      ))}
      {Boolean(currentCommunityMemberId) && (
        <PopoverWrapper
          reactions={reactions}
          currentCommunityMemberId={currentCommunityMemberId}
          reactionableId={reactionableId}
          reactionableType={reactionableType}
          showBorder
        />
      )}
    </div>
  );
};
