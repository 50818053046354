import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

export const useInfiniteQueryWrapper = (path, queryOptions = {}, key) => {
  const queryKey = Array.isArray(key) ? key : [key || path];

  const fetchPage = page => reactQueryGet(appendParamsToPath(path, { page }));

  const infiniteQuery = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      ...queryOptions,
    },
  );

  const mapPages = callback => {
    const { isLoading, data } = infiniteQuery;
    if (isLoading || !data) return [];
    return data.pages.map(callback);
  };

  return {
    ...infiniteQuery,
    mapPages,
  };
};

const appendParamsToPath = (path, params) => {
  // Need to add origin to the relative path to be a fully
  // qualified argument the URL class
  const url = new URL(window.location.origin + path);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }
  return url.pathname + url.search;
};
