interface MessageIndicatorButtonProps {
  label: string;
  onClick: () => void;
}

export const MessageIndicatorButton = ({
  label,
  onClick,
}: MessageIndicatorButtonProps) => (
  <button
    type="submit"
    className="bg-circle rounded-2xl px-2.5 py-1 text-xs font-semibold leading-5 text-white hover:opacity-90"
    onClick={onClick}
  >
    {label}
  </button>
);
