import { Icon } from "@circle-react-shared/Icon";
import { LabelSm } from "@circle-react-shared/uikit/Typography/components/Label/LabelSm";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import "./styles.css";

export interface SearchResultProps {
  id: string;
  type: string;
  name: string;
  avatarUrl: string;
  onClick: (id: string, type: string) => void;
}

export const SearchResult = ({
  id,
  type,
  name,
  avatarUrl,
  onClick,
}: SearchResultProps) => {
  let icon;

  if (type == "CommunityMember") {
    icon = <UserImage src={avatarUrl} name={name} size="5" />;
  } else if (type == "Paywall") {
    icon = (
      <Icon type="credit-card" className="text-default shrink-0" size={16} />
    );
  } else if (type == "InvitationLink") {
    icon = <Icon type="16-link" className="text-default shrink-0" size={16} />;
  }

  return (
    <li>
      <button
        tabIndex={0}
        type="button"
        className="text-dark bg-primary hover:bg-secondary focus:bg-secondary flex w-full cursor-pointer items-center justify-between gap-4 px-5 py-2.5 transition-colors"
        onClick={() => onClick(id, type)}
      >
        <div className="truncate-one-line flex items-center gap-3">
          {icon}
          <LabelSm>{name}</LabelSm>
        </div>
      </button>
    </li>
  );
};
