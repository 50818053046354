import { useState } from "react";
import { useQueryClient } from "react-query";
import { useRecurringEventRsvpApi } from "@circle-react/hooks/events/useRecurringEventRsvpApi";
import { RECURRING_EDIT_MODE } from "../../../Events/helpers/constants";

export interface UseRecurringUnRsvpProps {
  event: any;
  onChange: (event: any) => void;
}

export const useRecurringUnRsvp = ({
  event,
  onChange,
}: UseRecurringUnRsvpProps) => {
  const [editMode, setEditMode] = useState(RECURRING_EDIT_MODE.ALL);
  const queryClient = useQueryClient();

  const { onRecurringUnRsvpSubmit } = useRecurringEventRsvpApi({
    event,
  });

  const onUnRsvpConfirm = async () => {
    await onRecurringUnRsvpSubmit(editMode);
    onChange({ ...event, rsvped_event: false });
    await queryClient.invalidateQueries("posts");
  };

  return {
    setEditMode,
    onUnRsvpConfirm,
  };
};
