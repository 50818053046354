import { useModerationInfoModal } from "..";
import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { WarningNotice } from "@circle-react-uikit/Notice/WarningNotice";
import { Typography } from "@circle-react-uikit/Typography";
import type { ModerationInfoBaseProps } from "./shared";
import { getContentTranslation, shouldRenderContent } from "./shared";

export const Regular = ({ record, contentType }: ModerationInfoBaseProps) => {
  const moderationModal = useModerationInfoModal();

  if (!shouldRenderContent(record)) return null;

  const contentTranslation = getContentTranslation(contentType);

  return (
    <WarningNotice
      className="!py-2"
      iconEl={<Icon className="text-v2-warning" type="20-info" size={20} />}
    >
      <div className="flex w-full items-center justify-between">
        <Typography.LabelSm>
          {t("flagged_contents.moderation_info.flagged_report_content", {
            content: contentTranslation,
          })}
          <SanitizeHTMLString
            content={t(
              "flagged_contents.moderation_info.flagged_report_count",
              {
                count: record.unresolved_flagged_reports_count,
              },
            )}
            as="span"
          />
        </Typography.LabelSm>
        <button
          type="button"
          onClick={() =>
            moderationModal.show({
              flaggedContentId: record.id,
              flaggedContentType: contentType,
            })
          }
          className="hover:bg-v2-warning-100 dark:hover:bg-primary rounded-full border border-black bg-transparent px-4 py-1 transition dark:border-white"
        >
          <Typography.LabelSm weight="semibold">
            {t("flagged_contents.moderation_info.review_button")}
          </Typography.LabelSm>
        </button>
      </div>
    </WarningNotice>
  );
};
