import type { RefObject } from "react";
import { useEffect, useState } from "react";
import type { UsedInOptions } from "@circle-react/contexts";

// 678 is also the full width of a post in the feed / home view.
// Setting the max height to the same value permits two things:
// 1. In no device the image will be taller than the viewport
// 2. Square images will be displayed without empty space at the sides
const MAX_HEIGHT = 678;
const MAX_RATIO = 16 / 9;

export const useContainerCalculatedHeight = (
  galleryAspectRatio: number | undefined,
  containerRef: RefObject<HTMLDivElement>,
  usedIn: UsedInOptions,
) => {
  const [containerCalculatedHeight, setContainerCalculatedHeight] = useState<
    number | string | undefined
  >();
  const [containerWidth, setContainerWidth] = useState<number | undefined>();
  const [containerRatio, setContainerRatio] = useState<number | undefined>();

  useEffect(() => {
    if (galleryAspectRatio && containerRef.current) {
      const {
        clientWidth: containerRefWidth,
        clientHeight: containerRefHeight,
      } = containerRef.current;
      const containerRatio =
        galleryAspectRatio > MAX_RATIO ? MAX_RATIO : galleryAspectRatio;
      const desiredHeight = containerRefWidth / containerRatio;

      if (usedIn === "full-post-modal") {
        setContainerCalculatedHeight("100%");
        setContainerRatio(containerRefWidth / containerRefHeight);
      } else if (desiredHeight > MAX_HEIGHT) {
        setContainerCalculatedHeight(MAX_HEIGHT);
        setContainerRatio(containerRefWidth / MAX_HEIGHT);
      } else {
        setContainerCalculatedHeight(desiredHeight);
        setContainerRatio(containerRatio);
      }
      setContainerWidth(containerRefWidth);
    }
  }, [
    containerRef,
    galleryAspectRatio,
    usedIn,
    containerRef.current?.clientWidth,
  ]);

  return { containerCalculatedHeight, containerWidth, containerRatio };
};
