import { Suspense } from "react";
import type { SignUpProps } from "@circle-react/components/OnboardingPage/SignUp";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const DiscoverSignUpComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DiscoverSignUpPage" */ "@circle-react/components/Discover/SignUpPage"
    ),
);

export const DiscoverSignUpPage = (props: SignUpProps) => (
  <Suspense fallback={<PageLoader />}>
    <DiscoverSignUpComponent {...props} />
  </Suspense>
);
