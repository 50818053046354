import { noop } from "lodash";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import {
  reactQueryDelete,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { Dropdown } from "@circle-react/components/shared/uikit/Dropdown";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const FollowAction = ({ post, handleClose = noop }) => {
  const { success, error } = useToast();
  const isFollowing = !!post.post_follower_id;
  const { onPostFollow } = useCurrentPostHelpers();

  const handleUnfollow = () =>
    reactQueryDelete(
      internalApi.postFollowers.destroy({
        postId: post.id,
        postFollowerId: post.post_follower_id,
      }),
    );

  const handleFollow = () =>
    reactQueryPost(internalApi.postFollowers.create({ postId: post.id }));

  const handleMutation = useMutation(
    isFollowing ? handleUnfollow : handleFollow,
    {
      onSuccess: data => {
        onPostFollow({
          post_follower_id: isFollowing ? null : data?.post_follower?.id,
        });
        handleClose();
        success(
          I18n.t(isFollowing ? "post_unfollow.success" : "post_follow.success"),
        );
      },
      onError: () =>
        error(
          I18n.t(isFollowing ? "post_unfollow.error" : "post_follow.error"),
        ),
    },
  );

  const handleClick = () => {
    handleMutation.mutate();
  };

  return (
    <Dropdown.ItemLink onClick={handleClick}>
      <ToggleSwitchWithLabel
        label={t("follow_post")}
        field="follow_post"
        value={isFollowing}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
