import { useRecordingHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useStreamHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useLiveStreamsConfig } from "./useLiveStreamsConfig";
import { useLiveStreamsUsage } from "./useLiveStreamsUsage";

const SECONDS_IN_HOUR = 3600;

export const useLiveStreamHoursAvailable = (
  { enabled } = { enabled: true },
) => {
  const { config, isLoading: isLoadingConfig } = useLiveStreamsConfig({
    enabled,
  });
  const { usage, isLoading: isLoadingUsage } = useLiveStreamsUsage({ enabled });
  const { currentQuantity: recordingHoursAddonQuantity } =
    useRecordingHoursAddon(null, { enabled });
  const { currentQuantity: streamHoursAddonQuantity } = useStreamHoursAddon(
    null,
    { enabled },
  );

  const isLoading = isLoadingConfig || isLoadingUsage;

  const streamingUsedSeconds = usage?.stream_duration || 0;
  const streamingPlanValue = config?.live_stream_monthly_stream_hours || 0;
  const streamingPlanSeconds = streamingPlanValue * SECONDS_IN_HOUR || 0;
  const streamingAddonsSeconds =
    streamHoursAddonQuantity * SECONDS_IN_HOUR || 0;

  const recordingUsedSeconds = usage?.recordings_duration || 0;
  const recordingPlanValue = config?.live_stream_recording_hours || 0;
  const recordingPlanSeconds = recordingPlanValue * SECONDS_IN_HOUR || 0;
  const recordingAddonsSeconds =
    recordingHoursAddonQuantity * SECONDS_IN_HOUR || 0;

  const streamingTotalPlanHours = streamingPlanValue + streamHoursAddonQuantity;
  const recordingTotalPlanHours =
    recordingPlanValue + recordingHoursAddonQuantity;

  return {
    isLoading,
    streamingHoursAvailable:
      streamingUsedSeconds < streamingPlanSeconds + streamingAddonsSeconds,
    recordingHoursAvailable:
      recordingUsedSeconds < recordingPlanSeconds + recordingAddonsSeconds,
    streamingTotalPlanHours,
    recordingTotalPlanHours,
  };
};
