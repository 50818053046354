import { t } from "@/i18n-js/instance";
import { useLiveStreamIssue } from "@/react/components/Events/Rsvp/hooks/useLiveStreamIssue";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import { useLiveStreamsUsageLimits } from "@circle-react/components/LiveStreams/hooks";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { Button } from "@circle-react-uikit/Button";
import {
  isDraft,
  isStreamBackstage,
  isStreamEnded,
  isStreamLive,
} from "../../EditForm/helpers";
import { PlanLimitExceededLiveLocation } from "../PlanLimitExceededLiveLocation";
import { useOnCreateLiveStream } from "./../useOnCreateLiveStream";

interface LiveLocationProps {
  event: Event;
}

export const LiveLocation = ({ event }: LiveLocationProps) => {
  const { currentUser } = usePunditUserContext();
  const { isMonthlyHourLimitReached, isStorageLimitReached } =
    useLiveStreamsUsageLimits();
  const isLoggedIn = !!currentUser;
  const { event_setting_attributes: eventData } = event || {};
  const {
    location_type: locationType,
    live_stream_slug: liveStreamSlug,
    live_stream_room_setting_attributes: liveStreamRoomSetting,
  } = eventData || {};
  const { issueCreatingLive, canCreateLive } = useLiveStreamIssue(event) || {};
  const onCreateLiveStreamMutation = useOnCreateLiveStream(event);
  const shouldShowCreateButton =
    canCreateLive && (issueCreatingLive || isStreamEnded(event));
  const shouldShowStreamLink =
    liveStreamSlug && (isStreamBackstage(event) || isStreamLive(event));
  const isLiveLimitReached =
    isMonthlyHourLimitReached ||
    (liveStreamRoomSetting?.recording_enabled && isStorageLimitReached);

  const handleViewLiveStreamClick = () => {
    let url = liveStreamPaths.room({ roomSlug: liveStreamSlug });

    if (isPublicStream(event) && !isLoggedIn)
      url = liveStreamPaths.publicRoom({ roomSlug: liveStreamSlug });

    window.open(url, "_blank");
  };

  if (isLiveLimitReached) {
    return <PlanLimitExceededLiveLocation event={event} />;
  }

  return (
    <>
      {shouldShowCreateButton && (
        <div className="my-2">
          <Button
            variant="primary"
            onClick={onCreateLiveStreamMutation.mutate}
            disabled={
              isDraft(event) ||
              onCreateLiveStreamMutation.isLoading ||
              onCreateLiveStreamMutation.isSuccess
            }
          >
            {t(`events.locations.create_${locationType}`)}
          </Button>
        </div>
      )}
      {shouldShowStreamLink ? (
        <div className="mt-2">
          <Button variant="primary" onClick={handleViewLiveStreamClick}>
            {t(`events.locations.${locationType}_link`)}
          </Button>
        </div>
      ) : (
        <Typography.BodySm>
          {t(`events.locations.${locationType}_not_available`)}
        </Typography.BodySm>
      )}
    </>
  );
};
