import { useCallback, useMemo, useState } from "react";
import type { MouseEvent, TouchEvent } from "react";
import { throttle } from "lodash";

export type ZoomLevels = 1 | 2 | 3 | undefined;

const eventCoords = (event: MouseEvent | TouchEvent) => {
  let clientX: number | undefined, clientY: number | undefined;

  if ("clientX" in event && "clientY" in event) {
    clientX = event.clientX;
    clientY = event.clientY;
  } else if ("touches" in event && event.touches.length > 0) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  }

  return [clientX, clientY];
};

const calculateTranslation = (
  event: MouseEvent | TouchEvent,
  zoomLevel: ZoomLevels,
  zoomPosition: [number, number],
): [number, number] => {
  if (zoomLevel === undefined || zoomLevel === 1) return zoomPosition;
  if (!(event.target instanceof HTMLImageElement)) return zoomPosition;
  const img = event.target;
  const container = img.parentNode;
  if (!(container instanceof HTMLDivElement)) return zoomPosition;
  const containerRect = container.getBoundingClientRect();
  const imgRect = img.getBoundingClientRect();
  const containerCenterX = containerRect.left + containerRect.width / 2;
  const containerCenterY = containerRect.top + containerRect.height / 2;

  const [clientX, clientY] = eventCoords(event);

  if (clientX === undefined || clientY === undefined) return zoomPosition;

  const distanceX = containerCenterX - clientX;
  const distanceY = containerCenterY - clientY;

  let translateX = distanceX * (zoomLevel - 1);
  let translateY = distanceY * (zoomLevel - 1);

  const deltaX = translateX - zoomPosition[0];
  const deltaY = translateY - zoomPosition[1];

  // ignore event if would cause image to move out of container
  if (
    imgRect.left + deltaX > containerRect.left ||
    imgRect.right + deltaX < containerRect.right
  ) {
    translateX = zoomPosition[0];
  }
  if (
    imgRect.top + deltaY > containerRect.top ||
    imgRect.bottom + deltaY < containerRect.bottom
  ) {
    translateY = zoomPosition[1];
  }

  return [translateX, translateY];
};

export const useZoom = (isZoomEnabled: boolean) => {
  const [zoomLevel, setZoomLevel] = useState<ZoomLevels>(
    isZoomEnabled ? 1 : undefined,
  );
  const [zoomPosition, setZoomPosition] = useState<[number, number]>([0, 0]);

  const onMouseMove = useMemo(
    () =>
      throttle((event: MouseEvent) => {
        setZoomPosition(prevState =>
          calculateTranslation(event, zoomLevel, prevState),
        );
      }, 50),
    [zoomLevel, setZoomPosition],
  );

  const throttledOnMouseMove = useCallback(
    event => {
      event.persist();
      onMouseMove(event);
    },
    [onMouseMove],
  );

  const cycleZoom = () => {
    setZoomLevel(prevState => (prevState === 1 ? 2 : prevState === 2 ? 3 : 1));
  };

  const resetZoom = () => {
    setZoomPosition([0, 0]);
    setZoomLevel(1);
  };

  return {
    zoomLevel,
    resetZoom,
    cycleZoom,
    zoomPosition,
    setZoomPosition,
    onMouseMove: throttledOnMouseMove,
  };
};
