import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { toLocaleString } from "@circle-react/helpers/number";
import { Loader } from "@circle-react-uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberRow } from "./MemberRow";
import { useLikesList } from "./useLikesList";

interface LikesModalProps {
  id: number;
  placeholderLikesCount: number;
}

const LikesModalComponent = ({
  id,
  placeholderLikesCount,
}: LikesModalProps) => {
  const modal = useModal();

  const {
    likedByMembers,
    totalLikesCount,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useLikesList({
    id,
    isQueryEnabled: modal.visible,
    placeholderLikesCount: placeholderLikesCount,
  });

  return (
    <Modal
      title={t("comments.likes", { count: totalLikesCount })}
      isOpen={modal.visible}
      onClose={modal.hide}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.LabelLg weight="semibold">
              {t("comments.likes", {
                count: totalLikesCount,
                formatted_count: toLocaleString(totalLikesCount),
              })}
            </Typography.LabelLg>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pb-6 pt-4">
          {isLoading ? (
            <LoaderRow />
          ) : (
            <div
              id={`comment-likes-modal-${id}`}
              // height would be 100vh - modal header height - padding of the modal body
              className="max-h-[calc(100dvh-62px-44px-24px)] overflow-auto sm:max-h-80"
            >
              <InfiniteScroll
                scrollThreshold={0.75}
                next={fetchNextPage}
                hasMore={hasNextPage || false}
                loader={<Loader />}
                scrollableTarget={`comment-likes-modal-${id}`}
                dataLength={likedByMembers.length}
                className="flex flex-col space-y-4"
              >
                {likedByMembers.map(likedByMember => (
                  <MemberRow
                    key={likedByMember.id}
                    communityMember={likedByMember}
                    hideModal={modal.hide}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
LikesModalComponent.displayName = "LikesModalComponent";

const LikesModal = ModalManager.create(
  ({ id, placeholderLikesCount }: LikesModalProps) => (
    <LikesModalComponent
      id={id}
      placeholderLikesCount={placeholderLikesCount}
    />
  ),
);
LikesModal.displayName = "LikesModal";

export const useLikesModal = () => useModal(LikesModal);
