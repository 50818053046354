import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useThemeContext } from "@circle-react/providers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

interface SummarizeButtonProps {
  onClick: () => void;
}

export const SummarizeButton = ({ onClick }: SummarizeButtonProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const { isDarkAppearance } = useThemeContext();

  if (!isCommunityAdmin(currentCommunityMember)) {
    return null;
  }

  return (
    <TippyV2 content={t("post.summarize")} interactive={false}>
      <IconButton
        name={isDarkAppearance ? "20-stardust" : "20-stardust-gradient"}
        iconSize={20}
        className="group"
        iconClassName="group-hover:animate-spin-pause-reverse"
        onClick={onClick}
        buttonSize={24}
        ariaLabel={t("post.summarize")}
      />
    </TippyV2>
  );
};
