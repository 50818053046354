import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { CommunityAiUpgradeModalComponent } from "./CommunityAiUpgradeModalComponent";
import { ACTIVITY_SCORES_FEATURE } from "./constants";
import type { FeatureType } from "./constants";

export interface CommunityAiUpgradeModalProps {
  afterClose?: () => void | Promise<void>;
  feature?: FeatureType;
  source?: string;
  onSuccess?: () => void | Promise<void>;
}

const CommunityAiUpgradeModal = ModalManager.create(
  ({
    afterClose,
    feature = ACTIVITY_SCORES_FEATURE,
    source,
    onSuccess,
  }: CommunityAiUpgradeModalProps) => (
    <CommunityAiUpgradeModalComponent
      afterClose={afterClose}
      feature={feature}
      source={source}
      onSuccess={onSuccess}
    />
  ),
);

export const useCommunityAiUpgradeModal = () =>
  useModal(CommunityAiUpgradeModal);
