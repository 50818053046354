import { EventsV3 } from "@circle-react/components/EventsV3";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { AddonsProvider } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";

interface EditEventModalProps {
  event: any;
  defaultSection: string;
  locationType: string;
}

const EditEventModalComponent = ({
  event,
  defaultSection,
  locationType,
}: EditEventModalProps) => {
  const { visible: isVisible, hide } = useModal();

  return (
    <AddonsProvider>
      <EventsV3
        isOpen={isVisible}
        onClose={hide}
        event={event}
        locationType={locationType}
        initialSection={defaultSection}
      />
    </AddonsProvider>
  );
};

export const EditEventModal = ModalManager.create(
  ({ event, defaultSection, locationType }: EditEventModalProps) => (
    <EditEventModalComponent
      event={event}
      defaultSection={defaultSection}
      locationType={locationType}
    />
  ),
);

export const useEditEventModal = () => useModal(EditEventModal);
