import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ActivityScoresFeature } from "./ActivityScoresFeature";
import { CopilotFeature } from "./CopilotFeature";
import { MarketingItem } from "./MarketingItem";
import type { FeatureType } from "./constants";
import { ACTIVITY_SCORES_FEATURE, COPILOT_FEATURE } from "./constants";

interface ModalBodyProps {
  feature: FeatureType;
}

export const ModalBody = ({ feature }: ModalBodyProps) => (
  <Modal.Body>
    <div className="flex h-full w-full pt-2">
      <div className="max-w-xl">
        {feature === COPILOT_FEATURE && <CopilotFeature />}
        {feature === ACTIVITY_SCORES_FEATURE && <ActivityScoresFeature />}

        <div className="bg-tertiary mt-6 rounded p-4 pt-2">
          <div className="mb-2">
            <Typography.LabelXxs color="text-default">
              {t("settings.community_ai.marketing_modal.plus")}
            </Typography.LabelXxs>
          </div>
          {feature === COPILOT_FEATURE && (
            <MarketingItem localeItem="activity_scores" />
          )}
          {feature === ACTIVITY_SCORES_FEATURE && (
            <MarketingItem localeItem="copilot" />
          )}
          <MarketingItem localeItem="transcriptions" />
        </div>
      </div>
    </div>
  </Modal.Body>
);
