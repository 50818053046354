import { t } from "@/i18n-js/instance";
import { ActionButton } from "@/react/components/LiveStreams/Room/Sidebar/QA/common/ActionButton";
import { useChatMessageSpotlightActions } from "@/react/components/LiveStreams/Room/hooks/useChatMessageSpotlightActions";
import type { ChatRoom } from "@/react/types/Chat/ChatRoom";
import type { ChatRoomMessage } from "@/react/types/Chat/ChatRoomMessage";

interface StopSpotlightButtonProps {
  message: ChatRoomMessage;
  chatRoom: ChatRoom;
  isCurrentSpotlightedItem: boolean;
}

export const StopSpotlightButton = ({
  message,
  chatRoom,
  isCurrentSpotlightedItem,
}: StopSpotlightButtonProps) => {
  const { removeSpotlightMessage, isRemovingSpotlightMessage } =
    useChatMessageSpotlightActions({
      chatUuid: chatRoom.uuid,
    });
  const handleStopSpotlight = () => {
    void removeSpotlightMessage(message.id);
  };
  const label = isCurrentSpotlightedItem
    ? t("live_streams.room.qa.questions.stop_spotlight")
    : t("live_streams.room.qa.questions.remove_from_queue");

  return (
    <ActionButton
      variant="text-only"
      onClick={handleStopSpotlight}
      disabled={isRemovingSpotlightMessage}
    >
      {label}
    </ActionButton>
  );
};
