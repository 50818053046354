import { useState } from "react";
import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  CommunityMemberPicker,
  VARIANTS,
} from "@circle-react/components/shared/CommunityMemberPicker";
import { usePunditUserContext } from "@circle-react/contexts";
import { INITIAL_HOST_NUMBER } from "@circle-react/helpers/liveStreamRoomHelpers";
import { FormItem } from "../../../Common";
import { MembersPreview } from "../MembersPreview";
import { CohostsObserver } from "./CohostsObserver";

export const Cohosts = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const [isCohostLimitReached, setIsCohostLimitReached] = useState(false);
  const disableCommunityPickerSearch = isCohostLimitReached;
  const excludedMemberIds = [currentCommunityMember?.id];
  const cohostIds = useWatch({ name: "cohosts" });

  return (
    <>
      <FormItem name="cohosts" className="z-20 mb-6" hideLabel>
        <CommunityMemberPicker
          variant={VARIANTS.MODAL}
          countLabel={t("live_streams.form.cohosts_added_label")}
          selectionLabel={t("live_streams.form.cohosts_selection_label")}
          searchPlaceholder={t("live_streams.form.cohosts_placeholder")}
          disableSearch={disableCommunityPickerSearch}
          excludedMemberIds={excludedMemberIds}
          renderAfterSelection={
            <CohostsObserver
              setIsLimitReached={setIsCohostLimitReached}
              initialCohostQuantity={INITIAL_HOST_NUMBER}
              shouldDisplayDisabledSearchMessage={disableCommunityPickerSearch}
            />
          }
        />
      </FormItem>
      <MembersPreview memberIds={cohostIds} />
    </>
  );
};
