import { t } from "@/i18n-js/instance";
import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";
import { dateStringToTimeAgo } from "@/react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import type { Post } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

export interface CardViewItemLastRepliedOrPostedProps {
  post: Post;
}

export const CardViewItemLastRepliedOrPosted = ({
  post,
}: CardViewItemLastRepliedOrPostedProps) => {
  const { last_replied_or_posted, post_details_loaded: hasPostDetailsLoaded } =
    post;
  const isReply = last_replied_or_posted?.type === "replied";

  if (!hasPostDetailsLoaded)
    return <SkeletonLoader variant="row" lg almostFull />;

  if (!last_replied_or_posted) return null;

  const actionText = t(last_replied_or_posted.type);

  return (
    <ShowPageLink
      post={post}
      className="!text-default focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      shouldOpenPostModal
    >
      {isReply && (
        <Icon
          type="arrow-solid-bottom-right"
          useWithFillCurrentColor
          className="mr-1 !w-3.5"
          size={14}
        />
      )}
      <TippyV2
        interactive={false}
        content={getDetailedTimestamp(last_replied_or_posted.created_at)}
        className="inline-flex items-center gap-1"
      >
        <span className="meta-person-action text-sm">
          {`${last_replied_or_posted.user_name} ${actionText} `}
        </span>
        <span className="ago post--card__timestamp text-sm">
          {dateStringToTimeAgo(last_replied_or_posted.created_at)}
        </span>
      </TippyV2>
    </ShowPageLink>
  );
};
