import { t } from "@/i18n-js/instance";
import { Header as CustomizeHeader } from "@circle-react-shared/CustomizeUI";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = () => (
  <CustomizeHeader.Actions>
    <Typography.TitleSm weight="semibold">
      {t("live_streams.form.title_v3")}
    </Typography.TitleSm>
  </CustomizeHeader.Actions>
);
