import { useEffect, useRef, useState } from "react";
import { SCHEDULED_POST_DATE_RENDER_PORTAL_ID } from "@/react/components/QuickPostV2/Content";
import { useTailwindLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { useImagePostModalStore } from "../../store";
import { MainImage } from "./MainImage";
import { Thumbnails } from "./Thumbnails";

export const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const shouldRenderScheduledBarOnTop = !useTailwindLgScreenMediaQuery();
  const { uploadedImages, setIsScheduledBarPortalRendered } =
    useImagePostModalStore();
  const prevLengthRef = useRef(uploadedImages.length);
  const prevLength = prevLengthRef.current;
  const newLength = uploadedImages.length;

  // if the last image is deleted, move to the new last image.
  if (newLength > 0 && currentImageIndex > newLength - 1) {
    setCurrentImageIndex(uploadedImages.length - 1);
  } // If new images are added, update the currentImageIndex to the first new image
  else if (newLength > prevLength) {
    setCurrentImageIndex(prevLength);
  }

  // update the reference to the new length
  prevLengthRef.current = newLength;

  useEffect(() => {
    if (shouldRenderScheduledBarOnTop) {
      setIsScheduledBarPortalRendered(true);
    }
  }, [shouldRenderScheduledBarOnTop, setIsScheduledBarPortalRendered]);

  return (
    <div className="flex max-h-full w-full flex-col justify-center overflow-auto lg:h-full lg:w-3/4">
      {shouldRenderScheduledBarOnTop && (
        <div id={SCHEDULED_POST_DATE_RENDER_PORTAL_ID} />
      )}
      <div className="flex max-h-full min-h-[20rem] w-full flex-col overflow-auto p-6">
        <MainImage currentImageIndex={currentImageIndex} />
        {uploadedImages.length > 0 && (
          <Thumbnails
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
          />
        )}
      </div>
    </div>
  );
};
