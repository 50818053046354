import type { ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { IconProps, IconType } from "@circle-react-shared/Icon";

export interface ProfileInfoContainerProps {
  iconType?: IconType;
  iconProps?: Partial<IconProps>;
  info?: ReactNode;
  dataTestId?: string;
  className?: string;
  iconClassName?: string;
}

export const ProfileInfoContainer = ({
  iconType,
  iconProps = {},
  info,
  dataTestId,
  className = "",
  iconClassName = "",
}: ProfileInfoContainerProps) => {
  if (!info) return null;

  return (
    <div
      className={classNames(
        "profile-drawer__about__block whitespace-pre-line [word-break:break-word]",
        className,
      )}
      data-testid={dataTestId}
    >
      {iconType ? (
        <Icon
          useWithFillCurrentColor
          className={classNames("text-dark", iconClassName)}
          type={iconType}
          {...iconProps}
        />
      ) : null}{" "}
      <p>{info}</p>
    </div>
  );
};
