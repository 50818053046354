import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { useInvalidatePostsApiQueries } from "@/react/components/SettingsApp/Posts/hooks/usePostsApi";
import { useImagePostMutation } from "@/react/components/Spaces/ImageSpace/ImagePostModal/CreatePost/hooks/useImagePostMutation";
import { afterSaveRedirectPath } from "@/react/helpers/postHelpers";
import { REQUEST_KEY as POST_SHOW_KEY } from "@/react/hooks/posts/usePostResource";
import type { ImagePost } from "@/react/types";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { Actions } from "./Actions";
import { Carousel } from "./Carousel";
import { ImagePostForm } from "./ImagePostForm";
import { defaultValues } from "./helpers";

interface CreatePostProps {
  onClose: (skipDirtyValidation?: boolean) => void;
  onPostChange: (post: ImagePost) => void;
  shouldSkipRedirectAfterSubmit: boolean;
}

export const CreatePost = ({
  onClose,
  onPostChange,
  shouldSkipRedirectAfterSubmit,
}: CreatePostProps) => {
  const translationRoot = "image_space.post_modal.steps.create_post";
  const { invalidatePostsApiQueries } = useInvalidatePostsApiQueries();
  const { post } = useCurrentPostHelpers();
  const { data: space } = useCurrentSpaceContext();

  const history = useHistory();
  const queryClient = useQueryClient();
  const onSuccess = async (response: any) => {
    onPostChange(post);
    await queryClient.invalidateQueries(POST_SHOW_KEY);
    await invalidatePostsApiQueries();
    onClose(true);
    if (!shouldSkipRedirectAfterSubmit) {
      history.push(afterSaveRedirectPath({ post: response }));
    }
  };
  const { onSubmit } = useImagePostMutation({ space, post, onSuccess });
  return (
    <Modal isOpen onClose={onClose} contentPortalId="create-post-modal-portal">
      <Form
        onSubmit={onSubmit}
        defaultValues={defaultValues(post)}
        formClassName="fixed inset-0 z-[1060]"
      >
        <Modal.Overlay />
        <Modal.Content
          size="full"
          className="sm:!max-h-[836px] sm:!max-w-[1312px]"
        >
          <Modal.Header className="z-10 md:!py-4">
            <Typography.TitleSm weight="font-semibold">
              {t(`${translationRoot}.${post.id ? "edit" : "create"}_title`)}
            </Typography.TitleSm>
            <Modal.HeaderActions className="!gap-3">
              <Button
                type="button"
                variant="secondary"
                onClick={() => onClose(false)}
              >
                {t("cancel")}
              </Button>
              <Actions post={post} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body className="md:border-primary flex flex-wrap content-start !p-0 md:border-t">
            <Carousel />
            <ImagePostForm post={post} space={space} />
          </Modal.Body>
        </Modal.Content>
      </Form>
    </Modal>
  );
};
