import { forwardRef } from "react";
import classnames from "classnames";
import { truncate } from "lodash";
import type { Post } from "@/react/types/Post";
import { useCurrentPostContext } from "@circle-react/contexts";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ShowPageLink } from "./ShowPageLink";

export interface AuthorHeadlineProps {
  headline: string;
  post: Post;
}

export const AuthorHeadline = forwardRef<
  HTMLAnchorElement,
  AuthorHeadlineProps
>(({ headline, post }, pageLinkRef) => {
  const { community_member, created_at } = post;
  const isSmScreen = useSmScreenMediaQuery();
  const { usedIn } = useCurrentPostContext();
  const shouldShowInlineTimestampAndHeadline =
    isSmScreen || usedIn === "full-post-modal";
  const authorHeadline = shouldShowInlineTimestampAndHeadline
    ? truncate(headline, { length: 60 })
    : headline;

  if (!headline && !shouldShowInlineTimestampAndHeadline) return null;

  return (
    <div className="post__meta inline-flex flex-wrap items-center gap-x-2">
      <TippyV2
        content={headline}
        shouldUseSpanWrapper={false}
        interactive={false}
        disabled={!headline}
      >
        <button
          type="button"
          className={classnames(
            "author__credentials text-default focus-visible:outline-secondary focus-visible:text-dark text-left text-xs focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
            {
              "is-admin": !!community_member.is_admin,
            },
          )}
        >
          <span className="space-x-2">
            {headline && (
              <span
                className={classnames("overflow-anywhere", {
                  "md:line-clamp-1": usedIn !== "full-post-modal",
                })}
              >
                {authorHeadline}
              </span>
            )}
            {shouldShowInlineTimestampAndHeadline && !!headline && (
              <span className="bg-badge inline-block h-[0.15rem] w-[0.15rem] rounded-full align-middle" />
            )}
            {shouldShowInlineTimestampAndHeadline && (
              <span className="author__time">
                <ShowPageLink
                  post={post}
                  className="ago text-default hover:text-dark focus-visible:outline-secondary focus-visible:text-dark text-xs focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  forwardedRef={pageLinkRef}
                >
                  {dateStringToTimeAgo(created_at, {
                    format: dateToTimeAgoFormats.short,
                  })}
                </ShowPageLink>
              </span>
            )}
          </span>
        </button>
      </TippyV2>
    </div>
  );
});

AuthorHeadline.displayName = "AuthorHeadline";
