import { t } from "@/i18n-js/instance";
import { ContentBody } from "@/react/components/Chat/ChatWindowV2/MessageItem/ContentBody";
import { useRedirectToMessage } from "@/react/components/Chat/ChatWindowV2/MessageItem/hooks";
import { useRailbar } from "@/react/hooks/chatsV2";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export const MessageResultItem = ({ message }: { message: any }) => {
  const { sender, sent_at: sentAt } = message;
  const { day, time } = formattedMessageSentAt(sentAt);
  const { redirectToMessage } = useRedirectToMessage();
  const {
    toggleShowEmbeddedRailBar,
    shouldShowEmbeddedRailBar,
    closeThreadsRailbar,
  } = useRailbar();
  const isSmScreen = useSmOrMdOrLgScreenMediaQuery();

  const isToday = day === "Today";
  const getMessageTime = () => (isToday ? time : day);

  return (
    <button
      type="button"
      key={message.id}
      className="border-primary bg-primary hover:border-light w-full cursor-pointer rounded-xl border px-4 py-3 text-left"
      onClick={() => {
        if (shouldShowEmbeddedRailBar) {
          toggleShowEmbeddedRailBar();
        }
        if (isSmScreen) {
          closeThreadsRailbar();
        }
        redirectToMessage(message);
      }}
    >
      <div className="mb-px flex items-center gap-1.5">
        <div className="mb-1 flex items-center gap-1">
          <div className="mr-1 self-start">
            <UserImage src={sender.avatar_url} name={sender.name} size="6" />
          </div>
          <div
            className={classNames(
              "text-darkest truncate text-left text-sm font-semibold",
            )}
          >
            {sender?.name || ""}
          </div>
          <div className="text-timestamp mx-0.5 inline min-w-fit text-sm font-medium">
            {t("separator_dot")}
          </div>
          <div className="text-light inline min-w-fit text-sm font-normal">
            {getMessageTime()}
          </div>
        </div>
      </div>
      <ContentBody message={message} shouldShowPreviewBlocks={false} />
    </button>
  );
};
