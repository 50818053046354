import { usePunditUserContext } from "@circle-react/contexts";
import {
  ROOM_ACCESS_TYPES,
  ROOM_TYPES,
  ROOM_VIEW_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";

export const useDefaultValues = () => {
  const { currentCommunity } = usePunditUserContext();
  return {
    room_type: ROOM_TYPES.STREAM,
    view_type: ROOM_VIEW_TYPES.GRID,
    access_type: ROOM_ACCESS_TYPES.OPEN,
    recording_enabled: !!currentCommunity?.live_stream_recording_enabled,
    mute_on_join: false,
    hide_participants_list: false,
    limit_url_sharing: false,
  };
};
