import { Carousel } from "@/react/components/Spaces/ImageSpace/FeedView/Carousel";
import { isImagePost } from "@/react/helpers/postHelpers";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";

export const Body = () => {
  const { record: post, helpers } = useCurrentPostContext();

  const { body_trix_content: bodyTrixContent, tiptap_body: tiptapBody } = post;
  const isTiptapPost = !!helpers?.isTiptapPost;
  const isTrixPost = !!helpers?.isTrixPost;

  const { isPostBodyEmpty } = useCurrentPostHelpers();

  return (
    <>
      {!isPostBodyEmpty && (
        <SeeMoreLess seeMoreClassName="text-default hover:text-dark">
          <div className="profile-posts__post__body">
            {isTrixPost && bodyTrixContent && (
              <DisplayTrixContent content={bodyTrixContent} />
            )}
            {isTiptapPost && tiptapBody && (
              <TipTapBlockEditor
                readOnly
                value={tiptapBody.body}
                sgidToObjectMap={tiptapBody.sgids_to_object_map}
                inlineAttachments={tiptapBody.inline_attachments}
              />
            )}
          </div>
        </SeeMoreLess>
      )}
      {isImagePost(post) && <Carousel className="!py-2" />}
    </>
  );
};
