import {
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const refundCharge = ({ chargeProcessorId, params }) =>
  reactQueryPost(
    internalApi.admin.paywalls.charges.refunds({ chargeProcessorId }),
    params,
  );

const index = ({ filters, order, page, perPage, searchKey }) => {
  const searchKeyObject = searchKey
    ? {
        search_key: searchKey,
      }
    : {};

  return () =>
    reactQueryGet(
      internalApi.admin.paywalls.charges.index({
        params: {
          filters,
          page,
          per_page: perPage,
          order,
          ...searchKeyObject,
        },
      }),
    );
};

export const paywallChargesApi = {
  index,
  refundCharge,
};
