import type { KeyboardEvent } from "react";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";

interface Props {
  onChange: (value: string) => void;
  searchValue: string;
  onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClickCancel?: () => void;
  placeholder?: string;
  className?: string;
}

export const SearchInput = ({
  onChange,
  searchValue,
  onKeyPress,
  onClickCancel = noop,
  placeholder = t("messaging.actions.search_name"),
  className = "",
}: Props) => (
  <div
    className={classNames(
      "bg-primary isolate z-10 mx-2 flex w-full items-center justify-between gap-4",
    )}
  >
    <div
      className={classNames(
        "bg-secondary-dark active:border-very-dark focus-within:bg-primary focus-within:border-very-dark active:bg-primary hover:border-dark hover:focus-within:border-very-dark border-hover group flex h-8 w-full flex-1 items-center gap-2 overflow-hidden rounded-lg border p-3 marker:mr-0 hover:bg-transparent md:p-1",
        className,
      )}
    >
      <Icon
        type="16-search-v3"
        className="text-light group-focus-within:text-dark group-active:text-dark ml-2"
        size={16}
        useWithFillCurrentColor
      />
      <input
        className="text-darkest placeholder:text-light text-xs-plus w-full rounded bg-transparent font-medium outline-none placeholder:opacity-100 sm:rounded-none"
        placeholder={placeholder}
        data-name="v2-search"
        data-testid="v2-search"
        aria-autocomplete="list"
        value={searchValue}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
      {searchValue && (
        <Icon
          type="20-close"
          className="text-dark mr-2 !h-5 !w-5 cursor-pointer"
          size={20}
          useWithFillCurrentColor
          onClick={onClickCancel}
        />
      )}
    </div>
  </div>
);
