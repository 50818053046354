import { useCallback, useEffect, useRef, useState } from "react";
import { defer } from "lodash";
import { useFormContext } from "react-hook-form";
import { isValidTiptapBody } from "@circle-react/components/QuickPostV2/validation";

export const useEditorState = (isUsedInMinimalPostModal: boolean) => {
  const [isEditorExpanded, setIsEditorExpanded] = useState(false);
  const [editorKey, setEditorKey] = useState(0);

  const commentBoxRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLElement | null>(null);

  const formContext = useFormContext();
  const {
    watch,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = formContext;

  const tiptapBody = watch("tiptap_body");
  const isEditorEmpty = !isValidTiptapBody(tiptapBody);
  const isSubmitDisabled = isEditorEmpty || isSubmitting;

  const handleFocusChange = useCallback(
    (isFocused: boolean) => {
      if (!isUsedInMinimalPostModal) return;
      if (isFocused || !isEditorEmpty) {
        setIsEditorExpanded(true);
      } else {
        setIsEditorExpanded(false);
      }
    },
    [isUsedInMinimalPostModal, isEditorEmpty],
  );

  const handleEditorFocus = useCallback(() => {
    if (isUsedInMinimalPostModal) {
      setIsEditorExpanded(true);
    }
  }, [isUsedInMinimalPostModal]);

  useEffect(() => {
    if (!isEditorEmpty && isUsedInMinimalPostModal) {
      setIsEditorExpanded(true);
    }
  }, [isEditorEmpty, isUsedInMinimalPostModal]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (isUsedInMinimalPostModal) {
        setIsEditorExpanded(false);
      }
      defer(() => {
        reset();
        setEditorKey(key => key + 1);
      });
    }
  }, [isSubmitSuccessful, reset, isUsedInMinimalPostModal]);

  return {
    isEditorExpanded,
    setIsEditorExpanded,
    editorKey,
    commentBoxRef,
    toolbarRef,
    formContext,
    isSubmitting,
    isEditorEmpty,
    isSubmitDisabled,
    handleFocusChange,
    handleEditorFocus,
  };
};
