import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import {
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { chatRoomPath, internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useChatRoom = () => {
  const history = useHistory();
  const toast = useToast();

  const findChatRoom = async ({
    shouldNavigate = false,
    ...communityMembersData
  }) => {
    try {
      const { community_member_ids } = communityMembersData;
      const response = await reactQueryGet(
        internalApi.chatRooms.fetch({
          params: { community_member_ids },
        }),
      );
      const { uuid: currentChatRoomUuid } = response;
      shouldNavigate &&
        history.push(chatRoomPath({ uuid: currentChatRoomUuid }));
      return currentChatRoomUuid;
    } catch (err) {
      toast.error(t("something_went_wrong"));
      notifyBugsnag(err);
    }
  };

  const findOrCreateChatRoom = async ({
    shouldNavigate = false,
    ...communityMembersData
  }) => {
    try {
      const data = { chat_room: communityMembersData };
      const response = await reactQueryPost(internalApi.chatRooms.create(), {
        ...data,
      });
      const currentChatRoomUuid = response.chat_room.uuid;
      shouldNavigate &&
        history.push(chatRoomPath({ uuid: response.chat_room.uuid }));
      return currentChatRoomUuid;
    } catch (err) {
      toast.error(t("something_went_wrong"));
      notifyBugsnag(err);
    }
  };

  return { findChatRoom, findOrCreateChatRoom };
};
