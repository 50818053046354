import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { getTimeAgo } from "../utils";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const ReporterInfo = ({
  reporter,
  reported_at,
  reported_reason_type,
  profanity_filter_matches,
}: any) => {
  const reporterFullName = [reporter?.first_name, reporter?.last_name]
    .filter(n => n)
    .join(" ");

  return reporter ? (
    <div className="flex items-center gap-4">
      <UserImage name={reporterFullName} src={reporter.avatar_url} size="8" />
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <Typography.LabelSm weight="semibold">
            {reporterFullName}
          </Typography.LabelSm>
          <Typography.LabelSm color="text-dark">
            {t([i18nRoot, "reason_labels.reported_for"])}
          </Typography.LabelSm>
          <Typography.LabelSm
            color="text-dark"
            weight="semibold"
            data-testid="report-reason-type"
          >
            {t([i18nRoot, "report_reasons", reported_reason_type])}
          </Typography.LabelSm>
        </div>
        <Typography.LabelXs color="text-dark">
          {getTimeAgo(reported_at)}
        </Typography.LabelXs>
      </div>
    </div>
  ) : (
    <div className="flex items-center gap-4">
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-black">
        <Icon type="lightning" size={16} className="text-white" />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <Typography.LabelSm
            weight="semibold"
            data-testid="report-reason-type"
          >
            {t([i18nRoot, "automatically_flagged"])}
          </Typography.LabelSm>
          {profanity_filter_matches ? (
            <Typography.LabelSm color="text-dark">
              {t([i18nRoot, "reason_labels.profanity_filter"])}
            </Typography.LabelSm>
          ) : (
            <Typography.LabelSm color="text-dark">
              {t([i18nRoot, "reason_labels.for_approval"])}
            </Typography.LabelSm>
          )}
        </div>
        <Typography.LabelXs color="text-dark">
          {getTimeAgo(reported_at)}
        </Typography.LabelXs>
      </div>
    </div>
  );
};
