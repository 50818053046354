import { useCallback } from "react";
import { useQueryClient } from "react-query";

export const useInfiniteQueryHelpers = () => {
  const queryClient = useQueryClient();

  const findAndReplaceRecord = useCallback(
    (newRecord, queryKey) => {
      if (!queryKey) {
        throw new Error("queryKey is required");
      }
      return queryClient.setQueryData(queryKey, oldData => ({
        pages: oldData.pages.map(page => ({
          ...page,
          records: page.records.map(record =>
            record.id === newRecord.id ? newRecord : record,
          ),
        })),
        pageParams: oldData.pageParams,
      }));
    },
    [queryClient],
  );

  const removeRecord = useCallback(
    (idToRemove, queryKey) => {
      if (!queryKey) {
        throw new Error("queryKey is required");
      }

      return queryClient.setQueryData(queryKey, oldData => ({
        pages: oldData.pages.map(page => ({
          ...page,
          records: page.records.filter(record => record.id !== idToRemove),
        })),
        pageParams: oldData.pageParams,
      }));
    },
    [queryClient],
  );

  return {
    findAndReplaceRecord,
    removeRecord,
  };
};
