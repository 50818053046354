import { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import { isFunction } from "lodash";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { posts } from "@/react/helpers/urlHelpers";
import { isBasicPost } from "@circle-react/helpers/postHelpers";
import type { LineClampLevel } from "@circle-react-shared/uikit/SeeMoreLess";
import { SeeMoreLess } from "@circle-react-shared/uikit/SeeMoreLess";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { KnowledgeBaseFeedbackSection } from "../KnowledgeBaseFeedbackSection";
import { useNavigateToPost } from "../PostModal/hooks/useNavigateToPost";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";

export const MinimalTrixContent = () => {
  const { post, isTrixPost } = useCurrentPostHelpers();

  const {
    body_trix_content: bodyTrixContent,
    internal_custom_html: html,
    pinned_at_top_of_space: isPinned,
  } = post;
  const { navigateToPost } = useNavigateToPost();
  const location = useLocation();

  const { params, hasNextPage } = usePostNavigationContext();

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  let lines: LineClampLevel = 4;

  if (isPinned) {
    lines = 2;
  }

  const isKnowledgeBasePost =
    ["help", "help-en"].includes(post.community_slug) && isBasicPost(post);

  return (
    <div className="flex w-full flex-col items-start justify-start gap-2">
      <SeeMoreLess lines={lines} hideToggle>
        {isTrixPost && bodyTrixContent && (
          <DisplayTrixContent
            content={bodyTrixContent}
            contentParentPath={posts.show({
              slug: post.slug,
              spaceSlug: post.space_slug,
            })}
          />
        )}
        {html && <InnerHTML key="internal_custom_html" html={html} />}
        {isKnowledgeBasePost && <KnowledgeBaseFeedbackSection post={post} />}
      </SeeMoreLess>
      <button
        type="button"
        className="text-default hover:text-dark text-base"
        onClick={() => {
          navigateToPost({
            newPost: post,
            params,
            hasNextPage,
            prevLocation: location,
          });
        }}
      >
        {t("see_more")}
      </button>
    </div>
  );
};
