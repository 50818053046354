import { useRef } from "react";
import classnames from "classnames";
import type { UsedInOptions } from "@circle-react/contexts";
import type { GalleryImage } from "@circle-react/types/GalleryImage";
import { Image } from "./Image";
import { NavigationArrow } from "./NavigationArrow";
import { resolvePositionClassName } from "./helpers";
import { useContainerCalculatedHeight } from "./hooks/useContainerCalculatedHeight";
import type { ZoomLevels } from "./hooks/useZoom";

interface ImagesProps {
  images: GalleryImage[];
  currentImageIndex: number;
  onPrevImage: () => void;
  onNextImage: () => void;
  usedIn: UsedInOptions;
  shouldAnimate: boolean;
  zoomLevel: ZoomLevels;
  zoomPosition: [number, number];
  shouldHaveRoundedBottomCorners?: boolean;
}
export const Images = ({
  images,
  currentImageIndex,
  onPrevImage,
  onNextImage,
  usedIn,
  shouldAnimate,
  zoomLevel,
  zoomPosition,
  shouldHaveRoundedBottomCorners = false,
}: ImagesProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const galleryAspectRatio = images[0].width / images[0].height;

  const { containerRatio, containerCalculatedHeight, containerWidth } =
    useContainerCalculatedHeight(galleryAspectRatio, containerRef, usedIn);
  const positionClassName = resolvePositionClassName(
    galleryAspectRatio,
    containerRatio,
  );

  return (
    <div
      className={classnames(
        "group relative flex items-center overflow-hidden",
        {
          "lg:h-full": usedIn === "full-post-modal",
          "md:rounded-b-lg": shouldHaveRoundedBottomCorners,
        },
      )}
      style={{
        height:
          usedIn !== "full-post-modal" ? containerCalculatedHeight : undefined,
      }}
      ref={containerRef}
    >
      {images.map((image, index) => (
        <Image
          key={image.id}
          image={image}
          currentImageIndex={currentImageIndex}
          index={index}
          positionClassName={positionClassName}
          containerWidth={containerWidth}
          usedIn={usedIn}
          shouldAnimate={shouldAnimate}
          zoomLevel={zoomLevel}
          zoomPosition={zoomPosition}
        />
      ))}
      {currentImageIndex > 0 && (
        <NavigationArrow onClick={onPrevImage} direction="prev" />
      )}
      {currentImageIndex < images.length - 1 && (
        <NavigationArrow onClick={onNextImage} direction="next" />
      )}
    </div>
  );
};
