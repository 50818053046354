import {
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

interface CreateImagePostParams {
  spaceId: number;
  payload: Record<string, unknown>;
}

export const createImagePost = ({ spaceId, payload }: CreateImagePostParams) =>
  reactQueryPost(internalApi.imagePosts.create(spaceId), payload);

interface UpdateImagePostParams {
  spaceId: number;
  postSlug: string;
  payload: Record<string, unknown>;
}

export const updateImagePost = ({
  spaceId,
  postSlug,
  payload,
}: UpdateImagePostParams) =>
  reactQueryPut(internalApi.imagePosts.update(spaceId, postSlug), payload);

interface DuplicateImagePostParams {
  spaceId: number;
  postSlug: string;
  payload: {
    post: {
      space_id: number;
      send_notifications: boolean;
    };
  };
}

export const duplicateImagePost = ({
  spaceId,
  postSlug,
  payload,
}: DuplicateImagePostParams) =>
  reactQueryPut(internalApi.imagePosts.duplicate(spaceId, postSlug), payload);
