import { useFormContext } from "react-hook-form";
import { addSecondsToDate } from "@circle-react/helpers/dateTimeHelpers";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";

export const useEventSummary = event => {
  const {
    formState: { dirtyFields },
    watch,
  } = useFormContext();
  const { name, event_setting_attributes } = watch();
  const shouldCalculateEndsAt =
    dirtyFields?.event_setting_attributes?.duration_in_seconds ||
    dirtyFields?.event_setting_attributes?.starts_at;

  // Default data from loaded event
  const {
    event_setting_attributes: eventData,
    share_post_enabled: sharePostEnabled,
  } = event || {};

  const startsAt = event_setting_attributes?.starts_at || eventData?.starts_at;
  const endsAt = shouldCalculateEndsAt
    ? addSecondsToDate(startsAt, event_setting_attributes?.duration_in_seconds)
    : eventData?.ends_at;
  const timeZoneAbbreviations = eventData?.time_zone_abbreviations;
  const timeZone = eventData?.time_zone;
  const { dayOfMonth, shortMonthString } = dateComponents(startsAt, timeZone);

  return {
    name,
    startsAt,
    endsAt,
    timeZoneAbbreviations,
    timeZone,
    dayOfMonth,
    shortMonthString,
    sharePostEnabled,
  };
};
