import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { BadgeV2 } from "@circle-react/components/shared/uikit/BadgeV2";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { TruncateText } from "@circle-react-uikit/TruncateText";
import { Typography } from "@circle-react-uikit/Typography";
import { isPublished } from "../../utils";
import { Actions } from "./Actions";

export const Title = ({ event, isCreating, isEditMode, actionsProps }) => {
  const { title } = useCustomizeUIData();

  const badgeLabel = isPublished(event)
    ? t("events.badge_status.published")
    : t("events.badge_status.draft");

  const isSmScreen = useSmScreenMediaQuery();

  if (isSmScreen) {
    return (
      <div className="flex w-full flex-col gap-4 px-5 py-4">
        <div className="flex items-center justify-between">
          {isEditMode && <BadgeV2 label={badgeLabel} />}
          {isEditMode && (
            <Actions {...actionsProps} className="inline-flex lg:hidden" />
          )}
        </div>
        <Typography.TitleSm truncate weight="semibold">
          {title}
        </Typography.TitleSm>
      </div>
    );
  }

  return (
    <div className="flex w-full min-w-0 items-center justify-between gap-4">
      <TruncateText className="flex">
        {!isCreating && title && (
          <div className="flex items-center gap-2">
            <Typography.TitleSm truncate weight="semibold">
              {title}
            </Typography.TitleSm>
            <div className="flex items-center">
              {isEditMode && <BadgeV2 label={badgeLabel} />}
            </div>
          </div>
        )}
      </TruncateText>
      {isEditMode && <Actions {...actionsProps} className="lg:hidden" />}
    </div>
  );
};

Title.propTypes = {
  event: PropTypes.object,
  isCreating: PropTypes.bool,
  isEditMode: PropTypes.bool.isRequired,
  actionsProps: PropTypes.object,
};
