import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormItem } from "@circle-react/components/EventsV3/Modal/Common";
import {
  LOCATION_TYPES,
  TICKET_TYPES,
  isRecurring,
} from "@circle-react/components/EventsV3/Modal/utils";
import { hasEventStarted } from "@circle-react/components/EventsV3/Modal/utils";
import { Icon } from "@circle-react-shared/Icon";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { TicketContent } from "./TicketContent";

export const Ticket = ({ event }) => {
  const { watch } = useFormContext();

  const shouldTicketTypeDisabled = hasEventStarted(event) || isRecurring(event);
  const ticketType = watch("event_setting_attributes.ticket_type");
  const locationType = watch("event_setting_attributes.location_type");
  const shouldShowWarningMessage =
    ticketType == TICKET_TYPES.PAID &&
    [LOCATION_TYPES.LIVE_STREAM, LOCATION_TYPES.LIVE_ROOM].includes(
      locationType,
    );

  const ticketTypeOptions = [
    {
      label: t("events.create_modal.event_setting_attributes.free_event"),
      value: TICKET_TYPES.FREE,
    },
    {
      label: t("events.create_modal.event_setting_attributes.paid_event"),
      value: TICKET_TYPES.PAID,
    },
  ];

  return (
    <div className="my-6 md:mb-8 md:mt-16">
      <Typography.TitleMd>
        {t("events.create.form.event_ticket")}
      </Typography.TitleMd>
      <div className="flex items-stretch">
        <div className="w-full">
          <FormItem
            name="event_setting_attributes.ticket_type"
            placeholder={t(
              "events.create_modal.event_setting_attributes.ticket_type_placeholder",
            )}
            rules={{
              required: t(
                "events.create_modal.event_setting_attributes.ticket_type_required_error",
              ),
            }}
            translationRoot="events.create.form"
          >
            <Form.AnimatedSelect
              fullWidth
              options={ticketTypeOptions}
              dataTestId="event-ticket-type"
              disabled={shouldTicketTypeDisabled}
            />
          </FormItem>
        </div>
      </div>
      {shouldShowWarningMessage && (
        <div className="bg-tertiary my-2.5 flex h-14 w-full flex-none items-center justify-start gap-x-3 rounded-lg">
          <Icon type="24-info" size={24} className="ms-3" />
          <Typography.LabelSm color="bg-tertiary">
            {t(
              "events.create.form.event_setting_attributes.live_stream_room_setting_attributes.paid_event_warning_msg",
            )}
          </Typography.LabelSm>
        </div>
      )}
      <TicketContent event={event} />
    </div>
  );
};
