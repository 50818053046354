import type { Comment } from "@/react/types/Comment";
import { DisplayTiptapContent } from "@circle-react/components/CommentsV3/Comment/DisplayTiptapContent";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";

interface BodyProps {
  comment: Comment;
}

export const Body = ({ comment }: BodyProps) => {
  const { body_trix_content, editor, tiptap_body, updated_at } = comment;
  const isTiptap = editor === "tiptap";

  return (
    <div className="post__body text-base" data-testid="comment-body">
      <div
        key={updated_at}
        className="post__inside text-dark"
        data-testid="comment-body-inside"
      >
        <SeeMoreLess lines={8}>
          {isTiptap ? (
            <DisplayTiptapContent tiptapBody={tiptap_body} />
          ) : (
            <DisplayTrixContent content={body_trix_content} />
          )}
        </SeeMoreLess>
      </div>
    </div>
  );
};
