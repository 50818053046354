import { t } from "@/i18n-js/instance";
import { TiptapCommentBox } from "@circle-react/components/CommentsV3/CommentForm/TiptapCommentBox";
import { Form } from "@circle-react-shared/uikit/Form/Form";
import { TiptapEditor } from "@circle-react-uikit/TipTap";

export const CommentEditBox = ({ comment, updateComment }) => {
  const isPostFormat = comment?.tiptap_body?.format === "post";
  return (
    <div className="text-dark mb-1 whitespace-pre-line text-sm font-normal">
      {isPostFormat ? (
        <Form
          onSubmit={updateComment}
          defaultValues={{
            tiptap_body: comment.tiptap_body,
          }}
        >
          <TiptapCommentBox
            isEdit
            postId={comment.post_id}
            spaceId={comment.space_id}
            autoFocus
          />
        </Form>
      ) : (
        <TiptapEditor
          autofocus
          editable
          type="comment"
          hardBreakOnEnter
          submitOnEnter={false}
          submitOnModEnter
          rich_text_body={comment.tiptap_body}
          onSubmit={({ body, attachments }) =>
            updateComment({
              tiptap_body: { body, attachments },
            })
          }
          isUpdate
          placeholder={t("write_a_comment")}
          menubarProps={{
            submitButtonLabel: t("comments.create.actions.save"),
            submittingLabel: t("comments.create.actions.saving"),
          }}
        />
      )}
    </div>
  );
};
