import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Loader } from "@circle-react-uikit/Loader";
import { useImagePostModalStore } from "../../store";
import { EmptyImages } from "./EmptyImages";
import { ImageOptionsDropdown } from "./ImageOptionsDropDown";

interface MainImageProps {
  currentImageIndex: number;
}
export const MainImage = ({ currentImageIndex }: MainImageProps) => {
  const currentImageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [containerRatio, setContainerRatio] = useState<number>();
  const { uploadedImages, galleryAspectRatio, setGalleryAspectRatio } =
    useImagePostModalStore();
  const currentImage = uploadedImages[currentImageIndex];

  useEffect(() => {
    if (!galleryAspectRatio && currentImage) {
      const aspectRatio = currentImage.width / currentImage.height;
      setGalleryAspectRatio(aspectRatio);
    }
  }, [galleryAspectRatio, currentImage, setGalleryAspectRatio]);

  useEffect(() => {
    if (!isLoading && containerRef.current) {
      const { width: containerWidth, height: containerHeight } =
        containerRef.current.getBoundingClientRect();
      setContainerRatio(containerWidth / containerHeight);
    }
  }, [isLoading, containerRef]);

  if (!currentImage) return <EmptyImages />;

  let positionClassName;

  if (galleryAspectRatio && containerRatio) {
    if (galleryAspectRatio > containerRatio) {
      positionClassName = "w-full my-auto";
    } else if (galleryAspectRatio < containerRatio) {
      positionClassName = "h-full";
    } else {
      positionClassName = "h-full w-full";
    }
  }

  return (
    <div
      className="flex justify-center overflow-auto md:grow"
      ref={containerRef}
    >
      {isLoading && <Loader />}
      <div className={classnames("relative", positionClassName)}>
        <ImageOptionsDropdown currentImage={currentImage} />
        <img
          loading="eager"
          ref={currentImageRef}
          src={currentImage.url}
          alt={currentImage.filename}
          onLoad={() => setIsLoading(false)}
          className={classnames(
            "rounded-md",
            {
              hidden: isLoading,
            },
            positionClassName,
          )}
        />
      </div>
    </div>
  );
};
