import type { ReactNode } from "react";
import classNames from "classnames";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useBackendProps } from "@circle-react/providers";
import type { Post } from "@circle-react/types";

interface AuthorProfileWrapperProps {
  children: ReactNode;
  post: Post;
  className?: string;
}

export const AuthorProfileWrapper = ({
  children,
  post,
  className = "",
}: AuthorProfileWrapperProps) => {
  const { community_member } = post;
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid ?? "",
    params: { show_back_link: true },
    name: community_member.name,
  });

  if (isMinimalLayout) {
    return <div className={className}>{children}</div>;
  }

  return (
    <a
      {...profileLinkProps}
      className={classNames(
        "focus-visible:outline-secondary hover:text-dark inline-block focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        className,
      )}
      data-testid="post-avatar"
    >
      {children}
    </a>
  );
};
