/* eslint-disable max-lines
-- Disabled to set CI to fail on this issue on new files, PR #8526 */
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { defer, last, noop, sortBy, uniqBy } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { getVerticalHeightUnit } from "@/react/helpers/browserHelpers";
import { getSpaceNotificationDetail } from "@/react/helpers/spaceHelpers";
import { useSmOrMdScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useSpaceNotificationsState } from "@circle-react/contexts/sidebarNotificationsContext";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import {
  useBookmarkToggle,
  useCanSendMessage,
  useChatMessagesV2Api,
  useChatParticipantsApi,
  useUpdateMissingParticipants,
} from "@circle-react/hooks/chatsV2";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2/store";
import { useChatRoomContext } from "@circle-react/hooks/chatsV2/useChatRoomContext";
import { markChatRoomMessagesAsRead } from "@circle-react/hooks/chatsV2/useChatsV2Api";
import { useThreads } from "@circle-react/hooks/chatsV2/useThreads";
import { useHideHelpscout } from "@circle-react/hooks/useHideHelpscout";
import { useSpaceSidebarNotifications } from "@circle-react/hooks/useSpaceSidebarNotifications";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT } from "../../shared/uikit/Rail";
import { ChatBottomBanner } from "../ChatBottomBanner";
import { MessageItem } from "../ChatWindowV2/MessageItem";
import {
  appendLastMessageToChatMessages,
  calculateScrollHeight,
  findMissingParticipantIds,
  getMessageHashFromUrl,
  scrollToMessage,
} from "../ChatWindowV2/helpers";
import {
  ChatRoomBody,
  MessageIndicatorButton,
  NewConversation,
  RichTextMessageBox,
} from "../sharedV2";
import "../ChatWindowV2/overrides.scss";

export const SCROLL_WRAPPER_DEFAULT_HEIGHT = HEADER_HEIGHT + BOTTOM_NAV_HEIGHT;

export const EmbeddableMessagesContainer = ({
  chatRoom,
  displayDayChange = true,
  isChatPreview,
  isChatSpace,
  isInCourseSpace,
  chatUserSearchRef,
  isLiveStream,
  isDrawer,
  roles = {},
  messageBodyClassName,
}) => {
  const messageHashParamsId = getMessageHashFromUrl();
  const [messageHashId, setMessageHashId] = useState(messageHashParamsId);
  const [shouldAppendLastMessage, setShouldAppendLastMessage] = useState(false);
  const { openThreadsRailbarOnThreadsNotification } = useThreads();
  const { data: space } = useCurrentSpaceContext();
  const [isUnreadMessageLineDisabled, setIsUnreadMessageLineDisabled] =
    useState(false);

  const { refetchSpaceNotifications } = useSpaceSidebarNotifications([
    space?.id,
  ]);

  const { spaces: spaceNotifications } = useSpaceNotificationsState();
  const spaceNotificationDetail = getSpaceNotificationDetail(
    spaceNotifications,
    space?.id,
  );

  const history = useHistory();
  const [isLastPage, setIsLastPage] = useState(true);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const isSmOrMdScreen = useSmOrMdScreenMediaQuery();
  useHideHelpscout();
  const { chatParticipants: participants } = useChatParticipantsApi(
    chatRoom.uuid,
    { shouldDisableCache: true, per_page: 20 },
  );
  const { isMessaging } = useChatRoomContext();

  const {
    uuid,
    last_message,
    current_participant = {},
    unread_messages_count,
    pinned_message,
    first_unread_message_id: unreadMessageId,
  } = chatRoom;

  const prevDayRef = createRef(null);
  const {
    fetchChatMessagesFirstPage,
    adjustScroller,
    calculateScrollInfo,
    setUnreadMessageFlow,
    scrollerId: messagesContainerId,
  } = useChatMessagesV2Api();

  const {
    hasNextPage,
    chatRoomMessages = [],
    isLoading,
    setChatRoomMessages,
    setScrollInfo,
    isInitialPageLoaded,
    shouldShowLatestMessageBtn,
    setShouldShowLatestMessageBtn,
    setFirstMessageId,
    setHasPrevPage: setMessagesHasPrevPage,
  } = useChatRoomStore();

  const onBookmarkToggle = useBookmarkToggle(
    chatRoomMessages,
    setChatRoomMessages,
  );

  const isNewConversation = !isChatSpace && chatRoomMessages.length === 0;

  const { canSendMessage, canParticipantsMessage } =
    useCanSendMessage(chatRoom);

  const [scrollWrapperHeight, setScrollWrapperHeight] = useState();
  const footerRef = useRef(null);
  const isInitialPage = isLastPage && isFirstPage;

  useEffect(() => {
    setMessageHashId(messageHashParamsId);
  }, [messageHashParamsId]);

  useEffect(() => {
    fetchChatMessagesFirstPage(uuid, messageHashId || unreadMessageId);
    setIsFirstPage(true);
    setIsLastPage(true);
  }, [uuid, messageHashId, unreadMessageId]);

  useEffect(() => {
    if (messageHashId && isInitialPage && !isLoading) {
      scrollToMessage(messageHashId);
      openThreadsRailbarOnThreadsNotification({
        messageHashId,
        chatRoomMessages,
        chatRoom,
      });
    }
  }, [messageHashId, isLoading, isInitialPage]);

  useEffect(() => {
    const skipAdjustScroller =
      (isInitialPage && messageHashId) || !isInitialPageLoaded;

    if (!skipAdjustScroller) {
      defer(adjustScroller);
    }

    // Last message needs to be appended when the user sends message from notification landing page
    if (
      shouldAppendLastMessage &&
      !hasNextPage &&
      isChatSpace &&
      last_message?.id
    ) {
      setLatestMessagetoChatRoomMessages(() =>
        setShouldAppendLastMessage(false),
      );
    }
  }, [chatRoomMessages, messageHashId, isInitialPageLoaded]);

  const setLatestMessagetoChatRoomMessages = (onSuccess = noop) => {
    if (hasNextPage) return;
    const newChatRoomMessages = appendLastMessageToChatMessages({
      lastMessage: last_message,
      chatRoomMessages,
      currentParticipant: current_participant,
      setScrollInfo,
      calculateScrollInfo,
      isInitialPageLoaded,
      setChatRoomMessages,
      setFirstMessageId,
      setMessagesHasPrevPage,
    });

    if (newChatRoomMessages) {
      setChatRoomMessages(newChatRoomMessages);
      onSuccess();
    }
  };

  useEffect(() => {
    setLatestMessagetoChatRoomMessages();
  }, [last_message]);

  useEffect(() => {
    const calculateAndSetScrollHeight = () => {
      const height = calculateScrollHeight({
        searchInputClientHeight: chatUserSearchRef?.current?.clientHeight,
        footerClientHeight: footerRef?.current?.clientHeight,
        isSmOrMdScreen,
        isChatSpace,
        isLiveStream,
        isInCourseSpace,
        isDrawer,
      });
      setScrollWrapperHeight(height);
    };

    calculateAndSetScrollHeight();
    window.addEventListener("resize", calculateAndSetScrollHeight);
    return () =>
      window.removeEventListener("resize", calculateAndSetScrollHeight);
  }, [
    chatUserSearchRef,
    isNewConversation,
    canSendMessage,
    isLiveStream,
    isInCourseSpace,
    isSmOrMdScreen,
    footerRef,
    isChatSpace,
    isDrawer,
  ]);

  useEffect(() => {
    setIsUnreadMessageLineDisabled(
      !((isChatSpace || isMessaging) && canSendMessage),
    );
  }, [canSendMessage, isChatSpace, isMessaging]);

  const uniqChatRoomMessages = uniqBy(chatRoomMessages, "id");
  const orderedChatRoomMessages = sortBy(uniqChatRoomMessages, "sent_at");

  const showLatestMessageBtn =
    shouldShowLatestMessageBtn || (hasNextPage && isInitialPageLoaded);
  const showUnreadCountBtn =
    showLatestMessageBtn && messageHashId && unread_messages_count > 0;

  const missingParticipantIds = useMemo(
    () => findMissingParticipantIds(uniqChatRoomMessages, participants),
    [uniqChatRoomMessages, participants],
  );

  useUpdateMissingParticipants({
    uuid: chatRoom.uuid,
    ids: missingParticipantIds,
    enabled: Boolean(participants.length && missingParticipantIds.length),
  });

  const redirectAndMarkAsRead = async () => {
    if (isChatSpace && spaceNotificationDetail?.unread_content_count > 0) {
      const response = await markChatRoomMessagesAsRead(chatRoom.uuid);
      if (response) {
        refetchSpaceNotifications();
      }
    }
    if (messageHashId) {
      history.push();
    } else {
      fetchChatMessagesFirstPage(uuid);
    }
  };

  const heightUnit = getVerticalHeightUnit();

  return (
    <div
      id="scrollWrapper"
      className="flex grow flex-col overflow-y-auto border-r-0"
      data-testid="chat-room-wrapper"
      {...(scrollWrapperHeight && {
        style: {
          height: `calc(100${heightUnit} - ${scrollWrapperHeight}px)`,
        },
      })}
    >
      {showUnreadCountBtn && (
        <div className="relative top-2 z-50 m-auto h-0">
          <MessageIndicatorButton
            label={t("messaging.unread_messages", {
              unread_count: unread_messages_count,
            })}
            onClick={() => {
              setShouldShowLatestMessageBtn(false);
              redirectAndMarkAsRead();
            }}
          />
        </div>
      )}
      {!isInitialPageLoaded ? (
        <div id={messagesContainerId} className="overflow-hidden pt-0">
          <SkeletonLoader variant="chat-room" hideMessageBox />
        </div>
      ) : (
        <>
          {pinned_message && (
            <MessageItem
              message={pinned_message}
              currentParticipant={current_participant}
              isPinned
              roles={roles}
              chatRoom={chatRoom}
              chatThreadsEnabled={!isChatPreview}
              isLiveStream={isLiveStream}
              isInCourseSpace={isInCourseSpace}
              isPinnedMessageAtTop
              participants={participants}
              messageBodyClassName={messageBodyClassName}
              onBookmarkToggle={onBookmarkToggle}
              setUnreadMessageFlow={setUnreadMessageFlow}
              messagesContainerId={messagesContainerId}
            />
          )}
          <ChatRoomBody
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            setIsLastPage={setIsLastPage}
            setIsFirstPage={setIsFirstPage}
            chatRoomUuid={chatRoom.uuid}
            componentId={messagesContainerId}
          >
            {orderedChatRoomMessages.map(message => (
              <MessageItem
                key={message.id}
                message={message}
                displayDayChange={displayDayChange}
                ref={prevDayRef}
                currentParticipant={current_participant}
                isInitialPage={isInitialPage}
                isLastMessage={last(chatRoomMessages)?.id === message.id}
                messageHashId={messageHashId}
                roles={roles}
                isPinned={pinned_message?.id === message.id}
                disableUnreadMessageLine={isUnreadMessageLineDisabled}
                unreadMessageId={unreadMessageId}
                chatRoom={chatRoom}
                chatThreadsEnabled={!isChatPreview}
                isLiveStream={isLiveStream}
                participants={participants}
                messageBodyClassName={messageBodyClassName}
                onBookmarkToggle={onBookmarkToggle}
                setUnreadMessageFlow={setUnreadMessageFlow}
                isInCourseSpace={isInCourseSpace}
                messagesContainerId={messagesContainerId}
              />
            ))}
          </ChatRoomBody>
        </>
      )}
      {showLatestMessageBtn && (
        <div className="relative bottom-8 m-auto h-0">
          <MessageIndicatorButton
            label={t("messaging.latest_messages")}
            onClick={() => {
              setShouldShowLatestMessageBtn(false);
              redirectAndMarkAsRead();
            }}
          />
        </div>
      )}
      {canSendMessage ? (
        <div
          ref={footerRef}
          className={classNames(
            "bg-primary fixed z-[9] w-full md:static md:bottom-auto md:z-auto md:w-auto md:bg-auto",
            {
              "bottom-0": !isNewConversation,
              "bottom-16": isNewConversation || isChatSpace,
              "rounded-b-lg": isLiveStream,
            },
          )}
        >
          {!isLoading && isNewConversation && (
            <NewConversation
              isLiveStream={isLiveStream}
              isInCourseSpace={isInCourseSpace}
              isMessaging={isMessaging}
              chatRoomUuid={chatRoom.uuid}
            />
          )}
          <RichTextMessageBox
            spaceId={space?.id}
            chatRoom={chatRoom}
            onClick={() => {
              if (messageHashId || hasNextPage) {
                setShouldAppendLastMessage(true);
                redirectAndMarkAsRead();
              }
            }}
            isChatPreview={isChatPreview}
            isLiveStream={isLiveStream}
            isChatSpace={isChatSpace}
            isInCourseSpace={isInCourseSpace}
            isLoading={isChatSpace && !isInitialPageLoaded}
            participants={participants}
          />
        </div>
      ) : (
        <div
          className="fixed bottom-0 z-[9] h-24 w-full pb-1 pt-2 md:static md:z-auto md:w-auto"
          ref={footerRef}
        >
          <ChatBottomBanner>
            {canParticipantsMessage
              ? t("community_member.join_space_to_message")
              : t("community_member.messaging_disabled")}
          </ChatBottomBanner>
        </div>
      )}
    </div>
  );
};

EmbeddableMessagesContainer.propTypes = {
  chatRoom: PropTypes.object,
  mobileViewSection: PropTypes.func,
  displayDayChange: PropTypes.bool,
  isChatSpace: PropTypes.bool,
  isChatPreview: PropTypes.bool,
  chatUserSearchRef: PropTypes.node,
  isLiveStream: PropTypes.bool,
  isInCourseSpace: PropTypes.bool,
  isDrawer: PropTypes.bool,
  roles: PropTypes.object,
};
