import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";

export const OpenPostLink = ({ post, children }: any) => (
  <ShowPageLink
    post={post}
    className="focus-visible:outline-secondary peer inline-block focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
  >
    {children}
  </ShowPageLink>
);
