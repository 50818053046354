import { createContext, useContext } from "react";
import { noop } from "lodash";

const ProfileContext = createContext<{
  member: any;
  isLoading: boolean;
  isFullPageView?: boolean;
  onClose?: () => void;
}>({
  member: null,
  isLoading: true,
  isFullPageView: false,
  onClose: () => {},
});

ProfileContext.displayName = "ProfileContext";

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileContextProvider = ({
  member,
  isFullPageView = false,
  isLoading,
  children,
  onClose = noop,
}: {
  member: any;
  isLoading: boolean;
  children: any;
  isFullPageView?: boolean;
  onClose?: () => void;
}) => (
  <ProfileContext.Provider
    value={{ member, isLoading, isFullPageView, onClose }}
  >
    {children}
  </ProfileContext.Provider>
);
