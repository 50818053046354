import { useMutation, useQuery } from "react-query";
import { useSpacesContext } from "@circle-react/contexts";
import { useSpaceNotificationsDispatch } from "@circle-react/contexts/sidebarNotificationsContext";
import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useSpaceSidebarNotifications = (spaceIds = []) => {
  const dispatch = useSpaceNotificationsDispatch();

  const fetchRequest = () =>
    reactQueryGet(
      internalApi.sidebarNotifications.index({ space_ids: spaceIds }),
    );

  const { isLoading, refetch } = useQuery(
    ["space-sidebar-notifications", spaceIds],
    fetchRequest,
    {
      enabled: false,
      onSuccess: (spaces = []) => {
        if (spaces.length === 1) {
          dispatch({
            type: "UPDATE_SPACE_NOTIFICATIONS",
            payload: { space: spaces[0] },
          });
        } else {
          dispatch({
            type: "SET_SPACE_NOTIFICATIONS",
            payload: { spaces: spaces || [] },
          });
        }
      },
      onError: err => {
        console.error("Something went wrong", err);
      },
    },
  );

  return { refetchSpaceNotifications: refetch, isLoading };
};

export const useResetSpaceSidebarNotifications = spaceId => {
  const spacesContext = useSpacesContext();
  const { refetchSpaceNotifications } = useSpaceSidebarNotifications([spaceId]);

  const { mutateAsync: resetUnreadCount } = useMutation(
    () =>
      reactQueryPost(internalApi.spaces.resetUnreadCount({ spaceId }), {
        forReactQuery: true,
      }),
    {
      onSuccess: updatedSpace => {
        spacesContext.actions.updateSpace(updatedSpace);
        refetchSpaceNotifications();
      },
      onError: err => {
        console.error("Error while resetting the unread count", err);
      },
    },
  );

  return { resetUnreadCount };
};
