import { useMemo } from "react";
import { flatten } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { toLocaleString } from "@circle-react/helpers/number";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useInfiniteQueryWrapper } from "@circle-react/hooks/reactQuery/useInfiniteQueryWrapper";
import type { Post } from "@circle-react/types/Post";
import { Modal } from "@circle-react-uikit/ModalV2";
import { MemberRow } from "./MemberRow";

interface PostLikesModalProps {
  post: Post;
}

const PostLikesModalComponent = ({ post }: PostLikesModalProps) => {
  const modal = useModal();

  const { data, isLoading, fetchNextPage, hasNextPage, mapPages } =
    useInfiniteQueryWrapper(
      internalApi.posts.userLikesList({
        postId: String(post.id),
        params: {
          per_page: 15,
        },
      }),
      { enabled: !!post?.id },
      ["post-likes-modal", post?.id],
    );

  const likedMembers = useMemo(
    () => flatten(mapPages((page: any) => page.records)),
    [mapPages],
  );
  const totalLikesCount = data?.pages[0]?.count;

  return (
    <Modal
      title={t("like_modal_title")}
      isOpen={modal.visible}
      onClose={modal.hide}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            {totalLikesCount ? (
              <>
                {toLocaleString(totalLikesCount)}{" "}
                {totalLikesCount === 1 ? t("like") : t("likes")}
              </>
            ) : (
              t("likes")
            )}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pb-6 pt-4">
          {isLoading ? (
            <LoaderRow />
          ) : (
            <div
              id={`post-likes-modal-${post.id}`}
              // height would be 100vh - modal header height - padding of the modal body
              className="max-h-[calc(100dvh-62px-44px-24px)] overflow-auto sm:max-h-80"
            >
              <InfiniteScroll
                scrollThreshold={0.75}
                next={fetchNextPage}
                hasMore={hasNextPage || false}
                loader={<LoaderRow />}
                scrollableTarget={`post-likes-modal-${post.id}`}
                dataLength={likedMembers.length}
                className="flex flex-col space-y-4"
              >
                {likedMembers.map(likedMember => (
                  <MemberRow
                    key={likedMember.id}
                    member={likedMember}
                    hideModal={modal.hide}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const PostLikesModal = ModalManager.create(({ post }: PostLikesModalProps) => (
  <PostLikesModalComponent post={post} />
));

export const usePostLikesModal = () => useModal(PostLikesModal);
