import PropTypes from "prop-types";
import { SimpleSubmitButton } from "@/react/components/QuickPost/ModalForm/SubmitButton/SimpleSubmitButton";
import { isPublishedPost } from "@/react/helpers/postHelpers";
import { userIsAdminOrModeratorOfSpace } from "@/react/helpers/spaceHelpers";
import { useLargeScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { SchedulePostUI } from "./SchedulePostUI";

export const SubmitButton = ({
  post,
  space,
  submitDisabled,
  isSubmitting,
  datepickerPlacement,
}) => {
  const isMobile = useLargeScreenMediaQuery();
  const shouldShowActionSelector =
    !isMobile &&
    space &&
    userIsAdminOrModeratorOfSpace(space) &&
    !isPublishedPost(post);

  return (
    <>
      {shouldShowActionSelector ? (
        <SchedulePostUI
          submitDisabled={submitDisabled}
          isSubmitting={isSubmitting}
          datepickerPlacement={datepickerPlacement}
        />
      ) : (
        <SimpleSubmitButton
          post={post}
          submitDisabled={submitDisabled}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

SubmitButton.propTypes = {
  post: PropTypes.object.isRequired,
  space: PropTypes.object,
  submitDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  datepickerPlacement: PropTypes.string,
};
