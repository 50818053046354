import PropTypes from "prop-types";

export const Section = ({ title, children, dataTestId }) => (
  <div className="profile-drawer__about__section" data-testid={dataTestId}>
    {title && <div className="profile-drawer__about__heading">{title}</div>}
    {children}
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dataTestId: PropTypes.string,
};
