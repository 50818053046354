import type { RefObject } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { ShowPageLink } from "@circle-react/components/PostsV3/PostViewListItem/ShowPageLink";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import {
  getDetailedTimestamp,
  isImagePost,
} from "@circle-react/helpers/postHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { AuthorHeadline } from "./AuthorHeadline";
import { AuthorProfileWrapper } from "./AuthorProfileWrapper";
import { PostUserInfo } from "./PostUserInfo";
import { PostViewListItemWrapper } from "./PostViewListItemWrapper";

export interface PostMetaInfoRegularProps {
  communityMember: any;
  containerRef: RefObject<HTMLDivElement>;
  doesPostHaveCoverImage?: boolean;
  hasTags?: boolean;
  headline: any;
  hideAuthorHeadline?: boolean;
  isAdmin?: boolean;
  isSmScreen?: boolean;
  pageLinkRef: RefObject<HTMLAnchorElement>;
  post: any;
  posterJoinedDate: string;
  shouldDisplayMemberTags?: boolean;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  visibleMemberTags: any[];
  wrapperClassName?: string;
}

export const PostMetaInfoRegular = ({
  communityMember,
  containerRef,
  doesPostHaveCoverImage = false,
  hasTags = false,
  headline,
  hideAuthorHeadline = false,
  isAdmin = false,
  isSmScreen = false,
  pageLinkRef,
  post,
  posterJoinedDate,
  shouldDisplayMemberTags = false,
  tagsShowMoreLabelVariant,
  visibleMemberTags,
  wrapperClassName,
}: PostMetaInfoRegularProps) => {
  const hasHeadline = Boolean(headline);
  const hasJoinedDate = Boolean(posterJoinedDate);
  const hasAuthorHeadline = hasHeadline && !hideAuthorHeadline;
  const shouldShowJoinedDate = !hasAuthorHeadline && hasJoinedDate;

  return (
    <PostViewListItemWrapper
      doesPostHaveCoverImage={doesPostHaveCoverImage}
      ref={containerRef}
      className={wrapperClassName}
      data-testid="post-meta-info-regular"
    >
      <PostUserInfo post={post} />
      <button
        type="button"
        className="post__bio focus-visible:outline-secondary flex flex-col justify-between self-center text-left focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        onClick={event => {
          if (
            !(event.target instanceof HTMLAnchorElement) &&
            pageLinkRef.current
          ) {
            if (isIntendedOnNewTab(event)) {
              const url = pageLinkRef.current.href;
              window.open(url, "_blank", "noreferrer");
              event.preventDefault();
            } else {
              pageLinkRef.current.click();
            }
          }
        }}
      >
        <div
          className={classnames(
            "author flex flex-wrap items-baseline gap-x-2 gap-y-px",
            {
              "no-headline": !communityMember?.headline && post.name,
              "has-tags": hasTags,
            },
          )}
        >
          <div className="author__name inline-flex">
            <AuthorProfileWrapper
              post={post}
              className="!text-dark overflow-anywhere text-sm font-semibold leading-5"
            >
              {communityMember?.name}
            </AuthorProfileWrapper>
          </div>
          {shouldDisplayMemberTags && (
            <div className="author__tags member-tags self-center">
              <MemberTags
                memberTags={visibleMemberTags}
                isAdmin={isAdmin}
                limit={isSmScreen || isImagePost(post) ? 1 : 2}
                showMoreLabelVariant={tagsShowMoreLabelVariant}
              />
            </div>
          )}
          <div className="author__time inline-flex">
            <ShowPageLink
              post={post}
              className="ago text-default hover:text-dark focus-visible:outline-secondary focus-visible:text-dark flex items-baseline text-xs focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              forwardedRef={pageLinkRef}
            >
              <TippyV2
                interactive={false}
                content={getDetailedTimestamp(post.created_at)}
              >
                {dateStringToTimeAgo(post.created_at, {
                  format: dateToTimeAgoFormats.short,
                })}
              </TippyV2>
            </ShowPageLink>
          </div>
        </div>
        {!hideAuthorHeadline && (
          <AuthorHeadline headline={headline} post={post} />
        )}
        {shouldShowJoinedDate && (
          <div className="text-light flex text-xs">
            {t("post.member_since", { date: posterJoinedDate })}
          </div>
        )}
      </button>
    </PostViewListItemWrapper>
  );
};
