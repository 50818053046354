/* eslint-disable react-hooks/rules-of-hooks -- Added before disable comments where required */
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import I18n from "@/i18n-js/instance";
import { useEditEventModal } from "@circle-react/components/Modals/EditEventModal";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import {
  useCurrentSpaceContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { fetchRequest } from "@circle-react/helpers/backendRequestHelpers";
import { eventsShowPath, internalApi } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";
import { useToast } from "@circle-react-uikit/ToastV2";

const DISPLAY_EDIT_MODAL_DELAY_MILLISECONDS = 500;

export const DuplicateEventModal = ({ event, onClose, show }) => {
  const history = useHistory();
  const { success, error } = useToast();
  const {
    isLoading,
    helpers: { findById: findSpace, spacesWithEventCreationAllowed },
  } = useSpacesContext();
  if (isLoading) return null;
  const spaces = spacesWithEventCreationAllowed();
  const { data: space } = useCurrentSpaceContext();
  const editEventModal = useEditEventModal();
  const handleDuplicate = useMutation(
    data =>
      fetchRequest(
        internalApi.events.duplicate({
          spaceId: event.space_id,
          slug: event.slug,
        }),
        {
          method: "PUT",
          body: JSON.stringify({
            event: {
              event_id: event.id,
              space_id: data.space_id,
            },
          }),
          forReactQuery: true,
        },
      ),
    {
      onSuccess: event => {
        const eventSpace = findSpace(event.space_id);
        history.push(
          eventsShowPath({
            spaceSlug: eventSpace.slug,
            eventSlug: event.slug,
          }),
        );
        setTimeout(
          () => editEventModal.show({ event }),
          DISPLAY_EDIT_MODAL_DELAY_MILLISECONDS,
        );
        success(I18n.t("event_duplicated_successfully"));
      },
      onError: () => {
        error(I18n.t("event_duplication_failed"));
      },
    },
  );

  return (
    <Modal
      overflowVisible
      title={I18n.t("duplicate_event_title", { event_title: event.name })}
      show={show}
      onClose={onClose}
    >
      <Form
        onSubmit={handleDuplicate.mutate}
        defaultValues={{
          space_id: space?.id,
        }}
      >
        <Form.Item
          name="space_id"
          hideBorder
          fullWidth
          description={I18n.t("duplicate_event_to_space")}
          rules={{
            required: I18n.t("events.create.form.space_required_error"),
          }}
        >
          <SpaceSelect
            spaces={spaces}
            name="space_id"
            variant="form-input"
            defaultValue={space?.id}
          />
        </Form.Item>
        <p className="modal__small text-dark mb-4">
          {I18n.t("attendees_wont_be_duplicated")}
        </p>
        <Button type="submit" variant="primary">
          {I18n.t("duplicate")}
        </Button>
      </Form>
    </Modal>
  );
};

DuplicateEventModal.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  setDuplicatedPost: PropTypes.func,
};
