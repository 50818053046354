import { useState } from "react";
import { sortBy } from "lodash";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2/store";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { updateParentMessageOnNewMessage } from "./helper";
import { useMarkChatRoomAsRead } from "./useMarkChatRoomAsRead";
import { useScrollInfo } from "./useScrollInfo";

const MAX_MESSAGES_THRESHOLD = 60;
const MESSAGES_THRESHOLD = 40;

export const useChatMessagesV2Api = () => {
  const {
    chatRoomMessages,
    setChatRoomMessages,
    isLoading,
    setIsLoading,
    hasNextPage,
    hasPrevPage,
    setHasNextPage,
    setHasPrevPage,
    scrollInfo,
    setScrollInfo,
    firstMessageId,
    setFirstMessageId,
    lastMessageId,
    setLastMessageId,
    shouldShowLatestMessageBtn,
    setShouldShowLatestMessageBtn,
    topMessageId,
    setTopMessageId,
    isInitialPageLoaded,
    setIsInitialPageLoaded,
    resetChatRoom,
  } = useChatRoomStore();
  const [isUnreadMessageFlow, setUnreadMessageFlow] = useState(false);
  const { parentMessage, updateParentMessage } = useRailbar();
  const { markChatRoomAsRead } = useMarkChatRoomAsRead();
  const { calculateScrollInfo, getScrollingElement, scrollerId } =
    useScrollInfo();

  const fetchChatMessages = async ({
    id,
    previousPerPage = 20,
    nextPerPage = 0,
    chatRoomUuid,
    isInitialPage = false,
    parentMessageId,
    // eslint-disable-next-line sonarjs/cognitive-complexity -- Already existed before maximum complexity value was reduced
  }) => {
    const isScrolledDown = previousPerPage === 0;
    if (isScrolledDown && isLoading) {
      return;
    }
    setIsLoading(true);

    const params = {
      previous_per_page: previousPerPage,
      next_per_page: nextPerPage,
    };

    if (id) params["id"] = id;
    if (parentMessageId) params["parent_message_id"] = parentMessageId;

    const data = await reactQueryGet(
      internalApi.chatRoomMessages.index({
        uuid: chatRoomUuid,
        params: params,
      }),
    );
    if (isInitialPage) {
      setScrollInfo(calculateScrollInfo({ event: "chatRoomLoad" }));
      if (data.records) {
        setChatRoomMessages(data.records);
      }
      setHasNextPage(data.has_next_page);
      setHasPrevPage(data.has_previous_page);

      setFirstMessageId(data.first_id);
      setLastMessageId(data.last_id);
      setIsInitialPageLoaded(true);
    } else {
      if (isScrolledDown) {
        setScrollInfo(
          calculateScrollInfo({ event: "nextPaginatedMessageLoad" }),
        );
        setLastMessageId(data.last_id);
      } else {
        setScrollInfo(calculateScrollInfo({ event: "paginatedMessageLoad" }));
        setFirstMessageId(data.first_id);
      }

      // Memory Optimization
      let messages = [...chatRoomMessages];
      if (messages.length > MAX_MESSAGES_THRESHOLD) {
        if (isScrolledDown) {
          messages = messages.slice(0, MESSAGES_THRESHOLD);
          const sortedMessages = sortBy(messages, "sent_at");
          setFirstMessageId(sortedMessages[0].id);
          setHasPrevPage(true);
        } else {
          setTopMessageId(id);
          messages = messages.slice(0, MESSAGES_THRESHOLD);
          const sortedMessages = sortBy(messages, "sent_at");
          setLastMessageId(sortedMessages[messages.length - 1].id);
          setHasNextPage(true);
        }
      } else {
        setTopMessageId(null);
      }
      setChatRoomMessages([...data.records, ...messages]);

      hasNextPage && setHasNextPage(data.has_next_page);
      hasPrevPage && setHasPrevPage(data.has_previous_page);
    }

    const isLastPage = !data.has_next_page;
    isLastPage && !isUnreadMessageFlow && markChatRoomAsRead(chatRoomUuid);

    setIsLoading(false);
  };

  const fetchChatMessagesFirstPage = async (chatRoomUuid, messageId) => {
    try {
      resetChatRoom();
      let params = {
        chatRoomUuid: chatRoomUuid,
        isInitialPage: true,
      };
      if (messageId) {
        params = {
          ...params,
          id: messageId,
          previousPerPage: 20,
          nextPerPage: 20,
        };
      }
      await fetchChatMessages(params);
    } catch (err) {
      console.error(err);
    }
  };

  const adjustScroller = () => {
    const element = getScrollingElement();
    const unreadMessageBreakpoint = document.getElementById(
      "unread-message-breakpoint",
    );

    switch (scrollInfo.action) {
      case "scrollToBottom":
        element.scrollTop = element.scrollHeight;
        break;
      case "adjustTop":
        if (topMessageId) {
          const messageElement = document.getElementById(topMessageId);
          messageElement?.scrollIntoView();
        } else {
          element.scrollTop =
            element.scrollHeight -
            (scrollInfo.scrollHeightValue - scrollInfo.scrollTopValue);
        }
        break;
      case "scrollToUnreadMessageIndicator":
        if (unreadMessageBreakpoint) {
          unreadMessageBreakpoint.scrollIntoView({ behavior: "smooth" });
        } else {
          element.scrollTop = element.scrollHeight;
        }
        break;
    }
  };

  const onEventReceive = (eventData, chatMessages) => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(eventData, chatMessages);
        break;
      case "deletedMessage":
        onDeleteMessage(eventData, chatMessages);
        break;
      case "updatedMessage":
        onUpdateMessage(eventData, chatMessages);
        break;
    }
  };

  const onDeleteMessage = (eventData, chatMessages) => {
    const { id: messageId, parent_message: parentMessage } = eventData;
    if (parentMessage) {
      const updatedChatMessages = chatMessages.map(message => {
        if (message.id === parentMessage.id) {
          return parentMessage;
        }
        return message;
      });
      setChatRoomMessages(updatedChatMessages);
    } else {
      const updatedChatMessages = chatMessages.filter(
        message => message.id !== messageId,
      );
      setChatRoomMessages(updatedChatMessages);
    }
  };

  const onUpdateMessage = (eventData, chatMessages) => {
    setScrollInfo(calculateScrollInfo({ event: "messageUpdated" }));
    const updatedMessage = eventData.json_message;
    const updatedChatMessages = chatMessages.reduce((acc, message) => {
      if (message.id === updatedMessage.id) {
        acc.push({
          ...message,
          ...updatedMessage,
        });
      } else {
        acc.push(message);
      }
      return acc;
    }, []);
    setChatRoomMessages([...updatedChatMessages]);

    // Update parent message if it is in a reply
    if (updatedMessage.id === parentMessage?.id) {
      updateParentMessage(updatedMessage);
    }
  };

  const onNewMessage = (eventData, chatMessages = []) => {
    const latestChatRoomMessage = eventData.json_message;
    const { parent_message_id } = latestChatRoomMessage;

    if (!parent_message_id) return;

    setScrollInfo(calculateScrollInfo({ event: "parentMessageUpdated" }));
    updateParentMessageOnNewMessage({
      chatMessages,
      setChatRoomMessages,
      newMessage: latestChatRoomMessage,
    });
  };

  return {
    chatRoomMessages,
    setChatRoomMessages,
    messagesHasNextPage: hasNextPage,
    messagesHasPrevPage: hasPrevPage,
    setScrollInfo,
    fetchChatMessages,
    fetchChatMessagesFirstPage,
    calculateScrollInfo,
    adjustScroller,
    isLoading,
    firstMessageId,
    lastMessageId,
    onEventReceive,
    isInitialPageLoaded,
    shouldShowLatestMessageBtn,
    setShouldShowLatestMessageBtn,
    setFirstMessageId,
    setMessagesHasPrevPage: setHasPrevPage,
    setUnreadMessageFlow,
    scrollerId,
  };
};
