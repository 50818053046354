import { Controller, useFormContext } from "react-hook-form";
import type { TopicsPickerProps } from "@circle-react/components/QuickPostV2/Content/TopicsPicker";
import { TopicsPicker } from "@circle-react/components/QuickPostV2/Content/TopicsPicker";

interface FormTopicsPickerProps extends TopicsPickerProps {
  name: string;
  rules?: any;
}

export const FormTopicsPicker = ({
  name,
  rules,
  ...rest
}: FormTopicsPickerProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <TopicsPicker value={field.value} onChange={field.onChange} {...rest} />
      )}
    />
  );
};
