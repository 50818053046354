import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { chatRoomApi } from "@/react/api/chatRoomApi";
import { useToast } from "@circle-react-uikit/ToastV2";

interface UseChatMessageSpotlightActionsProps {
  chatUuid: string;
}

interface UseChatMessageSpotlightActionsReturn {
  spotlightMessage: (messageId: number) => Promise<any>;
  isSpotlightingMessage: boolean;
  removeSpotlightMessage: (messageId: number) => Promise<any>;
  isRemovingSpotlightMessage: boolean;
}

export const useChatMessageSpotlightActions = ({
  chatUuid,
}: UseChatMessageSpotlightActionsProps): UseChatMessageSpotlightActionsReturn => {
  const toastr = useToast();

  const { mutateAsync: spotlightMessage, isLoading: isSpotlightingMessage } =
    useMutation<any, unknown, number>(
      messageId => chatRoomApi.spotlightMessage(chatUuid, messageId),
      {
        onSuccess: () => {
          toastr.success(t("messaging.actions.spotlight_success"));
        },
        onError: () => {
          toastr.error(t("messaging.actions.spotlight_error"));
        },
      },
    );

  const {
    mutateAsync: removeSpotlightMessage,
    isLoading: isRemovingSpotlightMessage,
  } = useMutation<any, unknown, number>(
    messageId => chatRoomApi.removeSpotlightMessage(chatUuid, messageId),
    {
      onError: () => {
        toastr.error(t("messaging.actions.remove_spotlight_error"));
      },
    },
  );

  return {
    spotlightMessage,
    isSpotlightingMessage,
    removeSpotlightMessage,
    isRemovingSpotlightMessage,
  };
};
