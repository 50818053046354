import InnerHTML from "dangerously-set-html-content";
import { t } from "@/i18n-js/instance";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

interface CustomCommentsBanner {
  lockedPostCtaContent: any;
  containerClassNames: string;
}

export const CustomCommentsBanner = ({
  lockedPostCtaContent,
  containerClassNames,
}: CustomCommentsBanner) => (
  <div className={containerClassNames}>
    <div>
      <Typography.TitleSm weight="semibold">
        {lockedPostCtaContent.heading}
      </Typography.TitleSm>
    </div>
    <div className="mt-4">
      {lockedPostCtaContent.body && (
        <Typography.BodyMd>
          <InnerHTML html={lockedPostCtaContent.body} />
        </Typography.BodyMd>
      )}
    </div>
    <div className="mt-6">
      <Button
        onClick={() => (window.location.href = lockedPostCtaContent.buttonLink)}
      >
        {lockedPostCtaContent.buttonLabel}
      </Button>
    </div>
    <div className="already-member mt-4">
      <Typography.LabelSm>{t("already_a_member")}</Typography.LabelSm>
      <a
        className="signup-link text-link ml-1 text-sm font-medium"
        href={newSessionPath({
          params: {
            post_login_redirect: window.location.href,
          },
        })}
      >
        {t("log_in")}
      </a>
    </div>
  </div>
);
