import { useEffect } from "react";
import { t } from "@/i18n-js/instance";
import { SCHEDULED_POST_DATE_RENDER_PORTAL_ID } from "@/react/components/QuickPostV2/Content";
import { useImagePostModalStore } from "@/react/components/Spaces/ImageSpace/ImagePostModal/store";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { useTailwindLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { FormTopicsPicker } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/FormTopicsPicker";
import { Form } from "@circle-react-shared/uikit/Form";
import { CaptionEditor } from "./CaptionEditor";
import { Options } from "./Options";

export const ImagePostForm = ({ post, space }: any) => {
  const canCreateTopics = space && canManageSpace(space);
  const { setIsScheduledBarPortalRendered } = useImagePostModalStore();
  const shouldRenderScheduledBarOnTop = useTailwindLgScreenMediaQuery();

  useEffect(() => {
    if (shouldRenderScheduledBarOnTop) {
      setIsScheduledBarPortalRendered(true);
    }
  }, [shouldRenderScheduledBarOnTop, setIsScheduledBarPortalRendered]);

  return (
    <div className="border-primary w-full lg:h-full lg:w-1/4 lg:overflow-scroll lg:border-l">
      {shouldRenderScheduledBarOnTop && (
        <div id={SCHEDULED_POST_DATE_RENDER_PORTAL_ID} />
      )}
      <CaptionEditor space={space} post={post} />
      {(space.topics_count > 0 || canCreateTopics) && (
        <div className="border-primary border-b px-6 pb-2">
          <Form.Item
            name="topics"
            hideDescription
            hideBorder
            label={t("image_space.post_modal.steps.create_post.topics_label")}
            labelClassName="!mb-3"
          >
            <FormTopicsPicker
              containerClassName="!p-0 -mx-1"
              name="topics"
              spaceId={space.id}
              creatable={canCreateTopics}
            />
          </Form.Item>
        </div>
      )}
      {canManageSpace(space) && <Options space={space} />}
    </div>
  );
};
