import { useDropzone } from "react-dropzone";
import { t } from "@/i18n-js/instance";
import { useToast } from "@circle-react-uikit/ToastV2";

export const MAX_IMAGES = 10;

export const useMultiImageUpload = ({
  handleFileSelection,
  selectedFiles,
  uploadedFilesCount,
}: any) => {
  const toast = useToast();
  const errorBase = "image_space.post_modal.steps.upload_images.errors";
  const maxImages = MAX_IMAGES - uploadedFilesCount;

  const handleFileRejection = (fileRejections: any) => {
    const errors = fileRejections.reduce((acc: any, curr: any) => {
      const error = curr.errors[0].code;
      if (!acc.includes(error)) {
        acc.push(error);
      }
      return acc;
    }, []);

    errors.forEach((error: any) => {
      const options = { shouldShowCloseButton: true, shouldUseProgress: false };
      // We use "success" for the toast just to match the figma design ...
      switch (error) {
        case "file-invalid-type":
          return toast.error(t(`${errorBase}.file_invalid_type`), options);
        case "file-too-large":
          return toast.error(t(`${errorBase}.file_too_large`), options);
        case "too-many-files":
          return toast.error(
            t(`${errorBase}.too_many_files`, { max_images: MAX_IMAGES }),
            options,
          );
        default:
          return toast.error(t(`${errorBase}.something_went_wrong`), options);
      }
    });
  };

  const handleDrop = (acceptedFiles: any, fileRejections: any) => {
    if (fileRejections.length > 0) {
      handleFileRejection(fileRejections);
    } else {
      if (
        parseInt(selectedFiles.length) + parseInt(acceptedFiles.length) >
        maxImages
      ) {
        handleFileRejection([{ errors: [{ code: "too-many-files" }] }]);
      } else {
        handleFileSelection(acceptedFiles);
      }
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      onDrop: handleDrop,
      noClick: true,
      accept: {
        "image/*": [".jpg", ".jpeg", ".png"],
      },
      maxFiles: maxImages,
      maxSize: 20_000_000,
      multiple: true,
    });

  const isDragActive = isDragAccept || isDragReject;

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  };
};
