import { useState } from "react";
import { noop, toLower } from "lodash";
import { t } from "@/i18n-js/instance";
import { SearchInput } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/SearchInput";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { useDebouncedValue } from "@/react/hooks/utils/useDebouncedValue";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useRailbar } from "@circle-react/hooks/chatsV2";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Loader } from "@circle-react-uikit/Loader";
import { Rail } from "@circle-react-uikit/Rail";
import { EmptyState } from "./EmptyState";
import { MessageSearchResults } from "./MessageSearchResults";
import { useMessageSearch } from "./useMessageSearch";

export const ChatRoomMessageSearch = () => {
  const { data: currentSpace } = useCurrentSpaceContext();
  const { isDmsPage, closeThreadsRailbar } = useRailbar();
  const isSmScreen = useSmOrMdOrLgScreenMediaQuery();
  const spaceName = currentSpace ? currentSpace.name : "DMs";
  const [searchValue, setSearchValue] = useState("");
  const { ref: headerRef, height: headerHeight } = useElementHeight();
  const debouncedValue = useDebouncedValue(searchValue, 300);
  const { messages, isLoading, fetchNextPage, hasNextPage } = useMessageSearch({
    debouncedValue,
    isDmsPage,
    currentSpace,
  });

  const isSearched = !!debouncedValue;
  return (
    <RailBarPortal>
      <div ref={headerRef}>
        <Rail.Header
          className={classNames(isSmScreen ? "!px-4" : "!px-2", {
            "h-[56px]": isDmsPage,
          })}
        >
          {isSmScreen && (
            <IconButton
              name="16-arrow-left"
              onClick={closeThreadsRailbar}
              className="flex-none"
              ariaLabel={t("close")}
            />
          )}
          <SearchInput
            onChange={setSearchValue}
            searchValue={searchValue}
            onKeyPress={noop}
            onClickCancel={() => setSearchValue("")}
            placeholder={t("chat_space.search_messages.placeholder", {
              spaceName: toLower(spaceName),
            })}
            className={classNames("!bg-primary !border-dark rounded-full", {
              "!h-9": !isDmsPage,
            })}
          />
        </Rail.Header>
      </div>
      <Rail.Content
        noPadding
        heightGap={headerHeight}
        className="bg-secondary-dark"
        id="scrollableMessageSearch"
      >
        {!isSearched ? (
          <EmptyState name={spaceName} />
        ) : isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loader />
          </div>
        ) : (
          <MessageSearchResults
            messages={messages}
            fetchNextPage={fetchNextPage}
            hasNextPage={Boolean(hasNextPage)}
          />
        )}
      </Rail.Content>
    </RailBarPortal>
  );
};
