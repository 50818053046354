import classNames from "classnames";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { ShowPageLink } from "@circle-react/components/PostsV3/PostViewListItem/ShowPageLink";
import { Icon } from "@circle-react/components/shared/Icon";
import {
  useCurrentPostContext,
  useIsOrCanBeCommunityMember,
  usePunditUserContext,
} from "@circle-react/contexts";
import { toLocaleString } from "@circle-react/helpers/number";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

export const EngagementActions = ({ shouldOpenInNewTab }) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { hide: closeMemberProfileDrawer } = useProfileDrawer();
  const { record: post, onLikeToggle } = useCurrentPostContext();

  const {
    comments_count,
    is_comments_enabled,
    is_liked_by_current_user,
    is_liking_enabled: isLikingEnabled,
    user_likes_count,
    post_details_loaded,
  } = post;

  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const isLikingAndCommentsEnabled = is_comments_enabled || isLikingEnabled;

  if (!isLikingAndCommentsEnabled) return null;

  return (
    <div
      className={classNames("profile-posts__post__actions flex items-center", {
        "justify-between": isLikingEnabled,
        "justify-end": !isLikingEnabled,
      })}
    >
      {isLikingEnabled && (
        <TippyV2
          interactive={false}
          content={
            is_liked_by_current_user
              ? t("profile_tabs.post.unlike_description")
              : t("profile_tabs.post.like_description")
          }
          placement="bottom"
          disabled={!isOrCanBeCommunityMember || !post_details_loaded}
        >
          <button
            type="button"
            onClick={isViewOnlyMasquerading ? noop : onLikeToggle}
            className={classNames(
              "profile-posts__post__like focus-visible:outline-secondary hover:bg-tertiary active:text-default flex space-x-1 rounded-md p-1 text-sm focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
              {
                "text-default focus:text-default": !is_liked_by_current_user,
                "liked text-brand focus:text-brand": is_liked_by_current_user,
                disabled: !isOrCanBeCommunityMember || !post_details_loaded,
              },
            )}
          >
            {post_details_loaded ? (
              <>
                <Icon type="like" />
                <span className="profile-posts__post__like-count">
                  {toLocaleString(user_likes_count)}
                </span>
              </>
            ) : (
              <SkeletonLoader variant="row" lg short />
            )}
          </button>
        </TippyV2>
      )}
      {is_comments_enabled && (
        <TippyV2
          interactive={false}
          content={t("profile_tabs.post.comment_description")}
          placement="bottom"
          disabled={!post_details_loaded}
        >
          <ShowPageLink
            post={post}
            allComments
            className="profile-posts__post__comments-count text-default focus-visible:outline-secondary focus-visible:text-dark hover:text-dark inline-block rounded-md p-1 text-sm focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={closeMemberProfileDrawer}
            target={shouldOpenInNewTab && "_blank"}
          >
            {post_details_loaded &&
              t("post.comments", {
                count: comments_count,
                formatted_count: toLocaleString(comments_count),
              })}
            {!post_details_loaded && <SkeletonLoader variant="row" lg long />}
          </ShowPageLink>
        </TippyV2>
      )}
    </div>
  );
};
