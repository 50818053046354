import { usePostResource } from "@/react/hooks/posts/usePostResource";
import type { ImagePost, Post } from "@/react/types";

export interface NewPost {
  id: null;
  gallery: null;
}

export const usePost = (
  currentPost: ImagePost | Post | undefined,
): ImagePost | NewPost | null => {
  // in certain cases (like in settingsApp Posts crud), we send a minimal
  // post object to the modal and then we fetch the full post object from the API
  // We set the post to null while loading, so the useInitializeStore effect fires
  // after the post is fetched.
  const {
    post: fetchedImagePost,
    isLoading,
  }: { post: ImagePost; isLoading: boolean } = usePostResource({
    spaceId: currentPost?.space_id,
    postSlug: currentPost?.slug,
    disabled: currentPost && "gallery" in currentPost,
  });

  return isLoading
    ? null
    : fetchedImagePost || currentPost || { id: null, gallery: null };
};
