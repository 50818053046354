import PropTypes from "prop-types";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export const ChatBottomBanner = ({ children, className = "", ...props }) => (
  <div
    {...props}
    className={classNames(
      "bg-tertiary text-dark mx-6 mb-3 rounded-lg p-4 text-center text-sm",
      className,
    )}
  >
    {children}
  </div>
);

ChatBottomBanner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
